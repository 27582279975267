import { User } from './../../dtos/user';
import { UserService } from './../../services/felixApi/user.service';
import { NotificationService } from './../../services/notification.service';
import { TaskService } from './../../services/felixApi/task.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-reassign-job-tasks',
  templateUrl: './reassign-job-tasks.component.html',
  styleUrls: ['./reassign-job-tasks.component.scss']
})
export class ReassignJobTasksComponent implements OnInit {
  subscriptions: Subscription[] = [];
  deleteInProgress: boolean;
  changeStartedTasks = true;
  officeUsers: User[];
  previousUserId = null;
  newUserId = null;
  reassignRoles = false;

  constructor(private _activeModal: NgbActiveModal,
    private taskService: TaskService,
    private notiService: NotificationService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.officeUsers = this.userService.officeUsers;
  }

  close() {
    this._activeModal.dismiss();
  }

  runChange() {
    this.deleteInProgress = true;

    this.subscriptions.push(
      this.taskService.reassignAllJobTasks(this.changeStartedTasks, this.previousUserId, this.newUserId, this.reassignRoles)
        .subscribe({
          next: () => {
            this.deleteInProgress = false;
            this._activeModal.close();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.deleteInProgress = false;
          }
        })
    );
  }
}

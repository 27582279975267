<div class="modal-header">
  <h4 class="modal-title">Field Sections</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <div class="page">

    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <dx-data-grid id="dataGrid" class="m-auto taskgrid-height" [dataSource]="dataSource" [allowColumnReordering]="true"
      [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
      (onInitNewRow)="onInitNewRow($event)" (onExporting)="gridService.onExporting($event, 'FieldGroups')">

      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
      <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
        refreshMode="full">
      </dxo-editing>
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-row-dragging [allowReordering]="true" [onReorder]="onReorder"></dxo-row-dragging>
      <dxo-sorting mode="none"></dxo-sorting>

      <dxi-column dataField="id" dataType="number" [visible]='false'>
      </dxi-column>

      <dxi-column dataField="description" caption="Section Name">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="isShownInJobDetails" caption="Shown in Job Details" dataType='boolean' width='130'>
      </dxi-column>

      <dxi-column dataField="isTrackingFieldAdminOnly" caption="Requires 'Confidential Tracking Fields' permission" dataType='boolean' width='130'>
      </dxi-column>



      <dxi-column dataField="isActive" caption="Active" dataType='boolean' width='100'>
      </dxi-column>

    </dx-data-grid>

  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" (click)="close()">Close</button>
</div>

import { GlobalService } from './../../../services/global.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MaintenanceService } from '../../../services/felixApi/maintenance.service';
import { NotificationService } from '../../../services/notification.service';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';
import { UserService } from '../../../services/felixApi/user.service';
import { TemplateTask } from '../../../dtos/templateTask';
import { TaskType } from '../../../dtos/task-type';
import { GridService } from '../../../services/grid.service';

@Component({
  selector: 'js-template-days-modal',
  templateUrl: './template-days-modal.component.html',
  styleUrls: ['./template-days-modal.component.scss']
})
export class TemplateDaysModalComponent implements OnInit {
  @Input() templateDaysHeaderId: number;

  loading = true;
  dataSource: CustomStore;
  subscriptions: Subscription[] = [];
  users: CustomStore;
  taskHeaderList: CustomStore;
  allTasks: TemplateTask[];
  taskTypes: TaskType[];
  taskListFiltered: TemplateTask[];
  gridHeight: number;

  constructor(
    private activeModal: NgbActiveModal,
    private globalService: GlobalService,
    protected gridService: GridService,
    private maintenanceService: MaintenanceService,
    private userService: UserService,
    private notiService: NotificationService) {
    this.getTemplateDaysList = this.getTemplateDaysList.bind(this);
    this.getTaskList = this.getTaskList.bind(this);
  }

  ngOnInit() {
    this.getData();
    this.gridHeight = window.innerHeight - 280;
  }

  getData() {
    this.subscriptions.push(
      this.maintenanceService.getTemplateDaysData()
        .subscribe({
          next: (tasks) => {
            this.allTasks = tasks;
            this.taskTypes = this.maintenanceService.taskTypes;

            this.taskHeaderList = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.maintenanceService.taskHeaders
            });

            this.users = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.userService.users
            });

            this.loading = false;
            this.setUpDataSet();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  setUpDataSet() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.getTemplateDays().subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.addTemplateDay(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.updateTemplateDay(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.deleteTemplateDay(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  setCellValue(rowData, value) {
    rowData.templateDaysHeaderId = null;
    rowData.taskTypeId = null;
    rowData.templateTaskId = null;
    (<any>this).defaultSetCellValue(rowData, value);
  }

  getTemplateDaysList(options) {
    return {
      store: this.maintenanceService.templateDaysHeaders,
      filter: options.data ? ['templateTaskHeaderId', '=', options.data.templateTaskHeaderId] : null
    };
  }

  getTaskList(options) {
    if (options.data) {
      this.taskListFiltered = this.allTasks.filter(i => i.templateTaskHeaderId === options.data.templateTaskHeaderId);
    } else {
      this.taskListFiltered = this.allTasks;
    }
    return {
      store: this.taskListFiltered,
      filter: options.data ? ['taskTypeId', '=', options.data.taskTypeId] : null
    };
  }

  setTaskTypeCellValue(rowData, value) {
    rowData.templateTaskId = null;
    (<any>this).defaultSetCellValue(rowData, value);
  }

  close() {
    this.activeModal.close();
  }

}

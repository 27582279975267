export const APP_NAME = 'TruthEngineTracking';
export const APP_ID = 5;

export const MAJOR_VERSION = '2.12';
export const PATCH_VERSION = '.6p'; // not stored in DB or checked in refresh guard

/// B2C / MSAL ///
export const B2C_AUTHORITY = 'https://truthengine.b2clogin.com/truthengine.onmicrosoft.com/B2C_1_signup-signin-prod';
export const B2C_AUTHORITY_DEV = 'https://truthengine.b2clogin.com/truthengine.onmicrosoft.com/B2C_1_signup-signin-dev';
export const B2C_AUTHORITY_DOMAIN = 'truthengine.b2clogin.com';

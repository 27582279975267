<div class="page">

  <js-job-number (refreshJobNumber)='onJobNumberChanged($event)'></js-job-number>

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading && jobNumber" id="dataGrid" class="max-w-500 m-auto maintenancegrid-height"
    [dataSource]="dataSource" [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)"
    (onExporting)="gridService.onExporting($event, 'DocumentFolders')">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" width="100px"></dxo-search-panel>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxi-column dataField="name" cellTemplate="nameCellTemplate"> </dxi-column>

    <dxi-column [calculateCellValue]="calculateTemplateCellValue" caption="Templates" dataType="string"
      cellTemplate="sharePointCellTemplate" width="110" alignment="center"> </dxi-column>

    <!-- templates -->
    <div *dxTemplate="let cellInfo of 'nameCellTemplate'">
      <div id="commentClass" (click)="openJobLink(cellInfo.value)">{{cellInfo.value}}</div>
    </div>

    <div *dxTemplate="let cellInfo of 'sharePointCellTemplate'">
      <div *ngIf="cellInfo.value" id="commentClass" (click)="openTemplates(cellInfo.value)">Templates</div>
    </div>
  </dx-data-grid>

</div>

<js-loading-spinner *ngIf="loading"></js-loading-spinner>

<div *ngIf="!loading" class="page max-w-800 m-auto">

  <dx-data-grid id="dataGrid" class="maintenancegrid-height" [dataSource]="dataSource" [allowColumnReordering]="true"
    [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    (onInitNewRow)="onInitNewRow($event)" (onExporting)="gridService.onExporting($event, 'OverridingTemplates')">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>

    <!-- refresh mode full so we can get the data if a user goes to the modal -->
    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="full">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-sorting mode="multiple"></dxo-sorting>


    <dxi-column dataField="templateTaskHeaderId" caption="Template" dataType="number" sortOrder="asc">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="taskHeaders" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="description" dataType="string" sortOrder="asc">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

  </dx-data-grid>

  <dx-button class='mt-1' id="button" text="Edit Overriding Days" type="default" (onClick)="addEditDays()">
  </dx-button>

</div>

export class SendCancellation {
  public sendCancellationCallUp: boolean;
  public sendCancellationToEmail: string;
  public sendCancellationComment: string;
  public sendCancellationCcToSiteManager: boolean;

  constructor() {
    this.sendCancellationCallUp = false;
    this.sendCancellationToEmail = '';
    this.sendCancellationComment = '';
    this.sendCancellationCcToSiteManager = true;
  }
}

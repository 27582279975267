<div class="modal-header">
  <h4 class="modal-title">Restore - {{jobTask.manualTaskTitle}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <div aria-hidden="true">&times;</div>
  </button>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-primary" (click)="resetNotApplicable()">Restore</button>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>

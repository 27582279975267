export enum TaskStatusEnum {
    NotStarted = 1,
    InProgress = 2,
    Hold = 3,
    Problem = 4,
    Waiting = 5,
    Cancelled = 8,
    Completed = 9,
    NotApplicable = 10
}

export const TaskStatusOptions = [
  { id: 1, status: 'Not Started' },
  { id: 2, status: 'In Progress' },
  { id: 3, status: 'Hold' },
  { id: 4, status: 'Problem' },
  { id: 5, status: 'Waiting' },
  { id: 8, status: 'Cancelled' },
  { id: 9, status: 'Completed' },
  { id: 10, status: 'Not Applicable' }
];

import { TemplateTaskHeader } from './../../dtos/templateTaskHeader';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { TrackingFieldsService } from '../../services/felixApi/tracking-fields.service';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../services/notification.service';
import { TrackingFieldLookupComponent } from './tracking-field-lookup/tracking-field-lookup.component';
import { TrackingFieldTypeEnum } from '../../dtos/tracking-field-type.enum';
import { TrackingFieldGroupsComponent } from './tracking-field-groups/tracking-field-groups.component';
import { TrackingFieldGroup } from '../../dtos/tracking-field-group';
import CustomStore from 'devextreme/data/custom_store';
import { ConfigurationEnum } from '../../dtos/configuration-enum';

@Component({
  selector: 'js-tracking-fields',
  templateUrl: './tracking-fields.component.html',
  styleUrls: ['./tracking-fields.component.scss']
})
export class TrackingFieldsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;

  trackingCalculationFields = [
    { id: 1, description: 'Sales Date' },
    { id: 2, description: 'Contract Signed' },
    { id: 3, description: 'Handover Date' },
    { id: 4, description: 'Site Start' },
    { id: 5, description: 'Practical Completion' },
    { id: 6, description: 'Title Due' }
  ];
  trackingFieldGroups: TrackingFieldGroup[];
  loading = true;
  templateTaskHeaders: TemplateTaskHeader[];
  trackingFieldTypes: { id: number; description: string; }[];
  isConstructiveEnabled: boolean;

  constructor(
    public trackingFieldsService: TrackingFieldsService,
    private maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private modalService: NgbModal,
    private notiService: NotificationService
  ) {
    this.onReorder = this.onReorder.bind(this);
    this.isCalculatedDaysEditable = this.isCalculatedDaysEditable.bind(this);
  }

  ngOnInit(): void {
    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.ConstructiveIntegrationEnabled)) {
      this.isConstructiveEnabled = true;
    }

    this.trackingFieldTypes = [
      { id: 1, description: 'Yes/No' },
      { id: 2, description: 'Number' },
      { id: 3, description: 'Text' },
      { id: 4, description: 'Date' },
      { id: 6, description: 'Lookup' },
      { id: 8, description: 'Calculated' }
    ];

    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getData() {
    this.subscriptions.push(
      this.trackingFieldsService.getJobFieldsData().subscribe({
        next: () => {
          this.trackingFieldGroups = this.trackingFieldsService.trackingFieldGroups;
          this.templateTaskHeaders = this.maintenanceService.taskHeaders;
          this.loading = false;
          this.setUpDataSource();
        }, error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.trackingFieldsService.getTrackingFields(false, false).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.trackingFieldsService.addTrackingField(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.trackingFieldsService.updateTrackingField(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.trackingFieldsService.deleteTrackingField(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          stylingMode: 'default',
          type: 'default',
          text: 'Edit Sections',
          onClick: this.editGroups.bind(this)
        }
      });
  }

  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();

    if (e.itemData.trackingFieldGroupId === visibleRows[e.toIndex].data.trackingFieldGroupId) {
      const newOrderIndex = visibleRows[e.toIndex].data.orderNo;
      this.loading = true;

      this.subscriptions.push(
        this.trackingFieldsService.updateTrackingField(e.itemData.id, { orderNo: newOrderIndex }).subscribe({
          next: () => {
            this.loading = false;
            e.component.refresh();
          }, error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
      );
    } else {
      this.notiService.showInfo('Cannot drop here. Fields must belong to the same group.');
    }
  }

  editList(e) {
    if (e.data.trackingFieldTypeId !== TrackingFieldTypeEnum.Lookup) {
      this.notiService.showInfo('List entries are only applicable if the field type is Lookup');
    } else {
      const modalRef = this.modalService.open(TrackingFieldLookupComponent, { windowClass: 'modal-1000' });
      modalRef.componentInstance.trackingFieldId = e.data.id;
      modalRef.componentInstance.trackingFieldName = e.data.fieldName;

      modalRef.result.then(() => {
      });
    }
  }

  editGroups() {
    const modalRef = this.modalService.open(TrackingFieldGroupsComponent, { windowClass: 'modal-1000' });

    modalRef.result.then(() => {
      this.getData();
    }, () => {
      this.getData();
    });
  }

  onInitNewRow(e) {
    e.data.isActive = true;
    e.data.orderNo = 0;
    e.data.isCalculationDaysWorkingDays = false;
    e.data.sendToConstructive = false;
  }

  isCalculatedDaysEditable(data) {
    return data.row?.data?.trackingFieldTypeId !== TrackingFieldTypeEnum.Calculated;
  }

  setEditedQtyValue(valueChangedEventArg, cellInfo) {
    cellInfo.setValue(valueChangedEventArg.value);
  }

  onEditorPreparing(e: any) {
    if (e.parentType !== 'dataRow') {
      return;
    } else {
      if (e.dataField === 'fieldName') {
        e.editorName = 'dxTextArea';
        e.editorOptions.autoResizeEnabled = true;
        let prevHeight = null;
        e.editorOptions.onInput = (args) => {
          const td = args.element.closest('td');
          if (td && prevHeight !== td.offsetHeight) {
            const overlay = e.element.querySelector('.dx-datagrid-focus-overlay');
            if (overlay != null) {
              overlay.style.height = (td.offsetHeight + 1) + 'px';
            }
            prevHeight = td.offsetHeight;
          }
        };
      }
    }
  }
}

<div class="page">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" id="dataGrid" class="max-w-1000 m-auto" [height]="gridHeight" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="false"
    [wordWrapEnabled]="true" (onInitNewRow)="onInitNewRow($event)" (onExporting)="gridService.onExporting($event, 'Workflows')"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate')">

    <dxo-row-dragging [allowReordering]="modifiedByVisible" [onReorder]="onReorder"></dxo-row-dragging>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>

    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="full">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>


    <dxi-column dataField="jobId" dataType="number" [visible]='false'></dxi-column>

    <dxi-column dataField="templateTaskHeaderId" dataType="number" caption="Work Flow">
      <dxo-lookup [dataSource]="getTaskHeaders" displayExpr="description" valueExpr="id">
      </dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="templateDaysHeaderId" dataType="number" caption="Overriding Days"
      [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="getTaskDaysList" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column *ngIf="modifiedByVisible" dataField="modifiedUserId" caption="Modified By" dataType="number" width="130"
      cellTemplate="noWrapTemplate" [allowEditing]="false">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column *ngIf="modifiedByVisible" dataField="modifiedDate" caption="Modified" dataType="date" width="85"
      alignment="center" cellTemplate="dateTemplate" [allowEditing]="false">
    </dxi-column>


    <div *dxTemplate="let cell of 'noWrapTemplate'">
      <div style="white-space: nowrap; overflow: hidden;">{{cell.text}}</div>
    </div>

    <div *dxTemplate="let cell of 'dateTemplate'">
      <div *ngIf='cell && cell.value'>{{cell.value | date : "d-MMM-yy"}}</div>
    </div>


    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div class="w420">
        <div class="dx-field">
          <div class="dx-field-label">Region</div>
          <div class="dx-field-value">
            <dx-select-box [dataSource]="tradeRegions" displayExpr="description" valueExpr="id"
              [(value)]='tradeRegionId' (onSelectionChanged)='saveRegion()'>
            </dx-select-box>
          </div>
        </div>
      </div>
    </div>

  </dx-data-grid>
</div>

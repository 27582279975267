<div class="page">
  <dx-data-grid id="dataGrid" class="max-w-800 m-auto maintenancegrid-height" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" (onInitNewRow)="onInitNewRow($event)">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="full">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxi-column dataField="description">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
  </dx-data-grid>
</div>

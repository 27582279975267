import { TaskService } from './../../../services/felixApi/task.service';
import { JobWorkFlowService } from './../../../services/felixApi/job-work-flow.service';
import { GlobalService } from './../../../services/global.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MaintenanceService } from '../../../services/felixApi/maintenance.service';
import { NotificationService } from '../../../services/notification.service';
import { JobService } from '../../../services/felixApi/job.service';
import { Job } from '../../../dtos/job';
import CustomStore from 'devextreme/data/custom_store';
import { JobWorkFlow } from '../../../dtos/job-work-flow';

@Component({
  selector: 'js-update-jobs',
  templateUrl: './update-jobs.component.html',
  styleUrls: ['./update-jobs.component.scss']
})
export class UpdateJobsComponent implements OnInit, OnDestroy {
  @Input() taskHeaderId: number;

  loading = false;
  subscriptions: Subscription[] = [];
  jobs: Job[] = [];
  shownJobs: Job[] = [];
  jobData: CustomStore;
  jobWorkFlows: JobWorkFlow[] = [];
  selectedRows: number[] = [];
  gridHeight: number;
  remainingJobs: number;
  reorderNonChangedTasks = false;

  constructor(
    private activeModal: NgbActiveModal,
    private _globalService: GlobalService,
    private _jobService: JobService,
    private _jobWorkflowService: JobWorkFlowService,
    private _taskService: TaskService,
    private maintenanceService: MaintenanceService,
    private notiService: NotificationService) {
    this.calculateJobSortValue = this.calculateJobSortValue.bind(this);
  }

  ngOnInit() {
    this.gridHeight = window.innerHeight - 300;
    this.loadJobs();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadJobs() {
    // because we entered an address search clear the other search fields
    this.jobs = [];
    this.loading = true;

    this.subscriptions.push(
      this._jobService.getJobsByAddressWithExtras(false)
        .subscribe({
          next: (jobs) => {
            this.jobs = jobs.filter(i => i.isActive);
            this.loadJobWorkFlows();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  loadJobWorkFlows() {
    // only want the jobs with this workflow
    this.subscriptions.push(
      this._jobWorkflowService.getAllJobWorkFlows(false, false)
        .subscribe({
          next: (res) => {
            this.jobWorkFlows = res;
            this.createJobDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  createJobDataSource() {
    this.shownJobs = [];

    this.jobs.filter(i => i.isActive && i.salesDate).forEach(job => {
      job.jobAddressString = this._globalService.getJobString(job);
      const jobExtra = this._jobService.jobExtras?.find(i => i.jobId === job.id);
      if (jobExtra) {
        if (!jobExtra.maintenanceCompleteDate) {
          const activity = this.maintenanceService.activities?.find(i => i.id === jobExtra.currentActivityId);
          if (activity) {
            job.currentActivityDesc = activity.description;
            job.currentActivityCode = activity.activityCode;
          } else {
            job.currentActivityCode = ''; // to put at the end
          }

          const jobWorkFlow = this.jobWorkFlows.find(i => i.jobId === job.id && i.templateTaskHeaderId === this.taskHeaderId);
          if (jobWorkFlow) {
            this.shownJobs.push(job);
          }
        }
      }
    });

    this.jobData = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => this.shownJobs
    });
    this.loading = false;
  }

  close() {
    this.activeModal.dismiss();
  }

  generateTasks() {
    this.loading = true;
    this.remainingJobs = this.selectedRows.length;
    let count = 0
    this.selectedRows.forEach(jobId => {
      count++;
      // use timeout to delay the start of each so we don't overload the server
      setTimeout(() => {
        this.subscriptions.push(
          this._taskService.getCallUpJobTasks(jobId, this.taskHeaderId, true, this.reorderNonChangedTasks)
            .subscribe({
              next: () => {
                this.remainingJobs--;

                if (!this.remainingJobs) {
                  this.notiService.showSuccess('Update completed');
                  this.activeModal.close();
                }
              },
              error: (err) => {
                this.notiService.notify(err);
              }
            })
        );
      }, 3000 * count);
    });
  }

  calculateJobSortValue(data) {
    const job = this.jobs.find(i => i.id === data.jobId);
    if (job) {
      return job.jobNumber;
    }

    return '';
  }
}

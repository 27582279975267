import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { StateStoreService } from '../../services/felixApi/state-store.service';
// import DataSource from 'devextreme/data/data_source';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StateStore } from './state-store';
import { DxDataGridComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-state-store',
  templateUrl: './state-store.component.html',
  styleUrls: ['./state-store.component.scss']
})
export class StateStoreComponent implements OnInit, OnDestroy {
  @Input() stateStoreTypeId: number;
  @Input() stateString: string;

  @ViewChild('stateGrid') stateGrid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  gridHeight: number;
  selectedLayout: StateStore;
  showLoadDefaultButton: boolean;
  showSaveLayoutButton: boolean;
  savePopupVisible = false;
  loadDefaultLabel = 'Load default';

  constructor(
    private stateStoreService: StateStoreService,
    private auth: AuthService,
    private globalService: GlobalService,
    private _activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.setWidths();
    this.subscribeToInnerHeightWidth();
    this.setUpDataSource();

    // strip state of unused fields
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  subscribeToInnerHeightWidth() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          setTimeout(() => {
            this.setWidths();
          }, 10); // wait for iPhone and grid
        }
      )
    );
  }

  setWidths() {
    this.gridHeight = this.globalService.innerHeight > 820 ? 500 : this.globalService.innerHeight - 320; // for load buttons

    if (this.globalService.innerWidth > 570) {
      this.showLoadDefaultButton = true;
      this.showSaveLayoutButton = true;
      this.loadDefaultLabel = 'Load default';
    } else {
      this.showSaveLayoutButton = false;

      if (this.globalService.innerWidth > 370) {
        this.showLoadDefaultButton = true;
        this.loadDefaultLabel = 'Default';
      } else {
        this.showLoadDefaultButton = false;
      }
    }
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.stateStoreService.getAllStateStoresForMe(this.stateStoreTypeId).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values: StateStore) => {
        const dataRecord = {
          stateStoreTypeId: this.stateStoreTypeId,
          userId: values.privateStore ? this.auth.getCurrentUserId() : null,
          description: values.description,
          stateString: this.stateString
        };
        return new Promise((resolve, reject) =>
          this.stateStoreService.addStateStore(dataRecord).subscribe({
            next: (res) => {
              return resolve(res[0]);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.stateStoreService.updateStateStore(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.stateStoreService.deleteStateStore(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onSelectionChanged(event) {
    if (event.selectedRowKeys.length > 0) {
      this.selectedLayout = event.selectedRowsData[0];
    }
  }

  cancel() {
    this._activeModal.dismiss();
  }

  selectLayout() {
    this._activeModal.close(this.selectedLayout);
  }

  loadDefault() {
    this._activeModal.close(null);
  }

  saveLayout() {
    this.savePopupVisible = true;
  }

  saveLayoutGo() {
    this.savePopupVisible = false;
    const rowIndex = this.stateGrid.instance.getRowIndexByKey(this.selectedLayout.id);
    this.stateGrid.instance.cellValue(rowIndex, 'stateString', this.stateString);
    this.stateGrid.instance.saveEditData();
    this.cancel();
  }
}

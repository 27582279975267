import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TradeRegionVendorsComponent } from './trade-region-vendors/trade-region-vendors.component';

@Component({
  selector: 'js-trade-regions',
  templateUrl: './trade-regions.component.html',
  styleUrls: ['./trade-regions.component.scss']
})
export class TradeRegionsComponent implements OnInit, OnDestroy {

  dataSource: CustomStore;
  subscriptions: Subscription[] = [];

  constructor(
    private maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private modalService: NgbModal,
    private notiService: NotificationService
  ) {
    this.onReorder = this.onReorder.bind(this);
  }

  ngOnInit(): void {
    this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.getTradeRegions(false).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.addTradeRegion(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.updateTradeRegion(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.deleteTradeRegion(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          stylingMode: 'default',
          type: 'default',
          text: 'Edit Vendors for Trade/Region',
          onClick: this.editTradeVendorsForRegions.bind(this)
        }
      });
  }

  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();
    const newOrderIndex = visibleRows[e.toIndex].data.orderNumber;

    this.subscriptions.push(
      this.maintenanceService.moveTradeRegion(e.itemData.id, newOrderIndex).subscribe({
        next: () => {
          e.component.refresh();
        }, error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  editTradeVendorsForRegions() {
    const modalRef = this.modalService.open(TradeRegionVendorsComponent, { windowClass: 'modal-1500' });
    modalRef.result.then(() => { });
  }
}

import { JobEmail } from './../../dtos/job-email';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../services/global.service';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { JobEmailService } from '../../services/felixApi/job-email.service';
import { NotificationService } from '../../services/notification.service';
import { JobEmailAttachment } from './../../dtos/job-email-attachment';
import { DocumentService } from '../../services/felixApi/document.service';

@Component({
  selector: 'js-job-email-expanded',
  templateUrl: './job-email-expanded.component.html',
  styleUrls: ['./job-email-expanded.component.scss']
})
export class JobEmailExpandedComponent implements OnInit, OnDestroy {
  @Input() jobEmail: JobEmail;

  loading = true;
  hasAttachments = false;
  subscriptions: Subscription[] = [];
  popupHeight: number;
  popupWidth: number;
  bodyHeight: number;
  showCC: boolean;
  bodyText: SafeHtml;
  colCount: number;
  attachments: JobEmailAttachment[];
  showSharepointLink = false;
  loadingFile = false;
  baseSize = 220;
  attachmentsHeight = 172;

  constructor(
    private jobEmailService: JobEmailService,
    private notiService: NotificationService,
    private activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private documentService: DocumentService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.attachments = this.jobEmail.attachments;
    this.hasAttachments = this.jobEmail.attachments && this.jobEmail.attachments.length > 0;
    this.colCount = 3;
    this.showSharepointLink = this.jobEmail.emailFileId && this.jobEmail.emailFileId.length > 0;
    this.calculateWidthsHeights();
    this.subscribeToInnerWidth();

    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getData() {
    this.subscriptions.push(
      this.jobEmailService.getJobEmailDetailsData(this.jobEmail.id)
        .subscribe({
          next: (jobEmailDetails) => {
            if (jobEmailDetails.body) {
              this.bodyText = this.sanitizer.bypassSecurityTrustHtml(jobEmailDetails.body);
            }
            else {
              this.bodyText = this.jobEmail.bodyAsText.replace(/\n/g, '<br>');
            }
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  subscribeToInnerWidth() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.calculateWidthsHeights();
        }
      )
    );
  }

  calculateWidthsHeights() {
    this.loading = true;
    this.showCC = this.globalService.innerHeight >= 700;
    this.popupHeight = this.globalService.innerHeight >= 1000 ? 980 : this.globalService.innerHeight - 20;
    this.popupWidth = this.globalService.innerWidth < 1500 ? this.globalService.innerWidth - 20 : 1480;
    this.baseSize = this.popupWidth / 4;

    this.bodyHeight = this.popupHeight - 280;

    if (this.showSharepointLink) {
      this.bodyHeight -= 40;
    }

    this.attachmentsHeight = 172;
    if (!this.showCC) {
      this.bodyHeight += 40;
      this.attachmentsHeight -= 40;
    }

    setTimeout(() => {
      this.loading = false;
    }, 100);
  }

  downloadFile(fileName: string) {
    this.loadingFile = true;
    this.jobEmailService.getJobEmailAttachmentData(this.jobEmail.id, fileName).subscribe({
      next: (attachment) => {
        this.globalService.getBlobFromBase64(attachment.data, attachment.attachmentType, fileName);
        this.loadingFile = false;
      },
      error: (err) => {
        this.notiService.notify(err);
        this.loadingFile = false;
      }
    })
  }

  downloadFromSharepoint() {
    this.loadingFile = true;
    this.documentService.getSharePointDocument(this.jobEmail.jobNumber, this.jobEmail.emailFileId).subscribe({
      next: (res) => {
        this.globalService.getBlobFromBase64(res.attachment, res.attachmentTypeId, res.name);
        this.loadingFile = false;
      },
      error: (err) => {
        this.notiService.notify(err);
        this.loadingFile = false;
      }
    })
  }

  close() {
    this.activeModal.dismiss();
  }
}

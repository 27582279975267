<dx-popup class="popup" [width]="popupWidth" [height]="sendGridHeight" [showTitle]="true" title="Accept Call-Up"
  [dragEnabled]="false" [hideOnOutsideClick]="true" [visible]="true" (onHidden)="onHidden()" [showCloseButton]="true">

  <dx-button *ngIf="!loadingVisible" text="Mark Call-Up as Accepted" class="mt-2" type="default"
    (onClick)="acceptCallUp()"></dx-button>

</dx-popup>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingVisible" [showIndicator]="true"
  [showPane]="true" [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>

import { forkJoin, Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { GlobalService } from '../global.service';
import { Company } from '../../dtos/company';
import { CompanyConfiguration } from '../../dtos/company-configuration';
import { CompanyRole } from '../../dtos/companyRole';
import { HttpService } from '../http.service';
import { AuthApiService } from './auth-api.service';

@Injectable()
export class CompanyService {

  authCompanies: Company[];
  companyRoles: CompanyRole[];

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private authApi: AuthApiService,
    private globalService: GlobalService) { }

  setCurrentCompanies(comps: Company[]) {
    this.authCompanies = comps;
  }

  getCurrentCompanies() {
    return this.authCompanies;
  }

  getCompanies() {
    return this.http.get<Company[]>(this.globalService.getApiUrl() + '/companies', this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  // see getAuthorisedCompanies() in auth.service
  // getCompaniesByUserEmail(email: string) {
  //     return this.http.get<Company[]>(this.globalService.getApiUrl() + '/email-addresses/' + email + '/authorised-companiesx',
  //         this.httpService.getHttpOptions());
  // }

  getCompany(id) {
    return this.http.get<Company>(this.globalService.getApiUrl() + '/companies/' + id, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getCompanyConfigs() {
    // if (this.globalService.companyConfiguration && this.globalService.companyConfiguration.length) {
    //   return of(this.globalService.companyConfiguration);
    // }
    return this.http.get<CompanyConfiguration[]>(this.globalService.getApiUrl() + '/company-configurations',
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.globalService.companyConfiguration = res;
        }),
        catchError(this.handleError));
  }

  getCompanyConfigurations(): Observable<CompanyConfiguration[]> {
    return forkJoin(
      [
        this.getCompanyConfigs(),
        this.authApi.getAreaPermissions()
      ]
    )
      .pipe(map(
        ([configs, perms]) => {
          return configs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getCompanyRoles(): Observable<CompanyRole[]> {
    const url = this.globalService.getApiUrl() + '/company-roles';
    return this.http.get<CompanyRole[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.companyRoles = res.filter(i => i.isActive);
      }),
      catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceHelperService {

  sections = ['Task Types', 'Tasks', 'Workflow Templates', 'Workflows', 'Overriding Days',
    'Tracking Fields', 'Trades', 'Trade Regions', 'Call-Up Docs', 'Document Folders', 'Holidays', 'Control'];

  selectedSection = 'Task Types';

  houseTypeModMode = new BehaviorSubject(false);

  constructor() { }

}

import { TemplateTaskHeader } from './../../../dtos/templateTaskHeader';
import { MaintenanceService } from './../../../services/felixApi/maintenance.service';
import { GlobalService } from './../../../services/global.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TrackingFieldsService } from '../../../services/felixApi/tracking-fields.service';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-tracking-field-lookup',
  templateUrl: './tracking-field-lookup.component.html',
  styleUrls: ['./tracking-field-lookup.component.scss']
})
export class TrackingFieldLookupComponent implements OnInit {
  @Input() trackingFieldId: number;
  @Input() trackingFieldName: string;

  @ViewChild('trackingLookupGrid') grid: DxDataGridComponent;

  dataSource: CustomStore;
  templateTaskHeaders: TemplateTaskHeader[];

  constructor(
    private activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private maintenanceService: MaintenanceService,
    private trackingFieldService: TrackingFieldsService) {
    this.onReorder = this.onReorder.bind(this);
    this.addTaskOptions = this.addTaskOptions.bind(this);
    this.onInitNewRow = this.onInitNewRow.bind(this);
  }

  ngOnInit() {
    this.templateTaskHeaders = this.maintenanceService.taskHeaders;
    this.setUpDataSet();
  }

  setUpDataSet() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.trackingFieldService.getTrackingFieldLookups(this.trackingFieldId).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.trackingFieldService.addTrackingFieldLookup(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.trackingFieldService.updateTrackingFieldLookup(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.trackingFieldService.deleteTrackingFieldLookup(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Add Task Options',
          onClick: this.addTaskOptions.bind(this)
        }
      });
  }

  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();
    const newOrderIndex = visibleRows[e.toIndex].data.orderNo;

    this.trackingFieldService.updateTrackingFieldLookup(e.itemData.id, { orderNo: newOrderIndex }).subscribe(() => {
      e.component.refresh();
    });
  }

  addTaskOptions() {
    // add the default task options
    this.grid.instance.on('initNewRow', e => {
      e.data.description = 'To be done';
    });
    this.grid.instance.addRow();
    this.grid.instance.saveEditData();

    setTimeout(() => {
      this.grid.instance.on('initNewRow', e => {
        e.data.description = 'In Progress';
      });
      this.grid.instance.addRow();
      this.grid.instance.saveEditData();

      setTimeout(() => {
        this.grid.instance.on('initNewRow', e => {
          e.data.description = 'COMPLETED';
        });
        this.grid.instance.addRow();
        this.grid.instance.saveEditData();

        setTimeout(() => {
          this.grid.instance.on('initNewRow', e => {
            e.data.description = 'Not Required';
          });
          this.grid.instance.addRow();
          this.grid.instance.saveEditData();

          this.grid.instance.on('initNewRow', e => {
            e.data.description = '';
          });
        }, 300); // wait to complete in order
      }, 300); // wait to complete in order
    }, 300); // wait to complete in order
  }

  close() {
    this.activeModal.close();
  }

  onInitNewRow(e) {
    e.data.trackingFieldId = this.trackingFieldId;
    e.data.orderNo = 0;
  }
}

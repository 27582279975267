<div class='w300 mt-2 ms-2'>
  <div class="dx-field-label ">Time Scale</div>
  <div class="dx-field-value">
    <dx-select-box [items]="['auto', 'days', 'weeks', 'months', 'quarters']" [(value)]="timeScale"></dx-select-box>
  </div>
</div>

<dx-button id="refreshButton" icon="refresh" type="default" (onClick)="refresh()"> </dx-button>

<dx-select-box [dataSource]="templateTaskHeaders" displayExpr="description" valueExpr="id" placeholder="Choose Workflow"
  [width]="350" [(value)]='selectedWorkflowId' (onValueChanged)='onWorkflowChanged($event)' [showClearButton]="true"
  class='refreshClass ms-4 mt-2'>
</dx-select-box>

<js-loading-spinner *ngIf="loading"></js-loading-spinner>

<div *ngIf="!loading" class="mt-1 mx-2">
  <dx-tabs [items]="tabs" [(selectedIndex)]="selectedIndex"> </dx-tabs>

  <div *ngIf="selectedIndex === 0">
    <dx-gantt [taskListWidth]="titleColumnWidth" [scaleType]="timeScale" taskTitlePosition="outside"
      [height]="gridHeight" taskContentTemplate="taskContentTemplate"
      (onTaskEditDialogShowing)="onTaskEditDialogShowing($event)">

      <dxo-tasks [dataSource]="tasks" keyExpr="id" parentIdExpr="parentTaskId" titleExpr="taskTitle" startExpr="start"
        endExpr="end"></dxo-tasks>
      <dxo-dependencies [dataSource]="dependencies" keyExpr="id" predecessorIdExpr="taskPredecessorId"
        successorIdExpr="taskSuccessorId" typeExpr="dependencyTypeId"></dxo-dependencies>

      <dxo-header-filter [visible]="true" [search]="{enabled: true}"></dxo-header-filter>
      <dxo-editing enabled="true"></dxo-editing>

      <dxo-toolbar>
        <dxi-item name="zoomIn"></dxi-item>
        <dxi-item name="zoomOut"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item widget="dxButton" [options]="showTitleColumnsOptions"> </dxi-item>
        <dxi-item widget="dxButton" [options]="exportButtonOptions"> </dxi-item>
        <dxi-item widget="dxButton" [options]="exportToExcelButtonOptions"> </dxi-item>
      </dxo-toolbar>

      <dxi-column dataField="taskTitle" caption="Task" [width]="300"></dxi-column>
      <dxi-column dataField="start" caption="Start Date" dataType="date"></dxi-column>
      <dxi-column dataField="end" caption="End Date" dataType="date"></dxi-column>

      <div *dxTemplate="let item of 'taskContentTemplate'"
        style.width="{{ item.isMilestone ? 13 : item.taskSize.width }}px">
        <div *ngIf="item.isMilestone" [class.dx-gantt-milestone]="item.isMilestone"
          class="custom-task {{ getTaskColor(item) }}"> </div>
        <div *ngIf="!item.isMilestone" class="custom-task {{ getTaskColor(item) }}" class="custom-task-wrapper"> </div>
      </div>
    </dx-gantt>
  </div>

  <div *ngIf="selectedIndex === 1">
    <dx-diagram #diagram id="diagram" [height]="gridHeight" [readOnly]="false" units="cm"
      [pageSize]='{width: 29.7, height: 42, items: [{ text: "A4 (21cm x 29.7cm)", width: 21, height: 29.7 },{ text: "A3 (29.7cm x 42cm)", width: 29.7, height: 42 }]}'>
      <dxo-nodes [dataSource]="flowNodesDataSource" keyExpr="id" textExpr="taskTitle"
        [textStyleExpr]="itemTextStyleExpr" [styleExpr]="itemStyleExpr">
        <dxo-auto-layout type="layered"></dxo-auto-layout>
      </dxo-nodes>
      <dxo-edges [dataSource]="flowEdgesDataSource" keyExpr="id" fromExpr="taskPredecessorId" toExpr="taskSuccessorId"
        textExpr="dependencyText">
      </dxo-edges>

      <dxo-context-menu [enabled]="false"> </dxo-context-menu>
      <dxo-context-toolbox [enabled]="false"> </dxo-context-toolbox>
      <dxo-history-toolbar [visible]="false"> </dxo-history-toolbar>
      <dxo-view-toolbar [visible]="false"> </dxo-view-toolbar>
      <dxo-main-toolbar [visible]="false"> </dxo-main-toolbar>
      <dxo-toolbox visibility="disabled"> </dxo-toolbox>
      <dxo-view-toolbar [visible]="true"> </dxo-view-toolbar>
      <dxo-properties-panel visibility="invisible">
        <dxi-tab>
          <dxi-group title="Page Properties" [commands]='["pageSize","pageOrientation"]'></dxi-group>
        </dxi-tab>
      </dxo-properties-panel>
    </dx-diagram>

    <div class="mt-2">Note: Changes in the Diagram are not saved and can be used to improve the view for printing.</div>
  </div>
</div>

<!-- print pop-up -->
<dx-popup class="popup" [width]="850" [height]="390" [showTitle]="true" title="PDF Settings" [dragEnabled]="true"
  [hideOnOutsideClick]="true" [(visible)]="printSettingsPopupVisible" [shading]="false">
  <div *dxTemplate="let data of 'content'">
    <div class="options">
      <div class="column">
        <div class="caption">Export Options</div>
        <div class="option">
          <div class="label">Document format:</div>
          <div class="value">
            <dx-select-box id="formatBox" [items]="formats" [(value)]="formatBoxValue">
            </dx-select-box>
          </div>
        </div>
        <div class="option">
          <div class="label">Landscape orientation:</div>
          <div class="value">
            <dx-check-box [(value)]="landscapeCheckBoxValue"></dx-check-box>
          </div>
        </div>
        <div class="option">
          <div class="label">Export mode:</div>
          <div class="value">
            <dx-select-box [items]="exportModes" [(value)]="exportModeBoxValue">
            </dx-select-box>
          </div>
        </div>
        <div class="option">
          <div class="label">Date range:</div>
          <div class="value">
            <dx-select-box [items]="dateRanges" [(value)]="dateRangeBoxValue"
              (onValueChanged)="onDateRangeBoxSelectionChanged($event)">
            </dx-select-box>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="caption">Task Filter Options</div>
        <div class="option">
          <div class="label">Start task (index):</div>
          <div class="value">
            <dx-number-box [disabled]="customRangeDisabled" [(value)]="startTaskIndex" [min]="0" [max]="endTaskIndex"
              [showSpinButtons]="true">
            </dx-number-box>
          </div>
        </div>
        <div class="option">
          <div class="label">End task (index):</div>
          <div class="value">
            <dx-number-box [disabled]="customRangeDisabled" [(value)]="endTaskIndex" [min]="startTaskIndex"
              [max]="tasks.length - 1" [showSpinButtons]="true">
            </dx-number-box>
          </div>
        </div>
        <div class="option">
          <div class="label">Start date:</div>
          <div class="value">
            <dx-date-box [disabled]="customRangeDisabled" [(value)]="startDate" [max]="endDate" type="date">
            </dx-date-box>
          </div>
        </div>
        <div class="option">
          <div class="label">End date:</div>
          <div class="value">
            <dx-date-box [disabled]="customRangeDisabled" [(value)]="endDate" [min]="startDate" type="date">
            </dx-date-box>
          </div>
        </div>
      </div>
    </div>

    <dx-button text="Generate PDF" class="mt-1" type="default" (onClick)="exportButtonClick()">
    </dx-button>

  </div>
</dx-popup>

<dx-popup class="popup" [width]="850" [height]="600" [showTitle]="true" title="Export to Excel" [dragEnabled]="true"
  [hideOnOutsideClick]="true" [(visible)]="exportToExcelPopupVisible" [shading]="false">
  <dx-data-grid [dataSource]="tasks" [height]="510" keyExpr="id" [allowColumnReordering]="false"
    [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    (onExporting)="gridService.onExporting($event, 'JobGanttExport')">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" width="200px"></dxo-search-panel>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxi-column dataField="orderNumber" caption="ID" dataType="number" width="85"> </dxi-column>

    <dxi-column dataField="taskTitle" caption="Name"> </dxi-column>

    <dxi-column dataField="days" caption="Duration" dataType="number" width="90"> </dxi-column>

    <dxi-column dataField="predecessors" [width]="120"> </dxi-column>

    <dxi-column dataField="start" caption="Start" dataType="date" width="120" alignment="center" format="dd/MM/yyyy">
    </dxi-column>

    <dxi-column dataField="end" caption="Finish" dataType="date" width="120" alignment="center" format="dd/MM/yyyy">
    </dxi-column>

  </dx-data-grid>
</dx-popup>
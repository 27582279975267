import { GridService } from './../../../services/grid.service';
import { GlobalService } from './../../../services/global.service';
import { NotificationService } from './../../../services/notification.service';
import { UserService } from './../../../services/felixApi/user.service';
import { MaintenanceService } from './../../../services/felixApi/maintenance.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { Trade } from '../../../dtos/trade';
import { TradeRegion } from '../../../dtos/trade-region';
import { Vendor } from '../../../dtos/vendor';
@Component({
  selector: 'js-trade-region-vendors',
  templateUrl: './trade-region-vendors.component.html',
  styleUrls: ['./trade-region-vendors.component.scss']
})
export class TradeRegionVendorsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  loading: boolean;
  trades: Trade[];
  tradeRegions: TradeRegion[];
  vendors: Vendor[];
  gridHeight: number;

  constructor(
    private activeModal: NgbActiveModal,
    private maintenanceService: MaintenanceService,
    private userService: UserService,
    private notiService: NotificationService,
    protected gridService: GridService,
    private globalService: GlobalService) {
  }

  ngOnInit() {
    this.gridHeight = window.innerHeight - 300;
    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getData() {
    this.subscriptions.push(
      this.maintenanceService.getTradeVendorsData()
        .subscribe({
          next: () => {
            this.trades = this.maintenanceService.trades;
            this.tradeRegions = this.maintenanceService.tradeRegions;
            this.vendors = this.userService.vendors.filter(i => i.canAttachOrdersToCallUps);
            this.setUpDataSet();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  setUpDataSet() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.getTradeVendors(false).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.addTradeVendor(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.updateTradeVendor(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.deleteTradeVendor(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  close() {
    this.activeModal.close();
  }

  onInitNewRow(e) {
    e.data.isActive = true;
    e.data.isShownInJobDetails = false;
    e.data.orderNumber = 0;
  }
}

<div class="modal-header">
  <h4 class="modal-title">Vendor Summary</h4>
  <button *ngIf="!loading" type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <dx-data-grid *ngIf="!loading" id="grid-container" [dataSource]="callUpTasks" keyExpr="id" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [allowColumnReordering]="false"
    [allowColumnResizing]="false" [columnAutoWidth]="false" [ngStyle]="{'max-height': 'calc(' + sendGridHeight + 'px)'}"  (onExporting)="gridService.onExporting($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)">

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>

    <dxi-column dataField="taskMasterId" dataType="number" caption="Title">
      <dxo-lookup [dataSource]="taskMasters" displayExpr="taskTitle" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="jobId" caption="Job" dataType="number" [allowEditing]="false" [width]="75">
      <dxo-lookup [dataSource]="jobs" displayExpr="jobNumber" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="jobAddress" caption="Address" [allowEditing]="false"
      [calculateCellValue]="calculateJobAddress" [hidingPriority]="1">
    </dxi-column>

    <!-- <dxi-column dataField="vendorId" caption="Vendor" dataType="number">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
      </dxo-lookup>
    </dxi-column> -->

    <dxi-column dataField="startDate" caption="Delivery / Start" dataType="date" [width]="105" alignment="center"
      format="d-MMM" sortOrder="asc">
    </dxi-column>

    <dxi-column dataField="vendorComment" caption="Vendor Comment" cellTemplate="commentCellTemplate">
    </dxi-column>

    <!-- <dxi-column dataField="sendPO" caption="Send PO" dataType="boolean" [width]="55"
      [calculateCellValue]='calculateSendPO' [allowEditing]="false">
    </dxi-column> -->



    <div *dxTemplate="let cellInfo of 'commentCellTemplate'">
      <div id="vendorComment" class='mx-0 px-0'>{{cellInfo.value}}</div>
    </div>
  </dx-data-grid>

  <div class="inlineFlex mt-2 me-3">
    <span class="w100">Email to:</span>
    <dx-text-box [(value)]="emailAddress" [width]="300"> </dx-text-box>
  </div>

  <dx-check-box [(value)]="ccToSelf" text="cc email to yourself" [rtlEnabled]="true"></dx-check-box>
  <br>

  <div class="inlineFlex mt-2">
    <span class="w100">Subject:</span>
    <dx-text-box [(value)]="subjectLine" [width]="500"> </dx-text-box>
  </div>
  <br>

  <dx-button *ngIf="!loading" text="Send" class="mt-2" type="default" (onClick)="sendSummary()"
    [disabled]="!emailAddress || emailAddress === ''">
  </dx-button>
</div>


<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>

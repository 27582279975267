import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { JobWorkFlowService } from '../../services/felixApi/job-work-flow.service';
import { JobService } from '../../services/felixApi/job.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { TaskService } from '../../services/felixApi/task.service';
import { UserService } from '../../services/felixApi/user.service';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { NotificationService } from '../../services/notification.service';
import { TaskType } from '../../dtos/task-type';
import CustomStore from 'devextreme/data/custom_store';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'js-add-multiple-tasks',
  templateUrl: './add-multiple-tasks.component.html',
  styleUrls: ['./add-multiple-tasks.component.scss']
})
export class AddMultipleTasksComponent implements OnInit, OnDestroy {
  @Input() jobId: number;
  @Input() selectedTaskHeaderId: number;
  @Input() nextOrderNumber: number;
  @Input() newTaskTypeId: number;

  subscriptions: Subscription[] = [];
  loading = false;
  templateTaskMastersData: CustomStore;
  selectedRowKeys: number[] = [];
  taskTypes: TaskType[];
  gridHeight: number;

  constructor(
    private notiService: NotificationService,
    private activeModal: NgbActiveModal,
    private globalService: GlobalService,
    protected jobWorkFlowService: JobWorkFlowService,
    private taskService: TaskService,
    protected gridService: GridService,
    private maintenanceService: MaintenanceService) { }

  ngOnInit() {
    this.setJobStringMaxWidth();
    this.subscribeToInnerHeightWidth();
    this.taskTypes = this.maintenanceService.taskTypes;

    this.templateTaskMastersData = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => this.maintenanceService.taskMasters.filter(i => i.isActive)
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  close() {
    this.activeModal.dismiss();
  }

  subscribeToInnerHeightWidth() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          setTimeout(() => {
            this.setJobStringMaxWidth();
          }, 10); // wait for iPhone and grid
        }
      )
    );
  }

  setJobStringMaxWidth() {
    this.gridHeight = window.innerHeight - 200;
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          stylingMode: 'outlined',
          type: 'default',
          text: 'Add',
          onClick: this.addTasks.bind(this)
        }
      });
  }

  addTasks() {
    if (this.selectedRowKeys && this.selectedRowKeys.length) {
      this.loading = true;
      this.subscriptions.push(
        this.taskService.addMultipleJobTasks(this.jobId, this.selectedTaskHeaderId, this.newTaskTypeId, this.nextOrderNumber, this.selectedRowKeys)
          .subscribe({
            next: () => {
              this.activeModal.close();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    } else {
      this.notiService.showInfo('Please select some rows');
    }
  }
}

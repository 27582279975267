<div class="modal-header">
  <h4 class="modal-title">Update existing jobs</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <div class="page">

    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <dx-data-grid *ngIf="!loading" #sendGrid id="sendGrid" [dataSource]="jobData" [(selectedRowKeys)]="selectedRows"
      [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [allowColumnReordering]="false"
      [allowColumnResizing]="false" [columnAutoWidth]="false" [height]="gridHeight">
      <dxo-selection selectAllMode="allPages" showCheckBoxesMode="always" mode="multiple"></dxo-selection>
      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="true"> <dxo-search [enabled]="true"></dxo-search> </dxo-header-filter>

      <dxi-column dataField="id" caption="Job" dataType="number" [allowEditing]="false" [width]="120"
        [calculateSortValue]="calculateJobSortValue">
        <dxo-lookup [dataSource]="jobs" displayExpr="jobNumber" valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="jobAddressString" caption="Site Address"> </dxi-column>

      <dxi-column dataField="currentActivityCode" caption="Activity Code" [width]="150" alignment="center"
        sortOrder="asc">
      </dxi-column>

      <dxi-column dataField="currentActivityDesc" caption="Activity"> </dxi-column>

    </dx-data-grid>

  </div>
</div>

<div class="modal-footer">
  <dx-check-box [(value)]="reorderNonChangedTasks" text="Reorder Un-Changed Tasks" [rtlEnabled]="true" class="me-3">
  </dx-check-box>

  <button *ngIf="!loading" class="btn btn-primary" (click)="generateTasks()" [disabled]="!selectedRows.length">Run
    Create</button>
  <div *ngIf="remainingJobs">Remaining jobs to generate: {{remainingJobs}}</div>
</div>

<div class="modal-header">
  <h2 class="modal-title">Admin: Re-Assign Open Job Tasks</h2>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <js-loading-spinner *ngIf="deleteInProgress"></js-loading-spinner>

  <div *ngIf="!deleteInProgress">
    <div>Re-Assign any incomplete job tasks for a user.</div>
    <p>Note – this will not change invoice approvers, or email ‘address’ to notify on tasks complete</p>
    <br>

    <div class="w400">
      <div class="dx-field-label">Current User</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="officeUsers" displayExpr="fullName" valueExpr="id" [(value)]="previousUserId"
          [searchEnabled]="true">
        </dx-select-box>
      </div>
    </div>
    <br>
    <div class="w400">
      <div class="dx-field-label">New User</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="officeUsers" displayExpr="fullName" valueExpr="id" [(value)]="newUserId"
          [searchEnabled]="true">
        </dx-select-box>
      </div>
    </div>

    <br><br>
    <span class="me-1">Change tasks that have a start date?</span>
    <dx-check-box [(value)]="changeStartedTasks" [width]="30"> </dx-check-box>

    <br><br>
    <span class="me-1">Re-assign any roles also?</span>
    <dx-check-box [(value)]="reassignRoles" [width]="30"> </dx-check-box>
  </div>
</div>

<div *ngIf="!deleteInProgress" class="modal-footer">
  <button class="btn btn-sm btn-danger" (click)="runChange()" [disabled]="!newUserId || !previousUserId">
    Update Tasks
  </button>
</div>

import { UserService } from './../../services/felixApi/user.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { TaskService } from '../../services/felixApi/task.service';
import { NotificationService } from '../../services/notification.service';
import { SendCancellation } from '../../dtos/send-cancellation';

@Component({
  selector: 'js-send-cancellation',
  templateUrl: './send-cancellation.component.html',
  styleUrls: ['./send-cancellation.component.scss']
})
export class SendCancellationComponent implements OnInit, OnDestroy {
  @Input() jobTaskId: number;
  @Input() vendorId: number;

  subscriptions: Subscription[] = [];
  sendCancellationDto = new SendCancellation();
  loading = true;
  popupHeight: number;
  vendorName: string;

  constructor(
    private activeModal: NgbActiveModal,
    private taskService: TaskService,
    private userService: UserService,
    private notiService: NotificationService) {
  }

  ngOnInit() {
    this.popupHeight = 230;
    this.sendCancellationDto.sendCancellationComment = 'Advice of new date will be given when required.';
    this.vendorName = this.userService.vendors.find(i => i.id === this.vendorId).vendorName;
    // look up the email address
    this.getTask();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getTask() {
    this.subscriptions.push(
      this.taskService.getJobTaskQueue(this.jobTaskId)
        .subscribe({
          next: (tasks) => {
            if (tasks && tasks.length) {
              this.sendCancellationDto.sendCancellationToEmail = (tasks[0].emailAddresses ?? '');
            }

            // if the task was Marked-As-Sent, then the email address will be blank
            if (this.sendCancellationDto.sendCancellationToEmail === '') {
              const vendor = this.userService.vendors.find(i => i.id === this.vendorId);
              if (vendor) {
                this.sendCancellationDto.sendCancellationToEmail = vendor.callUpEmail && vendor.callUpEmail.length ? vendor.callUpEmail : vendor.email;
              }
            }

            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  sendCancellationEmail() {
    this.loading = true;
    this.subscriptions.push(
      this.taskService.sendJobTaskCancellation(this.jobTaskId, this.sendCancellationDto)
        .subscribe({
          next: () => {
            this.loading = false;
            this.notiService.showInfo('Cancellation email sent. Save changes to update the task.');
            this.activeModal.close();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  close() {
    this.activeModal.dismiss();
  }

}

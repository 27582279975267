import { GlobalService } from './../../../services/global.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MaintenanceService } from '../../../services/felixApi/maintenance.service';
import { NotificationService } from '../../../services/notification.service';
import { TemplateTaskHeader } from '../../../dtos/templateTaskHeader';
import { AuthApiService } from '../../../services/felixApi/auth-api.service';
import { Company } from '../../../dtos/company';

@Component({
  selector: 'js-copy-tasks-modal',
  templateUrl: './copy-tasks-modal.component.html',
  styleUrls: ['./copy-tasks-modal.component.scss']
})
export class CopyTasksModalComponent implements OnInit, OnDestroy {
  @Input() taskHeaderId: number;

  loading = false;
  fromTaskHeaderId: number;
  fromCompanyId: number;
  subscriptions: Subscription[] = [];
  taskHeaders: TemplateTaskHeader[];
  userCompanies: Company[] = [];
  multipleCompanies = false;

  constructor(
    private activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private maintenanceService: MaintenanceService,
    private authApi: AuthApiService,
    private notiService: NotificationService) {
    this.getFromData = this.getFromData.bind(this);
  }

  ngOnInit() {
    this.taskHeaders = this.maintenanceService.taskHeaders;

    this.subscriptions.push(
      this.authApi.getAuthorisedCompanies().subscribe({
        next: (companies) => {
          this.userCompanies = companies;

          if (this.userCompanies.length > 1) {
            this.multipleCompanies = true;
            this.fromCompanyId = this.globalService.getCurrentCompany().id;
          }
        },
        error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getFromData(e) {
    if (e) {
      if (e.selectedItem.id !== this.fromCompanyId) {
        this.fromCompanyId = e.selectedItem.id;
        this.loading = true;
        this.subscriptions.push(
          this.maintenanceService.getTemplateTaskHeadersFromAnotherCompany(e.selectedItem.id).subscribe({
            next: (res) => {
              this.taskHeaders = res;
              this.fromTaskHeaderId = null;
              this.loading = false;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
        );
      }
    }
  }

  runCopy() {
    this.loading = true;
    if (this.multipleCompanies && this.fromCompanyId !== this.globalService.getCurrentCompany().id) {
      this.subscriptions.push(
        this.maintenanceService.copyTemplateTasksFromAnotherCompany(this.fromCompanyId, this.fromTaskHeaderId, this.taskHeaderId)
          .subscribe({
            next: () => {
              this.activeModal.close();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    } else {
      this.subscriptions.push(
        this.maintenanceService.copyTemplateTasks(this.fromTaskHeaderId, this.taskHeaderId)
          .subscribe({
            next: () => {
              this.activeModal.close();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    }
  }

  close() {
    this.activeModal.dismiss();
  }
}

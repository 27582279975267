<div class="modal-header">
  <h4 class="modal-title">Send Call Ups</h4>
  <button *ngIf="!loading" type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <dx-data-grid *ngIf="!loading" #sendGrid id="sendGrid" [dataSource]="callUpData" [(selectedRowKeys)]="selectedRows"
    [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [allowColumnReordering]="false"
    [allowColumnResizing]="false" [columnAutoWidth]="false"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate')"
    [ngStyle]="{'max-height': 'calc(' + sendGridHeight + 'px)', 'min-height': 'calc(200px)'}">
    <dxo-selection selectAllMode="allPages" showCheckBoxesMode="always" mode="multiple" [allowSelectAll]="unSentOnly">
    </dxo-selection>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-search-panel [visible]="true" location="after" width="150px"></dxo-search-panel>

    <dxo-editing mode="row" [allowUpdating]="true" [useIcons]="true" startEditAction="click"> </dxo-editing>

    <dxi-column dataField="taskMasterId" dataType="number" caption="Title" [allowEditing]="false">
      <dxo-lookup [dataSource]="taskMasters" displayExpr="taskTitle" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="jobId" caption="Job" dataType="number" [allowEditing]="false" [width]="75">
      <dxo-lookup [dataSource]="jobs" displayExpr="jobNumber" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [allowEditing]="false">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="startDate" caption="Delivery / Start" dataType="date" [width]="65" alignment="center"
      format="d-MMM" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="vendorComment" caption="Vendor Comment" cellTemplate="commentCellTemplate"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="emailAddress" editCellTemplate="editEmailTemplate" [width]="275"
      [setCellValue]="setEmailCellValue"> </dxi-column>

    <dxi-column dataField="sendAddendum" [caption]='addendumCaption' dataType="boolean" [width]="50" [visible]='false'
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="hasAttachments" caption="" dataType="boolean" [width]="45"
      [calculateCellValue]='calculateHasAttachments' [allowEditing]="false" cellTemplate="attachmentCellTemplate2">
    </dxi-column>

    <dxi-column dataField="sendPO" caption="Send PO" dataType="boolean" [width]="55"
      [calculateCellValue]='calculateSendPO' [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="calledDate" caption="Date Sent" dataType="date" [width]="65" format="d-MMM" alignment="center"
      [allowEditing]="false" [visible]="!unSentOnly">
    </dxi-column>

    <dxi-column dataField="emailStatus" [width]="65" alignment="center" [allowEditing]="false"
      [calculateCellValue]="calculateSentFromQueueStatus" [visible]="!unSentOnly">
    </dxi-column>

    <dxi-column type="buttons" [width]="60">
      <dxi-button name="edit"></dxi-button>
    </dxi-column>


    <div *dxTemplate="let cellInfo of 'attachmentCellTemplate2'">
      <div *ngIf="cellInfo.value && cellInfo.value != ''">
        <span *ngIf="cellInfo.value[0] === 'A'" id="addendaAttached">{{addendumLetter}}</span>
        <i *ngIf="cellInfo.value[0] !== 'A' && cellInfo.value[0] !== 'D'" class="material-icons red"
          (click)="missingAttachmentsPopup(cellInfo.value)">error_outline</i>
        <i *ngIf="(cellInfo.value[0] === 'A' && cellInfo.value[1] === 'D') || cellInfo.value[0] === 'D'"
          class="material-icons">attach_file</i>
      </div>
    </div>

    <div *dxTemplate="let cellInfo of 'commentCellTemplate'">
      <div id="vendorComment" class='mx-0 px-0'>{{cellInfo.value}}</div>
    </div>

    <div *dxTemplate="let cellInfo of 'editEmailTemplate'">
      <div>
        <dx-text-box id="emailMeText" [value]="cellInfo.value"
          (onValueChanged)="setEmailValue($event, cellInfo)"></dx-text-box>
        <dx-button id="emailMeButton" text="Me" (onClick)="setEmailValueToMe(cellInfo)" [width]="30">
        </dx-button>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <span class="me-1">Un-Sent Only</span>
        <dx-check-box [(value)]="unSentOnly" [width]="30" (onValueChanged)="showUnSentOnlyChanged($event)">
        </dx-check-box>
      </div>
    </div>
  </dx-data-grid>

  <dx-button *ngIf="!loading && !anyMissingAttachments" text="Send" class="me-2 mt-1" type="default"
    (onClick)="sendCallUpsGo()" [disabled]="!selectedRows || !selectedRows.length">
  </dx-button>
  <!-- <dx-button *ngIf="!loadingCallUps" text="Cancel" class="mt-1" type="danger" (onClick)="cancelSendCallUps()">
      </dx-button> -->

  <span *ngIf="!loading" class="ms-4 me-2">cc email to yourself?</span>
  <dx-check-box *ngIf="!loading" [(value)]="ccToSelf" [width]="30"> </dx-check-box>

  <dx-button *ngIf="!loading && anyMissingAttachments" text="Send Anyway" class="mt-1" type="default"
    (onClick)="sendCallUpsGoWithMissing(false)">
  </dx-button>

  <!-- <dx-button *ngIf="!loading" text="Vendor Summaries" class="ms-2 mt-1 floatRight" type="default"
    (onClick)="vendorSummaries()">
  </dx-button> -->

  <dx-button *ngIf="!loading" text="Mark as Sent ONLY" class="mt-1 floatRight" type="outlined"
    (onClick)="markAsSentPopupVisible = true" [disabled]="!selectedRows || !selectedRows.length">
  </dx-button>
</div>


<!-- mark as send pop-up -->
<dx-popup class="popup" [width]="310" [height]="120" [showTitle]="true" title="Mark as Sent" [dragEnabled]="true"
  [hideOnOutsideClick]="true" [(visible)]="markAsSentPopupVisible" showCloseButton="true">
  <div *dxTemplate="let data of 'content'">
    <dx-button id="generateButton" text="Mark Call-Ups as Sent (Do not send)." type="danger"
      (onClick)="sendCallUpsGoWithMissing(true)">
    </dx-button>
  </div>
</dx-popup>


<!-- missing attachments pop-up -->
<dx-popup class="popup" [width]="320" [height]="370" [showTitle]="true" title="Attachments Missing"
  [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="missingAttachmentsPopupVisible" showCloseButton="true">
  <div *dxTemplate="let data of 'content'">
    <dx-data-grid id="missingAttachmentGrid" class="m-auto" [dataSource]="missingAttachments" keyExpr="id"
      [height]="280" [showBorders]="true" [rowAlternationEnabled]="false" [wordWrapEnabled]="true"  (onExporting)="gridService.onExporting($event)"
      [allowColumnResizing]="false">

      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-export [enabled]="true"></dxo-export>
      <dxi-column dataField="description" caption="Info Documents NOT found" dataType="string"> </dxi-column>
    </dx-data-grid>
  </div>
</dx-popup>


<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>

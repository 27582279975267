<div class="modal-header">
  <h4 class="modal-title">Copy tasks from another template</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <div class="page">

    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <div *ngIf="!loading && multipleCompanies" class="w400">
      <div class="dx-field-label">From Company:</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="userCompanies" displayExpr="companyName" valueExpr="id" [value]='fromCompanyId'
          (onSelectionChanged)='getFromData($event)'>
        </dx-select-box>
      </div>
    </div>

    <div *ngIf="!loading" class="w400">
      <div class="dx-field-label">From Workflow:</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="taskHeaders" displayExpr="description" valueExpr="id"
          [(value)]='fromTaskHeaderId'>
        </dx-select-box>
      </div>
    </div>

  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-secondary" (click)="close()">Close</button>
  <button class="btn btn-primary" (click)="runCopy()" [disabled]="!fromTaskHeaderId">Run Copy</button>
</div>

import { NotificationService } from './../../services/notification.service';
import { JobService } from './../../services/felixApi/job.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TrackingColourEnum } from '../../dtos/tracking-colour.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-colour-job',
  templateUrl: './colour-job.component.html',
  styleUrls: ['./colour-job.component.scss']
})
export class ColourJobComponent implements OnInit {
  trackingColourEnum = TrackingColourEnum;
  subscriptions: Subscription[] = [];
  updating = false;

  constructor(
    private _activeModal: NgbActiveModal,
    private jobService: JobService,
    private notiService: NotificationService
  ) { }

  ngOnInit() {
  }

  cancel() {
    this._activeModal.dismiss();
  }

  markColour(colourId: number) {
    if (this.jobService.currentJobExtra) {
      // edit
      if (colourId.toString() !== this.jobService.currentJobExtra.trackingColour) {
        this.updating = true;
        this.subscriptions.push(
          this.jobService.updateJobExtra(this.jobService.currentJobExtra.id, { trackingColour: colourId })
            .subscribe({
              next: () => {
                this.notiService.showSuccess('Colour updated');
                this._activeModal.close(colourId);
              },
              error: (err) => {
                this.updating = false;
                this.notiService.notify(err);
              }
            })
        );
      } else {
        this.notiService.showInfo('Colour already set');
      }
    } else {
      // add
      this.updating = true;
      this.subscriptions.push(
        this.jobService.addJobExtra({ jobId: this.jobService.currentJob.id, trackingColour: colourId })
          .subscribe({
            next: () => {
              this.notiService.showSuccess('Colour updated');
              this._activeModal.close(colourId);
            },
            error: (err) => {
              this.updating = false;
              this.notiService.notify(err);
            }
          })
      );
    }
  }

}

import { Injectable } from '@angular/core';

export class HelpDictionary {
  dataField: string;
  title: string;
  helpText: string;
}

const helpDictionary: HelpDictionary[] = [{
  dataField: 'leadDays',
  title: 'Lead Days',
  helpText: 'The days between the completion of the previous task to the start of this task'
}, {
  dataField: 'taskDays',
  title: 'Task Days',
  helpText: 'Read only field from the task maintenance screen for reference here only'
}, {
  dataField: 'costPercentSupplier',
  title: '% Cost Supplier',
  helpText: 'Cash flow: The percentage of the job cost applicable to suppliers at the completion of this task'
}, {
  dataField: 'costFixedSupplier',
  title: 'Fixed Cost Supplier',
  helpText: 'Cash flow: A fixed cost amount applicable to suppliers at the completion of this task. This is on top of the job cost total specified. E.g. Sales Commission'
}, {
  dataField: 'supplierPayTermsTypeId',
  title: 'Supplier Terms',
  helpText: 'Cash flow: Is invoice due date calculated from the date of the invoice (Invoice) or the end of the month (Statement)'
}, {
  dataField: 'supplierPayTermsDays',
  title: 'Supplier Terms Days',
  helpText: 'Cash flow: The due date for payment is this number of days following either the invoice date (if Supplier Terms is Invoice) or the end of the month (if Supplier Terms is Statement). E.g. 30 days from Statement will mean the payment is due 30th of the next month (or the last day of the month if shorter)'
}, {
  dataField: 'costPercentSubContractor',
  title: '% Cost Subbie',
  helpText: 'Cash flow: The percentage of the job cost applicable to sub-contractors at the completion of this task'
}, {
  dataField: 'costFixedSubContractor',
  title: 'Fixed Cost Subbie',
  helpText: 'Cash flow: A fixed cost amount applicable to subcontractors at the completion of this task. This is on top of the job cost total specified. E.g. $500 for a fee that is invoiced direct to a GL account'
}, {
  dataField: 'subContractorPayFrequency',
  title: 'Subbie Pay Frequency',
  helpText: 'Cash flow: The frequency in days at which subcontractors for this task are paid. e.g. 7 day or 14 day cycle'
}, {
  dataField: 'activityToSetStartDateId',
  title: 'Set Start For Activity',
  helpText: 'The delivery/start date entered for this task sets the start date for this activity'
}, {
  dataField: 'activityToSetEndDateId',
  title: 'Set End For Activity',
  helpText: 'The end/completion date entered for this task sets the end date for this activity'
}, {
  dataField: 'isInitiatedBySalesDate',
  title: 'Initiated by Sale / Prev Work Flow',
  helpText: 'For preconstruction workflows, this task is created when the sales date (or sales quote signed if enabled) is entered.\nFor other workflows, this task is created by the completion of the previous workflow specified for that job'
}, {
  dataField: 'isForecastStart',
  title: 'Site Start Task',
  helpText: 'Construction workflows only. This task is considered as site start. Dates against this task will reset the forecast dates for the job including completion'
}, {
  dataField: 'isPracticalCompletionTask',
  title: 'Practical Completion',
  helpText: 'The task considered as practical completion of the job for accounting and profit reporting'
}, {
  dataField: 'isKeyHandoverTask',
  title: 'Key Handover',
  helpText: 'The task considered as the key handover to the client.'
}, {
  dataField: 'isFormalApproval',
  title: 'Formal Approval',
  helpText: 'The task considered as the formal finance approval for the job.\nUsed in the cashflow where, for example, a 30% cancellation rate would mean that only 70% of cost and income of each job, with no formal approval, is inluded in the cash flow calculations'
}, {
  dataField: 'userId',
  title: 'Assign to User',
  helpText: 'Auto assign these tasks to this user. The Role user will override this if set'
}, {
  dataField: 'roleId',
  title: 'Assign to Role',
  helpText: 'Auto assign these tasks to the user set for the role for this job.\nSetting Assign to Previous User will override this but only on task creation.\nIf the user set to the role for a job is changed then all tasks not completed and not cancelled will have the assignee changed to that user'
}, {
  dataField: 'isAssignToPreviousUser',
  title: 'Assign to Previous User',
  helpText: 'Auto assign this task to the user assigned to the previous task that was completed and triggered this task'
}, {
  dataField: 'isNextWorkFlowTrigger',
  title: 'Trigger Next Work Flow',
  helpText: 'This task when completed, triggers the next work flow in the order defined in the Job-Data -> Work-Flows'
}, {
  dataField: 'isMultipleAllowed',
  title: 'Multiple Created',
  helpText: 'Allow multiple of these tasks to be created via the workflow. Cancelled task are ignored'
}, {
  dataField: 'isCreatedByPostContractVO',
  title: 'Created By Post Contract Variation',
  helpText: 'Only one can be specified. When a post-contract variation is created the system will ask to create this task'
}, {
  dataField: 'isCreatedBySalesVariation',
  title: 'Created By Sales Variation',
  helpText: 'Only one can be specified. When a sales variation is created the system will ask to create this task'
}, {
  dataField: 'isCreatedByPreContractVariation',
  title: 'Created By Pre-Contract Variation',
  helpText: 'Only one can be specified. When a post-contract variation is created the system will ask to create this task'
}, {
  dataField: 'copyCommentFromPrev',
  title: 'Copy Comment from Preceeding Task',
  helpText: 'If this task is created via the workflow then the comment from the preceding task will be copied to this task'
}, {
  dataField: 'tradeId',
  title: 'Trade',
  helpText: 'The trade associated with this task. The trade along with the region will filter the vendor list'
}, {
  dataField: 'vendorId',
  title: 'Default Vendor',
  helpText: 'When the call-up task is created this vendor is auto-selected'
}, {
  dataField: 'doNotSendCallup',
  title: 'Do Not Send',
  helpText: 'When the call-up task is created, set the Do Not Send Call-Up to true. This can be overriden via the Call-Ups if required. Do Not Send means that this task will not appear in the Send window and an email cannot be generated'
}, {
  dataField: 'includeAddendum',
  title: 'Attach Addendum/Specification',
  helpText: 'When the call-up task is created, set the Include Addendum/Specification flag to true. This can be overriden via the Call-Ups if required'
}, {
  dataField: 'vendorEqualToCode',
  title: 'Same Vendor Code',
  helpText: 'When the vendor on a call-up task is set, other tasks with this same vendor code specified will also have their vendor set to the one selected. The code can be any text.'
    + '\nE.g. setting a plumber on one task can set the same plumber on other call-ups automatically.'
    + '\nNote: This will only apply to call-ups not sent and not completed'
}, {
  dataField: 'notApplicableLinkCode',
  title: 'Not Applicable Link Code',
  helpText: 'When the a call-up task is marked Not-Applicable, other tasks with this same code specified will also be marked Not-Applicable. The code can be any text.'
    + '\nNote: This will only apply to call-ups not sent and not completed'
}, {
  dataField: 'isUseWorkFlow',
  title: 'Use Start to Start Workflow',
  helpText: 'Construction workflows do not trigger the creation of tasks as creation is done via the Create button in the Call-Ups screen.'
    + '\nSelect this flag to turn ON the workflow from this task to any successors linked from the START of this task.'
    + '\nSuccessor tasks not sent and not completed will have their start date set to the same start date specified.'
}, {
  dataField: 'isEndToStartEnabled',
  title: 'Use End to Start Workflow',
  helpText: 'Construction workflows do not trigger the creation of tasks as creation is done via the Create button in the Call-Ups screen.'
    + '\nSelect this flag to turn ON the workflow from this task to any successors linked from the END of this task.'
    + '\nSuccessor tasks will be created as normal from this task depending on the Multiple Created flag and if created will set the start date if blank.'
}, {
  dataField: 'callUpDocsTypes',
  title: 'Info Doc Types',
  helpText: 'The system will check the Info tab in the main Truth Engine application for attachments flagged with these types and send these with the call-up. E.g. to send plans.'
}, {
  dataField: 'costCentreId',
  title: 'Order Cost Centre',
  helpText: 'When creating the call-up (or Create -> Allocate PO Numbers) the system will try to auto match to a purchase order from this cost centre (trade code) and with the Order Phase also matching'
}, {
  dataField: 'orderPhaseId',
  title: 'Order Phase',
  helpText: 'When creating the call-up (or Create -> Allocate PO Numbers) the system will try to auto match to a purchase order from this Phase and with the Order Cost Centre (trade code) also matching'
}, {
  dataField: 'sendPOWithCallUp',
  title: 'Send PO with Call-Up',
  helpText: 'When sending the call-up the purchase order is ONLY attached when this is selected'
}, {
  dataField: 'isShowForecastStart',
  title: 'Show Forecast Start',
  helpText: 'If selected, and a start/delivery date has not been set on a call-up, the system will work out the expected start date via the workflow. This forecast date is shown in light grey/gold.'
}, {
  dataField: 'callUpSendLeadDays',
  title: 'Send Lead Days',
  helpText: 'Only applicable if the Show Forecast Start is selected.'
    + '\nIf a start/delivery date has not been set, the system will work out the expected start date via the workflow. This forecast date is shown in light grey/gold.'
    + '\nIf, for example, you require the call-up to be sent 10 days before the start/delivery date you can set this field to 10. This will make the forecast start date go red if today is 10 days prior to the forecast date.'
}, {
  dataField: 'isCreatedByConstructionVOApproved',
  title: 'Created By Approved Construction Variation',
  helpText: 'Only one can be specified. When a Construction variation is approved the system will create this task to action the variation'
}, {
  dataField: 'isHideNotApplicable',
  title: 'Hide if Not Applicable',
  helpText: 'Hide this task in the call-ups if set to Not Applicable'
}, {
  dataField: 'salesCannotCreate',
  title: 'Sales CANNOT Create',
  helpText: 'This task cannot be created from the Sales-Tasks tab in the main application, so is hidden from the drop down'
}, {
  dataField: 'mustHavePoToSend',
  title: 'Must have PO to Send',
  helpText: 'Cannot send this call-up if no PO specified'
}, {
  dataField: 'isAuthorisedByStartDate',
  title: 'Authorise By Start Date',
  helpText: 'Invoices need to be marked as Work-Done as part of the authorisation process.'
    + '\nThe system will attempt to find a call-up with that purchase order specified and if it is marked complete then the invoice is automatically marked as Work-Done.'
    + '\nUse this flag to tell the system to check the Start/Delivery date instead of the completion date. The Start/Delivery date must have passed for the invoice to be auto marked Work-Done.'
}];




@Injectable({
  providedIn: 'root'
})
export class HelpService {
  getHelpDictionary() {
    return helpDictionary;
  }
}

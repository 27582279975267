import { HelpDictionary, HelpService } from './../services/help.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'js-help',
  template: `
    <ng-container>
        <i (click)="$event.preventDefault(); $event.stopPropagation(); help()" class="material-icons md-18 help">help_outline</i>
    </ng-container>
    `,
  styles: ['.help {color: #b9b7b7;} .help:hover {color: grey; cursor: pointer;}']
})
export class HelpComponent implements OnInit {
  @Input() dataField: string;

  helpDictionary: HelpDictionary[];
  salesQuoteSignedDateShown = false;

  constructor(private modalService: NgbModal, private helpService: HelpService) { }

  ngOnInit() {
  }

  help() {
    this.helpDictionary = this.helpService.getHelpDictionary();
    const helpRecord = this.helpDictionary.find(i => i.dataField === this.dataField);

    const modalRef = this.modalService.open(HelpModalComponent, { windowClass: 'modal-help' });
    if (helpRecord) {
      modalRef.componentInstance.title = helpRecord.title;
      modalRef.componentInstance.text = helpRecord.helpText;
    } else {
      modalRef.componentInstance.title = this.dataField;
      modalRef.componentInstance.text = 'No help defined for this field';
    }

    modalRef.result.then(() => { });
  }

}

@Component({
  selector: 'js-help-modal',
  template: `
        <div *ngIf="title" style="height: 50px; padding-top: 8px;" class="modal-header">
            <b class="modal-title" style="font-size: 18px;">{{title}}</b>
            <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="font-size: 15px;">
            <div style="white-space: break-spaces;" [innerHTML]="sanitisedTemplate"></div>
        </div>
    `
})
export class HelpModalComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;

  sanitisedTemplate: SafeHtml;

  constructor(private sanitiser: DomSanitizer, private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.sanitisedTemplate = this.sanitiser.bypassSecurityTrustHtml(this.text);
  }

  cancel() {
    this.activeModal.close();
  }

}

<dx-tab-panel #panel height="calc(100vh - 49px)" [dataSource]="sections" [selectedIndex]="0" [loop]="false"
  [swipeEnabled]="false" (onTitleClick)="tabSelected($event)">

  <div *dxTemplate="let title of 'title'">
    <span [ngClass]="{'header': title == helper.selectedSection}">{{title}}</span>
  </div>

  <div *dxTemplate="let section of 'item'">
    <div style="height: calc(100vh - 100px);">
      <js-task-types *ngIf="section === 'Task Types'"></js-task-types>
      <js-task-masters *ngIf="section === 'Tasks'" [counter]=counter></js-task-masters>
      <js-template-task-headers *ngIf="section === 'Workflow Templates'"></js-template-task-headers>
      <js-template-tasks *ngIf="section === 'Workflows'" [counter]=counter></js-template-tasks>
      <js-template-days-headers *ngIf="section === 'Overriding Days'" [counter]=counter></js-template-days-headers>
      <js-holidays *ngIf="section === 'Holidays'"></js-holidays>
      <js-tracking-fields *ngIf="section === 'Tracking Fields'"></js-tracking-fields>
      <js-task-control *ngIf="section === 'Control'"></js-task-control>
      <js-trades *ngIf="section === 'Trades'"></js-trades>
      <js-trade-regions *ngIf="section === 'Trade Regions'"></js-trade-regions>
      <js-call-up-doc-type *ngIf="section === 'Call-Up Docs'"></js-call-up-doc-type>
      <js-document-folders *ngIf="section === 'Document Folders'"></js-document-folders>
    </div>
  </div>
</dx-tab-panel>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobCashFlowComponent } from './job-cash-flow.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [JobCashFlowComponent]
})
export class JobCashFlowModule { }

import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { GridService } from '../../services/grid.service';
import { GlobalService } from '../../services/global.service';
import { NotificationService } from '../../services/notification.service';
import { UserService } from '../../services/felixApi/user.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { VariationWorkflowService } from '../../services/felixApi/variation-workflow.service';
import { Division } from '../../dtos/division';
import { User } from '../../dtos/user';
import { VariationTypeEnum } from '../../dtos/variation-type.enum';
import { TemplateTaskHeader } from '../../dtos/templateTaskHeader';

@Component({
  selector: 'js-variation-workflows',
  templateUrl: './variation-workflows.component.html',
  styleUrls: ['./variation-workflows.component.scss']
})
export class VariationWorkflowsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  loading: boolean;
  gridHeight: number;
  divisions: Division[];
  users: User[];
  variationTypes: { id: number; description: string; }[] = [
    { id: VariationTypeEnum.PreConstruction, description: 'PreConstruction' },
    { id: VariationTypeEnum.Construction, description: 'Construction' },
    { id: VariationTypeEnum.Office, description: 'Office/No Charge' },
    { id: VariationTypeEnum.FinalAccount, description: 'Final Account' },
    { id: VariationTypeEnum.PreContractVariation, description: 'Pre-Contract Variation' },
    { id: VariationTypeEnum.PreContractProvisionalSum, description: 'Pre-Contract Siteworks & Preliminaries' },
    { id: VariationTypeEnum.PreContractOffice, description: 'Pre-Contract Office/Discount' },
    { id: VariationTypeEnum.PreContractExFromContract, description: 'Excluded from Contract' },
    { id: VariationTypeEnum.SalesVariation, description: 'Sales Variation' },
    { id: VariationTypeEnum.SalesProvisionalSum, description: 'Sales Siteworks & Preliminaries' },
    { id: VariationTypeEnum.SalesOffice, description: 'Sales Office/Discount' },
  ];
  variationStatuses: { id: number; description: string; }[] = [
    { id: 1, description: 'On Create' },
    { id: 2, description: 'Estimating' },
    { id: 5, description: 'Pending Approval' },
    { id: 7, description: 'Approved' },
  ];
  taskHeaders: TemplateTaskHeader[];

  constructor(
    private activeModal: NgbActiveModal,
    private variationWorkflowService: VariationWorkflowService,
    private maintenanceService: MaintenanceService,
    private userService: UserService,
    private notiService: NotificationService,
    protected gridService: GridService,
    private globalService: GlobalService) {
  }

  ngOnInit() {
    this.gridHeight = window.innerHeight - 300;
    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getData() {
    this.subscriptions.push(
      this.variationWorkflowService.getVariationWorkflowData()
        .subscribe({
          next: (divisions) => {
            this.divisions = divisions;
            this.taskHeaders = this.maintenanceService.taskHeaders;
            this.users = this.userService.officeUsers;
            this.setUpDataSet();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  setUpDataSet() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.variationWorkflowService.getVariationWorkflows(false).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.variationWorkflowService.addVariationWorkflow(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.variationWorkflowService.updateVariationWorkflow(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.variationWorkflowService.deleteVariationWorkflow(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onInitNewRow(e) {
    e.data.askUserToCreate = true;
  }

  close() {
    this.activeModal.close();
  }
}

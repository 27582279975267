import { JobEmailAttachment } from "./job-email-attachment";

export class JobEmail {
    public id: number;
    public jobId: number;
    public jobNumber: string;
    public dateSent: Date;
    public dateReceived: Date;
    public fromEmail: string;
    public toEmail: string;
    public ccToEmail: string;
    public subject: string;
    public bodyAsText: string;
    public emailServerId: string;
    public emailFileId: string;
    public jobEmailTypeId: number;
    public hasBlobs: boolean;

    public createDate: Date;
    public createUserId: number;

    public date: Date;

    public attachments: JobEmailAttachment[];
}

export enum JobEmailTypeEnum {
  Email = 1,
  Note = 2
}

import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-trades',
  templateUrl: './trades.component.html',
  styleUrls: ['./trades.component.scss']
})
export class TradesComponent implements OnInit {

  dataSource: CustomStore;

  constructor(
    private maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    protected gridService: GridService
  ) { }

  ngOnInit(): void {
    this.setUpDataSource();
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.getTrades(false).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.addTrade(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.updateTrade(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.deleteTrade(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }
}

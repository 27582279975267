import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { Subscription, debounceTime } from 'rxjs';
import { NotificationService } from '../services/notification.service';
import { GlobalService } from '../services/global.service';
import { GridService } from '../services/grid.service';
import { JobService } from '../services/felixApi/job.service';
import { TemplateTaskHeader } from '../dtos/templateTaskHeader';
import { Task } from '../dtos/task';
import { TaskType } from '../dtos/task-type';
import { User } from '../dtos/user';
import { Vendor } from '../dtos/vendor';
import { AuthService } from '../services/auth.service';
import { JobWorkFlowService } from '../services/felixApi/job-work-flow.service';
import { MaintenanceService } from '../services/felixApi/maintenance.service';
import { TaskService } from '../services/felixApi/task.service';
import { UserService } from '../services/felixApi/user.service';
import { Variation } from '../dtos/variation';
import DataSource from 'devextreme/data/data_source';
import DateBox, { Properties } from "devextreme/ui/date_box";
import { TaskMaster } from '../dtos/task-master';
import { JobTaskLog } from '../dtos/job-task-log';
import { VariationStatusOptions } from '../dtos/variation-status.enum';
import { PurchaseOrder } from '../dtos/purchase-order';
import { TaskStatusOptions } from '../dtos/task-status.enum';
import { Job } from '../dtos/job';

@Component({
  selector: 'js-job-task-log',
  templateUrl: './job-task-log.component.html',
  styleUrls: ['./job-task-log.component.scss']
})
export class JobTaskLogComponent implements OnInit, OnChanges, OnDestroy {
  @Input() jobNumber: string;

  loadingTasks = true;
  subscriptions: Subscription[] = [];
  showFullComments = false;
  gridHeight: number;
  vendors: Vendor[];
  jobVariations: Variation[];
  taskTypes: TaskType[];
  taskStatusOptions = TaskStatusOptions;
  taskMasters: TaskMaster[];
  users: User[];
  templateTaskHeaders: TemplateTaskHeader[];
  variations: Variation[];
  keyTasksOnly = true;
  dataSource: DataSource;
  shownJobTaskLogs: JobTaskLog[] = [];
  jobTaskLogs: JobTaskLog[];
  variationStatus = VariationStatusOptions;
  showDateChangesOnly = true;
  purchaseOrders: PurchaseOrder[];
  dateFrom: Date;
  showAllJobs = false;
  jobs: Job[];

  constructor(
    private taskService: TaskService,
    private auth: AuthService,
    private maintenanceService: MaintenanceService,
    private notiService: NotificationService,
    private globalService: GlobalService,
    protected gridService: GridService,
    protected jobWorkFlowService: JobWorkFlowService,
    private userService: UserService,
    private jobService: JobService
  ) {
    this.calculateTaskSortValue = this.calculateTaskSortValue.bind(this);
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
    this.calculateKeyTaskValue = this.calculateKeyTaskValue.bind(this);
    this.calculateLoadSortValue = this.calculateLoadSortValue.bind(this);

    DateBox.defaultOptions<Properties>({
      device: [
        { deviceType: 'desktop' },
        { deviceType: 'tablet' },
        { deviceType: 'phone' }
      ],
      options: {
        pickerType: 'calendar'
      }
    });
  }

  ngOnInit(): void {
    this.setGridMeasurements();
    this.subscribeToInnerHeight();
  }

  ngOnChanges(): void {
    this.dateFrom = new Date();
    // get the date less 7 days
    this.dateFrom.setDate(new Date().getDate() - 7);
    this.getData(true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  subscribeToInnerHeight() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.pipe(debounceTime(250)).subscribe(
        () => {
          setTimeout(() => {
            this.setGridMeasurements();
          }, 500); // wait for iPhone
        }
      )
    );
  }

  setGridMeasurements() {
    this.gridHeight = window.innerHeight - 155;
  }

  onEditorPreparing(e) {
    if (e.dataField === 'taskTypeId' || e.dataField === 'taskMasterId' 
      || e.dataField === 'templateTaskHeaderId' || e.dataField === 'vendorId') {
      e.editorOptions.dropDownOptions = { minWidth: 350 };
    }
    if (e.dataField === 'userId' || e.dataField === 'modifiedUserId') {
      e.editorOptions.dropDownOptions = { minWidth: 220 };
    }
    if (e.dataField === 'jobId' || e.dataField === 'statusId' 
      || e.dataField === 'purchaseOrderId' || e.dataField === 'jobVariationId') {
      e.editorOptions.dropDownOptions = { minWidth: 120 };
    }
    if (e.dataType === 'boolean') {
      e.editorOptions.dropDownOptions = { minWidth: 70 };
    }
  }

  getData(useCache: boolean) {
    if (!this.dateFrom && this.showAllJobs) {
      this.notiService.showWarning('Please select a date to show all jobs');
    } else {
      this.loadingTasks = true;

      this.subscriptions.push(
        this.taskService.getJobTaskLogsData(this.jobService.currentJob.id,
          useCache, this.keyTasksOnly, this.dateFrom, this.showAllJobs)
          .subscribe({
            next: (jobTaskLogs) => {
              this.jobTaskLogs = jobTaskLogs;
              this.jobVariations = this.jobService.currentJobVariations;
              this.taskTypes = this.maintenanceService.taskTypes;
              this.taskMasters = this.maintenanceService.taskMasters;
              this.templateTaskHeaders = this.maintenanceService.taskHeaders;
              this.users = this.userService.users;
              this.variations = this.jobService.currentJobVariations;
              this.vendors = this.userService.vendors;
              this.purchaseOrders = this.maintenanceService.purchaseOrders;
              this.jobs = this.jobService.jobs;

              this.setShownTasks();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loadingTasks = false;
            }
          })
      );
    }
  }

  setShownTasks() {
    if (this.showDateChangesOnly) {
      this.shownJobTaskLogs = this.jobTaskLogs.filter(i => i.startDateChanged || i.dueDateChanged || i.endDateChanged);
    } else {
      this.shownJobTaskLogs = this.jobTaskLogs;
    }
    this.setUpDataSource();
  }

  setUpDataSource() {
    this.loadingTasks = false;
    this.dataSource = new DataSource({
      key: 'id',
      loadMode: 'raw',
      load: () => this.shownJobTaskLogs
    });
  }

  refresh() {
    this.jobService.jobs = [];
    this.userService.users = [];
    this.jobService.jobVariations = [];
    this.getData(false);
  }

  calculateKeyTaskValue(data) {
    if (data && data.taskMasterId) {
      const taskMaster = this.maintenanceService.taskMasters.find(i => i.id === data.taskMasterId);
      return taskMaster.isKeyTask;
    }
    return false;
  }

  onToolbarPreparing(e, toolbarTemplate) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Date Changes only',
          rtlEnabled: true,
          value: this.showDateChangesOnly,
          onValueChanged: this.showDateChangesOnlyChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Key tasks only',
          rtlEnabled: true,
          value: this.keyTasksOnly,
          onValueChanged: this.showKeyTasksChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show full comment',
          rtlEnabled: true,
          value: true,
          onValueChanged: this.showFullComment.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'All Jobs',
          rtlEnabled: true,
          value: this.showAllJobs,
          onValueChanged: this.showAllJobsChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        template: toolbarTemplate
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      }
    );
  }

  calculateTaskSortValue(data) {
    let sortOrder = 0;
    const task = this.maintenanceService.taskMasters.find(i => i.id === data.taskMasterId);
    if (task) {
      sortOrder = task.orderNumber;
    }

    return sortOrder;
  }

  showFullComment() {
    this.showFullComments = !this.showFullComments;
  }

  showKeyTasksChanged() {
    this.keyTasksOnly = !this.keyTasksOnly;
    this.getData(true);
  }

  showDateChangesOnlyChanged() {
    this.showDateChangesOnly = !this.showDateChangesOnly;
    this.setShownTasks();
  }

  dateFromChanged(e) {
    if (this.dateFrom !== e.value && !this.loadingTasks) {
      this.dateFrom = e.value;
      this.getData(true);
    }
  }

  showAllJobsChanged() {
    this.showAllJobs = !this.showAllJobs;
    this.getData(true);
  }

  calculateVendorSortValue(data) {
    return this.vendors.find(i => i.id === data.vendorId)?.vendorName;
  }

  calculateLoadSortValue(jobTask: Task) {
    let orderNumber = 0;
    let description = 'Ad-Hoc';
    if (jobTask.taskTypeId) {
      const taskType = this.maintenanceService.taskTypes.find(i => i.id === jobTask.taskTypeId);
      if (taskType) {
        orderNumber = taskType.orderNumber;
        description = taskType.description;
        jobTask.taskTypeOrderNumber = taskType.orderNumber;
      }
    }
    return ('00000' + orderNumber.toString()).slice(-6) + ';' + description;
  }
}

import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { TrackingField } from '../../dtos/tracking-field';
import { JobField } from '../../dtos/job-field';
import { UserService } from './user.service';
import { TrackingFieldLookup } from '../../dtos/tracking-field-lookup';
import { User } from '../../dtos/user';
import { TrackingFieldGroup } from '../../dtos/tracking-field-group';
import { HttpService } from '../http.service';
import { MaintenanceService } from './maintenance.service';

@Injectable({
  providedIn: 'root'
})
export class TrackingFieldsService {
  trackingFieldsCompany: number;
  trackingFields: TrackingField[] = [];
  trackingFieldLookupsCompany: number;
  allTrackingFieldLookups: TrackingFieldLookup[] = [];
  allJobFields: JobField[] = [];
  allJobFieldsCompany: number;
  trackingFieldGroups: TrackingFieldGroup[] = [];
  trackingFieldGroupsCompany: number;

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService,
    private maintenanceService: MaintenanceService,
    private userService: UserService) { }

  getTrackingFields(activeOnly: boolean, useCache: boolean): Observable<TrackingField[]> {
    if (useCache && this.trackingFieldsCompany === this.globalService.getCurrentCompany().id
      && this.trackingFields && this.trackingFields.length) {
      return of(this.trackingFields.filter(i => i.isActive || !activeOnly));
    } else {
      return this._http.get<TrackingField[]>(this.globalService.getApiUrl() +
        '/tracking-fields?activeOnly=false', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.trackingFields = res;
            this.trackingFieldsCompany = this.globalService.getCurrentCompany().id;
            if (activeOnly) {
              res = res.filter(i => i.isActive);
            }
          }),
          catchError(this.handleError));
    }
  }

  addTrackingField(dataRecord: any): Observable<TrackingField> {
    const url = this.globalService.getApiUrl() + '/tracking-fields';
    return this._http.post<TrackingField>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateTrackingField(id: string, itm: any): Observable<TrackingField> {
    const url = this.globalService.getApiUrl() + '/tracking-fields/' + id;
    return this._http.patch<TrackingField>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteTrackingField(id: string) {
    const url = this.globalService.getApiUrl() + '/tracking-fields/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  /* Field Groups */

  getTrackingFieldGroups(activeOnly: boolean, useCache: boolean): Observable<TrackingFieldGroup[]> {
    if (useCache && this.trackingFieldGroupsCompany === this.globalService.getCurrentCompany().id
      && this.trackingFieldGroups && this.trackingFieldGroups.length) {
      return of(this.trackingFieldGroups.filter(i => i.isActive || !activeOnly));
    } else {
      return this._http.get<TrackingFieldGroup[]>(this.globalService.getApiUrl() +
        '/tracking-field-groups?activeOnly=false', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.trackingFieldGroups = res; this.trackingFieldGroupsCompany = this.globalService.getCurrentCompany().id;
            if (activeOnly) {
              res = res.filter(i => i.isActive);
            }
          }),
          catchError(this.handleError));
    }
  }

  addTrackingFieldGroup(dataRecord: any): Observable<TrackingFieldGroup> {
    const url = this.globalService.getApiUrl() + '/tracking-field-groups';
    return this._http.post<TrackingFieldGroup>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateTrackingFieldGroup(id: string, itm: any): Observable<TrackingFieldGroup> {
    const url = this.globalService.getApiUrl() + '/tracking-field-groups/' + id;
    return this._http.patch<TrackingFieldGroup>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteTrackingFieldGroup(id: string) {
    const url = this.globalService.getApiUrl() + '/tracking-field-groups/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  /* Field Lookups */

  getAllTrackingFieldLookups(useCache: boolean): Observable<TrackingFieldLookup[]> {
    if (useCache && this.trackingFieldLookupsCompany === this.globalService.getCurrentCompany().id
      && this.allTrackingFieldLookups && this.allTrackingFieldLookups.length) {
      return of(this.allTrackingFieldLookups);
    } else {
      return this._http.get<TrackingFieldLookup[]>(this.globalService.getApiUrl() +
        '/tracking-field-lookups', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.allTrackingFieldLookups = res; this.trackingFieldLookupsCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }

  getTrackingFieldLookups(trackingFieldId: number): Observable<TrackingFieldLookup[]> {
    return this._http.get<TrackingFieldLookup[]>(this.globalService.getApiUrl() +
      '/tracking-field/' + trackingFieldId + '/lookups', this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addTrackingFieldLookup(dataRecord: any): Observable<TrackingFieldLookup> {
    const url = this.globalService.getApiUrl() + '/tracking-field-lookups';
    return this._http.post<TrackingFieldLookup>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateTrackingFieldLookup(id: string, itm: any): Observable<TrackingFieldLookup> {
    const url = this.globalService.getApiUrl() + '/tracking-field-lookups/' + id;
    return this._http.patch<TrackingFieldLookup>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteTrackingFieldLookup(id: string) {
    const url = this.globalService.getApiUrl() + '/tracking-field-lookups/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  /* Job Fields */

  getJobFields(jobId: number): Observable<JobField[]> {
    return this._http.get<JobField[]>(this.globalService.getApiUrl() +
      '/job/' + jobId + '/fields', this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }


  addJobField(dataRecord: any): Observable<JobField> {
    const url = this.globalService.getApiUrl() + '/job-fields';
    return this._http.post<JobField>(url, JSON.stringify(dataRecord, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }

  updateJobField(jobId: number, id: string, itm: any): Observable<JobField> {
    const url = this.globalService.getApiUrl() + '/job/' + jobId + '/field/' + id;
    return this._http.patch<JobField>(url, JSON.stringify(itm, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }

  getJobFieldsData(): Observable<User[]> {
    return forkJoin(
      [
        this.userService.getCurrCompUsers(true),
        this.getAllTrackingFieldLookups(true),
        this.getTrackingFields(true, true),
        this.getTrackingFieldGroups(true, true),
        this.maintenanceService.getTemplateTaskHeaders(true, true),
        this.maintenanceService.getHolidays(true)
      ]
    )
      .pipe(map(
        ([users]) => {
          return users;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getAllJobFields(useCache: boolean, includeInactiveJobs: boolean): Observable<JobField[]> {
    if (useCache && this.allJobFieldsCompany === this.globalService.getCurrentCompany().id
      && this.allJobFields && this.allJobFields.length) {
      return of(this.allJobFields);
    } else {
      return this._http.get<JobField[]>(this.globalService.getApiUrl() +
        '/job-fields?includeInactiveJobs=' + includeInactiveJobs, this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.allJobFields = res;
            this.allJobFieldsCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'js-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  @Input() message: string;
  @Input() acceptMessage: string;

  constructor(private _activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  close() {
    this._activeModal.dismiss();
  }

  accept() {
    this._activeModal.close();
  }
}

import { GridService } from './../../../services/grid.service';
import { GlobalService } from './../../../services/global.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MaintenanceService } from '../../../services/felixApi/maintenance.service';
import { NotificationService } from '../../../services/notification.service';
import { TemplateTaskHeader } from '../../../dtos/templateTaskHeader';
import { Company } from '../../../dtos/company';
import { TemplateTask } from '../../../dtos/templateTask';
import DataSource from 'devextreme/data/data_source';
import { PriceFileItem } from '../../../dtos/price-file-item';

@Component({
  selector: 'js-template-task-cost-centre-modal',
  templateUrl: './template-task-cost-centre-modal.component.html',
  styleUrls: ['./template-task-cost-centre-modal.component.scss']
})
export class TemplateTaskCostCentreModalComponent implements OnInit, OnDestroy {
  @Input() templateTaskHeaderId: number;
  @Input() templateTask: TemplateTask;

  loading = true;
  fromTaskHeaderId: number;
  fromCompanyId: number;
  subscriptions: Subscription[] = [];
  taskHeaders: TemplateTaskHeader[];
  userCompanies: Company[] = [];
  multipleCompanies = false;
  dataSource: DataSource;
  costCentres: PriceFileItem[];
  templateTasks: TemplateTask[];
  gridHeight: number;

  constructor(
    private activeModal: NgbActiveModal,
    private globalService: GlobalService,
    protected gridService: GridService,
    private maintenanceService: MaintenanceService,
    private notiService: NotificationService) {
    this.onInitNewRow = this.onInitNewRow.bind(this);
  }

  ngOnInit() {
    this.templateTasks = this.maintenanceService.templateTasks;
    this.getData();
    this.gridHeight = window.innerHeight - 300;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getData() {
    this.subscriptions.push(
      this.maintenanceService.getPriceFileItemGroups(true).subscribe({
        next: () => {
          this.costCentres = this.maintenanceService.costCentres;
          this.loading = false;
          this.setUpDataSource();
        }, error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
          this.setUpDataSource();
        }
      })
    );
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.getTemplateTaskCostCentres(this.templateTaskHeaderId, this.templateTask?.id).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.addTemplateTaskCostCentre(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.updateTemplateTaskCostCentre(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.deleteTemplateTaskCostCentre(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  close() {
    this.activeModal.dismiss();
  }

  onInitNewRow(e) {
    e.data.templateTaskId = this.templateTask.id;
  }
}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobService } from '../../services/felixApi/job.service';
import { NotificationService } from '../../services/notification.service';
import { JobExtra, JobExtraForm } from '../../dtos/job-extra';
import { UserService } from '../../services/felixApi/user.service';
import { User } from '../../dtos/user';

@Component({
  selector: 'js-hold-job',
  templateUrl: './hold-job.component.html',
  styleUrls: ['./hold-job.component.scss']
})
export class HoldJobComponent implements OnInit, OnDestroy {
  @Input() jobExtra: JobExtra;
  @Input() jobId: number;

  loading = false;
  jobExtraForm: JobExtraForm;
  subscriptions: Subscription[] = [];
  updatedData: any;
  user: User;
  updateButtonOptions = {
    text: 'Update',
    type: 'default',
    stylingMode: 'contained',
    onClick: () => {
      this.update();
    },
    useSubmitBehavior: true
  };

  constructor(
    private activeModal: NgbActiveModal,
    private jobService: JobService,
    private userService: UserService,
    private notiService: NotificationService) { }

  ngOnInit() {
    if (this.jobExtra) {
      this.user = this.userService.users.find(i => i.id === this.jobExtra.onHoldModifiedUserId);
    }
    this.jobExtraForm = {
      isOnHold: this.jobExtra ? this.jobExtra.isOnHold : false,
      onHoldReason: this.jobExtra ? this.jobExtra.onHoldReason : '',
      holdDate: this.jobExtra ? this.jobExtra.holdDate : null,
      modifiedUser: this.user ? this.user.fullName : ''
    };
    this.loading = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  close() {
    this.activeModal.dismiss();
  }

  update() {
    if (this.jobExtraForm.onHoldReason) {
      this.jobExtraForm.onHoldReason = this.jobExtraForm.onHoldReason.trim();
    }

    if (this.jobExtraForm.isOnHold && (!this.jobExtraForm.onHoldReason || this.jobExtraForm.onHoldReason === '')) {
      this.notiService.showInfo('Reason is required');
    } else if (this.jobExtraForm.isOnHold && (!this.jobExtraForm.holdDate)) {
      this.notiService.showInfo('Date is required');
    } else {
      if (this.jobExtra) {
        // edit
        this.updatedData = {};
        let isItemsToUpdate = false;

        if (this.jobExtraForm.isOnHold !== this.jobExtra.isOnHold) {
          this.updatedData.isOnHold = this.jobExtraForm.isOnHold;
          isItemsToUpdate = true;
        }

        if (this.jobExtraForm.onHoldReason !== this.jobExtra.onHoldReason) {
          this.updatedData.onHoldReason = this.jobExtraForm.onHoldReason;
          isItemsToUpdate = true;
        }

        if (!this.jobExtraForm.isOnHold) {
          if (this.jobExtraForm.holdDate) {
            this.updatedData.holdDate = null;
            isItemsToUpdate = true;
          }
        } else {
          if (this.jobExtraForm.holdDate !== this.jobExtra.holdDate) {
            this.updatedData.holdDate = this.jobExtraForm.holdDate;
            isItemsToUpdate = true;
          }
        }

        if (isItemsToUpdate) {
          this.loading = true;

          this.subscriptions.push(
            this.jobService.updateJobExtra(this.jobExtra.id, this.updatedData)
              .subscribe({
                next: (jobExtra) => {
                  this.activeModal.close(jobExtra);
                },
                error: (err) => {
                  this.notiService.notify(err);
                  this.loading = false;
                }
              })
          );
        }
      } else {
        // add
        this.loading = true;

        this.updatedData = {};
        this.updatedData.jobId = this.jobId;
        this.updatedData.isOnHold = this.jobExtraForm.isOnHold;
        this.updatedData.onHoldReason = this.jobExtraForm.onHoldReason;
        this.updatedData.holdDate = this.jobExtraForm.isOnHold ? this.jobExtraForm.holdDate : null;

        this.subscriptions.push(
          this.jobService.addJobExtra(this.updatedData)
            .subscribe({
              next: (jobExtra) => {
                this.activeModal.close(jobExtra);
              },
              error: (err) => {
                this.notiService.notify(err);
                this.loading = false;
              }
            })
        );
      }
    }
  }
}

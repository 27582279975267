import { Injectable } from '@angular/core';
import { VariationWorkFlow } from '../../dtos/variation-workflow';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';
import { MaintenanceService } from './maintenance.service';
import { Division } from '../../dtos/division';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class VariationWorkflowService {
  variationWorkFlows: VariationWorkFlow[];
  variationWorkFlowsCompany: number;

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private maintenanceService: MaintenanceService,
    private userService: UserService,
    private globalService: GlobalService) { }

  getVariationWorkflows(useCache: boolean): Observable<VariationWorkFlow[]> {
    if (useCache && this.variationWorkFlowsCompany === this.globalService.getCurrentCompany().id
      && this.variationWorkFlows && this.variationWorkFlows.length) {
      return of(this.variationWorkFlows);
    } else {
      return this._http.get<VariationWorkFlow[]>(this.globalService.getApiUrl() +
        '/variation-work-flows', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.variationWorkFlows = res;
            this.variationWorkFlowsCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }

  addVariationWorkflow(dataRecord: any): Observable<VariationWorkFlow> {
    const url = this.globalService.getApiUrl() + '/variation-work-flows';
    return this._http.post<VariationWorkFlow>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateVariationWorkflow(id: string, itm: any): Observable<VariationWorkFlow> {
    const url = this.globalService.getApiUrl() + '/variation-work-flows/' + id;
    return this._http.patch<VariationWorkFlow>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteVariationWorkflow(id: string) {
    const url = this.globalService.getApiUrl() + '/variation-work-flows/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }



  getVariationWorkflowData(): Observable<Division[]> {
    return forkJoin(
      [
        this.maintenanceService.getDivisions(),
        this.maintenanceService.getTemplateTaskHeaders(false, false),
        this.userService.getCurrCompUsers(true)
      ]
    )
      .pipe(map(
        ([data]) => {
          return data;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}

<div class="page">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" id="dataGrid" class="max-w-1200 m-auto" [height]="gridHeight" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate', 'constructiveTemplate')"
    (onRowPrepared)="onRowPrepared($event)" (onEditingStart)="onEditingStart($event)"
    (onEditorPreparing)="onEditorPreparing($event)" (onExporting)="gridService.onExporting($event, 'JobData')">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="modifiedByVisible"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" width="200px"></dxo-search-panel>

    <dxo-editing [mode]="editMode" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false"
      [useIcons]="true" refreshMode="repaint">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
    <dxo-header-filter [visible]="true">
      <dxo-search [enabled]="true"></dxo-search>
    </dxo-header-filter>
    <dxo-grouping #expand [autoExpandAll]="false" [contextMenuEnabled]="true" expandMode="rowClick"></dxo-grouping>


    <dxi-column dataField="jobId" dataType="number" [visible]='false'></dxi-column>
    <dxi-column dataField="trackingFieldId" dataType="number" [visible]='false'></dxi-column>
    <dxi-column dataField="trackingFieldTypeId" dataType="number" [visible]='false'></dxi-column>
    <dxi-column dataField="trackingFieldOrderNo" dataType="number" [visible]='false' sortOrder="asc"></dxi-column>
    <dxi-column dataField="colourId" dataType="number" [visible]='false'></dxi-column>


    <dxi-column dataField="trackingFieldGroupId" caption="Section" dataType="number" width='200' [groupIndex]="0"
      [calculateGroupValue]="calculateGroupSortValue" groupCellTemplate="groupCellTemplate" [allowEditing]="false">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="trackingFieldGroups" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="fieldName" caption="Field" [width]="labelWidth" [allowEditing]="false"
      cellTemplate="fieldNameCellTemplate">
    </dxi-column>

    <dxi-column dataField="textValue" caption="Value" [width]="valueWidth" cellTemplate="cellTemplate">
    </dxi-column>

    <dxi-column *ngIf="modifiedByVisible" dataField="modifiedUserId" caption="Modified By" dataType="number" width="120"
      allowEditing="false" cellTemplate="noWrapTemplate" [allowEditing]="false" [hidingPriority]="1">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column *ngIf="modifiedByVisible" dataField="modifiedDate" caption="Modified" dataType="date" width="105"
      alignment="center" cellTemplate="dateTemplate" [allowEditing]="false" [hidingPriority]="2">
    </dxi-column>

    <div *dxTemplate="let cell of 'noWrapTemplate'">
      <div style="white-space: nowrap; overflow: hidden;">{{cell.text}}</div>
    </div>

    <div *dxTemplate="let cellInfo of 'fieldNameCellTemplate'">
      <div id="fieldName" class='mx-0 px-0'>{{cellInfo.value}}</div>
    </div>

    <div *dxTemplate="let cell of 'dateTemplate'">
      <div *ngIf="cell && cell.value && dateOnly(cell)">{{cell.value | date : "d-MMM-yy"}}</div>
    </div>

    <div *dxTemplate="let data of 'groupCellTemplate'">
      {{ getGroupTitle(data) }}
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate'">
      <span *ngIf="colourId === trackingColourEnum.Orange">
        <dx-button id="orangeButton" type="default" text="Colour" (onClick)="colourJob()"></dx-button>
      </span>
      <span *ngIf="colourId === trackingColourEnum.Yellow">
        <dx-button id="yellowButton" type="danger" text="Colour" (onClick)="colourJob()"></dx-button>
      </span>
      <span *ngIf="colourId === trackingColourEnum.Teal">
        <dx-button id="tealButton" type="danger" text="Colour" (onClick)="colourJob()">
        </dx-button>
      </span>
      <span *ngIf="colourId === trackingColourEnum.Blue">
        <dx-button id="blueButton" type="default" text="Colour" (onClick)="colourJob()">
        </dx-button>
      </span>
      <span *ngIf="colourId === trackingColourEnum.Magenta">
        <dx-button id="magentaButton" type="default" text="Colour" (onClick)="colourJob()"></dx-button>
      </span>
      <span *ngIf="colourId === trackingColourEnum.Grey">
        <dx-button id="greyButton" type="default" text="Colour" (onClick)="colourJob()"></dx-button>
      </span>
      <span *ngIf="!colourId || colourId === 0">
        <dx-button id="clearButton" type="default" stylingMode="outlined" text="Colour" (onClick)="colourJob()">
        </dx-button>
      </span>
    </div>

    <div *dxTemplate="let data of 'constructiveTemplate'">
      <dx-check-box [(value)]="sendToConstructive" (onValueChanged)="setSendToConstructive($event)" [rtlEnabled]="true"
        text="Send to Constructive">
      </dx-check-box>
    </div>

    <!-- here we define the different types for viewing -->
    <div *dxTemplate="let cell of 'cellTemplate'">
      <div id="wrapComment" class='mx-0 px-0' *ngIf='cell.data.trackingFieldTypeId === trackingFieldTypeEnum.Text
        || cell.data.trackingFieldTypeId === trackingFieldTypeEnum.Boolean
        || cell.data.trackingFieldTypeId === trackingFieldTypeEnum.Number
        || cell.data.trackingFieldTypeId === trackingFieldTypeEnum.Lookup'>{{cell.value}}
      </div>

      <div *ngIf='cell.data.trackingFieldTypeId === trackingFieldTypeEnum.Date'>
        <div *ngIf='cell && cell.value'>{{cell.value | date : "d-MMM-yy"}}</div>
      </div>

      <div *ngIf='cell.data.trackingFieldTypeId === trackingFieldTypeEnum.Time'>
        <div *ngIf='cell && cell.value'>{{cell.value | date : "shortTime"}}</div>
      </div>

      <div *ngIf='cell.data.trackingFieldTypeId === trackingFieldTypeEnum.Calculated'>
        <div *ngIf='cell && cell.data'>{{calculateField(cell.data) | date : "d-MMM-yy"}}</div>
      </div>
    </div>

    <dxi-column type="buttons" [width]="65">
      <dxi-button name="edit"></dxi-button>
      <dxi-button *ngIf="editMode === 'row'" template="lockCommand">
        <div class="colourButtonClass" *dxTemplate="let data of 'lockCommand'">
          <i *ngIf="!data.data.isLocked" class="material-icons" (click)="changeColour(data)">flag</i>
        </div>
      </dxi-button>
    </dxi-column>

  </dx-data-grid>
</div>

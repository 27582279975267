import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentService } from '.././../services/felixApi/document.service';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { DocumentFolder } from '../../dtos/document-folder';
import { DxTreeListComponent } from 'devextreme-angular/ui/tree-list';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-document-templates',
  templateUrl: './document-templates.component.html',
  styleUrls: ['./document-templates.component.scss']
})
export class DocumentTemplatesComponent implements OnInit {
  @Input() folderId: string;
  @Input() folderName: string;
  @Input() jobNumber: string;
  @Input() isSharePoint: boolean;

  @ViewChild(DxTreeListComponent, { static: false }) treeList: DxTreeListComponent;

  subscriptions: Subscription[] = [];
  loading = false;
  dataSource: CustomStore;
  selectedRowIds: string[] = [];
  folderNames: string[] = [];
  docTemplates: DocumentFolder[];
  createPDFs = false;

  constructor(
    private activeModal: NgbActiveModal,
    private notiService: NotificationService,
    private documentService: DocumentService,
    private globalService: GlobalService,
    protected gridService: GridService) {
    this.onRowClick = this.onRowClick.bind(this);
  }


  ngOnInit(): void {
    this.getFiles();
  }

  close() {
    this.activeModal.dismiss();
  }

  getFiles() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.documentService.getSharePointDocTemplates(this.folderName, this.isSharePoint).subscribe({
            next: (res) => {
              this.docTemplates = res;
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  createDocuments() {
    this.loading = true;

    this.subscriptions.push(
      this.documentService.createSharePointMergeDocs(this.jobNumber, this.folderName, this.selectedRowIds, this.isSharePoint, this.createPDFs)
        .subscribe({
          next: (webUrl) => {
            window.open(webUrl);
            this.activeModal.close();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  onRowPrepared(e: any) {
    if (e.rowType === 'data' && e.data.isFolder) {
      e.rowElement.classList.add('hideCheckBoxClass');
    }
  }

  onRowClick(e) {
    // check in case we have clicked the expand button
    if (e.event.target.tagName.toLowerCase() !== 'span') {
      if (e.data.isFolder) {
        if (this.treeList.instance.isRowExpanded(e.key)) {
          this.treeList.instance.collapseRow(e.key);
        } else {
          this.treeList.instance.expandRow(e.key);
        }
      } else {
        // select or deselect
        const foundIndex = this.selectedRowIds.findIndex(i => i === e.key);
        if (foundIndex >= 0) {
          // de-select
          this.selectedRowIds.splice(foundIndex, 1);
        } else {
          this.selectedRowIds.push(e.key);
        }
      }
    }
  }
}

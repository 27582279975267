<div class="page">
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" id="recipeGrid" class="m-auto grid-height" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)"
    (onExporting)="gridService.onExporting($event, 'CashFlow')">

    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"> <dxo-search [enabled]="true"></dxo-search> </dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-sorting mode='multiple'></dxo-sorting>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>

    <dxo-editing mode="row" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false">
    </dxo-editing>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="cash-flow-forecast" [savingTimeout]="500">
    </dxo-state-storing>

    <dxi-column dataField="jobNumber" [width]="110" sortOrder="asc"></dxi-column>

    <dxi-column dataField="jobDescription" caption="Contract Name" sortOrder="asc"></dxi-column>

    <dxi-column dataField="taskDescription"></dxi-column>

    <dxi-column dataField="forecastStartDate" caption="Forecast Start" dataType="date" alignment="center" [width]="120">
    </dxi-column>

    <dxi-column dataField="forecastCompletionDate" caption="Forecast Completion" dataType="date" alignment="center"
      [width]="150" sortOrder="asc"></dxi-column>

    <dxi-column dataField="forecastPaymentDate" caption="Forecast Payment" dataType="date" alignment="center"
      [width]="130"></dxi-column>

    <dxi-column dataField="costSubcontractor" caption="Cost Subcontractor Ex.GST" dataType="number" [format]="{ type: 'fixedPoint', precision: 0 }"
      [width]="135">
    </dxi-column>

    <dxi-column dataField="costSupplier" caption="Cost Supplier Ex.GST" dataType="number" [format]="{ type: 'fixedPoint', precision: 0 }"
      [width]="110"></dxi-column>

    <dxi-column dataField="incomeReceivable" caption="Income Receivable Inc.GST" dataType="number" [format]="{ type: 'fixedPoint', precision: 0 }"
      [width]="130">
    </dxi-column>

    <dxi-column dataField="totalFlow" caption="Total Flow Inc.GST" dataType="number"
      [format]="{ type: 'fixedPoint', precision: 0 }" [width]="110">
    </dxi-column>

    <dxi-column dataField="siteAddress" [calculateCellValue]="calculateSiteAddress"></dxi-column>

    <dxi-column dataField="siteManager" [calculateCellValue]="calculateSiteManager"></dxi-column>

    <dxi-column dataField="contractTotal" caption="Contract Value Exc. Post Contract VOs" dataType="number"
      [format]="{ type: 'fixedPoint', precision: 0 }" [calculateCellValue]="calculateContractTotal" [width]="140">
    </dxi-column>

    <dxi-column dataField="contractTotalIncVOs" caption="Contract Total Inc. Post Contract VOs" dataType="number"
      [format]="{ type: 'fixedPoint', precision: 0 }" [calculateCellValue]="calculateContractTotalIncPostVOs"
      [width]="140" [visible]="true"></dxi-column>

    <dxi-column dataField="incomeReceivableExGst" caption="Income Ex.GST" dataType="number"
      [format]="{ type: 'fixedPoint', precision: 0 }" [width]="130" [visible]="false">
    </dxi-column>

  </dx-data-grid>
</div>

<!-- show any jobs cancelled but active -->
<dx-popup class="popup" [width]="240" [height]="240" [showTitle]="true" title="Cancelled Job" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="showCancelledActivePopup" showCloseButton="true">
  <div *dxTemplate="let data of 'content'">
    <br>
    <div>Warning: Job <b>{{cancelledJobNumber}}</b> is cancelled but has been marked Active!</div>
    <br>
    <br>
    <dx-button text="Cancel" type="danger" (onClick)="goJobSetup()" class="me-4">
    </dx-button>
    <dx-button text="Continue" type="default" (onClick)="continue()">
    </dx-button>
  </div>
</dx-popup>

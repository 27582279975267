import { VariationStatusEnum } from './../dtos/variation-status.enum';
import { UtilsService } from './../services/utils.service';
import { TaskService } from './../services/felixApi/task.service';
import { MaintenanceService } from './../services/felixApi/maintenance.service';
import { AuthService } from './../services/auth.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { NotificationService } from '../services/notification.service';
import { GlobalService } from '../services/global.service';
import { JobCashFlowService } from '../services/felixApi/jobCashFlow.service';
import { GridService } from '../services/grid.service';
import { UserService } from '../services/felixApi/user.service';
import { TemplateTask } from '../dtos/templateTask';
import { Router } from '@angular/router';
import { JobService } from '../services/felixApi/job.service';
import { RoleTypeEnum } from '../dtos/role-type.enum';
import { TrackingColourEnum } from '../dtos/tracking-colour.enum';

@Component({
  selector: 'js-job-cash-flow',
  templateUrl: './job-cash-flow.component.html',
  styleUrls: ['./job-cash-flow.component.scss']
})
export class JobCashFlowComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  loading = true;
  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  dropDownOptions: object;
  jobData: CustomStore;
  claimMasters: CustomStore;
  users: CustomStore;
  includeForecast = true;
  taskList: TemplateTask[];
  canRunCashFlow = false;
  expectedPercentCancellation: number;
  showForecastTask = false;
  showSiteAddress = false;
  showForecastSlab = false;
  canUpdateRecords: boolean;
  showContractualCompletion = false;
  showJobsHandedOver = false;
  refreshOnSaveComplete: boolean;

  constructor(
    private jobCashFlowService: JobCashFlowService,
    private jobService: JobService,
    private notiService: NotificationService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private userService: UserService,
    private maintenanceService: MaintenanceService,
    private router: Router,
    private authService: AuthService,
    private taskService: TaskService,
    private utilService: UtilsService
  ) {
    this.dropDownOptions = { width: 900, minHeight: 300};
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.calculateJobSortValue = this.calculateJobSortValue.bind(this);
    this.calculateSkippedCost = this.calculateSkippedCost.bind(this);
    // this.calculateSkippedIncome = this.calculateSkippedIncome.bind(this);
    this.calculateSiteManager = this.calculateSiteManager.bind(this);
    this.calculateSiteAddress = this.calculateSiteAddress.bind(this);
    this.calculateSalesRep = this.calculateSalesRep.bind(this);
    this.calculateExtensionDays = this.calculateExtensionDays.bind(this);
    this.calculateCurrentContractualCompletion = this.calculateCurrentContractualCompletion.bind(this);
    this.calculatePracticalCompletionDate = this.calculatePracticalCompletionDate.bind(this);
    this.calculateContractsRequiredByDate = this.calculateContractsRequiredByDate.bind(this);
    this.calculateDaysToContractsRequired = this.calculateDaysToContractsRequired.bind(this);
    this.onSaved = this.onSaved.bind(this);
  }

  ngOnInit(): void {
    this.canRunCashFlow = false;
    this.canUpdateRecords = false;

    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Forecast') === 'Admin') {
      this.canRunCashFlow = true;
      this.canUpdateRecords = true;
    } else if (this.authService.getSelectionsPermissions('Forecast') === 'Write') {
      this.canUpdateRecords = true;
    }

    // set remembered default
    this.showSiteAddress = this.globalService.getStorageItem('showSiteAddressInForecast');
    this.showContractualCompletion = this.globalService.getStorageItem('showContractualCompletionInForecast');
    this.showJobsHandedOver = this.globalService.getStorageItem('showJobsHandedOverInForecast');

    this.getData(true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onEditorPreparing(e) {
    if (e.dataField === 'claimScheduleId') {
      e.editorOptions.dropDownOptions = { minWidth: 350 };
    }
    if (e.dataField === 'jobId') {
      e.editorOptions.dropDownOptions = { minWidth: 120 };
    }
  }

  getData(useCache: boolean) {
    this.subscriptions.push(
      this.taskService.getJobCashFlowData(useCache)
        .subscribe({
          next: (jobs) => {
            this.jobData = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => jobs
            });

            this.expectedPercentCancellation = this.maintenanceService.taskControl?.expectedPercentCancellation;

            this.claimMasters = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.jobCashFlowService.claimMasters
            });

            this.taskList = [new TemplateTask(-1, 'JOB ON HOLD')].concat(this.jobCashFlowService.tasks);

            this.users = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.userService.users
            });

            this.loading = false;
            this.setUpDataSource(false);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  setUpDataSource(useCache: boolean) {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.jobCashFlowService.getJobCashFlows(true, this.includeForecast, useCache).subscribe({
            next: (res) => {
              if (this.showJobsHandedOver) {
                return resolve(res);
              } else {
                res.forEach(row => {
                  const jobExtra = this.jobService.jobExtras.find(i => i.jobId === row.jobId);
                  row.handoverDate = jobExtra?.handoverDate;
                });
                const newResult = res.filter(i => !i.handoverDate);
                return resolve(newResult);
              }
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.jobCashFlowService.addJobCashFlow(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        if (values.targetStartDate instanceof Date) {
          values.targetStartDate = this.utilService.convertDateToString(values.targetStartDate);
        }
        if (values.fixedStartDate !== undefined && values.fixedStartDate instanceof Date) {
          values.fixedStartDate = this.utilService.convertDateToString(values.fixedStartDate);
        }
        if (values.originalContractualCompletion instanceof Date) {
          values.originalContractualCompletion = this.utilService.convertDateToString(values.originalContractualCompletion);
        }

        // store calculated values so we can add after update
        const rowIndex = this.grid.instance.getRowIndexByKey(key);
        const forecastTaskId = this.grid.instance.cellValue(rowIndex, 'forecastTaskId');
        const forecastTaskStartDate = this.grid.instance.cellValue(rowIndex, 'forecastTaskStartDate');
        const forecastTaskCompletionDate = this.grid.instance.cellValue(rowIndex, 'forecastTaskCompletionDate');
        const foreCastStartDate = this.grid.instance.cellValue(rowIndex, 'foreCastStartDate');
        const forecastCompletionDate = this.grid.instance.cellValue(rowIndex, 'forecastCompletionDate');
        return new Promise((resolve, reject) =>
          this.jobCashFlowService.updateJobCashFlow(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              res.forecastTaskId = forecastTaskId;
              res.forecastTaskStartDate = forecastTaskStartDate;
              res.forecastTaskCompletionDate = forecastTaskCompletionDate;
              res.foreCastStartDate = foreCastStartDate;
              res.forecastCompletionDate = forecastCompletionDate;
              if (values.fixedStartDate !== undefined) {
                this.refreshOnSaveComplete = true;
              }
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.jobCashFlowService.deleteJobCashFlow(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onToolbarPreparing(e, templateName2) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      location: 'after',
      locateInMenu: 'auto',
      template: templateName2
    });

    if (this.canRunCashFlow) {
      toolbarItems.unshift(
        {
          location: 'before',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            text: 'Run Cash Flow Forecast',
            type: 'default',
            onClick: this.runForecast.bind(this)
          }
        });
    }

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show site address',
          value: this.showSiteAddress,
          rtlEnabled: true,
          onValueChanged: this.showSiteAddressChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show forecast task fields',
          value: this.showForecastTask,
          rtlEnabled: true,
          onValueChanged: this.showForecastTaskChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show forecast slab date',
          value: this.showForecastSlab,
          rtlEnabled: true,
          onValueChanged: this.showForecastSlabChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show contractual completion',
          value: this.showContractualCompletion,
          rtlEnabled: true,
          onValueChanged: this.showContractualCompletionChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show jobs handed over',
          value: this.showJobsHandedOver,
          rtlEnabled: true,
          onValueChanged: this.showJobsHandedOverChanged.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          width: 40,
          icon: 'refresh',
          onClick: this.refresh.bind(this),
          matTooltip: 'Refresh'
        }
      });
  }

  onSelectionChanged(cellInfo, e, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0]);
      e.component.close();
    }
  }

  onJobValueChanged(cellInfo, e) {
    if (!e.value) {
      cellInfo.setValue(null);
    }
  }

  setJobIdCellValue(rowData, value) {
    if (value) {
      rowData.jobId = value.id;
      rowData.description = value.contractName;
      rowData.salesDate = value.salesDate;
    } else {
      rowData.jobId = null;
    }
  }

  calculateJobSortValue(data) {
    let jobNumber = '';
    const job = this.jobService.jobs.find(i => i.id === data.jobId);
    if (job) {
      jobNumber = job.jobNumber;
    }

    return jobNumber;
  }

  refresh() {
    this.loading = true;
    this.getData(false);
  }

  getJustTask(v: TemplateTask, index, self) {
    return { id: v.id, taskTitle: v.taskTitle };
  }

  setCellValue(rowData, value) {
    if (value) {
      rowData.templateTaskHeaderId = value.id;
      rowData.templateDaysHeaderId = null;
      rowData.currentTaskId = null;
      rowData.currentTaskStartDate = null;
      (<any>this).defaultSetCellValue(rowData, value);
    }
  }

  onCellPrepared(e) {
    if (e.rowType === 'data' && e.column.dataField === 'foreCastStartDate' && e.data.targetStartDate) {
      e.cellElement.style.color = e.data.targetStartDate >= e.data.foreCastStartDate ? 'green' : 'red';
    }
    if (e.rowType === 'data' && e.column.dataField === 'forecastCompletionDate' && e.data.originalContractualCompletion) {
      const currentDate = this.jobService.calculateContractualCompletion(e.data.originalContractualCompletion, e.data.jobId);
      e.cellElement.style.color =
        this.utilService.convertDateToString(currentDate) >= this.utilService.convertDateToString(e.data.forecastCompletionDate) ? 'green' : 'red';
    }
  }

  runForecast() {
    // check we have all data required
    this.loading = true;
    this.subscriptions.push(
      this.jobCashFlowService.getJobCashFlows(true, false, false)
        .subscribe({
          next: (jobs) => {
            let foundClaimSchedulesMissing = false;
            let foundJobCostMissing = false;
            let foundIncomeMissing = false;

            jobs.forEach(job => {
              if (!job.claimScheduleId) {
                foundClaimSchedulesMissing = true;
              }
              if (!job.jobCostExcGST) {
                foundJobCostMissing = true;
              }
              if (!job.contractPriceIncGST) {
                foundIncomeMissing = true;
              }
            });

            if (foundClaimSchedulesMissing) {
              this.notiService.showWarning('Jobs with claims not set up will require the claim schedule to be specified here!');
            }
            if (foundJobCostMissing) {
              this.notiService.showWarning('The forecast uses greater of the estimated total and the job cost specified here. If orders are locked, the total of all orders is written here.');
            }
            if (foundIncomeMissing) {
              this.notiService.showWarning('Income missing for some jobs!');
            }

            this.jobCashFlowService.expectedPercentCancellation = this.expectedPercentCancellation; // store so we can access
            this.router.navigateByUrl('/cashFlow');
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  onInitNewRow(e) {
    e.data.activeJob = true;
  }

  calculateSkippedCost(data) {
    const skippedRecord = this.jobCashFlowService.skippedCosts.find(i => i.jobId === data.jobId);
    if (skippedRecord) {
      return skippedRecord.taskTypeDescription + ': ' + skippedRecord.taskTitle + ' ' + skippedRecord.poNumber;
    } else {
      return '';
    }
  }

  // calculateSkippedIncome(data) {
  //   const skippedRecord = this.jobCashFlowService.skippedTasks.find(i => i.id === data.jobId && i.incomeReceivable);
  //   if (skippedRecord) {
  //     let dateString = '';
  //     dateString = skippedRecord.forecastCompletionDate.toString();
  //     const startDate = new Date(+dateString.substring(0, 4),
  //       +dateString.substring(5, 2) - 1, +dateString.substring(8, 2), 0, 0, 0, 0);
  //     return skippedRecord.taskDescription + ': '
  //       + dateString.substring(8, 10) + '-' + dateString.substring(5, 7) + '-' + dateString.substring(0, 4);
  //   } else {
  //     return '';
  //   }
  // }

  calculatePracticalCompletionDate(data): Date {
    return this.jobService.jobExtras.find(i => i.jobId === data.jobId)?.completionDate;
  }

  calculateSiteManager(data) {
    return this.taskService.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.SiteManager)?.user.fullName;
  }

  calculateSalesRep(data) {
    return this.taskService.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.SalesRep)?.user.fullName;
  }

  calculateSiteAddress(data) {
    return this.jobService.jobs.find(i => i.id === data.jobId)?.jobAddressString;
  }

  calculateExtensionDays(data) {
    let days = 0;
    this.jobService.jobVariations.filter(i => i.jobId === data.jobId && i.statusId >= VariationStatusEnum.Approved).forEach(varn => {
      if (varn.additionalDays) {
        days += varn.additionalDays;
      }
    });
    return days;
  }

  calculateCurrentContractualCompletion(data) {
    return this.jobService.calculateContractualCompletion(data.originalContractualCompletion, data.jobId);
  }

  calculateContractsRequiredByDate(data): Date {
    if (data && data.jobId) {
      const job = this.jobService.jobs.find(i => i.id === data.jobId);
      if (job && job.contractsRequiredByDate) {
        return new Date(job.contractsRequiredByDate);
      }
    }
    return null;
  }

  calculateDaysToContractsRequired(data): number {
    if (data && data.jobId) {
      const job = this.jobService.jobs.find(i => i.id === data.jobId);
      if (job?.contractsRequiredByDate) {
        return this.utilService.getCalendarDaysDifference(job.contractsRequiredByDate, new Date()) ?? 0;
      }
    }
    return null;
  }

  showForecastTaskChanged(e) {
    this.showForecastTask = e.value;
  }

  showForecastSlabChanged(e) {
    this.showForecastSlab = e.value;
  }

  showSiteAddressChanged(e) {
    this.showSiteAddress = e.value;
    this.globalService.setStorageItem('showSiteAddressInForecast', e.value);
  }

  showContractualCompletionChanged(e) {
    this.showContractualCompletion = e.value;
    this.globalService.setStorageItem('showContractualCompletionInForecast', e.value);
  }

  showJobsHandedOverChanged(e) {
    this.showJobsHandedOver = e.value;
    this.globalService.setStorageItem('showJobsHandedOverInForecast', e.value);
    this.setUpDataSource(true);
  }

  onRowPrepared(e) {
    if (e.rowType === 'data') {
      // set isOnHold
      const jobExtra = this.jobService.jobExtras.find(i => i.jobId === e.data.jobId);
      if (jobExtra && jobExtra.cancellationDate) {
        e.rowElement.style.backgroundColor = 'rgb(250, 140, 160)';
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
      } else if (jobExtra && jobExtra.isOnHold) {
        e.rowElement.style.backgroundColor = 'pink';
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
      } else {
        if (jobExtra && jobExtra.trackingColour && jobExtra.trackingColour !== '0') {
          e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
          if (jobExtra.trackingColour === TrackingColourEnum.Orange.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(253, 209, 127, 0.6)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Yellow.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(253, 253, 139, 0.6)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Teal.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(132, 247, 237, 0.6)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Blue.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(147, 198, 247, 0.4)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Magenta.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(208, 157, 250, 0.6)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Grey.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(0, 0, 0, 0.2)';
          }
        }
      }
    }
  }

  onSaved() {
    if (this.refreshOnSaveComplete) {
      this.notiService.showSuccess('Fixed start date updated. Refreshing to see forecast changes.');
      this.refreshOnSaveComplete = false;
      this.refresh();
    }
  }
}

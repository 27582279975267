<div class="modal-header">
  <h4 class="modal-title">Task Maintenance</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <div class="page">

    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <dx-data-grid *ngIf="!loading" id="recipeGrid" class="m-auto taskgrid-height" [dataSource]="dataSource"
      [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true" [wordWrapEnabled]="true" (onInitNewRow)="onInitNewRow($event)"
      (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate', 'toolbarTemplate2')"
      (onRowInserted)="onRowInserted($event)" (onExporting)="onExporting($event)">

      <dxo-scrolling mode="standard" rowRenderingMode="virtual"></dxo-scrolling>
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
      <dxo-editing [mode]="editMode" [allowAdding]="isWorkflowAdmin" [allowUpdating]="isWorkflowWrite"
        [allowDeleting]="isWorkflowAdmin" [useIcons]="true" [newRowPosition]="newRowPosition" [changes]="changes"
        [(editRowKey)]="editRowKey" refreshMode="reshape">
      </dxo-editing>
      <dxo-paging [enabled]="false"></dxo-paging>

      <dxi-column dataField="id" dataType="number" [visible]='false'>
      </dxi-column>

      <dxi-column dataField="taskTypeId" dataType="number" caption="Type" [minWidth]="130" [setCellValue]="setCellValue"
        [fixed]="true" [allowEditing]="isWorkflowWrite">
        <dxo-lookup [dataSource]="taskTypes" displayExpr="description" valueExpr="id"></dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="taskMasterId" dataType="number" caption="Task" [minWidth]="140" [fixed]="true"
        [allowEditing]="isWorkflowWrite" editCellTemplate="taskMasterDropDown" [setCellValue]="setTaskMasterCellValue">
        <dxo-lookup [dataSource]="taskMasters" displayExpr="taskTitle" valueExpr="id"></dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="leadDays" dataType="number" width="50" alignment="center"
        headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="taskDays" dataType="number" width="50" alignment="center"
        [calculateCellValue]="calculateTaskDays" [allowEditing]="false" headerCellTemplate="headerTemplate">
      </dxi-column>

      <dxi-column *ngIf="showCashFlowFields" caption="Cash-Flow">


        <dxi-column dataField="costPercentSupplier" caption="% Cost Supplier" dataType="number" width="66"
          alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
        </dxi-column>

        <dxi-column dataField="costFixedSupplier" caption="Fixed Cost Supplier" dataType="number" width="66"
          alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
        </dxi-column>

        <dxi-column dataField="supplierPayTermsTypeId" caption="Supplier Terms" dataType="number" width="85"
          alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
          <dxo-lookup [dataSource]="supplierTermsTypes" displayExpr="description" valueExpr="id"></dxo-lookup>
        </dxi-column>

        <dxi-column dataField="supplierPayTermsDays" caption="Terms Days" dataType="number" width="54"
          alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
        </dxi-column>

        <dxi-column dataField="costPercentSubContractor" caption="% Cost Subbie" dataType="number" width="60"
          alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
        </dxi-column>

        <dxi-column dataField="costFixedSubContractor" caption="Fixed Cost Subbie" dataType="number" width="60"
          alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
        </dxi-column>

        <dxi-column dataField="subContractorPayFrequency" caption="Subbie Pay Frequency" dataType="number" width="60"
          alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
        </dxi-column>
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="activityToSetStartDateId" caption="Set Start For Activity"
        dataType="number" width="90" alignment="center" [editorOptions]="{ showClearButton: true }"
        headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
        <dxo-lookup [dataSource]="activities" displayExpr="description" valueExpr="id"></dxo-lookup>
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="activityToSetEndDateId" caption="Set End For Activity"
        dataType="number" width="90" alignment="center" [editorOptions]="{ showClearButton: true }"
        headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
        <dxo-lookup [dataSource]="activities" displayExpr="description" valueExpr="id"></dxo-lookup>
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="isInitiatedBySalesDate"
        caption="Initiated by Sale / Prev Work Flow / Trigger" dataType="boolean" width="80" alignment="center"
        headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="isForecastStart" caption="Site Start Task" dataType="boolean"
        width="50" alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="isPracticalCompletionTask" caption="Practical Completion"
        dataType="boolean" width="78" alignment="center" headerCellTemplate="headerTemplate"
        [allowEditing]="isWorkflowWrite">
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="isKeyHandoverTask" caption="Key Handover" dataType="boolean"
        width="70" alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="isFormalApproval" caption="Formal Approval" dataType="boolean"
        width="75" alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="userId" caption="Assign to User" dataType="number" width="110"
        [editorOptions]="{ showClearButton: true }" headerCellTemplate="headerTemplate"
        [allowEditing]="isWorkflowWrite">
        <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="roleId" caption="Assign to Role" dataType="number" width="110"
        [editorOptions]="{ showClearButton: true }" headerCellTemplate="headerTemplate"
        [allowEditing]="isWorkflowWrite">
        <dxo-lookup [dataSource]="companyRoles" displayExpr="companyRoleDescription" valueExpr="roleId"></dxo-lookup>
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="isAssignToPreviousUser" caption="Assign to Previous User"
        dataType="boolean" width="68" alignment="center" headerCellTemplate="headerTemplate"
        [allowEditing]="isWorkflowWrite">
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="isNextWorkFlowTrigger" caption="Trigger Next Work Flow"
        dataType="boolean" width="60" alignment="center" headerCellTemplate="headerTemplate"
        [allowEditing]="isWorkflowWrite">
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="isMultipleAllowed" caption="Multiple Created" dataType="boolean"
        width="70" alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="isCreatedByPostContractVO"
        caption="Created By Post Contract Variation" dataType="boolean" width="70" alignment="center"
        headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="isCreatedByConstructionVOApproved"
        caption="Created By Approved Construction Variation" dataType="boolean" width="93" alignment="center"
        headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="isCreatedBySalesVariation" caption="Created By Sales Variation"
        dataType="boolean" width="72" alignment="center" headerCellTemplate="headerTemplate"
        [allowEditing]="isWorkflowWrite">
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="isCreatedByPreContractVariation"
        caption="Created By Pre-Contract Variation" dataType="boolean" width="72" alignment="center"
        headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields" dataField="copyCommentFromPrev" caption="Copy Comment from Preceeding Task"
        dataType="boolean" width="84" alignment="center" headerCellTemplate="headerTemplate"
        [allowEditing]="isWorkflowWrite">
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields && taskHeader.trackingWorkFlowTypeId === trackingWorkFlowTypeEnum.Sales"
        dataField="salesCannotCreate" caption="Sales Cannot Create" dataType="boolean" width="65" alignment="center"
        headerCellTemplate="headerTemplate" [allowEditing]="isWorkflowWrite">
      </dxi-column>

      <dxi-column *ngIf="showWorkflowFields || showCallUpfields" dataField="notApplicableLinkCode" dataType="string"
        width="85" alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isCallUpAdmin">
      </dxi-column>

      <dxi-column *ngIf="showCallUpfields" caption="Call-Ups">
        <dxi-column dataField="tradeId" caption="Trade" dataType="number" width="110"
          [editorOptions]="{ showClearButton: true, hoverStateEnabled: true }" headerCellTemplate="headerTemplate"
          [allowEditing]="isCallUpAdmin">
          <dxo-lookup [dataSource]="trades" displayExpr="description" valueExpr="id"></dxo-lookup>
        </dxi-column>

        <dxi-column dataField="vendorId" caption="Default Vendor" dataType="number" width="110"
          [editorOptions]="{ showClearButton: true }" headerCellTemplate="headerTemplate"
          [calculateSortValue]="calculateVendorSortValue" [allowEditing]="isCallUpAdmin">
          <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id"></dxo-lookup>
        </dxi-column>

        <dxi-column dataField="doNotSendCallup" caption="Do Not Send" dataType="boolean" width="55" alignment="center"
          headerCellTemplate="headerTemplate" [allowEditing]="isCallUpAdmin">
        </dxi-column>

        <dxi-column dataField="includeAddendum" [caption]='addendumCaption' dataType="boolean" width="95"
          alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isCallUpAdmin">
        </dxi-column>

        <dxi-column dataField="vendorEqualToCode" caption="Same Vendor Code" dataType="string" width="85"
          alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isCallUpAdmin">
        </dxi-column>

        <!-- <dxi-column dataField="notApplicableLinkCode" dataType="string" width="85" alignment="center"
          headerCellTemplate="headerTemplate" [allowEditing]="isCallUpAdmin">
        </dxi-column> -->

        <dxi-column dataField="isHideNotApplicable" caption="Hide if Not Applicable" dataType="boolean" width="85"
          alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isCallUpAdmin">
        </dxi-column>

        <dxi-column dataField="isUseWorkFlow" caption="Use Start to Start Workflow" dataType="boolean" width="80"
          alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isCallUpAdmin">
        </dxi-column>

        <dxi-column dataField="isEndToStartEnabled" caption="Use End to Start Workflow" dataType="boolean" width="80"
          alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isCallUpAdmin">
        </dxi-column>

        <dxi-column dataField="callUpDocsTypes" caption="Info Doc Types" [width]="150" [allowSorting]="false"
          editCellTemplate="tagBoxEditor" [cellTemplate]="cellTemplate"
          [calculateFilterExpression]="calculateFilterExpression" headerCellTemplate="headerTemplate"
          [allowEditing]="isCallUpAdmin">
          <dxo-lookup [dataSource]="callUpDocsTypes" valueExpr="id" displayExpr="description">
          </dxo-lookup>
        </dxi-column>

        <dxi-column dataField="costCentreId" caption="Order Cost Centre" dataType="number" width="150"
          [editorOptions]="{ showClearButton: true }" headerCellTemplate="headerTemplate"
          [calculateSortValue]="calculateCostCentreSortValue" [allowEditing]="isCallUpAdmin">
          <dxo-lookup [dataSource]="costCentres" displayExpr="description" valueExpr="id"></dxo-lookup>
        </dxi-column>

        <dxi-column dataField="orderPhaseId" caption="Order Phase" dataType="number" width="110"
          headerCellTemplate="headerTemplate" [allowEditing]="isCallUpAdmin">
          <dxo-lookup [dataSource]="phases" displayExpr="description" valueExpr="id"></dxo-lookup>
        </dxi-column>

        <dxi-column dataField="sendPOWithCallUp" caption="Send PO with Call-Up" dataType="boolean" width="65"
          alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isCallUpAdmin">
        </dxi-column>

        <dxi-column dataField="mustHavePoToSend" caption="Must have PO to Send" dataType="boolean" width="65"
          alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isCallUpAdmin">
        </dxi-column>

        <dxi-column dataField="isShowForecastStart" caption="Show Forecast Start" dataType="boolean" width="70"
          alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isCallUpAdmin">
        </dxi-column>

        <dxi-column dataField="callUpSendLeadDays" caption="Send Lead Days" dataType="number" width="60"
          alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isCallUpAdmin">
        </dxi-column>

        <dxi-column dataField="isAuthorisedByStartDate" caption="Authorise By Start Date" dataType="boolean" width="75"
          alignment="center" headerCellTemplate="headerTemplate" [allowEditing]="isCallUpAdmin">
        </dxi-column>
      </dxi-column>

      <dxi-column dataField="modifiedDate" caption="Modified" dataType="date" width="85" alignment="center"
        [allowEditing]="false" format="d-MMM-yy">
      </dxi-column>

      <dxi-column dataField="modifiedUserId" caption="Modified By" dataType="number" width="110" [allowEditing]="false">
        <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
      </dxi-column>

      <dxi-column type="buttons">
        <dxi-button icon="add" [onClick]="onAddButtonClick" [visible]="isAddButtonVisible"></dxi-button>
        <dxi-button name="edit"></dxi-button>
        <dxi-button name="delete"></dxi-button>
        <dxi-button name="save"></dxi-button>
        <dxi-button name="cancel"></dxi-button>
      </dxi-column>

      <div *dxTemplate="let info of 'headerTemplate'">
        <p>{{info.column.caption}}</p>
        <js-help [dataField]=info.column.dataField></js-help>
      </div>

      <div *dxTemplate="let cellInfo of 'taskMasterDropDown'">
        <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="taskMasters" [(value)]="cellInfo.value"
          displayExpr="taskTitle" valueExpr="id" contentTemplate="contentTemplate" (onOpened)="getTaskList(cellInfo)">
          <div *dxTemplate="let e of 'contentTemplate'">
            <dx-data-grid [dataSource]="filteredTaskMasters" [remoteOperations]="false" height="500px"
              [showBorders]="true" [focusedRowEnabled]="true" [selectedRowKeys]="[cellInfo.value]"
              [hoverStateEnabled]="true" [focusedRowKey]="cellInfo.value"
              (onSelectionChanged)="onSelectionChanged(cellInfo, e, $event)">

              <dxo-scrolling mode="standard" preloadEnabled="true"></dxo-scrolling>
              <dxo-paging [enabled]="false"></dxo-paging>
              <dxo-selection mode="single"></dxo-selection>
              <dxo-search-panel [visible]="true" location="before" [width]="100"></dxo-search-panel>

              <dxi-column dataField="id" [visible]='false'></dxi-column>
              <dxi-column dataField="taskTitle"></dxi-column>
            </dx-data-grid>
          </div>
        </dx-drop-down-box>
      </div>

      <div *dxTemplate="let cellInfo of 'tagBoxEditor'">
        <dx-tag-box [dataSource]="callUpDocsTypes" [value]="cellInfo.value" valueExpr="id" displayExpr="description"
          [showSelectionControls]="true" [maxDisplayedTags]="55" [showMultiTagOnly]="false" applyValueMode="useButtons"
          [searchEnabled]="true" (onValueChanged)="cellInfo.setValue($event.value)"
          (onSelectionChanged)="cellInfo.component.updateDimensions()">
        </dx-tag-box>
      </div>

      <div *dxTemplate="let data of 'toolbarTemplate'">
        <div>
          <span *ngIf="isWorkflowWrite">
            <span class="me-2">Show Cash Flow Fields</span>
            <dx-check-box [value]="showCashFlowFields" [width]="30" (onValueChanged)="showCashFlow()" class="me-0">
            </dx-check-box>
          </span>
          <span class="me-2 ms-2">Show Work Flow fields</span>
          <dx-check-box [value]="showWorkflowFields" [width]="30"
            (onValueChanged)="showWorkflowFields = !showWorkflowFields" class="me-0">
          </dx-check-box>
          <span class="me-2 ms-2">Show Call Up fields</span>
          <dx-check-box [value]="showCallUpfields" [width]="30" (onValueChanged)="showCallUpfields = !showCallUpfields"
            class="me-0">
          </dx-check-box>
        </div>
      </div>

      <div *dxTemplate="let data of 'toolbarTemplate2'">
        <div>
          <dx-button *ngIf="editMode === 'row'" text="Batch Edit" type="default" (onClick)="changEditMode()">
          </dx-button>

          <dx-button *ngIf="editMode !== 'row'" icon="close" type="default" (onClick)="changEditMode()">
          </dx-button>
        </div>
      </div>

    </dx-data-grid>

  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" (click)="close()">Close</button>
</div>

<dx-popup class="popup" [width]="400" [height]="350" [showTitle]="true" title="Load from Excel" [dragEnabled]="false"
  [hideOnOutsideClick]="false" [(visible)]="popupVisible">
  <div *dxTemplate="let data of 'content'">
    <p>Loads Task Type, Task Title & Lead Days only as columns A, B & C. If the record exists the lead days will be
      updated.</p>

    <div id="fileuploader-container">
      <dx-file-uploader selectButtonText="Select file" labelText="or drop file here" [multiple]="false" accept="*"
        [(value)]="fileValue" uploadMode="useForm" [allowedFileExtensions]="['.xls', '.xlsx']">
      </dx-file-uploader>
    </div>

    <dx-button id="button" text="Load" type="success" (onClick)="uploadClick()" class="mt-3 ms-4">
    </dx-button>
  </div>
</dx-popup>

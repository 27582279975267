import { TemplateTaskCostCentre } from "./template-task-cost-centre";

export class TemplateTask {
  public id: number;
  public templateTaskHeaderId: number;
  public taskTypeId: number;
  public parentTaskId: number;
  public taskMasterId: number;
  public taskTitle: string;
  public days: number;
  public start: Date;
  public end: Date;
  public leadDays: number;
  public costPercentSupplier: number;
  public costPercentSubContractor: number;
  public costFixedSupplier: number;
  public costFixedSubContractor: number;
  public subContractorPayFrequency: number;
  public supplierPayTermsTypeId: number;
  public supplierPayTermsDays: number;
  public activityToSetStartDateId: number;
  public activityToSetEndDateId: number;
  public costCentreId: number;
  public orderPhaseId: number;
  public resourceTypeId: number;
  public roleId: number;
  public userId: number;
  public tradeId: number;
  public vendorId: number;
  public isInitiatedByJobEntry: boolean;
  public isInitiatedBySalesDate: boolean;
  public doNotSendCallup: boolean;
  public isForecastStart: boolean;
  public canSetForcastToThis: boolean;
  public isPracticalCompletionTask: boolean;
  public isKeyHandoverTask: boolean;
  public modifiedUserId: number;
  public modifiedDate: Date;
  public isNextWorkFlowTrigger: boolean;
  public isMultipleAllowed: boolean;
  public isCreatedByPostContractVO: boolean;
  public includeAddendum: boolean;
  public isAssignToPreviousUser: boolean;
  public vendorEqualToCode: string;
  public isUseWorkFlow: boolean;
  public notApplicableLinkCode: string;
  public copyCommentFromPrev: boolean;
  public sendPOWithCallUp: boolean;
  public isAuthorisedByStartDate: boolean;
  public isFormalApproval: boolean;
  public callUpSendLeadDays: number;
  public isShowForecastStart: boolean;
  public isCreatedByConstructionVOApproved: boolean;
  public isEndToStartEnabled: boolean;
  public callUpDocsTypes: number[];
  public isCreatedBySalesVariation: boolean;
  public isCreatedByPreContractVariation: boolean;
  public isHideNotApplicable: boolean;
  public salesCannotCreate: boolean;
  public mustHavePoToSend: boolean;
  public templateTaskCostCentres: TemplateTaskCostCentre[];
  public taskTypeOrderNumber: number;
  public orderNumber: number;
  public predecessors: string;

  constructor(id: number, taskTitle: string) {
    this.id = id;
    this.taskTitle = taskTitle;
  }
}

import { JobTaskPurchaseOrder } from './../../dtos/job-task-purchase-order';
import { TaskService } from './../../services/felixApi/task.service';
import { NotificationService } from './../../services/notification.service';
import { Vendor } from './../../dtos/vendor';
import { PriceFileItem } from './../../dtos/price-file-item';
import { UserService } from './../../services/felixApi/user.service';
import { GlobalService } from './../../services/global.service';
import { PurchaseOrder } from './../../dtos/purchase-order';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-secondary-purchase-orders',
  templateUrl: './secondary-purchase-orders.component.html',
  styleUrls: ['./secondary-purchase-orders.component.scss']
})
export class SecondaryPurchaseOrdersComponent implements OnInit {
  @Input() jobId: number;
  @Input() jobNumber: string;
  @Input() taskId: number;

  subscriptions: Subscription[] = [];
  purchaseOrders: PurchaseOrder[];
  sendGridHeight: number;
  vendors: Vendor[];
  costCentres: PriceFileItem[];
  loadingVisible = true;
  poIds: number[];
  dataSource: CustomStore<any, any>;
  taskPurchaseOrders: JobTaskPurchaseOrder[];
  popupWidth: number;
  firstPurchaseOrderId: number;


  constructor(private maintenanceService: MaintenanceService,
    private taskService: TaskService,
    private activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private userService: UserService,
    private notiService: NotificationService) {
    this.calculateCostCentreSortValue = this.calculateCostCentreSortValue.bind(this);
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
    this.openPO = this.openPO.bind(this);
  }

  ngOnInit() {
    this.setJobStringMaxWidth();
    this.subscribeToInnerHeightWidth();

    this.purchaseOrders = this.maintenanceService.purchaseOrders.filter(i => !i.isCancelled && i.canAttachOrdersToCallUps);
    this.vendors = this.userService.vendors.filter(i => i.canAttachOrdersToCallUps);
    this.costCentres = this.maintenanceService.costCentres;

    this.getData();
  }

  subscribeToInnerHeightWidth() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          setTimeout(() => {
            this.setJobStringMaxWidth();
          }, 100); // wait for iPhone and grid
        }
      )
    );
  }

  setJobStringMaxWidth() {
    this.sendGridHeight = window.innerHeight - 205;
    this.popupWidth = this.globalService.innerWidth < 650 ? this.globalService.innerWidth : 650;
  }

  getData() {
    this.subscriptions.push(
      this.taskService.getPurchaseOrdersForTask(this.jobId, this.taskId)
        .subscribe({
          next: (taskPurchaseOrders) => {
            this.taskPurchaseOrders = taskPurchaseOrders;
            this.loadingVisible = false;

            this.purchaseOrders.forEach(po => {
              const taskPO = this.taskPurchaseOrders.find(i => i.purchaseOrderId === po.id);
              if (taskPO) {
                po.selected = true;
                if (!this.firstPurchaseOrderId) {
                  this.firstPurchaseOrderId = po.id;
                }
                po.showPrices = taskPO.showPrices;
              } else {
                po.selected = false;
                po.showPrices = false;
              }
            });

            this.setupDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingVisible = false;
          }
        })
    );
  }

  setupDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => this.purchaseOrders,
      update: async (key, values) => {
        return new Promise((resolve, reject) => {
          if (values.selected !== undefined) {
            if (values.selected) {
              this.taskService.addPurchaseOrdersForTask(this.taskId, encodeURIComponent(key), values.showPrices ? true : false)
                .subscribe({
                  next: (res) => {
                    const poAdded = this.purchaseOrders.find(i => i.id === key);
                    poAdded.selected = true;
                    poAdded.showPrices = values.showPrices ? true : false;
                    return resolve(res);
                  }, error: (err) => {
                    return reject(this.globalService.returnError(err));
                  }
                });
            } else {
              this.taskService.deletePurchaseOrdersForTask(this.taskId, encodeURIComponent(key)).subscribe({
                next: (res) => {
                  const poAdded = this.purchaseOrders.find(i => i.id === key);
                  poAdded.selected = false;
                  poAdded.showPrices = false;
                  return resolve(res);
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              });
            }
          } else {
            // patch
            this.taskService.updatePurchaseOrderForTask(this.taskId, encodeURIComponent(key), values.showPrices).subscribe({
              next: (res) => {
                const poChanged = this.purchaseOrders.find(i => i.id === key);
                poChanged.showPrices = values.showPrices;
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            });
          }
        });
      }
    });
  }

  calculateCostCentreSortValue(data) {
    return this.costCentres.find(i => i.id === data?.costCentreId)?.orderNumber;
  }

  calculateVendorSortValue(data) {
    return this.vendors.find(i => i.id === data?.vendorId)?.vendorName;
  }

  openPO(e) {
    if (e.row.data.id) {
      this.loadingVisible = true;
      this.poIds = [];
      this.poIds.push(e.row.data.id);

      const po = this.maintenanceService.purchaseOrders.find(i => i.id === e.row.data.id);
      const isHideCostsFromTracking = this.costCentres.find(i => i.id === po.costCentreId)?.isHideCostsFromTracking

      const dataRecord = {
        purchaseOrderIds: this.poIds,
        emailAddresses: [],
        ccToSelf: false,
        download: true,
        emailSubject: '',
        emailMessage: '',
        printPrices: !isHideCostsFromTracking
      };

      this.subscriptions.push(
        this.maintenanceService.sendPurchaseOrders(this.jobId, dataRecord)
          .subscribe({
            next: (orderResponse) => {
              this.globalService.convertAndSave(orderResponse.pdfOrders, this.jobNumber);
              this.loadingVisible = false;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loadingVisible = false;
            }
          })
      );
    }
  }

  close() {
    this.activeModal.dismiss();
  }

  onHidden() {
    // this.showPopup = false;
    this.activeModal.dismiss();
  }

}

<div class="page">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" id="dataGrid" class="max-w-800 m-auto maintenancegrid-height" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" (onInitNewRow)="onInitNewRow($event)" (onExporting)="gridService.onExporting($event, 'Holidays')">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="full">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxi-column dataField="date" dataType="date" cellTemplate="dateTemplate" sortOrder="asc">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="description">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="isUsedInCashflow" caption="Used in Cash-Flow" dataType="boolean" width="85">
    </dxi-column>

    <dxi-column dataField="modifiedUserId" caption="Modified By" dataType="number" width="90" allowEditing="false"
      cellTemplate="noWrapTemplate" [allowEditing]="false">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="modifiedDate" caption="Modified" dataType="date" width="85" alignment="center"
      allowEditing="false" cellTemplate="dateTemplate" [allowEditing]="false">
    </dxi-column>



    <div *dxTemplate="let cell of 'noWrapTemplate'">
      <div style="white-space: nowrap; overflow: hidden;">{{cell.text}}</div>
    </div>

    <div *dxTemplate="let cell of 'dateTemplate'">
      <div>{{cell.value | date : "d-MMM-yy"}}</div>
    </div>

  </dx-data-grid>

</div>

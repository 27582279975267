import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { catchError, tap } from 'rxjs/operators';
import { StateStore } from '../../shared/state-store/state-store';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class StateStoreService {
  stateStoresForMe: StateStore[];

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  getAllStateStoresForMe(stateStoreTypeId: number): Observable<StateStore[]> {
    const url = this.globalService.getApiUrl() + '/state-stores/me/' + stateStoreTypeId;

    return this._http.get<StateStore[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.stateStoresForMe = res;
        this.stateStoresForMe.forEach(stateRow => {
          stateRow.privateStore = stateRow.userId ? true : false;
        });
      }),
      catchError(this.handleError));
  }

  addStateStore(dataRecord: any): Observable<StateStore> {
    const url = this.globalService.getApiUrl() + '/state-stores/';
    return this._http.post<StateStore>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateStateStore(id: string, itm: any): Observable<StateStore> {
    const url = this.globalService.getApiUrl() + '/state-stores/' + id;
    return this._http.patch<StateStore>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteStateStore(id: string) {
    const url = this.globalService.getApiUrl() + '/state-stores/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}

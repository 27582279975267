import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'js-job-data',
  templateUrl: './job-data.component.html',
  styleUrls: ['./job-data.component.scss']
})
export class JobDataComponent implements OnInit {

  jobNumber: string;
  subscriptions: Subscription[] = [];
  sections: string[] = [];

  selectedSection = 'Job-Data';

  constructor(private authService: AuthService,
    private globalService: GlobalService) { }

  ngOnInit() {
    if (window.innerWidth > 1000) {
      this.sections = ['Job-Data', 'Job-Tasks', 'Emails/Notes', 'Workflows', 'Task-Log', 'Gantt-Chart'];
    } else {
      this.sections = ['Job-Data', 'Emails/Notes', 'Workflows'];
    }
  }

  tabSelected(e) {
    this.selectedSection = e.itemData;
  }

  onJobNumberChanged(jobNo: string) {
    this.jobNumber = jobNo;
  }
}

import { JobTaskPurchaseOrder } from './../../dtos/job-task-purchase-order';
import { TaskService } from './../../services/felixApi/task.service';
import { NotificationService } from './../../services/notification.service';
import { Vendor } from './../../dtos/vendor';
import { PriceFileItem } from './../../dtos/price-file-item';
import { UserService } from './../../services/felixApi/user.service';
import { GlobalService } from './../../services/global.service';
import { PurchaseOrder } from './../../dtos/purchase-order';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-accept-call-up',
  templateUrl: './accept-call-up.component.html',
  styleUrls: ['./accept-call-up.component.scss']
})
export class AcceptCallUpComponent implements OnInit {
  @Input() taskId: number;

  subscriptions: Subscription[] = [];
  purchaseOrders: PurchaseOrder[];
  sendGridHeight: number;
  vendors: Vendor[];
  costCentres: PriceFileItem[];
  loadingVisible = false;
  poIds: number[];
  dataSource: CustomStore<any, any>;
  taskPurchaseOrders: JobTaskPurchaseOrder[];
  popupWidth: number;
  firstPurchaseOrderId: number;


  constructor(private maintenanceService: MaintenanceService,
    private taskService: TaskService,
    private activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private userService: UserService,
    private notiService: NotificationService) {
  }

  ngOnInit() {
    this.setJobStringMaxWidth();
    this.subscribeToInnerHeightWidth();

    this.purchaseOrders = this.maintenanceService.purchaseOrders.filter(i => !i.isCancelled && i.canAttachOrdersToCallUps);
    this.vendors = this.userService.vendors.filter(i => i.canAttachOrdersToCallUps);
    this.costCentres = this.maintenanceService.costCentres;
  }

  subscribeToInnerHeightWidth() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          setTimeout(() => {
            this.setJobStringMaxWidth();
          }, 100); // wait for iPhone and grid
        }
      )
    );
  }

  setJobStringMaxWidth() {
    this.popupWidth = this.globalService.innerWidth < 350 ? this.globalService.innerWidth : 350;
  }

  acceptCallUp() {
    this.loadingVisible = true;
    this.subscriptions.push(
      this.taskService.updateJobTask(this.taskId.toString(), { isAcceptedByVendor: true })
        .subscribe({
          next: () => {
            this.close();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingVisible = false;
          }
        })
    );
  }

  close() {
    this.activeModal.close();
  }

  onHidden() {
    this.activeModal.dismiss();
  }

}

import { CallUpDocsType } from './../../dtos/call-up-docs-type';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { NotificationService } from './../../services/notification.service';
import { ConfigurationEnum, DocumentManagementEnum } from './../../dtos/configuration-enum';
import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { DocumentService } from '../../services/felixApi/document.service';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-document-folders',
  templateUrl: './document-folders.component.html',
  styleUrls: ['./document-folders.component.scss']
})
export class DocumentFoldersComponent implements OnInit {

  dataSource: CustomStore;
  isSharePoint: boolean;
  callUpDocsTypes: CallUpDocsType[];
  subscriptions: Subscription[] = [];
  loading: boolean;

  constructor(
    private documentService: DocumentService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private notiService: NotificationService,
    private maintenanceService: MaintenanceService
  ) {
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.setIsShownInInfo = this.setIsShownInInfo.bind(this);
  }

  ngOnInit(): void {
    // do we have SharePoint?
    const documentsConfigValue = this.globalService.getCompanyConfigValue(ConfigurationEnum.DocumentManagementSystem);

    if (documentsConfigValue === DocumentManagementEnum.SharePoint) {
      this.isSharePoint = true;
    }

    this.subscriptions.push(
      this.maintenanceService.getCallUpDocsTypes(false)
        .subscribe({
          next: () => {
            this.callUpDocsTypes = this.maintenanceService.callUpDocsTypes;

            this.setUpDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.documentService.getDocumentFolders(false).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.documentService.addDocumentFolder(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.documentService.updateDocumentFolder(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.documentService.deleteDocumentFolder(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onToolbarPreparing(e) {
    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.DocumentManagementSystem)) {
      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            stylingMode: 'outlined',
            type: 'default',
            text: 'Run ' + (this.isSharePoint ? 'SharePoint' : 'Google') + ' Set-Up',
            onClick: this.runSetup.bind(this)
          }
        });
    }
  }

  onInitNewRow(e) {
    e.data.isActive = true;
  }

  runSetup() {
    this.loading = true;

    if (this.isSharePoint) {
      this.subscriptions.push(
        this.documentService.runSharePointSetup()
          .subscribe({
            next: () => {
              this.loading = false;
              this.notiService.showSuccess('Setup complete');
            },
            error: (err) => {
              this.loading = false;
              this.notiService.notify(err);
            }
          })
      );
    } else {
      this.subscriptions.push(
        this.documentService.runGoogleSetup()
          .subscribe({
            next: () => {
              this.loading = false;
              this.notiService.showSuccess('Setup complete');
            },
            error: (err) => {
              this.loading = false;
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  setIsShownInInfo(rowData, value, originalData) {
    if (!value) {
      if ((rowData.callUpDocsTypeId === undefined && originalData.callUpDocsTypeId)) {
        this.notiService.showInfo('Cannot un-set this when the Call-Up Doc Type is set.');
      } else {
        rowData.isShownInInfo = false;
      }
    } else {
      rowData.isShownInInfo = value;
    }
  }
}

<div class="stickToTop">

  <js-menu></js-menu>

  <!-- for iPhone rotation we add the calc 100vh -->
  <div class="container" [style.height.px]="containerHeight" [style.maxHeight.px]="containerHeight">

    <div *ngIf="authService.versionIncorrect" class="refresh">
      <h4>Your Truth Engine version ({{authService.currentVersion}}) is out of date.</h4>
      <h4>{{authService.correctVersion}} is now available.</h4>
      <br>
      <h5>Please refresh the browser below to update. Control-F5 on windows</h5>
      <br>
      <button class="btn btn-primary" (click)="refresh()">Refresh Browser</button>
    </div>

    <router-outlet *ngIf="!isIframe && !authService.versionIncorrect"></router-outlet>
  </div>

</div>

import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';
import { DocumentFolder } from '../../dtos/document-folder';
import { SharePointDocument } from '../../dtos/sharepoint-document';
import { IJobDocAttachment } from '../../dtos/job-doc-attachment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  baseFolders: DocumentFolder[];
  baseFoldersCompany: number;
  documentFolders: DocumentFolder[];
  documentFoldersCompany: number;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }


  getDocumentFolders(useCache: boolean = true): Observable<DocumentFolder[]> {
    if (useCache && this.documentFoldersCompany === this.globalService.getCurrentCompany().id
      && this.documentFolders && this.documentFolders.length) {
      return of(this.documentFolders);
    } else {
      const url = this.globalService.getApiUrl() + '/document-folders/';
      return this.http.get<DocumentFolder[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.documentFolders = res;
          this.documentFoldersCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  addDocumentFolder(dataRecord: any): Observable<DocumentFolder> {
    const url = this.globalService.getApiUrl() + '/document-folders/';
    return this.http.post<DocumentFolder>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateDocumentFolder(id: string, itm: any): Observable<DocumentFolder> {
    const url = this.globalService.getApiUrl() + '/document-folders/' + id;
    return this.http.patch<DocumentFolder>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteDocumentFolder(id: string) {
    const url = this.globalService.getApiUrl() + '/document-folders/' + id;
    return this.http.delete(url, this.httpService.getHttpOptions());
  }


  getSharePointDocFolders(isSharePoint: boolean, useCache = true): Observable<DocumentFolder[]> {
    if (useCache && this.baseFoldersCompany === this.globalService.getCurrentCompany().id
      && this.baseFolders && this.baseFolders.length) {
      return of(this.baseFolders);
    } else {
      const url = this.globalService.getApiUrl() + '/' + (isSharePoint ? 'sharepoint' : 'google-drive') + '/get-folders';
      return this.http.get<DocumentFolder[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.baseFolders = res; this.baseFoldersCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  getSharePointDocTemplates(folderName, isSharePoint: boolean): Observable<DocumentFolder[]> {
    const url = this.globalService.getApiUrl() + '/' + (isSharePoint ? 'sharepoint' : 'google-drive') + '/get-templates'
      + '?folderName=' + encodeURIComponent(folderName);

    return this.http.get<DocumentFolder[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }


  runSharePointSetup() {
    const url = this.globalService.getApiUrl() + '/sharepoint/setup';
    return this.http.post(url, '', this.httpService.getHttpOptions());
  }


  runGoogleSetup() {
    const url = this.globalService.getApiUrl() + '/google-drive/create-groups';
    return this.http.post(url, '', this.httpService.getHttpOptions());
  }

  getFolderUrl(jobNumber: string, folderName: string, isSharePoint: boolean): Observable<string> {
    const url = this.globalService.getApiUrl()
      + (isSharePoint ? '/sharepoint/' : '/google-drive/') + 'get-job-folder-url'
      + '?jobNumber=' + jobNumber + '&folderName=' + encodeURIComponent(folderName);

    return this.http.get<string>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  createSharePointMergeDocs(jobNumber: string, folderName: string, files: string[], isSharePoint: boolean, createPDFs: boolean): Observable<string> {
    let url = this.globalService.getApiUrl() + (isSharePoint ? '/sharepoint/' : '/google-drive/') + 'job/' + jobNumber + '/template-folder/merge';
    url += '?createpdfs=' + createPDFs + '&folderName=' + encodeURIComponent(folderName);

    return this.http.post<string>(url, JSON.stringify({ fileIds: files }), this.httpService.getHttpOptions());
  }

  createJobDocumentFolders(jobNumber: string, isSharePoint: boolean) {
    const url = this.globalService.getApiUrl() + '/' + (isSharePoint ? 'sharepoint' : 'google-drive') + '/job/' + jobNumber + '/create-folders';
    return this.http.post(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }

  runFileCheck(jobId: number) {
    const url = this.globalService.getApiUrl() + '/sharepoint/check-external-documents?jobId=' + jobId;
    return this.http.post(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }

  // Get a SharePoint document
  getSharePointDocument(jobNumber: string, sharePointFileId: string): Observable<SharePointDocument> {
    const url = this.globalService.getApiUrl() + '/sharepoint/job/' + jobNumber + '/get-file?sharePointFileId=' + sharePointFileId;

    return this.http.get<SharePointDocument>(url, this.httpService.getHttpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  getJobDocAttachment(jobDocId: number): Observable<IJobDocAttachment> {
    const url = this.globalService.getApiUrl() + '/job-document-attachments/' + jobDocId;

    return this.http.get<IJobDocAttachment>(url, this.httpService.getHttpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadDocument(jobDocumentId: string, jobDocAttachment: any, jobNumber: string): Observable<string> {
    const url = this.globalService.getApiUrl() + '/job-document-attachments/' + jobDocumentId + '?jobNumber=' + jobNumber;
    return this.http.post<string>(url, jobDocAttachment, this.httpService.getHttpFileOptions())
      .pipe(catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}

import { SendCancellation } from './../../dtos/send-cancellation';
import { JobTaskPurchaseOrder } from './../../dtos/job-task-purchase-order';
import { TemplateTaskHeader } from './../../dtos/templateTaskHeader';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { Job } from '../../dtos/job';
import { UserService } from './user.service';
import { MaintenanceService } from './maintenance.service';
import { Task } from '../../dtos/task';
import { JobCashFlowService } from './jobCashFlow.service';
import { JobService } from './job.service';
import { TrackingFieldsService } from './tracking-fields.service';
import { JobRole } from '../../dtos/job-role';
import { CompanyRole } from '../../dtos/companyRole';
import { TaskComment } from '../../dtos/task-comment';
import { CompanyActivity } from '../../dtos/company-activity';
import { TaskMaster } from '../../dtos/task-master';
import { JobWorkFlowService } from './job-work-flow.service';
import { CreateEmailsForJobTasksStatsDto } from '../../dtos/createEmailsForJobTasksStatsDto';
import { TradeVendor } from '../../dtos/trade-vendor';
import { HttpService } from '../http.service';
import { TemplateTask } from '../../dtos/templateTask';
import { JobTaskForecast } from '../../dtos/job-task-forecast';
import { JobField } from '../../dtos/job-field';
import { PriceFileItem } from '../../dtos/price-file-item';
import { JobTaskQueue } from '../../dtos/job-task-queue';
import { JobExtra } from '../../dtos/job-extra';
import { JobTaskLog } from '../../dtos/job-task-log';
import { formatDate } from 'devextreme/localization';
import { JobEmailService } from './job-email.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  jobTasks: Task[];
  allJobRoles: JobRole[];
  companyRoles: CompanyRole[];
  companyRolesCompany: number;
  allJobRolesCompany: number;
  callUpJobTasks: Task[];
  forecastTasks: JobTaskForecast[];
  forecastTasksCompany: number;
  reportGridTasks: Task[];
  reportGridTasksCompany: number;
  allJobRolesIncludeInactiveJobs: boolean;

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService,
    private jobService: JobService,
    private maintenanceService: MaintenanceService,
    private jobCashFlowService: JobCashFlowService,
    private trackingFieldsService: TrackingFieldsService,
    private jobWorkFlowService: JobWorkFlowService,
    private jobEmailService: JobEmailService,
    private userService: UserService) { }


  getAllJobTasks(jobId: number, showMyTasksOnly: boolean, includeOpenTasks: boolean, includeClosedTasks: boolean,
    includeCancelledTasks: boolean, showNotStarted: boolean, notCalledOnly: boolean, showJobsOnHold: boolean): Observable<Task[]> {
    let url = this.globalService.getApiUrl()
      + '/job-tasks?includeOpenTasks=' + includeOpenTasks
      + '&onlyMyTasks=' + showMyTasksOnly
      + '&includeClosedTasks=' + includeClosedTasks
      + '&includeCancelledTasks=' + includeCancelledTasks
      + '&showNotStarted=' + showNotStarted
      + '&notCalledOnly=' + notCalledOnly
      + '&showJobsOnHold=' + showJobsOnHold;

    if (jobId) {
      url += '&jobId=' + jobId;
    }
    return this._http.get<Task[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getJobFieldsWithTasks(jobId: number): Observable<JobField[]> {
    return forkJoin(
      [
        this.trackingFieldsService.getJobFields(jobId),
        this.getAllJobTasks(jobId, false, true, true, false, false, false, true),
        this.maintenanceService.getAllTemplateTasks(false)
      ]
    )
      .pipe(map(
        ([jobFields, jobTasks]) => {
          this.jobTasks = jobTasks;
          return jobFields;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getJobTasksForReportGid(useCache: boolean, includeInactiveJobs: boolean): Observable<Task[]> {
    if (useCache && this.reportGridTasks && this.reportGridTasksCompany === this.globalService.getCurrentCompany().id) {
      return of(this.reportGridTasks);
    }
    let url = this.globalService.getApiUrl()
      + '/job-tasks/report-grid';

    if (includeInactiveJobs) {
      url += '?includeInactiveJobs=' + includeInactiveJobs;
    }

    return this._http.get<Task[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.reportGridTasks = res;
        this.reportGridTasksCompany = this.globalService.getCurrentCompany().id;
      }),
      catchError(this.handleError));
  }

  getCallUpTasksForMaster(jobId: number, templateTaskHeaderId: number): Observable<Task[]> {
    const url = this.globalService.getApiUrl()
      + '/job-tasks/' + jobId + '/call-ups/' + templateTaskHeaderId + '/include-children';

    return this._http.get<Task[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  addJobTask(dataRecord: any): Observable<Task[]> {
    const url = this.globalService.getApiUrl() + '/job-tasks';
    return this._http.post<Task[]>(url, JSON.stringify(dataRecord, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }

  updateJobTask(id: string, itm: any): Observable<Task[]> {
    const dataToPass = JSON.stringify(itm, this.httpService.jsonReplacer);
    const url = this.globalService.getApiUrl() + '/job-tasks/' + id;
    return this._http.patch<Task[]>(url, dataToPass, this.httpService.getHttpOptions());
  }

  markJobTasksNotApplicable(jobId: number, jobTaskIds: number[], updateOtherChildJobs: boolean, cancelTasks: boolean): Observable<Task[]> {
    let url = this.globalService.getApiUrl() + '/job-tasks/' + jobId + '/mark-not-applicable';

    if (updateOtherChildJobs) {
      url += '?updateOtherChildJobs=' + updateOtherChildJobs;
    }
    if (cancelTasks) {
      url += (updateOtherChildJobs ? '&' : '?') + 'cancelTasks=' + cancelTasks;
    }
    return this._http.patch<Task[]>(url, JSON.stringify(jobTaskIds), this.httpService.getHttpOptions());
  }

  extendTaskStartDates(jobId: number, jobTaskIds: number[], updateOtherChildJobs: boolean, extendStartsBy: number): Observable<Task[]> {
    let url = this.globalService.getApiUrl() + '/job-tasks/' + jobId + '/extend-start-dates' + '?extendStartsBy=' + extendStartsBy;

    if (updateOtherChildJobs) {
      url += '&updateOtherChildJobs=' + updateOtherChildJobs;
    }
    return this._http.patch<Task[]>(url, JSON.stringify(jobTaskIds), this.httpService.getHttpOptions());
  }

  addMultipleJobTasks(jobId: number, templateTaskHeaderId: number, newTaskTypeId: number, nextOrderNumber: number, taskMasterIds: number[]): Observable<Task[]> {
    let url = this.globalService.getApiUrl() + '/job-tasks/' + jobId + '/add-multiple-task-masters?templateTaskHeaderId=' + templateTaskHeaderId;
    url += '&newTaskTypeId=' + newTaskTypeId;
    url += '&nextOrderNumber=' + nextOrderNumber;

    return this._http.post<Task[]>(url, JSON.stringify(taskMasterIds), this.httpService.getHttpOptions());
  }

  resetNotApplicable(jobId: number, jobTaskId: number): Observable<Task[]> {
    const url = this.globalService.getApiUrl() + '/job-tasks/' + jobId + '/reset-not-applicable?jobTaskId=' + jobTaskId;
    return this._http.patch<Task[]>(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }

  moveJobTask(jobTaskId: number, orderNumber: any, newTaskTypeId: number): Observable<Task[]> {
    const url = this.globalService.getApiUrl() + '/job-tasks/move/' + jobTaskId
      + '?orderNumber=' + orderNumber + '&newTaskTypeId=' + newTaskTypeId;
    return this._http.patch<Task[]>(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }

  cancelAllJobTasks(jobId: number, cancelStartedTasks: boolean, cancelMaintenanceTasks: boolean) {
    const url = this.globalService.getApiUrl() + '/job/' + jobId
      + '/close-all?cancelStartedTasks=' + cancelStartedTasks
      + '&cancelMaintenanceTasks=' + cancelMaintenanceTasks;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  cancelJobTasksByIds(includeStarted: boolean, jobTaskIds: number[]) {
    const url = this.globalService.getApiUrl() + '/job-tasks/cancel-old-tasks'
      + '?includeStarted=' + includeStarted;
    return this._http.patch(url, JSON.stringify(jobTaskIds), this.httpService.getHttpOptions());
  }

  reassignAllJobTasks(changeStartedTasks: boolean, fromUserId: number, toUserId: number, reassignRoles: boolean) {
    const url = this.globalService.getApiUrl() + '/job-tasks/reassign?changeStartedTasks=' + changeStartedTasks
      + '&fromUserId=' + fromUserId + '&toUserId=' + toUserId + '&reassignRoles=' + reassignRoles;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }


  getJobTaskForecast(jobNumber: string, useStoredForecast: boolean): Observable<TemplateTask[]> {
    const url = this.globalService.getApiUrl()
      + '/job-task-forecasts/job?jobNumber=' + jobNumber + '&useStoredForecast=' + useStoredForecast;

    return this._http.get<TemplateTask[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }


  recalcForecasts(useCache: boolean): Observable<JobTaskForecast[]> {
    if (useCache && this.forecastTasks && this.forecastTasksCompany === this.globalService.getCurrentCompany().id) {
      return of(this.forecastTasks);
    }
    const url = this.globalService.getApiUrl() + '/job-task-forecasts/recalc-completion';

    return this._http.patch<JobTaskForecast[]>(url, '', this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.forecastTasks = res;
        this.forecastTasksCompany = this.globalService.getCurrentCompany().id;
      }),
      catchError(this.handleError));
  }


  getJobTaskForecastData(jobNumber: string, useCache: boolean, jobId: number): Observable<TemplateTask[]> {
    return forkJoin(
      [
        this.getJobTaskForecast(jobNumber, false),
        this.maintenanceService.getTaskDependencies(useCache),
        this.getJobTasksData(jobId, false, true, true, true, true, useCache, false, true)
      ]
    )
      .pipe(map(
        ([templateTasks]) => {
          return templateTasks;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  getCallUpDataForMaster(jobId: number, templateTaskHeaderId: number): Observable<Task[]> {
    return forkJoin(
      [
        this.getCallUpTasksForMaster(jobId, templateTaskHeaderId),
        this.jobService.getJobDocumentsForMasterChildJobs(jobId),
        this.maintenanceService.getTaskControl(false),
        this.jobService.getJobTaskDocumentsForMasterChild(jobId),
      ]
    )
      .pipe(map(
        ([res]) => {
          return res;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  getJobTasksForVendorSummary(masterJobId: number, templateTaskHeaderId: number, vendorId: number): Observable<Task[]> {
    const url = this.globalService.getApiUrl() + '/job-tasks/' + masterJobId + '/call-ups/' + templateTaskHeaderId + '/vendor/' + vendorId + '/summary';
    return this._http.get<Task[]>(url, this.httpService.getHttpOptions());
  }


  updateJobTaskPONumbers(jobId: number, templateTaskHeaderId: any): Observable<Task[]> {
    const dataToPass = JSON.stringify({});
    const url = this.globalService.getApiUrl() + '/job-tasks/update-pos?jobId=' + jobId + '&templateTaskHeaderId=' + templateTaskHeaderId;
    return this._http.patch<Task[]>(url, dataToPass, this.httpService.getHttpOptions());
  }


  // get the roles for each job
  getCompanyRoles(useCache: boolean): Observable<CompanyRole[]> {
    if (useCache && this.companyRolesCompany === this.globalService.getCurrentCompany().id
      && this.companyRoles && this.companyRoles.length) {
      return of(this.companyRoles);
    } else {
      const url = this.globalService.getApiUrl() + '/company-roles';
      return this._http.get<CompanyRole[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.companyRoles = res;
          this.companyRolesCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  getAllJobRoles(useCache: boolean, includeInactiveJobs: boolean): Observable<JobRole[]> {
    if (useCache && this.allJobRolesCompany === this.globalService.getCurrentCompany().id
      && this.allJobRoles && this.allJobRoles.length
      && this.allJobRolesIncludeInactiveJobs === includeInactiveJobs) {
      return of(this.allJobRoles);
    } else {
      const url = this.globalService.getApiUrl() + '/jobs/roles?includeInactiveJobs=' + includeInactiveJobs;
      return this._http.get<JobRole[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.allJobRoles = res;
          this.allJobRolesCompany = this.globalService.getCurrentCompany().id;
          this.allJobRolesIncludeInactiveJobs = includeInactiveJobs;
        }),
        catchError(this.handleError));
    }
  }

  getAllJobsWithRoles(useCache: boolean, includeInactiveJobs: boolean): Observable<JobRole[]> {
    return forkJoin(
      [
        this.getAllJobRoles(useCache, includeInactiveJobs),
        this.getCompanyRoles(useCache)
      ]
    )
      .pipe(map(
        ([jobRoles, companyRoles]) => {
          return jobRoles;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getAllRolesWithJobs(useCache: boolean): Observable<JobRole[]> {
    return forkJoin(
      [
        this.getAllJobRoles(useCache, false),
        this.jobService.getJobsByAddress(useCache),
        this.jobService.getAllVariations(useCache, false),
        this.jobService.getAllJobExtras(useCache, false),
        this.userService.getCurrCompUsers(useCache)
      ]
    )
      .pipe(map(
        ([jobRoles]) => {
          return jobRoles;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  getJobsUsersTypes(useCache: boolean): Observable<Job[]> {
    return forkJoin(
      [
        this.jobService.getJobsByAddressWithExtras(useCache),
        this.maintenanceService.getTaskMasters(useCache),
        this.maintenanceService.getHolidays(useCache),
        this.jobService.getHouseTypes(useCache),
        this.userService.getCurrCompUsers(useCache),
        this.jobService.getJobClientLoginsForReportGrid(useCache)
      ]
    )
      .pipe(map(
        ([jobs]) => {
          jobs.forEach(job => {
            // job.jobAddressString = this.globalService.getJobString(job);
            const jobExtra = this.jobService.jobExtras?.find(i => i.jobId === job.id);
            if (jobExtra) {
              const activity = this.maintenanceService.activities?.find(i => i.id === jobExtra.currentActivityId);
              if (activity) {
                job.currentActivityDesc = activity.description;
                job.currentActivityCode = activity.activityCode;
              } else {
                job.currentActivityCode = ''; // to put at the end
              }
            } else {
              job.currentActivityCode = ''; // to put at the end
            }
          });

          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getJobTasksData(jobId: number, showMyTasksOnly: boolean, showInProgress: boolean,
    showCompleted: boolean, showCancelled: boolean, showNotStarted: boolean, useCache: boolean,
    fromTimer, showJobsOnHold: boolean): Observable<Job[]> {
    return forkJoin(
      [
        this.getJobsUsersTypes(useCache),
        this.getAllJobTasks(jobId, showMyTasksOnly, showInProgress, showCompleted, showCancelled, showNotStarted, false, showJobsOnHold),
        this.maintenanceService.getTaskTypes(useCache),
        this.maintenanceService.getTemplateDaysHeaders(useCache),
        this.getJobTasksData2(useCache),
        this.jobService.getAllVariations(fromTimer ? false: useCache, false),
        this.maintenanceService.getDivisions(),
      ]
    )
      .pipe(map(
        ([jobs, jobTasks]) => {
          this.jobTasks = jobTasks;
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getJobTasksData2(useCache: boolean): Observable<TemplateTaskHeader[]> {
    return forkJoin(
      [
        this.maintenanceService.getTemplateTaskHeaders(useCache, true),
        this.maintenanceService.getAllTemplateTasksWithCallUpDocs(),
        this.jobWorkFlowService.getAllJobWorkFlows(useCache, true),
        this.maintenanceService.getTemplateDays(useCache),
        this.getCompanyRoles(useCache),
        this.getAllJobRoles(useCache, false)
      ]
    )
      .pipe(map(
        ([templateTaskHeader]) => {
          return templateTaskHeader;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getAllTasksGridData(useCache: boolean, includeInactiveJobs: boolean): Observable<Job[]> {
    return forkJoin(
      [
        this.getJobsUsersTypes(useCache),
        this.getJobTasksForReportGid(useCache, includeInactiveJobs),
        this.trackingFieldsService.getTrackingFields(true, useCache),
        this.getAllTasksGridData2(useCache, includeInactiveJobs),
        this.getAllTasksGridData3(useCache, includeInactiveJobs),
        this.maintenanceService.getTradeRegions(useCache),
        this.trackingFieldsService.getAllTrackingFieldLookups(useCache),
        this.recalcForecasts(useCache),
        this.maintenanceService.getAllTemplateTasks(false)
      ]
    )
      .pipe(map(
        ([jobs, jobTasks]) => {
          this.jobTasks = jobTasks;
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getAllTasksGridData2(useCache: boolean, includeInactiveJobs: boolean): Observable<CompanyActivity[]> {
    return forkJoin(
      [
        this.maintenanceService.getCompanyActivities(false, useCache),
        this.jobCashFlowService.getJobCashFlows(!includeInactiveJobs, false, useCache),
        this.jobWorkFlowService.getAllJobWorkFlows(useCache, includeInactiveJobs),
        this.userService.getVendors(useCache, false),
        this.maintenanceService.getDivisions(),
        this.jobService.getJobBanks(useCache),
        this.jobService.getNextClaims(useCache)
      ]
    )
      .pipe(map(
        ([activities]) => {
          return activities;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getAllTasksGridData3(useCache: boolean, includeInactiveJobs: boolean): Observable<JobField[]> {
    return forkJoin(
      [
        this.trackingFieldsService.getAllJobFields(useCache, includeInactiveJobs),
        this.getAllJobsWithRoles(useCache, true), // include inactive jobs here for reporting
        this.jobService.getAllVariations(useCache, includeInactiveJobs),
        this.maintenanceService.getLandZones(),
        this.maintenanceService.getLandTitleTypes(),
        this.maintenanceService.getLandTypes(),
        this.maintenanceService.getTaskControl(true),
        this.jobEmailService.getLatestJobEmails(useCache),
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getCallUpsData(useCache: boolean): Observable<TaskMaster[]> {
    return forkJoin(
      [
        this.maintenanceService.getTaskMasters(useCache),
        this.maintenanceService.getTemplateTaskHeaders(useCache, true),
        this.userService.getCurrCompUsers(useCache),
        this.userService.getVendors(useCache, true),
        this.userService.getVendorPayables(useCache),
        this.getCallUpsData2(useCache)
      ]
    )
      .pipe(map(
        ([taskMasters]) => {
          return taskMasters;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getCallUpsData2(useCache: boolean): Observable<TradeVendor[]> {
    return forkJoin(
      [
        this.maintenanceService.getTradeVendors(useCache),
        this.maintenanceService.getTradeRegions(useCache),
        this.maintenanceService.getHolidays(useCache),
        this.maintenanceService.getTemplateDays(useCache),
        this.maintenanceService.getCallUpDocsTypes(useCache),
        this.getCallUpsData3(useCache)
      ]
    )
      .pipe(map(
        ([tradeRegions]) => {
          return tradeRegions;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getCallUpsData3(useCache: boolean): Observable<PriceFileItem[]> {
    return forkJoin(
      [
        this.maintenanceService.getPriceFileItemGroups(useCache),
        this.jobService.getJobsByAddress(useCache)
      ]
    )
      .pipe(map(
        ([tradeRegions]) => {
          return tradeRegions;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getTaskDocumentsData(useCache: boolean, templateTaskHeaderId: number): Observable<TemplateTask[]> {
    return forkJoin(
      [
        this.maintenanceService.getTemplateTasks(templateTaskHeaderId, false),
        this.maintenanceService.getCallUpDocsTypes(useCache),
        this.maintenanceService.getTemplateTaskDocsTypes(useCache),
      ]
    )
      .pipe(map(
        ([data]) => {
          // attach the call-up docs types to the template tasks
          this.maintenanceService.templateTasks.forEach(templateTask => {
            templateTask.callUpDocsTypes = [];
            this.maintenanceService.templateTaskDocsTypes.filter(i => i.templateTaskId === templateTask.id).forEach(templateTaskDocsType => {
              templateTask.callUpDocsTypes.push(templateTaskDocsType.callUpDocsTypeId);
            });
          });
          return this.maintenanceService.templateTasks;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getCallUpTasks(jobId: number, templateTaskHeaderId: number, generateTasks: boolean, reorderNonChangedTasks: boolean): Observable<Task[]> {
    return forkJoin(
      [
        this.getCallUpJobTasks(jobId, templateTaskHeaderId, generateTasks, reorderNonChangedTasks),
        this.maintenanceService.getTaskTypes(true),
        this.maintenanceService.getTemplateTasksWithCallUpDocs(templateTaskHeaderId, false),
        this.maintenanceService.getTrades(),
        this.maintenanceService.getTaskDependencies(true)
      ]
    )
      .pipe(map(
        ([tasks]) => {
          return tasks;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getJobTaskLogsData(jobId: number, useCache: boolean, keyTasksOnly: boolean, dateFrom: Date, showAllJobs: boolean): Observable<JobTaskLog[]> {
    return forkJoin(
      [
        this.getJobTaskLogs(showAllJobs? null : jobId, keyTasksOnly, dateFrom),
        this.maintenanceService.getTaskMasters(useCache),
        this.maintenanceService.getTaskTypes(useCache),
        this.userService.getCurrCompUsers(useCache),
        this.maintenanceService.getTemplateTaskHeaders(useCache, true),
        this.jobService.getJobVariations(jobId),
        this.userService.getVendors(useCache, false),
        this.maintenanceService.getPurchaseOrders(jobId),
        this.jobService.getJobsByAddress(useCache)
      ]
    )
      .pipe(map(
        ([data]) => {
          return data;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getJobTaskLogs(jobId: number, keyTasksOnly: boolean, dateFrom: Date): Observable<JobTaskLog[]> {
    let url = this.globalService.getApiUrl() + '/job-tasks/task-logs';

    if (jobId) {
      url += '?jobId=' + jobId;
    }
    if (keyTasksOnly) {
      url += (jobId ? '&' : '?') + 'keyTasksOnly=' + keyTasksOnly;
    }
    if (dateFrom) {
      url += (jobId || keyTasksOnly ? '&' : '?') + 'dateFrom=' + formatDate(dateFrom, 'yyyy-MMM-dd');
    }

    return this._http.get<JobTaskLog[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getCallUpJobTasks(jobId: number, templateTaskHeaderId: number, generateTasks: boolean, reorderNonChangedTasks: boolean): Observable<Task[]> {
    let url = this.globalService.getApiUrl() + '/job-tasks/' + jobId + '/call-ups/' + templateTaskHeaderId;

    url += '?generateTasks=' + generateTasks;
    url += '&reorderNonChangedTasks=' + reorderNonChangedTasks;

    return this._http.get<Task[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.callUpJobTasks = res;
      }),
      catchError(this.handleError));
  }

  getCallUpChildJobTasks(jobTaskId: number, withMaster: boolean): Observable<Task[]> {
    const url = this.globalService.getApiUrl() + '/job-tasks/' + jobTaskId + '/child-tasks?withMaster=' + withMaster;

    return this._http.get<Task[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getPurchaseOrdersForTask(jobId: number, jobTaskId: number): Observable<JobTaskPurchaseOrder[]> {
    return this._http.get<JobTaskPurchaseOrder[]>(this.globalService.getApiUrl() +
      '/job-task-purchase-orders?jobId=' + jobId + '&jobTaskId=' + jobTaskId, this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addPurchaseOrdersForTask(jobTaskId: number, purchaseOrderId: any, showPrices: boolean): Observable<JobTaskPurchaseOrder> {
    const url = this.globalService.getApiUrl() + '/job-task-purchase-orders';
    return this._http.post<JobTaskPurchaseOrder>(url,
      JSON.stringify({ jobTaskId: jobTaskId, purchaseOrderId: purchaseOrderId, showPrices: showPrices }),
      this.httpService.getHttpOptions());
  }

  deletePurchaseOrdersForTask(jobTaskId: number, purchaseOrderId: any) {
    const url = this.globalService.getApiUrl() + '/job-task-purchase-orders?jobTaskId=' + jobTaskId + '&purchaseOrderId=' + purchaseOrderId;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  updatePurchaseOrderForTask(jobTaskId: number, purchaseOrderId: any, showPrices: boolean): Observable<JobTaskPurchaseOrder> {
    const url = this.globalService.getApiUrl() + '/job-task-purchase-orders?jobTaskId=' + jobTaskId + '&purchaseOrderId=' + purchaseOrderId;
    return this._http.patch<JobTaskPurchaseOrder>(url, { showPrices: showPrices }, this.httpService.getHttpOptions());
  }

  sendCallUps(jobId: number, jobTaskIds: number[], ccToSelf: boolean,
    markAsSent: boolean, emailAddresses: string[]): Observable<CreateEmailsForJobTasksStatsDto> {
    if (markAsSent) {
      let url = this.globalService.getApiUrl() + '/jobs/' + jobId + '/job-tasks-emails';
      url += '?markAsSent=' + markAsSent;
      return this._http.post<CreateEmailsForJobTasksStatsDto>(url,
        JSON.stringify({ jobTaskIds: jobTaskIds, ccToSelf: ccToSelf, emailAddresses: emailAddresses }),
        this.httpService.getHttpOptions());
    } else {
      const queueUrl = this.globalService.getApiUrl() + '/jobs/' + jobId + '/queue-job-tasks-emails';
      return this._http.post<CreateEmailsForJobTasksStatsDto>(queueUrl,
        JSON.stringify({ jobTaskIds: jobTaskIds, ccToSelf: ccToSelf, emailAddresses: emailAddresses }),
        this.httpService.getHttpOptions());
    }
  }

  sendCallUpForOneTask(jobTaskId: number, callUpEmail: string, subjectLine: string, ccToSelf: boolean,
    purchaseOrderId: number) {
    const url = this.globalService.getApiUrl() + '/job-tasks/' + jobTaskId + '/email';
    return this._http.post(url,
      JSON.stringify({ callUpEmail: callUpEmail, subjectLine: subjectLine, ccToSelf: ccToSelf, purchaseOrderId: purchaseOrderId }),
      this.httpService.getHttpOptions());
  }

  getJobTaskComments(jobTaskId: number): Observable<TaskComment[]> {
    const url = this.globalService.getApiUrl() + '/job-task-comments?jobTaskId=' + jobTaskId;

    return this._http.get<TaskComment[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getJobTaskQueue(jobTaskId: number): Observable<JobTaskQueue[]> {
    const url = this.globalService.getApiUrl() + '/job-task-queues?jobTaskId=' + jobTaskId;

    return this._http.get<JobTaskQueue[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  sendJobTaskCancellation(jobTaskId: number, sendCancellationDto: SendCancellation) {
    const url = this.globalService.getApiUrl() + '/job-tasks/' + jobTaskId + '/cancel-call-up';

    return this._http.post(url, sendCancellationDto, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }



  calcCurrentActivity(jobId: number): Observable<JobExtra> {
    return forkJoin(
      [
        this.calcCurrentActivityForJob(jobId)
      ]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  calcCurrentActivityForJob(jobId: number): Observable<JobExtra> {
    const url = this.globalService.getApiUrl() + '/job-tasks/' + jobId + '/calc-current-activity';
    return this._http.patch<JobExtra>(url, {}, this.httpService.getHttpOptions());
  }


  getJobCashFlowData(useCache: boolean): Observable<Job[]> {
    return forkJoin(
      [
        this.jobCashFlowService.getJobCashFlowData(useCache),
        this.getJobsUsersTypes(useCache),
        this.getAllJobRoles(useCache, false),
        this.jobService.getAllVariations(useCache, false),
      ]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}

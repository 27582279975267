<dx-popup class="popup" [width]="350" [height]="popupHeight" [showTitle]="true" title="Send Cancellation"
  [dragEnabled]="false" [hideOnOutsideClick]="false" [visible]="true" [showCloseButton]="true" (onHiding)="close()">
  <div *dxTemplate="let data of 'content'">
    <div *ngIf="!sendCancellationDto.sendCancellationCallUp">
      <div>To: {{vendorName}}</div>
      <br>
      <dx-button id="generateButton" text="Yes" type="success"
        (onClick)="sendCancellationDto.sendCancellationCallUp = true; popupHeight = 420">
      </dx-button>
      <br>
      <br>
      <dx-button id="generateButton" text="No" type="danger" (onClick)="close()">
      </dx-button>
    </div>

    <div *ngIf="!loading && sendCancellationDto.sendCancellationCallUp">
      <div class="row mt-3">
        <div class="col-md-12">
          To:
          <dx-text-box [(value)]="sendCancellationDto.sendCancellationToEmail" placeholder="Email" mode="email"
            [showClearButton]="true">
          </dx-text-box>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          Comment:
          <dx-text-area [(value)]="sendCancellationDto.sendCancellationComment" [height]="150">
          </dx-text-area>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-12">
          <dx-check-box text="cc to self" [rtlEnabled]="true"
            [(value)]="sendCancellationDto.sendCancellationCcToSiteManager">
          </dx-check-box>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-12">
          <dx-button id="sendButton" text="Send" type="success" (onClick)="sendCancellationEmail()"
            [disabled]="sendCancellationDto.sendCancellationToEmail === ''">
          </dx-button>
        </div>
      </div>
    </div>
  </div>
</dx-popup>


<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>

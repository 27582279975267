import { JobWorkFlowService } from './../../services/felixApi/job-work-flow.service';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { UtilsService } from './../../services/utils.service';
import { NotificationService } from './../../services/notification.service';
import { UserService } from './../../services/felixApi/user.service';
import { GlobalService } from './../../services/global.service';
import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Job } from '../../dtos/job';
import { JobService } from '../../services/felixApi/job.service';
import { Task } from '../../dtos/task';
import { TaskService } from '../../services/felixApi/task.service';
import CustomStore from 'devextreme/data/custom_store';
import { Vendor } from '../../dtos/vendor';
import { DxDataGridComponent } from 'devextreme-angular';
import { formatDate } from 'devextreme/localization';
import { TaskStatusEnum } from '../../dtos/task-status.enum';
import { TaskMaster } from '../../dtos/task-master';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'js-master-child-call-ups',
  templateUrl: './master-child-call-ups.component.html',
  styleUrls: ['./master-child-call-ups.component.scss']
})
export class MasterChildCallUpsComponent implements OnInit, OnDestroy {
  @Input() jobTask: Task;
  @Input() vendorDescMinWidth: number;
  @Input() selectedTaskHeaderId: number;
  @Input() title: string;

  @ViewChild(DxDataGridComponent) childJobsGrid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  jobs: Job[];
  vendors: Vendor[];
  childTasks: Task[];
  taskMasters: TaskMaster[];
  selectedTemplateDaysHeaderId: number;
  vendorWidth: number;
  loading = false;
  settingAllDates: boolean;
  gridHeight: number;
  selectedJobTasks: number[];
  cancelTasksComfirmation: boolean;

  constructor(
    private globalService: GlobalService,
    private activeModal: NgbActiveModal,
    private taskService: TaskService,
    private userService: UserService,
    private notiService: NotificationService,
    private utilService: UtilsService,
    private maintenanceService: MaintenanceService,
    private jobWorkFlowService: JobWorkFlowService,
    private jobService: JobService) {
    this.calculateJobAddress = this.calculateJobAddress.bind(this);
    this.setStartDateCellValue = this.setStartDateCellValue.bind(this);
  }

  ngOnInit() {
    this.jobs = this.jobService.jobs;
    this.vendors = this.userService.vendors;
    this.taskMasters = this.maintenanceService.taskMasters;
    this.gridHeight = this.globalService.innerHeight - 230;

    // get the workflow
    this.subscriptions.push(
      this.jobWorkFlowService.getJobWorkFlows(this.jobTask.jobId).subscribe({
        next: (res) => {
          this.selectedTemplateDaysHeaderId = res.find(i => i.templateTaskHeaderId === this.selectedTaskHeaderId)?.templateDaysHeaderId;
        }, error: (err) => {
          this.notiService.notify(err);
        }
      })
    );

    this.setUpDataSet();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setWidths() {
    this.vendorWidth = this.vendorDescMinWidth - 23;

    if (this.vendorWidth > 241) {
      this.vendorWidth = 241;
    }
  }

  setUpDataSet() {
    this.setWidths();

    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.taskService.getCallUpChildJobTasks(this.jobTask.id, true).subscribe({
            next: (res) => {
              this.childTasks = res;
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) => {
          this.taskService.updateJobTask(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          });
        });
      }
    });
  }

  onRowPrepared(e) {
    if (e.rowType === 'data' && (e.data.endDate || e.data.statusId === TaskStatusEnum.NotApplicable)) {
      // completed in green or orange
      if (e.data.statusId === TaskStatusEnum.NotApplicable) {
        e.rowElement.style.backgroundColor = 'rgb(230, 245, 220)';
      } else {
        e.rowElement.style.backgroundColor = 'rgb(200, 250, 200)';
      }
      e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
    }
  }

  onCellPrepared(e) {
    if (e.rowType === 'data' && e.column.dataField === 'vendorId'
      && !e.displayValue && e.data.startDate && !e.data.doNotSendCallup && !e.data.endDate) {
      e.cellElement.style.backgroundColor = 'red';
    }

    if (e.rowType === 'data'
      && e.data.statusId === TaskStatusEnum.NotApplicable) {
      e.cellElement.style.textDecoration = 'line-through';
    }

    if (e.rowType === 'data' && e.column.dataField === 'calledDate') {
      if (e.data.isAcceptedByVendor) {
        e.cellElement.style.color = 'rgb(20, 240, 0)';
      }
    }
  }

  calculateJobAddress(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobAddressString;
  }

  setStartDateCellValue(newData, value, currentRowData) {
    // must use formatDate as we get ISO format and lose hours so date can be yesterday
    if (currentRowData.endDate) {
      this.notiService.showWarning('Cannot change delivery date unless completed date is blank');
    } else {
      if (value) {
        if (currentRowData.calledDate) {
          newData.prevStartDate = currentRowData.startDate;
          const prevComment = 'Rescheduled from ' + this.globalService.getDateString(newData.prevStartDate) + '.';
          if (currentRowData.vendorComment) {
            newData.vendorComment = prevComment + '\n' + currentRowData.vendorComment;
          } else {
            newData.vendorComment = prevComment;
          }
        }

        const startDateString = this.utilService.convertDateToString(value);
        const todaysDateString = formatDate(new Date(), 'yyyy-MM-dd');

        if (startDateString < todaysDateString) {
          this.notiService.showWarning('Warning - date in the past');
        } else if (value.getDay() === 6 || value.getDay() === 0) {
          this.notiService.showWarning('Warning - weekend date');
        } else if (this.maintenanceService.holidays.find(i => i.date.toString().substr(0, 10) === startDateString)) {
          this.notiService.showWarning('Warning - date is a holiday');
        }

        newData.startDate = startDateString;
        if (currentRowData.statusId === TaskStatusEnum.NotStarted || currentRowData.statusId === TaskStatusEnum.NotApplicable) {
          newData.statusId = TaskStatusEnum.InProgress;
        }

        const templateTask = this.maintenanceService.templateTasks.find(i => i.taskMasterId === currentRowData.taskMasterId);

        if (!currentRowData.userId) {
          newData.userId = templateTask ? templateTask.userId : null;
        }

        this.resetDueDate(currentRowData, newData, startDateString, currentRowData.manualDays);
      } else {
        newData.statusId = TaskStatusEnum.NotStarted;
        newData.startDate = null;
        newData.dueDate = null;
        newData.endDate = null;
        newData.isCompleted = false;
      }
      newData.calledDate = null;
    }
  }

  resetDueDate(currentRowData: any, newData: any, startDateString: string, manualDays: number) {
    if (!currentRowData.endDate && startDateString && startDateString.length) {
      // we can reset the due date
      const taskMaster = this.taskMasters.find(i => i.id === currentRowData.taskMasterId);
      if (taskMaster) {

        const startDate =
          new Date(+startDateString.substr(0, 4), +startDateString.substr(5, 2) - 1, +startDateString.substr(8, 2), 0, 0, 0, 0);

        let days = taskMaster.days;

        if (manualDays) {
          days = manualDays;
        } else if (this.maintenanceService.templateDays && this.maintenanceService.templateDays.length) {
          const templateTask = this.maintenanceService.templateTasks.find(i => i.taskMasterId === currentRowData.taskMasterId);

          const templateDays = this.maintenanceService.templateDays
            .find(i => i.templateDaysHeaderId === this.selectedTemplateDaysHeaderId && i.templateTaskId === templateTask?.id);

          if (templateDays && !templateDays.isOnlyForForecast) {
            days = templateDays.days ? templateDays.days : 0;
          }
        }

        newData.dueDate = this.utilService.convertDateToString(this.maintenanceService.addDaysExHolidays(startDate, days));
      }
    }
  }

  close() {
    this.activeModal.close();
  }

  markNotApplicable(cancelTasks: boolean) {
    if (this.selectedJobTasks && this.selectedJobTasks.length) {

      const tasksToMark: number[] = [];

      this.selectedJobTasks.forEach(selectedJobTaskId => {
        var selectedTask = this.childTasks.find(i => i.id === selectedJobTaskId);

        if (selectedTask.statusId !== TaskStatusEnum.NotApplicable) {
          tasksToMark.push(selectedJobTaskId);
        }
      });

      if (tasksToMark.length) {
        this.loading = true;
        this.subscriptions.push(
          this.taskService.markJobTasksNotApplicable(this.jobService.currentJob.id, tasksToMark, false, cancelTasks)
            .subscribe({
              next: () => {
                this.activeModal.close();
              },
              error: (err) => {
                this.notiService.notify(err);
                this.loading = false;
              }
            })
        );
      }
    }
  }

  cancelTasks() {
    this.cancelTasksComfirmation = true;
  }
}

import { MaintenanceService } from './maintenance.service';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Job } from '../../dtos/job';
import { GlobalService } from '../global.service';
import { TruthEngineSession, SessionName } from '../../dtos/session';
import { JobExtra } from '../../dtos/job-extra';
import { HouseType } from '../../dtos/house-type';
import { JobDocument } from '../../dtos/job-document';
import { JobTaskDocument } from '../../dtos/job-task-document';
import { JobDocumentRecordTypeEnum } from '../../dtos/job-document-record-type.enum';
import { HttpService } from '../http.service';
import { AuthService } from '../auth.service';
import { Variation } from '../../dtos/variation';
import { JobRole } from '../../dtos/job-role';
import { JobBank } from '../../dtos/job-bank';
import { NotificationService } from '../notification.service';
import { VariationStatusEnum } from '../../dtos/variation-status.enum';
import { FileAttachment } from '../../dtos/file-attachment';
import { ClaimJobLine } from '../../dtos/claim-job-line';
import { JobCustomerRaw } from '../../dtos/job-customer';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  private currentJobNo: string;
  jobs: Job[];
  currentJob: Job;
  jobsCompany: number;
  jobExtras: JobExtra[] = [];
  currentJobExtra: JobExtra;
  jobExtrasCompany: number;
  houseTypes: HouseType[];
  jobDocuments: JobDocument[] = [];
  // jobTaskDocuments: JobTaskDocument[];
  jobDocumentsJobId: number;
  // jobTaskDocumentsJobId: number;
  jobVariations: Variation[];
  jobVariationsCompany: number;
  jobBanks: JobBank[];
  jobBanksCompany: number;
  jobDocumentsForMasterJob: JobDocument[];
  currentActivity: string;
  jobTaskDocumentsForMasterChildJobs: JobTaskDocument[];
  cachCompanyHouseTypes: string;
  nextClaims: ClaimJobLine[];
  nextClaimsCompany: number;
  currentJobVariations: Variation[];
  jobClientLogins: JobCustomerRaw[];
  jobClientLoginsCompany: number;

  constructor(
    private _http: HttpClient,
    private auth: AuthService,
    private httpService: HttpService,
    private maintenanceService: MaintenanceService,
    private notiService: NotificationService,
    private globalService: GlobalService) { }


  getSessionObject(): TruthEngineSession {
    if (sessionStorage.getItem(SessionName)) {
      return JSON.parse(sessionStorage.getItem(SessionName));
    } else {
      const session = new TruthEngineSession();
      sessionStorage.setItem(SessionName, JSON.stringify(session));
      return JSON.parse(sessionStorage.getItem(SessionName));
    }
  }

  setSessionAtt(att: string, obj: any) {
    const session = this.getSessionObject();
    session[att] = obj;
    const sessionString = JSON.stringify(session);
    sessionStorage.setItem(SessionName, sessionString);
  }

  setCurrentJob(job: string) {
    this.currentJobNo = job;
    // store the latest job number to be the default in long term storage as well as session
    localStorage.setItem('jobANNX-LastJobNumber', job);
    this.setSessionAtt('currentJobNo', job);
  }

  // get one job
  getJob(jobNo: string): Observable<Job> {
    return this._http.get<Job>(this.globalService.getApiUrl() + '/jobs/' + jobNo,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getCurrentJob(): string {
    if (this.currentJobNo !== undefined && this.currentJobNo !== null) {
      return this.currentJobNo;
    }
    // return the last used job number on this client as default
    const session = this.getSessionObject();
    if (session && session.currentJobNo) {
      this.currentJobNo = session.currentJobNo;
      return this.currentJobNo;
    }
    if (localStorage.getItem('jobANNX-LastJobNumber')) {
      this.currentJobNo = localStorage.getItem('jobANNX-LastJobNumber');
      if (this.currentJobNo !== undefined && this.currentJobNo !== null) {
        return this.currentJobNo;
      }
    }
    return '';
  }

  // search for jobs by a matching contract name string
  getJobsByAddress(useCache: boolean): Observable<Job[]> {
    if (useCache && this.jobsCompany === this.globalService.getCurrentCompany().id
      && this.jobs && this.jobs.length) {
      return of(this.jobs);
    } else {
      const searchString = '';
      return this._http.get<Job[]>(this.globalService.getApiUrl() + '/jobs?jobSearchType=JobAddress&searchString=' + searchString,
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            res.forEach(job => {
              job.jobAddressString = this.globalService.getJobString(job);
            });
            this.jobs = res;
            this.jobsCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }

  // search for jobs by a matching contract name string
  getTemplateJobs(): Observable<Job[]> {
    return this._http.get<Job[]>(this.globalService.getApiUrl() + '/jobs?jobSearchType=Templates',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getMyJobs() {
    return this._http.get<Job[]>(this.globalService.getApiUrl() + '/jobs?jobSearchType=ContractName&userId='
      + this.auth.getCurrentUserId(), this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getJobsByAddressWithExtras(useCache: boolean): Observable<Job[]> {
    return forkJoin(
      [
        this.getJobsByAddress(useCache),
        this.getAllJobExtras(useCache, false),
        this.maintenanceService.getCompanyActivities(false, useCache)
      ]
    )
      .pipe(map(
        ([jobs]) => {
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getJobsByAssignee(userId: number) {
    return this._http.get<Job[]>(this.globalService.getApiUrl() + '/jobs?jobSearchType=ContractName&userId='
      + userId, this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getJobRoles(jobNumber: string): Observable<JobRole[]> {
    return this._http.get<JobRole[]>(this.globalService.getApiUrl() + '/jobs/' + jobNumber + '/roles',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getJobRole(jobNumber: string, roleId: number): Observable<JobRole[]> {
    return this._http.get<JobRole[]>(this.globalService.getApiUrl() + '/jobs/' + jobNumber + '/roles?roleId=' + roleId,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getMyJobsWithExtras(): Observable<Job[]> {
    return forkJoin(
      [this.getMyJobs(),
      this.getAllJobExtras(true, false),
      this.maintenanceService.getCompanyActivities(false, true)]
    )
      .pipe(map(
        ([jobs]) => {
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  // extra details for hold flag etc
  getAllJobExtras(useCache: boolean, calcCurrentActivity: boolean): Observable<JobExtra[]> {
    if (useCache && this.jobExtrasCompany === this.globalService.getCurrentCompany().id
      && this.jobExtras && this.jobExtras.length) {
      return of(this.jobExtras);
    } else {
      return this._http.get<JobExtra[]>(this.globalService.getApiUrl() + '/job-extras?calcCurrentActivity=' + calcCurrentActivity,
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.jobExtras = res; this.jobExtrasCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }

  getJobExtras(jobId: number): Observable<JobExtra> {
    return this._http.get<JobExtra>(this.globalService.getApiUrl() + '/job-extras/' + jobId,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addJobExtra(itm: any): Observable<JobExtra> {
    const url = this.globalService.getApiUrl() + '/job-extras/';
    return this._http.post<JobExtra>(url, JSON.stringify(itm), this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.currentJobExtra = res;
        this.jobExtras.push(res);
      }),
      catchError(this.handleError));
  }

  updateJobExtra(id: number, itm: any): Observable<JobExtra> {
    const url = this.globalService.getApiUrl() + '/job-extras/' + id;
    return this._http.patch<JobExtra>(url, JSON.stringify(itm), this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.currentJobExtra = res;

        const jobExtraIndex = this.jobExtras?.findIndex(i => i.id === id);
        if (jobExtraIndex >= 0) {
          this.jobExtras.splice(jobExtraIndex, 1, res);
        } else {
          this.jobExtras.push(res);
        }
      }),
      catchError(this.handleError));
  }

  // search for jobs by a matching contract name string
  getJobClientLoginsForReportGrid(useCache: boolean): Observable<JobCustomerRaw[]> {
    if (useCache && this.jobClientLoginsCompany === this.globalService.getCurrentCompany().id
      && this.jobClientLogins && this.jobClientLogins.length) {
      return of(this.jobClientLogins);
    } else {
      return this._http.get<JobCustomerRaw[]>(this.globalService.getApiUrl() + '/job-customers/client-logins',
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.jobClientLogins = res;
            this.jobClientLoginsCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }


  getHouseTypes(useCache: boolean): Observable<HouseType[]> {
    if (useCache && this.houseTypes && this.houseTypes.length
      && this.cachCompanyHouseTypes === this.globalService.getCurrentCompanyId()) {
      return of(this.houseTypes);
    } else {
      return this._http.get<HouseType[]>(this.globalService.getApiUrl() + '/house-types?activeOnly=false',
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.houseTypes = res;
            this.cachCompanyHouseTypes === this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }


  getJobDocuments(jobId: number, useCache: boolean): Observable<JobDocument[]> {
    if (useCache && this.jobDocuments && this.jobDocuments.length && this.jobDocumentsJobId === jobId) {
      // this.setCallUpDocsTypeIdFromInheritance(null, null);
      return of(this.jobDocuments);
    } else {
      return this._http.get<JobDocument[]>(this.globalService.getApiUrl() + '/job/' + jobId + '/documents?onlyAttachments=false',
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.jobDocuments = res.filter(i => i.recordTypeId === JobDocumentRecordTypeEnum.Heading || i.attachmentTypeId);
            this.jobDocumentsJobId = jobId;
            // this.setCallUpDocsTypeIdFromInheritance(null, null);
            return this.jobDocuments;
          }),
          catchError(this.handleError));
    }
  }

  getJobDocumentsForMasterChildJobs(jobId: number): Observable<JobDocument[]> {
    return this._http.get<JobDocument[]>(this.globalService.getApiUrl() + '/job/' + jobId + '/documents/master-child',
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          res = res.filter(i => i.recordTypeId === JobDocumentRecordTypeEnum.Heading || i.attachmentTypeId);
          this.jobDocumentsForMasterJob = res;
          // this.setCallUpDocsTypeIdFromInheritanceChildJobs(null, null);
        }),
        catchError(this.handleError));
  }

  getJobTaskDocuments(jobId: number, jobTaskId: number): Observable<JobTaskDocument[]> {
    let url = this.globalService.getApiUrl() + '/job-task-documents?jobId=' + jobId;

    if (jobTaskId) {
      url += '&jobTaskId=' + jobTaskId;
    }

    return this._http.get<JobTaskDocument[]>(url,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getJobTaskDocumentsForMasterChild(jobId: number): Observable<JobTaskDocument[]> {
    const url = this.globalService.getApiUrl() + '/job-task-documents/for-master-child/' + jobId;

    return this._http.get<JobTaskDocument[]>(url,
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.jobTaskDocumentsForMasterChildJobs = res;
        }),
        catchError(this.handleError));
  }

  getAllVariations(useCache: boolean, includeInactiveJobs: boolean): Observable<Variation[]> {
    if (useCache && this.jobVariations && this.jobVariations.length
      && this.jobVariationsCompany === this.globalService.getCurrentCompany().id) {
      return of(this.jobVariations);
    } else {
      let url = this.globalService.getApiUrl();
      url += '/job-variations/all?includeInactiveJobs=' + includeInactiveJobs;

      return this._http.get<Variation[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.jobVariations = res;
          this.jobVariationsCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  getJobVariations(jobId: number): Observable<Variation[]> {
    let url = this.globalService.getApiUrl();
    url += '/job-variations/for-job?jobId=' + jobId;

    return this._http.get<Variation[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.currentJobVariations = res;
      }),
      catchError(this.handleError));
  }

  updateVariationStatus(variationId: number, statusId: number) {
    let url = this.globalService.getApiUrl() + '/job-variations/' + variationId;
    let itm = { statusId: statusId };

    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  downloadJobVariation(jobNumber: string, variationNumber: number, canReadAmountsPermission: boolean): Observable<FileAttachment> {
    let url = this.globalService.getApiUrl() + '/jobs/' + jobNumber + '/items/pdf?variationNumber=' + variationNumber;

    url += '&printImages=true';
    url += '&printPrices=' + canReadAmountsPermission;
    url += '&printNotApplicable=true';
    url += '&printNonPrintItems=false';
    url += '&printConnectedTogether=true';
    url += '&printVONumber=true';
    url += '&excelExport=false';
    url += '&printProvisionalSums=true';
    url += '&printQuantities=true';
    url += '&showSignatureSection=false';
    url += '&printPdfAttachments=true';
    url += '&showCheckBoxes=false';

    return this._http.post<FileAttachment>(url, { categoryIds: [] },
      this.httpService.getHttpFilePDFOptions()).pipe(
        catchError(this.handleError));
  }

  addJobTaskDocuments(jobTaskId: number, items: any) {
    const url = this.globalService.getApiUrl() + '/job-task-documents/' + jobTaskId;
    return this._http.post(url, JSON.stringify(items), this.httpService.getHttpOptions());
  }

  // setCallUpDocsTypeIdFromInheritance(parentId: number, parentCallUpDocsTypeId: number) {
  //   // we set the callUpDocsTypeId if inherited
  //   const childDocsToSet = this.jobDocuments.filter(i => i.parentId === parentId);

  //   childDocsToSet.forEach(childDoc => {
  //     if (!childDoc.callUpDocsTypeId) {
  //       childDoc.callUpDocsTypeId = parentCallUpDocsTypeId;
  //     }

  //     if (childDoc.recordTypeId === JobDocumentRecordTypeEnum.Heading) {
  //       // set children
  //       this.setCallUpDocsTypeIdFromInheritance(childDoc.id, childDoc.callUpDocsTypeId);
  //     }
  //   });
  // }

  // setCallUpDocsTypeIdFromInheritanceChildJobs(parentId: number, parentCallUpDocsTypeId: number) {
  //   // we set the callUpDocsTypeId if inherited
  //   const childDocsToSet = this.jobDocumentsForMasterJob.filter(i => i.parentId === parentId);

  //   childDocsToSet.forEach(childDoc => {
  //     if (!childDoc.callUpDocsTypeId) {
  //       childDoc.callUpDocsTypeId = parentCallUpDocsTypeId;
  //     }

  //     if (childDoc.recordTypeId === JobDocumentRecordTypeEnum.Heading) {
  //       // set children
  //       this.setCallUpDocsTypeIdFromInheritanceChildJobs(childDoc.id, childDoc.callUpDocsTypeId);
  //     }
  //   });
  // }

  getJobBanks(useCache: boolean) {
    if (useCache && this.jobBanksCompany === this.globalService.getCurrentCompany().id
      && this.jobBanks && this.jobBanks.length) {
      return of(this.jobBanks);
    } else {
      return this._http.get<JobBank[]>(this.globalService.getApiUrl() + '/job-banks', this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.jobBanks = res;
          this.jobBanksCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  getNextClaims(useCache: boolean) {
    if (useCache && this.nextClaimsCompany === this.globalService.getCurrentCompany().id
      && this.nextClaims && this.nextClaims.length) {
      return of(this.nextClaims);
    } else {
      return this._http.get<ClaimJobLine[]>(this.globalService.getApiUrl() + '/claim-job-lines/next-claims', this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.nextClaims = res;
          this.nextClaimsCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }


  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));

    if (JSON.stringify(err).includes(') does not match this email address')) {
      // we have logged in with another email and the user ID does not match - log out
      this.notiService.showWarning('Invalid login most likely due to multiple logins being used - please log out and back in again');
    }

    return observableThrowError(err);
  }


  // calc the contractual completion from the current date + variation days
  calculateContractualCompletion(currentDate: Date, jobId: number): Date {
    if (!currentDate) {
      return null;
    }
    let days = 0;
    this.jobVariations.filter(i => i.jobId === jobId && i.statusId >= VariationStatusEnum.Approved).forEach(varn => {
      if (varn.additionalDays) {
        days += varn.additionalDays;
      }
    });

    if (this.maintenanceService.taskControl.addContractualDaysAsWorkingDays) {
      return this.maintenanceService.addDaysExHolidays(currentDate, days);
    } else {
      return this.maintenanceService.addDays(currentDate, days);
    }

  }
}

import { NotificationService } from './../../services/notification.service';
import { Component, Input, ViewChild, OnDestroy } from '@angular/core';
import { JobService } from '../../services/felixApi/job.service';
import { TaskService } from '../../services/felixApi/task.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Task } from '../../dtos/task';

@Component({
  selector: 'js-reset-job-task',
  templateUrl: './reset-job-task.component.html',
  styleUrls: ['./reset-job-task.component.scss']
})
export class ResetJobTaskComponent implements OnDestroy {
  @Input() jobTask: Task;

  @ViewChild(DxDataGridComponent) childJobsGrid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  loading = false;

  constructor(
    private activeModal: NgbActiveModal,
    private taskService: TaskService,
    private notiService: NotificationService,
    private jobService: JobService) { }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  close() {
    this.activeModal.close();
  }

  resetNotApplicable() {
    this.loading = true;
    this.subscriptions.push(
      this.taskService.resetNotApplicable(this.jobService.currentJob.id, this.jobTask.id)
        .subscribe({
          next: () => {
            this.activeModal.close();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }})
    );
  }
}

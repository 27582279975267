import { JobEmail } from './../../dtos/job-email';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../services/global.service';
import { Subscription } from 'rxjs';
import { JobEmailAttachment } from './../../dtos/job-email-attachment';

@Component({
  selector: 'js-job-note-expanded',
  templateUrl: './job-note-expanded.component.html',
  styleUrls: ['./job-note-expanded.component.scss']
})
export class JobNoteExpandedComponent implements OnInit, OnDestroy {
  @Input() jobEmail: JobEmail;

  loading = true;
  subscriptions: Subscription[] = [];
  popupHeight: number;
  popupWidth: number;
  bodyHeight: number;
  colCount: number;

  constructor(
    private activeModal: NgbActiveModal,
    private globalService: GlobalService) { }

  ngOnInit() {
    this.colCount = 2;
    this.calculateWidthsHeights();
    this.subscribeToInnerWidth();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  subscribeToInnerWidth() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.calculateWidthsHeights();
        }
      )
    );
  }

  calculateWidthsHeights() {
    this.popupHeight = this.globalService.innerHeight >= 700 ? 700 : this.globalService.innerHeight;
    this.bodyHeight = this.popupHeight - (this.globalService.innerHeight >= 700 ? 180 : 180);
    this.popupWidth = this.globalService.innerWidth < 800 ? this.globalService.innerWidth : 800;
    this.loading = false;
  }

  close() {
    this.activeModal.dismiss();
  }
}

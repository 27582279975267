export enum VariationStatusEnum {
    Open = 1,
    Estimating = 2,
    PendingApproval = 5,
    Cancelled = 6,
    Approved = 7,
    Actioned = 8,
    Invoiced = 9
}

export const VariationStatusOptions = [
  { id: 1, status: 'Open' },
  { id: 2, status: 'Estimating' },
  { id: 5, status: 'Pending Approval' },
  { id: 6, status: 'Cancelled' },
  { id: 7, status: 'Approved' },
  { id: 8, status: 'Actioned' },
  { id: 9, status: 'Invoiced' }
];

<div class="modal-header">
  <h4 class="modal-title">Add multiple tasks</h4>
  <button *ngIf="!loading" type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <dx-data-grid *ngIf="!loading" [dataSource]="templateTaskMastersData" [remoteOperations]="false" [height]="gridHeight"
    [showBorders]="true" [wordWrapEnabled]="true" [selectedRowKeys]="selectedRowKeys" [hoverStateEnabled]="true"
    (onToolbarPreparing)="onToolbarPreparing($event)">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-search-panel [visible]="true" location="before" width="200px"></dxo-search-panel>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"> <dxo-search [enabled]="true"></dxo-search> </dxo-header-filter>
    <dxo-selection selectAllMode="allPages" showCheckBoxesMode="always" mode="multiple"></dxo-selection>

    <dxi-column dataField="id" [visible]='false'></dxi-column>

    <dxi-column dataField="taskTypeId" dataType="number" caption="Type" [width]="200">
      <dxo-lookup [dataSource]="taskTypes" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="taskTitle" dataType="string">
    </dxi-column>
  </dx-data-grid>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>

export class User {
  public id: number;
  public email: string;
  public password: string;
  public title: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public suffix: string;
  public isActive: boolean;
  public isSuperUser: boolean;
  public mobileNumber: string;
  public userTypeId: number;
  public userType: string;
  public fullName: string;
  public vendorId: number;
  public canOnlySeeAllocatedJobs: boolean;
  public vendorName: string;

  checked: boolean;
  jobCustomerId: number;

  constructor(firstName: string, lastName: string, id: number, fullName: string) {
    this.firstName = firstName;
    this.id = id;
    this.lastName = lastName;
    this.fullName = fullName;
  }
}

<div class="page">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <div *ngIf="!loading" class="page max-w-800 m-auto">

    <dx-form id="form" [(formData)]="taskControlForm" [readOnly]="false" [showColonAfterLabel]="true"
      validationGroup="formData" labelLocation="left" [minColWidth]="500" [colCount]="1" [width]="800">

      <!-- <dxi-item dataField="cutOffTimeToPopulateNextStart" editorType="dxDateBox"
        [editorOptions]="{ width: '120px', type: 'time' }">
        <dxo-label location="left" alignment="right"
          text="Cut off time when setting the start date of a subsequent task">
        </dxo-label>
      </dxi-item> -->

      <dxi-item dataField="subContractorPayStartDate" editorType="dxDateBox"
        [editorOptions]="{ width: '120px', type: 'date' }">
        <dxo-label location="left" alignment="right" text="The first cut-off date for paying subcontractors">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="daysToPaySubContractorsFromCutOff" editorType="dxNumberBox"
        [editorOptions]="{ width: '120px' }">
        <dxo-label location="left" alignment="right" text="Calendar days to pay subcontractors from cut-off">
        </dxo-label>
      </dxi-item>

      <dxi-item *ngIf="accountingSystemType !== 2" dataField="clientsDaysToPay" editorType="dxNumberBox"
        [editorOptions]="{ width: '120px' }">
        <dxo-label location="left" alignment="right" text="Allowed days for clients pay claims">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="callUpEmailStoreAddress" editorType="dxTextBox">
        <dxo-label location="left" alignment="right" text="BCC Call-Up emails to">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="infoEmailStoreAddress" editorType="dxTextBox">
        <dxo-label location="left" alignment="right" text="BCC Info emails to">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="quoteRequestReplyToEmail" editorType="dxTextBox">
        <dxo-label location="left" alignment="right" text="Quote Request reply email address">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="ccToSiteManager" editorType="dxCheckBox">
        <dxo-label location="left" alignment="right" text="CC Call-Ups to Site Manager by default">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="isCashFlowUsingPurchaseOrders" editorType="dxCheckBox">
        <dxo-label location="left" alignment="right" text="Cash Flow uses Purchase Orders if Orders locked">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="isCashFlowUsingEstimating" editorType="dxCheckBox">
        <dxo-label location="left" alignment="right" text="Cash Flow uses Estimating if Orders not locked">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="wipOptionId" editorType="dxSelectBox" [editorOptions]="wipSelectOptions">
        <dxo-label location="left" alignment="right" text="WIP report uses">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="addContractualDaysAsWorkingDays" editorType="dxCheckBox">
        <dxo-label location="left" alignment="right"
          text="Add Additional Days to Contractual Completion as working days">
        </dxo-label>
      </dxi-item>

      <dxi-item *ngIf="emailUpdatesToClientsActive" dataField="clientEmailMinLeadDays" editorType="dxNumberBox"
        [editorOptions]="{ width: '120px' }">
        <dxo-label location="left" alignment="right" text="Client Email Updates - Min days before start">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="modifiedUser" [editorOptions]="{ disabled: true }" editorType="dxTextBox">
        <dxo-label location="left" alignment="right" text="Last modified by"></dxo-label>
      </dxi-item>

      <dxi-item dataField="modifiedDate" [editorOptions]="{ width: '120px', disabled: true }" editorType="dxDateBox">
        <dxo-label location="left" alignment="right" text="Last modified"></dxo-label>
      </dxi-item>

      <dxi-item itemType="button" horizontalAlignment="right" [buttonOptions]="buttonOptions">
      </dxi-item>

    </dx-form>
  </div>
</div>
import { Component, OnInit, HostListener } from '@angular/core';
import { locale } from 'devextreme/localization';

import { AuthService } from './services/auth.service';
import { GlobalService } from './services/global.service';
import { MENU_HEIGHT } from '../config/variables';
import { NotificationService } from './services/notification.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import dxDataGrid from 'devextreme/ui/data_grid';


@Component({
  selector: 'js-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  menuHeight = MENU_HEIGHT;
  containerHeight: number;
  isIframe = false;

  @HostListener('window:resize') onResize() {
    setTimeout(() => {
      this.setWidths();
    }, 500); // wait for iPhone
  }

  constructor(
    private globalService: GlobalService,
    public authService: AuthService,
    private notiService: NotificationService,
    private deviceService: DeviceDetectorService
  ) {
    locale('en-AU'); // can use navigator.language (browser language) but is mostly left on US default

    const browser = window.navigator.userAgent.toLowerCase();
    if (browser.indexOf('firefox') > 0) {
      this.notiService.showWarning(
        'While this app functions on Firefox, Chrome is the officially supported browser. <br><br> If you encounter any issues please test on Chrome before raising it with support.',
        'Warning',
        { timeOut: 0, enableHtml: true, positionClass: 'toast-bottom-full-width' }
      );
    }

    if (this.deviceService.isTablet() || this.deviceService.isMobile()) {
      this.globalService.isTablet = true;
      this.globalService.browser = this.deviceService.browser;
    } else {
      this.globalService.isTablet = false;
    }

    // data grid default
    dxDataGrid.defaultOptions({
      options: {
        filterRow: { showAllText: '' }
      }
    });
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.setWidths();
  }

  setWidths() {
    this.globalService.setInnerHeightWidth();
    this.containerHeight = this.globalService.innerHeight - this.menuHeight;
  }


  refresh(): void {
    window.location.reload();
  }
}

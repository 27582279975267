export class JobDocument {
  id: number;
  jobId: number;
  recordTypeId: number;
  parentId: number;
  orderNo: number;
  description: string;
  jobDocAttachmentId: number;
  attachmentTypeId: number;
  isCustomerViewable: boolean;
  isTradeViewable: boolean;
  children: JobDocument[];
  attachment: Blob;
  modifiedDate: Date;
  jobDocumentStatusId: number;
  jobDocumentTypeId: number;
  callUpDocsTypeId: number;
  isDeleted: boolean;
  isStatusRequired: boolean; // from document type
  hasBlob: boolean;
  isSharePoint: boolean;
  sharePointId: string;
  sharePointUrl: string;
  keyId: string;
  parentKeyId: string;

  constructor(id: number, jobId: number, recordTypeId: number, parentKeyId: string, keyId: string, description: string, sharePointId: string, callUpDocsTypeId: number) {
    this.id = id;
    this.jobId = jobId;
    this.recordTypeId = recordTypeId;
    this.parentKeyId = parentKeyId;
    this.keyId = keyId;
    this.description = description;
    this.sharePointId = sharePointId;
    this.isSharePoint = sharePointId != null;
    this.callUpDocsTypeId = callUpDocsTypeId;
  }
}

import { DocumentService } from './../services/felixApi/document.service';
import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { GridService } from '../services/grid.service';
import { Subscription } from 'rxjs';
import { NotificationService } from '../services/notification.service';
import { DocumentFolder } from '../dtos/document-folder';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentTemplatesComponent } from './document-templates/document-templates.component';
import { ConfigurationEnum, DocumentManagementEnum } from '../dtos/configuration-enum';
import CustomStore from 'devextreme/data/custom_store';
import { JobService } from '../services/felixApi/job.service';

@Component({
  selector: 'js-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  loading = false;
  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  folders: DocumentFolder[];
  jobNumber: string;
  documentsConfigValue: number;
  isGoogleDocs: boolean;
  isSharePoint: boolean;

  constructor(
    private documentService: DocumentService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private notiService: NotificationService,
    private modalService: NgbModal,
    private jobService: JobService
  ) { }

  ngOnInit(): void {
    this.documentsConfigValue = this.globalService.getCompanyConfigValue(ConfigurationEnum.DocumentManagementSystem);

    if (this.documentsConfigValue) {
      if (this.documentsConfigValue === DocumentManagementEnum.GoogleDocs) {
        this.isGoogleDocs = true;
      } else if (this.documentsConfigValue === DocumentManagementEnum.SharePoint) {
        this.isSharePoint = true;
      }

      this.getFolders();
    }
  }

  getFolders() {
    this.dataSource = new CustomStore({
      key: 'name',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.documentService.getSharePointDocFolders(this.isSharePoint, true).subscribe({
            next: (res) => {
              this.folders = res;
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onJobNumberChanged(jobNo: string) {
    this.jobNumber = jobNo;
  }

  openLink(linkAddress: string) {
    window.open(linkAddress);
  }

  openJobLink(folderName: string) {
    this.loading = true;
    this.subscriptions.push(
      this.documentService.getFolderUrl(this.jobNumber, folderName, this.isSharePoint)
        .subscribe({
          next: (folder) => {
            this.loading = false;
            this.openLink(folder);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  openTemplates(name: string) {
    const folder = this.folders.find(i => i.name === name);
    const modalRef = this.modalService.open(DocumentTemplatesComponent, { windowClass: 'modal-1000', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.folderName = folder.name;
    modalRef.componentInstance.jobNumber = this.jobNumber;
    modalRef.componentInstance.isSharePoint = this.isSharePoint;

    modalRef.result.then(() => {
    });
  }

  calculateTemplateCellValue(data) {
    if (data.hasTemplateAccess) {
      return data.name;
    }
    return null;
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          stylingMode: 'outlined',
          type: 'default',
          text: 'Create Folders',
          onClick: this.createFolders.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          stylingMode: 'outlined',
          type: 'default',
          text: 'Run File Check',
          onClick: this.runFileCheck.bind(this)
        }
      });
  }

  createFolders() {
    this.loading = true;
    this.subscriptions.push(
      this.documentService.createJobDocumentFolders(this.jobNumber, this.isSharePoint)
        .subscribe({
          next: () => {
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  runFileCheck() {
    if (this.isSharePoint) {
      this.loading = true;
      this.subscriptions.push(
        this.documentService.runFileCheck(this.jobService.currentJob.id)
          .subscribe({
            next: () => {
              this.loading = false;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    }
  }
}

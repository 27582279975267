import { Injectable } from '@angular/core';
import { UtilsService } from '../services/utils.service';
import { ReportGridItem } from './report-grid-item.type';

@Injectable({
  providedIn: 'root'
})
export class ReportGridService {

  gridColumns: ReportGridItem[] = [];
  extraColumns: ReportGridItem[] = [];

  constructor(private utils: UtilsService) { }

  clear() {
    this.gridColumns = [];
    this.extraColumns = [];
  }

  sortExtraCols() {
    this.extraColumns = this.extraColumns.sort(this.utils.dynamicSort('caption'));
  }

}

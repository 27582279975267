<!-- Add attachments -->
<dx-popup class="popup" [width]="popupWidth" [height]="popupHeight" [showTitle]="true" title="Upload Attachments"
  [dragEnabled]="false" [hideOnOutsideClick]="true" [visible]="true" (onHidden)="close()" [shading]="false"
  [showCloseButton]="true">
  <div *dxTemplate="let data of 'content'">
    <js-loading-spinner *ngIf="loadingDocs"></js-loading-spinner>

    <dx-scroll-view *ngIf="!loadingDocs" width="100%" [height]="popupHeight - 100">
      <dx-tree-list id="jobDocumentsTree" [dataSource]="jobDocuments" keyExpr="keyId" [height]="popupHeight - 180"
        parentIdExpr="parentKeyId" [showRowLines]="true" [showBorders]="true" [columnAutoWidth]="false"
        [(selectedRowKeys)]="selectedFolder">

        <dxo-search-panel [visible]="true" width="200"></dxo-search-panel>
        <dxo-selection mode="single"> </dxo-selection>

        <dxi-column dataField="description" caption="Save to:" [allowSorting]="false"></dxi-column>

        <div *dxTemplate="let data of 'toolbarTemplate'">
          <dx-button *ngIf="fileValue.length" text="Save" type="default" (onClick)="save()">
          </dx-button>
        </div>
      </dx-tree-list>

      <div *ngIf="!loadingDocs && fileValue.length" class="fileNamesClass">{{getFileNames()}}</div>

      <dx-button *ngIf="!loadingDocs && fileValue.length && selectedFolder.length" text="Save" class="mt-1"
        type="default" (onClick)="save()">
      </dx-button>

      <dx-button *ngIf="!loadingDocs && fileValue.length && selectedFolder.length" text="Re-Select" class="mt-1 ms-4"
        type="default" stylingMode="outlined" (onClick)="fileValue = []">
      </dx-button>


      <dx-file-uploader *ngIf="!loadingDocs && !fileValue.length && selectedFolder.length" #fileUploader
        selectButtonText="Select file" labelText="or drop file here" [multiple]="true" [(value)]="fileValue"
        uploadMode="useForm">
      </dx-file-uploader>
    </dx-scroll-view>
  </div>
</dx-popup>

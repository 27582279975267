import { TrackingWorkFlowTypeEnum } from './../../dtos/tracking-work-flow-type.enum';
import { TemplateTaskHeader } from './../../dtos/templateTaskHeader';
import { JobWorkFlowService } from './../../services/felixApi/job-work-flow.service';
import { JobService } from './../../services/felixApi/job.service';
import { Task } from './../../dtos/task';
import { TaskService } from './../../services/felixApi/task.service';
import { Component, Input, OnInit, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { GlobalService } from '../../services/global.service';
import { NotificationService } from '../../services/notification.service';
import { TemplateTask } from '../../dtos/templateTask';
import { TaskDependency } from '../../dtos/task-dependency';
import { debounceTime } from 'rxjs/operators';
import { jsPDF } from 'jspdf';
import { exportGantt as exportGanttToPdf } from 'devextreme/pdf_exporter';
import 'jspdf-autotable';
import { DxGanttComponent } from 'devextreme-angular';
import { GridService } from '../../services/grid.service';
import { TaskStatusEnum } from '../../dtos/task-status.enum';
import ArrayStore from 'devextreme/data/array_store';

@Component({
  selector: 'js-job-gantt',
  templateUrl: './job-gantt.component.html',
  styleUrls: ['./job-gantt.component.scss']
})
export class JobGanttComponent implements OnInit, OnChanges, OnDestroy {
  @Input() jobNumber: string;

  @ViewChild(DxGanttComponent, { static: false }) gantt: DxGanttComponent;

  loading = true;
  subscriptions: Subscription[] = [];
  timeScale = 'months';
  gridHeight: number;
  tasks: TemplateTask[];
  dependencies: TaskDependency[];
  showTitleColumnsOptions: { text: string; stylingMode: string; onClick: () => void; };
  exportButtonOptions: { text: string; stylingMode: string; onClick: () => void; };
  exportToExcelButtonOptions: { text: string; stylingMode: string; onClick: () => void; };
  showTitlesText = 'Hide Title Columns';
  taskDataLoaded = false;
  showTitles = true;
  titleColumnWidth = 500;
  printSettingsPopupVisible = false;
  formats: string[] = ['A0', 'A1', 'A2', 'A3', 'A4', 'Auto'];
  exportModes: string[] = ['All', 'Chart', 'Tree List'];
  dateRanges: string[] = ['All', 'Visible', 'Custom'];
  formatBoxValue: string;
  exportModeBoxValue: string;
  dateRangeBoxValue: string;
  landscapeCheckBoxValue: boolean;
  startTaskIndex = 0;
  endTaskIndex = 0;
  startDate: Date;
  endDate: Date;
  customRangeDisabled: boolean;
  templateDescription: string;
  contextMenuItems: {};
  exportToExcelPopupVisible: boolean;
  exportTasks: Task[];
  templateTaskHeaders: TemplateTaskHeader[] = [];
  selectedWorkflowId: number;
  selectedIndex = 0;
  tabs = [
    { text: 'Gantt' },
    { text: 'Diagram' }
  ];
  flowNodesDataSource: ArrayStore;
  flowEdgesDataSource: ArrayStore;

  constructor(public taskService: TaskService,
    private jobService: JobService,
    private maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    private notiService: NotificationService,
    private jobWorkFlowService: JobWorkFlowService,
    protected gridService: GridService) {
    this.itemTextStyleExpr = this.itemTextStyleExpr.bind(this);
    this.itemStyleExpr = this.itemStyleExpr.bind(this);
  }

  ngOnInit() {
    this.showTitleColumnsOptions = {
      text: this.showTitlesText,
      stylingMode: 'text',
      onClick: () => this.showTitleColumns(),
    };

    this.exportButtonOptions = {
      text: 'Export to PDF',
      stylingMode: 'text',
      onClick: () => this.openPrintSettings(),
    };

    this.exportToExcelButtonOptions = {
      text: 'Export to Excel',
      stylingMode: 'text',
      onClick: () => this.exportToExcel(),
    };

    this.gridHeight = this.globalService.innerHeight - 235;

    this.formatBoxValue = this.formats[4];
    this.landscapeCheckBoxValue = true;
    this.exportModeBoxValue = this.exportModes[0];
    this.dateRangeBoxValue = this.dateRanges[1];
    this.startTaskIndex = 0;
    this.customRangeDisabled = true;
    this.contextMenuItems = this.getContextMenuItems();
  }

  ngOnChanges(): void {
    this.getData(true);
    this.subscribeToResizeChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getContextMenuItems() {
    return [
      'deleteDependency',
    ];
  }

  subscribeToResizeChanges() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.pipe(debounceTime(500)).subscribe(() => {
        this.gridHeight = this.globalService.innerHeight - 235;
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 100); // wait for grid
      })
    );
  }

  getData(useCache: boolean) {
    this.loading = true;
    this.subscriptions.push(
      this.taskService.getJobTaskForecastData(this.jobNumber, useCache, this.jobService.currentJob.id)
        .subscribe({
          next: (tasks) => {
            this.tasks = this.selectedWorkflowId ? tasks.filter(i => i.templateTaskHeaderId === this.selectedWorkflowId) : tasks;
            this.dependencies = this.maintenanceService.taskDependencies;

            const jobWorkFlows = this.jobWorkFlowService.allJobWorkflows.filter(jwf => jwf.jobId === this.jobService.currentJob.id);
            this.templateTaskHeaders = [];
            jobWorkFlows?.forEach(workflow => {
              this.templateTaskHeaders.push(this.maintenanceService.taskHeaders.find(tth => tth.id === workflow.templateTaskHeaderId));
            });

            // for diagram
            const preconWorkflow = jobWorkFlows.find(jwf => jwf.trackingWorkFlowTypeId === TrackingWorkFlowTypeEnum.PreConstruction);
            const lastPreconTask = this.tasks.find(t => t.templateTaskHeaderId === preconWorkflow?.templateTaskHeaderId && t.isNextWorkFlowTrigger);

            const conWorkflow = jobWorkFlows.find(jwf => jwf.trackingWorkFlowTypeId === TrackingWorkFlowTypeEnum.Construction);
            const firstConstructionTasks = this.tasks.filter(t => t.templateTaskHeaderId === conWorkflow?.templateTaskHeaderId && t.isInitiatedBySalesDate);
            const diagramDepenencies = [...this.dependencies];

            if (lastPreconTask && firstConstructionTasks) {
              let nextId = this.dependencies.length + 1;
              firstConstructionTasks.forEach(firstConstructionTask => {
                diagramDepenencies.push(new TaskDependency(nextId, lastPreconTask.id, firstConstructionTask.id));
                nextId++;
              });
            }

            this.flowNodesDataSource = new ArrayStore({
              key: 'id',
              data: this.tasks
            });
            this.flowEdgesDataSource = new ArrayStore({
              key: 'id',
              data: diagramDepenencies
            });
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  refresh() {
    this.getData(false);
  }

  showTitleColumns() {
    this.taskDataLoaded = false;
    setTimeout(() => {
      if (this.showTitles) {
        this.titleColumnWidth = 1;
        this.showTitlesText = 'Show Title Columns';
      } else {
        this.titleColumnWidth = 500;
        this.showTitlesText = 'Hide Title Columns';
      }

      this.showTitleColumnsOptions = {
        text: this.showTitlesText,
        stylingMode: 'text',
        onClick: () => this.showTitleColumns(),
      };

      this.showTitles = !this.showTitles;
      this.taskDataLoaded = true;
    }, 10); // wait for grid
  }

  openPrintSettings() {
    this.printSettingsPopupVisible = true;
  }

  onDateRangeBoxSelectionChanged(e) {
    this.customRangeDisabled = e.value !== 'Custom';
  }

  exportButtonClick() {
    const gantt = this.gantt.instance;
    const format = this.formatBoxValue;
    const isLandscape = this.landscapeCheckBoxValue;
    const exportMode = this.getExportMode();
    const dataRangeMode = this.dateRangeBoxValue.toLowerCase();
    let dataRange;
    if (dataRangeMode === 'custom') {
      dataRange = {
        startIndex: this.startTaskIndex,
        endIndex: this.endTaskIndex,
        startDate: this.startDate,
        endDate: this.endDate,
      };
    } else {
      dataRange = dataRangeMode;
    }

    exportGanttToPdf(
      {
        component: gantt,
        createDocumentMethod: (args?: any) => new jsPDF(args),
        format,
        landscape: isLandscape,
        exportMode,
        dateRange: dataRange,
      },
    ).then((doc) => doc.save('gantt.pdf'));
  }

  getExportMode() {
    if (this.exportModeBoxValue === 'Tree List') { return 'treeList'; }
    if (this.exportModeBoxValue === 'All') { return 'all'; }
    if (this.exportModeBoxValue === 'Chart') { return 'chart'; }
    return 'all';
  }

  exportToExcel() {
    this.exportToExcelPopupVisible = true;
    let orderNumber = 1;

    this.tasks.forEach(task => {
      task.orderNumber = orderNumber;
      orderNumber++;
    });

    // can now set the predecessors
    this.tasks.forEach(task => {
      task.predecessors = '';
      this.dependencies.forEach(dependency => {
        if (dependency.taskSuccessorId === task.id) {
          let currentTask = this.tasks.find(t => t.id === dependency.taskPredecessorId);
          task.predecessors += currentTask.orderNumber + ', ';
        }
      });
    });
  }

  getTaskColor(item: any) {
    const task = this.tasks.find(t => t.id === item.taskData.id);
    const jobTask = this.taskService.jobTasks
      .find(t => t.taskMasterId === task.taskMasterId && t.templateTaskHeaderId === task.templateTaskHeaderId && t.statusId !== TaskStatusEnum.Cancelled);

    const color = (!jobTask || jobTask.statusId === TaskStatusEnum.NotStarted) ? '0' : jobTask.statusId < TaskStatusEnum.Completed ? '1' : '2';
    return `custom-task-color-${color}`;
  }

  onWorkflowChanged(e) {
    this.selectedWorkflowId = e.value;
    this.getData(true);
  }

  itemTextStyleExpr(task) {
    return { 'font-size': 11 };
  }

  itemStyleExpr(task): object {
    const jobTask = this.taskService.jobTasks
      .find(t => t.taskMasterId === task.taskMasterId && t.templateTaskHeaderId === task.templateTaskHeaderId && t.statusId !== TaskStatusEnum.Cancelled);

    const diagramColor = (!jobTask || jobTask.statusId === TaskStatusEnum.NotStarted) ? '' : jobTask.statusId < TaskStatusEnum.Completed ? 'orange' : '#69ca69';

    return {
      fill: diagramColor,
    };
  }

  onTaskEditDialogShowing(e) {
    e.cancel = true;
  }
}

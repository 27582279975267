<div class="modal-header">
  <h3 class="modal-title">Back-Charge Invoice</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <div class="card p-2">

    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <form (submit)="reject()">
      <dx-form *ngIf="!loading" id="form" [(formData)]="backChargeForm" [readOnly]="false" [showColonAfterLabel]="true"
        labelLocation="left" width="100%" [showValidationSummary]="false" validationGroup="customerData" [colCount]="2">


        <dxi-item dataField="invoicedVendor" [colSpan]="2" [editorOptions]="{ disabled: true }"></dxi-item>

        <dxi-item dataField="vendorId" dataType="number" editorType="dxSelectBox" [editorOptions]="vendorOptions"
          [colSpan]="2">
          <dxo-label location="left" text="Back-Charge To"></dxo-label>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="jobNumber" [colSpan]="2" [editorOptions]="{ disabled: true }"></dxi-item>

        <dxi-item dataField="jobAddressString" [colSpan]="2" [editorOptions]="{ disabled: true }"></dxi-item>

        <dxi-item dataField="invoiceNumber" caption="Credit Number" [colSpan]="2" editorType="dxTextBox">
          <dxo-label location="left" text="Credit Number"></dxo-label>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="invoiceDate" [colSpan]="1" editorType="dxDateBox" [editorOptions]="invoiceDateOptions">
        </dxi-item>

        <dxi-item dataField="totalIncGST" [colSpan]="1" editorType="dxNumberBox" [editorOptions]="invoiceTotalIncOptions">
        </dxi-item>

        <dxi-item dataField="sendToEmail" [colSpan]="2">
          <dxo-label location="left" text="Email"></dxo-label>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="emailSubject" [colSpan]="2">
          <dxo-label location="left" text="Subject"></dxo-label>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="ccToSelf" [colSpan]="2">
          <dxo-label location="left" text="CC to yourself"></dxo-label>
        </dxi-item>

        <dxi-item dataField="comment" editorType="dxTextArea" [colSpan]="2" [editorOptions]="{ height: 70 }">
          <dxo-label location="left" text="Reason"></dxo-label>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item itemType="button" [colSpan]="2" horizontalAlignment="right" [buttonOptions]="updateButtonOptions">
        </dxi-item>
      </dx-form>
    </form>
  </div>
</div>

import { AuthService } from './../services/auth.service';
import { GlobalService } from './../services/global.service';
import { MaintenanceService } from './../services/felixApi/maintenance.service';
import { JobService } from './../services/felixApi/job.service';
import { VarianceCode } from './../dtos/variance-code';
import { ManualPurchaseOrdersService } from './../services/felixApi/manual-purchase-orders.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { PurchaseOrder, PurchaseOrderForm } from '../dtos/purchase-order';
import { UserService } from '../services/felixApi/user.service';
import { NotificationService } from '../services/notification.service';
import { Job } from '../dtos/job';
import { VarianceReason } from '../dtos/variance-reason';
import { Vendor } from '../dtos/vendor';
import { PriceFileItem } from '../dtos/price-file-item';
import { UnitOfMeasure } from '../dtos/unitOfMeasure';
import { PriceFileItemVendorRate } from '../dtos/price-file-item-vendor-rate';
import CustomStore from 'devextreme/data/custom_store';
import { ManualOrderLine } from '../dtos/manual-order-line';
import { DxDataGridComponent } from 'devextreme-angular';
import { RoleTypeEnum } from '../dtos/role-type.enum';
import { formatDate } from 'devextreme/localization';

@Component({
  selector: 'js-manual-purchase-orders',
  templateUrl: './manual-purchase-orders.component.html',
  styleUrls: ['./manual-purchase-orders.component.scss']
})
export class ManualPurchaseOrdersComponent implements OnInit, OnDestroy {

  @ViewChild('orderLineGrid', { static: false }) orderLineGrid: DxDataGridComponent;

  loading = true;
  subscriptions: Subscription[] = [];
  purchaseOrder: PurchaseOrder;
  purchaseOrderForm: PurchaseOrderForm = new PurchaseOrderForm;
  updatedData: any;
  jobs: Job[];
  varianceCodes: VarianceCode[];
  varianceReasons: VarianceReason[];
  vendors: Vendor[];
  costCentres: PriceFileItem[];
  adHocReason: VarianceReason;
  dropDownOptions: { width: number; minHeight: number; };
  vendorOptions: object;
  costCentreOptions: object;
  varianceCodeOptions: object;
  showItemLookupPopup: boolean;
  createButtonOptions = {
    text: 'Create & Send',
    type: 'default',
    stylingMode: 'contained',
    useSubmitBehavior: true
  };
  unitsOfMeasure: UnitOfMeasure[];
  priceFileItemsForDateAndDistrict: PriceFileItemVendorRate[];
  orderLinesDataStore: CustomStore;
  orderLines: ManualOrderLine[] = [];
  orderLineId = 0;
  lookupData: CustomStore;
  codeLookupVisible = true;
  itemGridHeight: number;
  itemGridVisible = true;
  selectedPriceFileItem: PriceFileItemVendorRate;
  scrollHeight: number;
  districtId: number;
  validJob = false;
  addPhoneButtonOptions: any;
  deliveryDateButtonOptions: any;

  constructor(
    private userService: UserService,
    private notiService: NotificationService,
    private manualPurchaseOrdersService: ManualPurchaseOrdersService,
    private jobService: JobService,
    private maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    private authService: AuthService
  ) {
    this.addPhoneButtonOptions = {
      text: 'Email Me',
      onClick: () => {
        this.setEmailAddress();
      },
    };

    this.deliveryDateButtonOptions = { text: '(optional)', stylingMode: 'text', type: 'default' };

    this.setQuantityCellValue = this.setQuantityCellValue.bind(this);
    this.setRateCellValue = this.setRateCellValue.bind(this);
    this.setUnitsCellValue = this.setUnitsCellValue.bind(this);
    this.setItemCellValue = this.setItemCellValue.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onVarianceReasonChanged = this.onVarianceReasonChanged.bind(this);
    this.addCustomItem = this.addCustomItem.bind(this);
  }

  ngOnInit(): void {
    this.setHeightWidthVariables();
    this.subscribeToInnerHeightWidth();

    this.getData();
    this.setUpOrderLineDataSet();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  subscribeToInnerHeightWidth() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.itemGridVisible = false;
          setTimeout(() => {
            this.setHeightWidthVariables();
          }, 200); // wait for iPhone and grid
        }
      )
    );
  }

  setHeightWidthVariables() {
    this.dropDownOptions = { width: 750, minHeight: 250 };
    this.itemGridHeight = window.innerHeight < 750 ? 210 : window.innerHeight - 540;
    this.scrollHeight = window.innerHeight - 90;
    this.itemGridVisible = true;
  }

  getData() {
    this.subscriptions.push(
      this.manualPurchaseOrdersService.getPurchaseOrderData(true)
        .subscribe({
          next: (jobs) => {
            this.jobs = jobs;
            this.varianceCodes = this.manualPurchaseOrdersService.varianceCodes;
            this.varianceReasons = this.manualPurchaseOrdersService.varianceReasons;
            this.vendors = this.userService.vendors;
            this.costCentres = this.maintenanceService.costCentres;
            this.unitsOfMeasure = this.maintenanceService.unitOfMeasures;

            this.costCentreOptions = {
              items: this.costCentres,
              required: true,
              displayExpr: function (item) {
                if (!item) {
                  return item;
                }
                return item.priceFileCode + ': ' + item.description;
              },
              valueExpr: 'id',
              searchEnabled: true,
              showClearButton: true,
              onValueChanged: (e) => {
                this.onCostCentreChanged(e.value);
              }
            };

            this.vendorOptions = {
              items: this.vendors,
              required: true,
              displayExpr: function (item) {
                if (!item) {
                  return item;
                }
                return item.vendorCode + ': ' + item.vendorName;
              },
              valueExpr: 'id',
              searchEnabled: true,
              showClearButton: true,
              onValueChanged: (e) => {
                this.onVendorChanged(e.value);
              }
            };

            this.varianceCodeOptions = {
              items: this.varianceCodes,
              required: true,
              displayExpr: function (item) {
                if (!item) {
                  return item;
                }
                return item.code ? item.code + ': ' + item.description : item.description;
              },
              valueExpr: 'id',
              searchEnabled: true
            };
            this.getPriceFile(false);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  getPriceFile(useCache: boolean) {
    // get the price file items
    this.subscriptions = this.subscriptions.concat(
      this.manualPurchaseOrdersService.getPriceFileItemsWithRates(useCache)
        .subscribe({
          next: () => {
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  onJobNumberChanged(jobNo: string) {
    if (jobNo && this.jobService.currentJob) {

      this.validJob = true;
      this.resetForm();

      this.purchaseOrderForm.jobId = this.jobService.currentJob.id;
      this.priceFileItemsForDateAndDistrict = [];
      this.districtId = null;

      // read the order header for the district.
      this.subscriptions = this.subscriptions.concat(
        this.manualPurchaseOrdersService.getOrderHeader(this.purchaseOrderForm.jobId)
          .subscribe({
            next: (orderHeader) => {
              if (orderHeader) {
                this.districtId = orderHeader.districtId;
                this.getPriceFileItems();

                this.codeLookupVisible = false;
                setTimeout(() => {
                  this.codeLookupVisible = true;
                }, 200); // wait
              } else {
                this.notiService.showError('Order header not created. See Construction Orders');
                this.validJob = false;
              }
            },
            error: () => {
              this.notiService.showError('Order header not created. See Construction Orders');
            }
          })
      );
    } else {
      this.purchaseOrderForm.jobId = null;
    }
  }

  setUpOrderLineDataSet() {
    this.orderLinesDataStore = new CustomStore({
      key: 'id',
      load: () => this.orderLines,
      insert: async (values) => {
        const newOrderLine = new ManualOrderLine;
        newOrderLine.priceFileItemId = values.priceFileItemId;
        newOrderLine.description = values.description;
        newOrderLine.unitOfMeasure = values.unitOfMeasure;
        newOrderLine.quantity = values.quantity;
        newOrderLine.rate = values.rate;
        newOrderLine.productCode = values.productCode;
        newOrderLine.lineTotal = values.lineTotal;

        this.orderLineId++;
        newOrderLine.id = this.orderLineId;
        this.orderLines.push(newOrderLine);

        return newOrderLine;
      },
      update: async (key, values) => {
        const orderLine = this.orderLines.find(i => i.id === key);
        if (values.priceFileItemId !== undefined) {
          orderLine.priceFileItemId = values.priceFileItemId;
        }
        if (values.description !== undefined) {
          orderLine.description = values.description;
        }
        if (values.quantity !== undefined) {
          orderLine.quantity = values.quantity;
        }
        if (values.rate !== undefined) {
          orderLine.rate = values.rate;
        }
        if (values.unitOfMeasure !== undefined) {
          orderLine.unitOfMeasure = values.unitOfMeasure;
        }
        if (values.lineTotal !== undefined) {
          orderLine.lineTotal = values.lineTotal;
        }
        if (values.productCode !== undefined) {
          orderLine.productCode = values.productCode;
        }

        return orderLine;
      },
      remove: async (key) => {
        return new Promise((resolve, reject) => {
          const lineIndex = this.orderLines.findIndex(i => i.id === key);
          this.orderLines.splice(lineIndex, 1);
          return resolve();
        });
      }
    });
  }

  update() {
    if (this.orderLineGrid?.instance?.hasEditData()) {
      this.orderLineGrid?.instance.saveEditData();
    }

    if (!this.orderLines.length) {
      this.notiService.showInfo('No items specified');
    } else if (this.orderLines.find(i => !i.quantity)) {
      this.notiService.showInfo('Items must have a quantity');
    } else {
      this.orderLines.forEach(orderLine => {
        orderLine.measuredQuantity = orderLine.quantity;
        orderLine.supplierQuantity = orderLine.quantity;
        orderLine.quantityString = orderLine.quantity.toString();
      });

      const data = {
        jobId: this.purchaseOrderForm.jobId,
        vendorId: this.purchaseOrderForm.vendorId,
        costCentreId: this.purchaseOrderForm.costCentreId,
        varianceCodeId: this.purchaseOrderForm.varianceCodeId,
        varianceReason: this.purchaseOrderForm.varianceReason,
        emailAddress: this.purchaseOrderForm.emailAddress,
        emailSubject: this.purchaseOrderForm.emailSubject,
        emailMessage: this.purchaseOrderForm.emailMessage,
        ccToSelf: this.purchaseOrderForm.ccToSelf,
        orderLines: this.orderLines
      };

      this.loading = true;

      this.subscriptions.push(
        this.manualPurchaseOrdersService.createManualPurchaseOrder(data).subscribe({
          next: (res) => {
            this.notiService.showSuccess('Order ' + res.poNumber + ' created & queued for send.');
            // send
            this.sendPOEmail(res.id);
          }, error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
      );
    }
  }

  sendPOEmail(poId: number) {
    const poIds: number[] = [];
    poIds.push(poId);

    const emailAddresses: string[] = [];
    emailAddresses.push(this.purchaseOrderForm.emailAddress);

    const dataRecord = {
      purchaseOrderIds: poIds,
      emailAddresses: emailAddresses,
      ccToSelf: this.purchaseOrderForm.ccToSelf,
      download: false,
      emailSubject: this.purchaseOrderForm.emailSubject,
      emailMessage: this.purchaseOrderForm.emailMessage,
      doNotSendAttachments: true,
      sendHistoryOfChanges: false,
      boldAddedExtras: false,
      ccToSiteManager: false,
      printPrices: true
    };

    if (this.purchaseOrderForm.deliveryDate) {
      dataRecord.emailMessage += '\n\nPlease delivery on: ' + formatDate(this.purchaseOrderForm.deliveryDate, 'dd-MMM-yy');
    }

    this.subscriptions.push(
      this.manualPurchaseOrdersService.queuePurchaseOrders(this.jobService.currentJob.id, dataRecord)
        .subscribe({
          next: () => {
            // now we queue a record for the scheduler as an FYI
            this.getJobRoles(poId);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  getJobRoles(poId: number) {
    this.subscriptions = this.subscriptions.concat(
      this.jobService.getJobRole(this.jobService.currentJob.jobNumber, RoleTypeEnum.SchedulerConstructionEstimator)
        .subscribe({
          next: (jobRoles) => {
            if (jobRoles && jobRoles.length && jobRoles[0].user) {
              // scheduler exists so we cc to them
              this.sendEmailToScheduler(poId, jobRoles[0].user.email);
            } else {
              this.loading = false;
              this.resetForm();
            }
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
            this.resetForm();
          }
        })
    );
  }

  sendEmailToScheduler(poId: number, schedulerEmail: string) {
    const poIds: number[] = [];
    poIds.push(poId);

    const emailAddresses: string[] = [];
    emailAddresses.push(schedulerEmail);

    const dataRecord = {
      purchaseOrderIds: poIds,
      emailAddresses: emailAddresses,
      ccToSelf: false,
      download: false,
      emailSubject: this.purchaseOrderForm.emailSubject,
      emailMessage: 'FYI: PO Created.\n\n' + this.purchaseOrderForm.emailMessage,
      doNotSendAttachments: true,
      sendHistoryOfChanges: false,
      boldAddedExtras: false,
      ccToSiteManager: false,
      printPrices: true
    };

    this.subscriptions.push(
      this.manualPurchaseOrdersService.queuePurchaseOrders(this.jobService.currentJob.id, dataRecord)
        .subscribe({
          next: () => {
            this.loading = false;
            this.resetForm();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
            this.resetForm();
          }
        })
    );
  }

  setItemCellValue(rowData, value, originalData) {
    if (value) {
      rowData.priceFileCode = value;
      rowData.description = this.selectedPriceFileItem.description;
      rowData.unitOfMeasure = this.selectedPriceFileItem.unitOfMeasure;
      rowData.priceFileItemId = this.selectedPriceFileItem.priceFileItemId;
      rowData.rate = this.selectedPriceFileItem.rate;

      rowData.lineTotal = (originalData.quantity ? originalData.quantity : 0) * this.calculateRate(+rowData.rate, rowData.unitOfMeasure);
    }
  }

  onSelectionChanged(cellInfo, e, event) {
    if (event.selectedRowKeys.length > 0) {
      this.selectedPriceFileItem = event.selectedRowsData[0];
      cellInfo.setValue(event.selectedRowsData[0].priceFileItemId);
      e.component.close();
    }
  }

  getGroupTitle(cellInfo) {
    return cellInfo.data.key.split(';')[1];
  }

  setRateCellValue(rowData, value, originalData) {
    if (value) {
      rowData.rate = value;
    } else {
      rowData.rate = 0;
    }

    rowData.lineTotal = (originalData.quantity ? originalData.quantity : 0)
      * this.calculateRate(rowData.rate, originalData.unitOfMeasure);
  }

  setQuantityCellValue(rowData, value, originalData) {
    if (value) {
      rowData.quantity = value;
      rowData.lineTotal = value * this.calculateRate(originalData.rate, originalData.unitOfMeasure);
    } else {
      rowData.quantity = null;
      rowData.lineTotal = 0;
    }
  }

  setUnitsCellValue(rowData, value, originalData) {
    if (value) {
      rowData.unitOfMeasure = value;
      rowData.lineTotal = (originalData.quantity ? originalData.quantity : 0) * this.calculateRate(originalData.rate, value);
    } else {
      rowData.unitOfMeasure = '';
      rowData.lineTotal = (originalData.quantity ? originalData.quantity : 0) * this.calculateRate(originalData.rate, '');
    }
  }

  calculateRate(originalRate: number, unitOfMeasure: string): number {
    let newRate = originalRate ? originalRate : 0;

    const uom = this.maintenanceService.unitOfMeasures.find(i => i.description === unitOfMeasure);
    if (uom && uom.costIsPer) {
      newRate /= uom.costIsPer;
    }
    return newRate;
  }

  onCostCentreChanged(newCostCentreId: number) {
    this.getPriceFileItems();
  }

  onVendorChanged(newVendorId: number) {
    const vendor = this.vendors.find(i => i.id === newVendorId);
    this.purchaseOrderForm.emailAddress = vendor?.ordersEmail && vendor?.ordersEmail !== '' ? vendor?.ordersEmail : vendor?.email;
    this.getPriceFileItems();
  }

  onVarianceReasonChanged(ea) {
    if (!this.adHocReason) {
      this.purchaseOrderForm.varianceReason = ea.value;
    } else {
      this.purchaseOrderForm.varianceReason = this.adHocReason.reason;
      this.adHocReason = null;
    }
  }

  addCustomItem(data) {
    if (!data.text) {
      data.customItem = null;
      return;
    }

    this.addToList(data.text);

    this.purchaseOrderForm.varianceReason = data.text;
    return this.adHocReason;
  }

  addToList(adhocText: string) {
    const productIds = this.varianceReasons.map(function (item) {
      return item.id;
    });
    const incrementedId = Math.max.apply(null, productIds) + 1;

    this.adHocReason = new VarianceReason(incrementedId, adhocText);
    this.varianceReasons.push(this.adHocReason);
  }

  getPriceFileItems() {
    this.priceFileItemsForDateAndDistrict = [];

    if (this.purchaseOrderForm.vendorId) {
      this.manualPurchaseOrdersService.priceFileItemsForVendor
        = this.manualPurchaseOrdersService.priceFileItemVendorRates.filter(i => i.vendorId === this.purchaseOrderForm.vendorId);

      this.priceFileItemsForDateAndDistrict =
        this.manualPurchaseOrdersService.getItemsForDateAndDistrict(new Date, this.districtId);

      this.lookupData = new CustomStore({
        key: 'priceFileItemId',
        loadMode: 'raw',
        load: () => this.priceFileItemsForDateAndDistrict.filter(i => i.priceFileGroupId === this.purchaseOrderForm.costCentreId)
      });
    }

    this.codeLookupVisible = false;
    setTimeout(() => {
      this.codeLookupVisible = true;
    }, 200); // wait
  }

  resetForm() {
    this.orderLineId = 0;
    this.orderLines = [];
    this.purchaseOrderForm = new PurchaseOrderForm;
    this.purchaseOrderForm.jobId = this.jobService.currentJob.id;
    this.purchaseOrderForm.emailSubject = 'Purchase Order: ' + this.globalService.getJobString(this.jobService.currentJob);
    this.purchaseOrderForm.emailMessage = 'Please see purchase order attached.';
    this.purchaseOrderForm.ccToSelf = true;
    this.priceFileItemsForDateAndDistrict = [];

    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 200); // wait for iPhone and grid
  }

  onRowPrepared(e) {
    if (e.rowType === 'data') {
      // if new line
      if (!e.data.id) {
        e.rowElement.style.background = 'lightyellow';
      } else {
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', 'dark-grey');
      }
    }
  }

  setEmailAddress() {
    const usersEmail = this.authService.getCurrentUser().email;
    if (this.purchaseOrderForm.emailAddress === usersEmail) {
      const vendor = this.vendors.find(i => i.id === this.purchaseOrderForm.vendorId);
      this.purchaseOrderForm.emailAddress = vendor?.ordersEmail && vendor?.ordersEmail !== '' ? vendor?.ordersEmail : vendor?.email;
    } else {
      this.purchaseOrderForm.emailAddress = usersEmail;
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CashFlowForecastComponent } from './cash-flow-forecast.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [CashFlowForecastComponent]
})
export class CashFlowForecastModule { }

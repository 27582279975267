import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { JobSearchTypeEnum } from '../../dtos/job-search-type.enum';
import { JobService } from '../../services/felixApi/job.service';
import { GlobalService } from '../../services/global.service';
import { JobSearchModalComponent } from '../job-search-modal/job-search-modal.component';

@Component({
  selector: 'js-job-number',
  templateUrl: './job-number.component.html',
  styleUrls: ['./job-number.component.scss']
})
export class JobNumberComponent implements OnInit, OnDestroy {
  @Input() smallWindow: boolean;

  @Output() refreshJobNumber: EventEmitter<string> =
    new EventEmitter<string>();

  subscriptions: Subscription[] = [];
  jobId: number;
  jobString: string;
  jobAddress: string;
  jobStringMaxWidth: number;
  jobNumber = '';
  isRestrictedScreen: boolean;
  warningNote = '';
  newJobNumberUpperCase: string;

  constructor(
    private globalService: GlobalService,
    private notiService: NotificationService,
    private maintenanceService: MaintenanceService,
    public jobService: JobService,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.setJobStringMaxWidth();
    this.subscribeToInnerWidth();

    this.jobNumber = this.jobService.getCurrentJob();
    this.newJobNumberUpperCase = this.jobNumber.toUpperCase().trim();
    if (this.jobNumber && this.jobNumber !== '') {
      this.getJob();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setJobStringMaxWidth() {
    const innerWidth = this.globalService.innerWidth;

    if (this.smallWindow) {
      this.jobStringMaxWidth = 350;
    } else {
      this.jobStringMaxWidth = innerWidth - 20 - 194; // grid width minus margin - job field
      if (innerWidth < 600) {
        this.isRestrictedScreen = true;
      } else {
        this.isRestrictedScreen = false;
        this.jobStringMaxWidth = this.jobStringMaxWidth - 100; // grid width minus job number text
      }
    }
  }

  subscribeToInnerWidth() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          setTimeout(() => {
            this.setJobStringMaxWidth();
          }, 50); // wait for iPhone
        }
      )
    );
  }

  onJobNumberChanged() {
    this.jobNumber = this.jobNumber.trim();
    if (this.jobNumber && this.jobNumber !== '' && this.newJobNumberUpperCase !== this.jobNumber.toUpperCase()) {
      this.newJobNumberUpperCase = this.jobNumber.toUpperCase();
      this.getJob();
    }
  }

  getJob() {
    this.jobString = 'Loading...';
    this.jobAddress = '';
    this.jobService.currentActivity = '';
    this.warningNote = '';
    this.subscriptions.push(
      this.jobService.getJob(this.jobNumber)
        .subscribe({
          next: (currentJob) => {
            if (currentJob) {
              this.jobId = currentJob.id;
              this.jobNumber = currentJob.jobNumber;
              this.newJobNumberUpperCase = this.jobNumber.toUpperCase();
              this.jobString = currentJob.contractName;
              this.jobAddress = this.globalService.getJobString(currentJob);
              this.jobService.setCurrentJob(this.jobNumber);
              this.jobService.currentJob = currentJob;
              this.warningNote = currentJob.warningNote ?? '';
              this.getJobExtras();
            } else {
              this.clearJob();
            }
          },
          error: () => {
            this.clearJob();
          }
        })
    );
  }

  clearJob() {
    this.jobId = null;
    this.jobString = 'Not found';
    this.jobAddress = '';
    this.jobService.currentJob = null;
    this.jobService.currentJobExtra = null;
    this.refreshJobNumber.emit(null);
  }

  jobSearch() {
    const modalRef = this.modalService.open(JobSearchModalComponent, { windowClass: 'modal-search' });
    modalRef.componentInstance.searchType = JobSearchTypeEnum.AllJobs;

    modalRef.result.then((jobNumber) => {
      if (jobNumber) {
        this.jobNumber = jobNumber;
        this.getJob();
      }
    });
  }

  getJobExtras() {
    this.subscriptions.push(
      this.jobService.getJobExtras(this.jobId)
        .subscribe({
          next: (jobExtra) => {
            this.jobService.currentJobExtra = jobExtra;
            if (jobExtra?.maintenanceCompleteDate) {
              this.jobService.currentActivity = 'Maintenance Complete';
            } else {
              this.jobService.currentActivity = this.maintenanceService
                .activities?.find(i => i.id === jobExtra?.currentActivityId)?.description;

              this.setActivityNotSold();
            }
            this.refreshJobNumber.emit(this.jobNumber);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.jobService.currentJobExtra = null;
            this.setActivityNotSold();
          }
        })
    );
  }

  setActivityNotSold() {
    if ((!this.jobService.currentActivity || this.jobService.currentActivity === '') && !this.jobService.currentJob.salesDate) {
      this.jobService.currentActivity = 'Pending Sale';
    }
  }
}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobService } from '../../services/felixApi/job.service';
import { NotificationService } from '../../services/notification.service';
import { JobExtra, JobExtraCancelledForm } from '../../dtos/job-extra';
import { UserService } from '../../services/felixApi/user.service';
import { User } from '../../dtos/user';

@Component({
  selector: 'js-cancel-job',
  templateUrl: './cancel-job.component.html',
  styleUrls: ['./cancel-job.component.scss']
})
export class CancelJobComponent implements OnInit, OnDestroy {
  @Input() jobExtra: JobExtra;
  @Input() jobId: number;

  loading = false;
  jobExtraForm: JobExtraCancelledForm;
  subscriptions: Subscription[] = [];
  updatedData: any;
  user: User;
  updateButtonOptions = {
    text: 'Update',
    type: 'default',
    stylingMode: 'contained',
    onClick: () => {
      this.update();
    },
    useSubmitBehavior: true
  };

  constructor(
    private activeModal: NgbActiveModal,
    private jobService: JobService,
    private userService: UserService,
    private notiService: NotificationService) { }

  ngOnInit() {
    if (this.jobExtra) {
      this.user = this.userService.users.find(i => i.id === this.jobExtra.cancellationModifiedUserId);
    }
    this.jobExtraForm = {
      cancellationDate: this.jobExtra ? this.jobExtra.cancellationDate : null,
      cancellationReason: this.jobExtra ? this.jobExtra.cancellationReason : '',
      modifiedDate: this.jobExtra ? this.jobExtra.modifiedDate : null,
      modifiedUser: this.user ? this.user.fullName : ''
    };
    this.loading = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  close() {
    this.activeModal.dismiss();
  }

  update() {
    if (this.jobExtraForm.cancellationReason) {
      this.jobExtraForm.cancellationReason = this.jobExtraForm.cancellationReason.trim();
    }
    if (this.jobExtraForm.cancellationDate && (!this.jobExtraForm.cancellationReason || this.jobExtraForm.cancellationReason === '')) {
      this.notiService.showInfo('Reason is required');
    } else {
      if (this.jobExtra) {
        // edit
        this.updatedData = {};
        let isItemsToUpdate = false;

        if (this.jobExtraForm.cancellationDate !== this.jobExtra.cancellationDate) {
          this.updatedData.cancellationDate = this.jobExtraForm.cancellationDate;
          isItemsToUpdate = true;
        }

        if (this.jobExtraForm.cancellationReason !== this.jobExtra.cancellationReason) {
          this.updatedData.cancellationReason = this.jobExtraForm.cancellationReason;
          isItemsToUpdate = true;
        }

        if (isItemsToUpdate) {
          this.loading = true;

          this.subscriptions.push(
            this.jobService.updateJobExtra(this.jobExtra.id, this.updatedData)
              .subscribe({
                next: (jobExtra) => {
                  this.activeModal.close(jobExtra);
                },
                error: (err) => {
                  this.notiService.notify(err);
                  this.loading = false;
                }
              })
          );
        }
      } else {
        // add
        this.loading = true;

        this.updatedData = {};
        this.updatedData.jobId = this.jobId;
        this.updatedData.cancellationDate = this.jobExtraForm.cancellationDate;
        this.updatedData.cancellationReason = this.jobExtraForm.cancellationReason;

        this.subscriptions.push(
          this.jobService.addJobExtra(this.updatedData)
            .subscribe({
              next:
                (jobExtra) => {
                  this.activeModal.close(jobExtra);
                },
              error: (err) => {
                this.notiService.notify(err);
                this.loading = false;
              }
            })
        );
      }
    }
  }
}

<div class="modal-header">
  <h4 class="modal-title">{{folderName}} - Templates</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <div class="page">

    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <dx-tree-list *ngIf="!loading" class="m-auto templates-height" [dataSource]="dataSource" keyExpr="id"
      parentIdExpr="parentId" [showRowLines]="false" [showBorders]="true" [columnAutoWidth]="false"
      [(selectedRowKeys)]="selectedRowIds" (onRowPrepared)="onRowPrepared($event)" (onRowClick)="onRowClick($event)">

      <dxo-search-panel [visible]="true" width="200"></dxo-search-panel>
      <dxo-selection selectAllMode="allPages" showCheckBoxesMode="always" mode="multiple" [allowSelectAll]="false">
      </dxo-selection>

      <dxi-column dataField="name" [allowSorting]="false" sortOrder="asc"></dxi-column>
    </dx-tree-list>

    <dx-button *ngIf="!loading" text="Create" class="me-2 mt-1" type="default" (onClick)="createDocuments()"
      [disabled]="!selectedRowIds || !selectedRowIds.length">
    </dx-button>

    <dx-check-box *ngIf="!loading" text="Create Word docs as PDFs" class="ms-2 mt-1" [(value)]="createPDFs"> </dx-check-box>
  </div>
</div>

export class PurchaseOrder {
  id: number;
  jobId: number;
  costCentreId: number;
  orderNumber: number;
  vendorId: number;
  phaseId: number;
  poNumber: string;
  comment: string;
  printedDate: Date;
  isDeleted: boolean;
  isNoAccrual: boolean;
  isCancelled: boolean;
  modifiedDate: Date;
  modifiedUserId: number;
  createDate: Date;
  createUserId: number;

  orderTotal: number;
  approvedInvoicesTotal: number;
  canAttachOrdersToCallUps: boolean;
  remainingBudgetExGst: number;
  hasInvoicesNotApproved: boolean;

  costCentreDescription: string;
  vendorEmail: string;
  missingAttachments: number[]; // call up docs types missing

  selected: boolean; // to select secondary/extra POs
  showPrices: boolean; // to select secondary/extra POs
}



export class PurchaseOrderForm {
  jobId: number;
  costCentreId: number;
  vendorId: number;
  varianceCodeId: number;
  varianceReason: string;
  orderLineDescription: string;
  emailAddress: string;
  emailSubject: string;
  emailMessage: string;
  ccToSelf: boolean;
  deliveryDate: Date;
}

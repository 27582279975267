<div class="modal-header">
  <h4 class="modal-title">Overriding Days Maintenance</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <div class="page">

    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <dx-data-grid *ngIf="!loading" id="dataGrid" class="m-auto" [height]="gridHeight" [dataSource]="dataSource"
      [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true"
      [wordWrapEnabled]="true" (onExporting)="gridService.onExporting($event, 'OverridingDays')">

      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
      <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
        refreshMode="reshape">
      </dxo-editing>
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="true"> <dxo-search [enabled]="true"></dxo-search> </dxo-header-filter>


      <dxi-column dataField="id" dataType="number" [visible]='false'>
      </dxi-column>

      <dxi-column dataField="templateTaskHeaderId" dataType="number" caption="Task Template"
        [setCellValue]="setCellValue" cellTemplate="noWrapTemplate" sortOrder="asc">
        <dxo-lookup [dataSource]="taskHeaderList" displayExpr="description" valueExpr="id"></dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="templateDaysHeaderId" dataType="number" caption="Overriding Days Template"
        cellTemplate="noWrapTemplate" sortOrder="asc">
        <dxo-lookup [dataSource]="getTemplateDaysList" displayExpr="description" valueExpr="id"></dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="taskTypeId" dataType="number" caption="Type" [minWidth]="150"
        [setCellValue]="setTaskTypeCellValue">
        <dxo-lookup [dataSource]="taskTypes" displayExpr="description" valueExpr="id"></dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="templateTaskId" dataType="number" caption="Task" cellTemplate="noWrapTemplate"
        sortOrder="asc">
        <dxo-lookup [dataSource]="getTaskList" displayExpr="taskTitle" valueExpr="id"></dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="days" caption="Task Days" dataType="number" width="90" alignment="center">
      </dxi-column>

      <dxi-column dataField="isOnlyForForecast" dataType="boolean" width="110" alignment="center">
      </dxi-column>

      <dxi-column dataField="modifiedUserId" caption="Modified By" dataType="number" width="110"
        cellTemplate="noWrapTemplate" [allowEditing]="false">
        <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="modifiedDate" caption="Modified" dataType="date" width="90" alignment="center"
        cellTemplate="dateTemplate" [allowEditing]="false">
      </dxi-column>


      <div *dxTemplate="let cell of 'noWrapTemplate'">
        <div style="white-space: nowrap; overflow: hidden;">{{cell.text}}</div>
      </div>

      <div *dxTemplate="let cell of 'dateTemplate'">
        <div>{{cell.value | date : "d-MMM-yy"}}</div>
      </div>

    </dx-data-grid>

    <div *ngIf="!loading">
      Only for forecast: only used when a task is NOT started. Started tasks will use the original days.
    </div>

  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" (click)="close()">Close</button>
</div>

import { GridService } from './../../../services/grid.service';
import { GlobalService } from './../../../services/global.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TrackingFieldsService } from '../../../services/felixApi/tracking-fields.service';
import { NotificationService } from '../../../services/notification.service';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-tracking-field-groups',
  templateUrl: './tracking-field-groups.component.html',
  styleUrls: ['./tracking-field-groups.component.scss']
})
export class TrackingFieldGroupsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  loading: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private notiService: NotificationService,
    protected gridService: GridService,
    private globalService: GlobalService,
    private trackingFieldService: TrackingFieldsService) {
    this.onReorder = this.onReorder.bind(this);
  }

  ngOnInit() {
    this.setUpDataSet();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setUpDataSet() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.trackingFieldService.getTrackingFieldGroups(false, false).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.trackingFieldService.addTrackingFieldGroup(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.trackingFieldService.updateTrackingFieldGroup(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.trackingFieldService.deleteTrackingFieldGroup(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();
    const newOrderIndex = visibleRows[e.toIndex].data.orderNumber;

    this.loading = true;

    this.subscriptions.push(
      this.trackingFieldService.updateTrackingFieldGroup(e.itemData.id, { orderNumber: newOrderIndex }).subscribe({
        next: () => {
          this.loading = false;
          e.component.refresh();
        }, error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      }));
  }

  close() {
    this.activeModal.close();
  }

  onInitNewRow(e) {
    e.data.isActive = true;
    e.data.isShownInJobDetails = false;
    e.data.orderNumber = 0;
    e.data.isTrackingFieldAdminOnly = false;
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DxHtmlEditorComponent } from 'devextreme-angular/ui/html-editor';

@Component({
  selector: 'js-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss']
})
export class HtmlEditorComponent implements OnInit {
  @Input() modalTitle: string;
  @Input() htmlText: string;
  @Input() allowNonHtml: boolean;

  isHtml = true;

  @ViewChild('editor') editor: DxHtmlEditorComponent;

  constructor(private _activeModal: NgbActiveModal) { }

  ngOnInit() {
    if (this.allowNonHtml) {
      this.isHtml = false;
    }
  }

  dismiss() {
    this._activeModal.dismiss();
  }

  close() {
    this._activeModal.close(this.htmlText);
  }

  editorFocusOut() {
    this.htmlText = this.editor.instance.option('value');
  }
}

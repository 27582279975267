import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared/shared.module';

import { MaintenanceService } from '../services/felixApi/maintenance.service';
import { TemplateTasksComponent } from './template-tasks/template-tasks.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { TemplateTaskModalComponent } from './template-tasks/template-task-modal/template-task-modal.component';
import { TemplateTaskHeadersComponent } from './template-task-headers/template-task-headers.component';
import { TaskTypesComponent } from './task-types/task-types.component';
import { TemplateDaysHeadersComponent } from './template-days-headers/template-days-headers.component';
import { TemplateDaysModalComponent } from './template-days-headers/template-days-modal/template-days-modal.component';
import { HolidaysComponent } from './holidays/holidays.component';
import { TaskControlComponent } from './task-control/task-control.component';
import { TrackingFieldsComponent } from './tracking-fields/tracking-fields.component';
import { TrackingFieldLookupComponent } from './tracking-fields/tracking-field-lookup/tracking-field-lookup.component';
import { TaskMastersComponent } from './task-masters/task-masters.component';
import { TradesComponent } from './trades/trades.component';
import { TradeRegionsComponent } from './trade-regions/trade-regions.component';
import { CopyTasksModalComponent } from './template-tasks/copy-tasks-modal/copy-tasks-modal.component';
import { CallUpDocTypeComponent } from './call-up-doc-type/call-up-doc-type.component';
import { TrackingFieldGroupsComponent } from './tracking-fields/tracking-field-groups/tracking-field-groups.component';
import { DocumentFoldersComponent } from './document-folders/document-folders.component';
import { UpdateJobsComponent } from './template-tasks/update-jobs/update-jobs.component';
import { TradeRegionVendorsComponent } from './trade-regions/trade-region-vendors/trade-region-vendors.component';
import { TemplateTaskCostCentreModalComponent } from './template-tasks/template-task-cost-centre-modal/template-task-cost-centre-modal.component';
import { TemplateTaskPurchaseOrderComponent } from './template-tasks/template-task-purchase-order/template-task-purchase-order.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgbModule
    ],
    declarations: [
        MaintenanceComponent,
        TemplateTasksComponent,
        TemplateTaskModalComponent,
        TemplateTaskHeadersComponent,
        TemplateDaysHeadersComponent,
        TaskTypesComponent,
        TemplateDaysModalComponent,
        HolidaysComponent,
        TaskControlComponent,
        TrackingFieldsComponent,
        TrackingFieldGroupsComponent,
        TrackingFieldLookupComponent,
        TaskMastersComponent,
        TradesComponent,
        TradeRegionsComponent,
        CopyTasksModalComponent,
        CallUpDocTypeComponent,
        DocumentFoldersComponent,
        UpdateJobsComponent,
        TradeRegionVendorsComponent,
        TemplateTaskCostCentreModalComponent,
        TemplateTaskPurchaseOrderComponent
    ],
    providers: [
        MaintenanceService
    ]
})
export class MaintenanceModule { }

import { NotificationService } from './../../services/notification.service';
import { TaskService } from './../../services/felixApi/task.service';
import { JobService } from './../../services/felixApi/job.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-cancel-job-tasks',
  templateUrl: './cancel-job-tasks.component.html',
  styleUrls: ['./cancel-job-tasks.component.scss']
})
export class CancelJobTasksComponent implements OnInit {
  jobId: number;
  subscriptions: Subscription[] = [];
  deleteInProgress: boolean;
  cancelStartedTasks = false;
  cancelMaintenanceTasks = false;

  constructor(private _activeModal: NgbActiveModal,
    private jobService: JobService,
    private taskService: TaskService,
    private notiService: NotificationService
  ) { }

  ngOnInit() {
  }

  close() {
    this._activeModal.dismiss();
  }

  onJobNumberChanged() {
    this.jobId = this.jobService.currentJob?.id;
  }

  runCancel() {
    this.deleteInProgress = true;

    this.subscriptions.push(
      this.taskService.cancelAllJobTasks(this.jobId, this.cancelStartedTasks, this.cancelMaintenanceTasks)
        .subscribe({
          next: () => {
            this.deleteInProgress = false;
            this._activeModal.close();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.deleteInProgress = false;
          }
        })
    );
  }
}

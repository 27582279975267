<div class="page">

  <js-job-number (refreshJobNumber)='onJobNumberChanged($event)'></js-job-number>
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <div *ngIf="!loading && validJob" class="page max-w-1000 m-auto scroll">

    <form>
      <dx-form *ngIf="!loading" id="form" [(formData)]="purchaseOrderForm" [readOnly]="false"
        [showColonAfterLabel]="true" labelLocation="left" [showValidationSummary]="false" [colCount]="8"
        validationGroup="customerData" [minColWidth]="200">

        <dxi-item dataField="vendorId" editorType="dxSelectBox" [editorOptions]="vendorOptions" [colSpan]="8">
          <dxo-label location="left" text="Vendor"></dxo-label>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="costCentreId" editorType="dxSelectBox" [editorOptions]="costCentreOptions" [colSpan]="8">
          <dxo-label location="left" text="Cost Centre"></dxo-label>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item [colSpan]="8">
          <!-- <dxo-label location="left" text="Items"></dxo-label> -->
          <dx-data-grid *ngIf="itemGridVisible" #orderLineGrid id="orderLineGrid" [dataSource]="orderLinesDataStore"
            [height]="itemGridHeight" [allowColumnReordering]="true" [allowColumnResizing]="true"
            [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
            [hoverStateEnabled]="true" [remoteOperations]="false" (onRowPrepared)="onRowPrepared($event)"
            (onRowInserted)="$event.component.navigateToRow($event.key)">

            <dxo-scrolling mode="standard"></dxo-scrolling>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
            <dxo-editing mode="batch" refreshMode="full" [useIcons]="true" [allowDeleting]="true" [allowAdding]="true"
              [allowUpdating]="true">
            </dxo-editing>

            <dxi-column dataField="priceFileItemId" dataType="number" caption="Code" [width]="150"
              editCellTemplate="codeDropDown" [setCellValue]="setItemCellValue" sortOrder="asc">
              <dxo-lookup [dataSource]="priceFileItemsForDateAndDistrict" displayExpr="priceFileCode"
                valueExpr="priceFileItemId"></dxo-lookup>
            </dxi-column>

            <dxi-column dataField="description" dataType="string" sortOrder="asc" minWidth="100">
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>

            <dxi-column dataField="quantity" caption="Qty" dataType="number" [width]="70"
              [setCellValue]="setQuantityCellValue">
            </dxi-column>

            <dxi-column dataField="unitOfMeasure" dataType="string" caption="Units" [width]="90"
              [setCellValue]="setUnitsCellValue">
              <dxo-lookup [dataSource]="unitsOfMeasure" displayExpr="description" valueExpr="description">
              </dxo-lookup>
            </dxi-column>

            <dxi-column dataField="rate" dataType="number" [width]="85" format="#,##0.00"
              [setCellValue]="setRateCellValue">
            </dxi-column>

            <dxi-column dataField="lineTotal" caption="Total" dataType="number" [width]="90" format="#,##0.00"
              [allowEditing]="false">
            </dxi-column>


            <div *dxTemplate="let cellInfo of 'codeDropDown'">
              <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="lookupData" [(value)]="cellInfo.value"
                displayExpr="priceFileCode" valueExpr="priceFileItemId" contentTemplate="contentTemplate">
                <div *dxTemplate="let e of 'contentTemplate'">
                  <dx-data-grid *ngIf="codeLookupVisible" [dataSource]="lookupData" [remoteOperations]="false"
                    [height]="itemGridHeight + 80" [showBorders]="true" [wordWrapEnabled]="true"
                    [focusedRowEnabled]="true" [focusedRowKey]="cellInfo.value" [selectedRowKeys]="[cellInfo.value]"
                    [hoverStateEnabled]="true" [scrolling]="{ mode: 'standard', preloadEnabled: true }"
                    (onSelectionChanged)="onSelectionChanged(cellInfo, e, $event)">

                    <dxo-paging [enabled]="false"></dxo-paging>
                    <dxo-selection mode="single"></dxo-selection>
                    <dxo-search-panel [visible]="true" width="300"></dxo-search-panel>
                    <dxo-group-panel [visible]="false"></dxo-group-panel>
                    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
                    </dxo-grouping>

                    <dxi-column dataField="subGroupItemDesc" dataType="string" caption="" [groupIndex]="1"
                      groupCellTemplate="groupCellTemplate">
                    </dxi-column>

                    <dxi-column dataField="priceFileCode" caption="Code" [width]="100"></dxi-column>

                    <dxi-column dataField="description"></dxi-column>

                    <dxi-column dataField="rate" dataType="number" [width]="100" format="#,##0.00">
                    </dxi-column>

                    <dxi-column dataField="scale" [visible]="false"></dxi-column>

                    <dxi-column dataField="unitOfMeasure" dataType="number" caption="Units" [width]="80">
                    </dxi-column>

                    <dxi-column dataField="vendorItemDescription" [visible]="false"></dxi-column>

                    <div *dxTemplate="let data of 'groupCellTemplate'">
                      {{ getGroupTitle(data) }}
                    </div>
                  </dx-data-grid>
                </div>
              </dx-drop-down-box>
            </div>

            <dxi-column type="buttons" [width]="50">
              <dxi-button name="delete"> </dxi-button>
            </dxi-column>

            <dxo-summary [recalculateWhileEditing]="true">
              <dxi-total-item column="Total" summaryType="sum" valueFormat="#,##0.00" displayFormat="${0}">
              </dxi-total-item>
            </dxo-summary>
          </dx-data-grid>
        </dxi-item>


        <!-- Code & Reason -->
        <dxi-item dataField="varianceCodeId" editorType="dxSelectBox" [editorOptions]="varianceCodeOptions"
          [colSpan]="8">
          <dxo-label location="left" text="Extra Code"></dxo-label>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="varianceReason" [colSpan]="8">
          <dxo-label location="left" text="Extra Reason"></dxo-label>
          <dxi-validation-rule type="required"></dxi-validation-rule>
          <div *dxTemplate>
            <dx-select-box [(ngModel)]="purchaseOrderForm.varianceReason" [ngModelOptions]="{standalone: true}"
              [dataSource]="varianceReasons" valueExpr="reason" [acceptCustomValue]="true" [showClearButton]="true"
              [searchEnabled]="true" (onValueChanged)="onVarianceReasonChanged($event)"
              (onCustomItemCreating)="addCustomItem($event)" displayExpr="reason">
            </dx-select-box>
          </div>
        </dxi-item>

        <!-- Email Info -->
        <dxi-item dataField="emailAddress" editorType="dxTextBox" [colSpan]="6">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item itemType="button" horizontalAlignment="left" cssClass="add-phone-button"
          [buttonOptions]="addPhoneButtonOptions" [colSpan]="2">
        </dxi-item>

        <dxi-item dataField="emailSubject" editorType="dxTextBox" [colSpan]="8">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="deliveryDate" editorType="dxDateBox" [editorOptions]="{ showClearButton: true }"
          [colSpan]="3">
          <dxo-label location="left" alignment="left"> </dxo-label>
        </dxi-item>

        <dxi-item itemType="button" [colSpan]="5" horizontalAlignment="left"
          [buttonOptions]="deliveryDateButtonOptions">
        </dxi-item>

        <dxi-item dataField="emailMessage" editorType="dxTextArea" [editorOptions]="{ height: 50 }" [colSpan]="8">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
      </dx-form>
    </form>

    <div class="buttonLine">
      <dx-button type="outline" text='Create & Send' type='default' stylingMode='contained' (onClick)="update()"
        class="goButton">
      </dx-button>

      <dx-check-box [(ngModel)]="purchaseOrderForm.ccToSelf" text="cc to self" [width]="100" [rtlEnabled]="true"
        class="ccButton">
      </dx-check-box>
    </div>
  </div>
</div>

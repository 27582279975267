import { GlobalService } from './../../services/global.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { UserService } from '../../services/felixApi/user.service';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { TaskControl, TaskControlForm } from '../../dtos/task-control';
import { User } from '../../dtos/user';
import { ConfigurationEnum } from '../../dtos/configuration-enum';

@Component({
  selector: 'js-task-control',
  templateUrl: './task-control.component.html',
  styleUrls: ['./task-control.component.scss']
})
export class TaskControlComponent implements OnInit, OnDestroy {

  loading = true;
  subscriptions: Subscription[] = [];
  taskControl: TaskControl;
  newTaskControl: TaskControl;
  taskControlForm: TaskControlForm;
  cutOffTimeToPopulateNextStart: Date;
  accountingSystemType: number;
  updatedData: any;
  user: User;
  buttonOptions = {
    text: 'Update',
    type: 'default',
    stylingMode: 'contained',
    onClick: () => {
      this.update();
    },
    useSubmitBehavior: true
  };
  emailUpdatesToClientsActive: boolean;
  wipOptions = [
    { id: 0, name: 'Total of invoices approved' },
    // { id: 1, name: 'Cost centre percentages' },
    { id: 2, name: 'Task complete to accrue order total' }
  ]
  wipSelectOptions: object;

  constructor(
    private maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    private userService: UserService,
    private notiService: NotificationService
  ) { }

  ngOnInit(): void {
    this.emailUpdatesToClientsActive = this.globalService.getCompanyConfigValue(ConfigurationEnum.EmailUpdatesToClientsActive) === 1;

    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onWipSelectionChanged(id: number) {
    this.taskControlForm.wipOptionId = id;
  }

  getData() {
    this.subscriptions.push(
      this.maintenanceService.getTaskControlData(false)
        .subscribe({
          next: (taskControl) => {
            this.taskControl = taskControl;

            if (taskControl) {
              this.cutOffTimeToPopulateNextStart = new Date(2020, 9, 10,
                taskControl.cutOffHoursToPopulateNextStart,
                taskControl.cutOffMinutesToPopulateNextStart);

              this.user = this.userService.users.find(i => i.id === taskControl.modifiedUserId);
            }
            this.taskControlForm = {
              cutOffTimeToPopulateNextStart: this.cutOffTimeToPopulateNextStart,
              subContractorPayStartDate: taskControl ? taskControl.subContractorPayStartDate : null,
              daysToPaySubContractorsFromCutOff: taskControl ? taskControl.daysToPaySubContractorsFromCutOff : null,
              clientsDaysToPay: taskControl ? taskControl.clientsDaysToPay : null,
              callUpEmailStoreAddress: taskControl ? taskControl.callUpEmailStoreAddress : null,
              infoEmailStoreAddress: taskControl ? taskControl.infoEmailStoreAddress : null,
              ccToSiteManager: taskControl ? taskControl.ccToSiteManager : true,
              isCashFlowUsingPurchaseOrders: taskControl ? taskControl.isCashFlowUsingPurchaseOrders : false,
              isCashFlowUsingEstimating: taskControl ? taskControl.isCashFlowUsingEstimating : false,
              addContractualDaysAsWorkingDays: taskControl ? taskControl.addContractualDaysAsWorkingDays : false,
              clientEmailMinLeadDays: taskControl ? taskControl.clientEmailMinLeadDays : 0,
              modifiedDate: taskControl ? taskControl.modifiedDate : null,
              modifiedUser: this.user ? this.user.fullName : '',
              quoteRequestReplyToEmail: taskControl ? taskControl.quoteRequestReplyToEmail : null,
              wipOptionId: taskControl ? taskControl.isWipUsingCostCentrePercentages ? 1 : taskControl.isWipUsingTaskCompleteToAccruePo ? 2 : 0 : 0
            };

            // set up the WIP options
            this.wipSelectOptions = {
              items: this.wipOptions,
              required: true,
              valueExpr: 'id',
              displayExpr: 'name',
              value: this.taskControlForm.wipOptionId,
              onValueChanged: (e) => {
                this.onWipSelectionChanged(e.value);
              }
            };
            this.accountingSystemType = this.globalService.getCompanyConfigValue(ConfigurationEnum.AccountingSystem);
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  update() {
    if (this.taskControl) {
      // edit
      this.updatedData = {};
      let isItemsToUpdate = false;

      if (this.taskControlForm.subContractorPayStartDate !== this.taskControl.subContractorPayStartDate) {
        this.updatedData.subContractorPayStartDate = this.taskControlForm.subContractorPayStartDate;
        isItemsToUpdate = true;
      }

      if (this.taskControlForm.daysToPaySubContractorsFromCutOff !== this.taskControl.daysToPaySubContractorsFromCutOff) {
        this.updatedData.daysToPaySubContractorsFromCutOff = this.taskControlForm.daysToPaySubContractorsFromCutOff;
        isItemsToUpdate = true;
      }

      if (this.taskControlForm.clientsDaysToPay !== this.taskControl.clientsDaysToPay) {
        this.updatedData.clientsDaysToPay = this.taskControlForm.clientsDaysToPay;
        isItemsToUpdate = true;
      }

      if (this.taskControlForm.callUpEmailStoreAddress !== this.taskControl.callUpEmailStoreAddress) {
        this.updatedData.callUpEmailStoreAddress = this.taskControlForm.callUpEmailStoreAddress;
        isItemsToUpdate = true;
      }

      if (this.taskControlForm.infoEmailStoreAddress !== this.taskControl.infoEmailStoreAddress) {
        this.updatedData.infoEmailStoreAddress = this.taskControlForm.infoEmailStoreAddress;
        isItemsToUpdate = true;
      }

      if (this.taskControlForm.quoteRequestReplyToEmail !== this.taskControl.quoteRequestReplyToEmail) {
        this.updatedData.quoteRequestReplyToEmail = this.taskControlForm.quoteRequestReplyToEmail;
        isItemsToUpdate = true;
      }

      if (this.taskControlForm.ccToSiteManager !== this.taskControl.ccToSiteManager) {
        this.updatedData.ccToSiteManager = this.taskControlForm.ccToSiteManager;
        isItemsToUpdate = true;
      }

      if (this.taskControlForm.isCashFlowUsingPurchaseOrders !== this.taskControl.isCashFlowUsingPurchaseOrders) {
        this.updatedData.isCashFlowUsingPurchaseOrders = this.taskControlForm.isCashFlowUsingPurchaseOrders;
        isItemsToUpdate = true;
      }

      if (this.taskControlForm.isCashFlowUsingEstimating !== this.taskControl.isCashFlowUsingEstimating) {
        this.updatedData.isCashFlowUsingEstimating = this.taskControlForm.isCashFlowUsingEstimating;
        isItemsToUpdate = true;
      }

      if (this.taskControlForm.addContractualDaysAsWorkingDays !== this.taskControl.addContractualDaysAsWorkingDays) {
        this.updatedData.addContractualDaysAsWorkingDays = this.taskControlForm.addContractualDaysAsWorkingDays;
        isItemsToUpdate = true;
      }

      if (this.taskControlForm.clientEmailMinLeadDays !== this.taskControl.clientEmailMinLeadDays) {
        this.updatedData.clientEmailMinLeadDays = this.taskControlForm.clientEmailMinLeadDays;
        isItemsToUpdate = true;
      }

      if (this.taskControlForm.wipOptionId !== (this.taskControl.isWipUsingCostCentrePercentages ? 1 : this.taskControl.isWipUsingTaskCompleteToAccruePo ? 2 : 0)) {
        this.updatedData.isWipUsingCostCentrePercentages = this.taskControlForm.wipOptionId == 1;
        this.updatedData.isWipUsingTaskCompleteToAccruePo = this.taskControlForm.wipOptionId === 2;
        isItemsToUpdate = true;
      }

      if (isItemsToUpdate) {
        this.loading = true;

        this.subscriptions.push(
          this.maintenanceService.updateTaskControl(this.taskControl.id, this.updatedData)
            .subscribe({
              next: () => {
                this.getData();
              },
              error: (err) => {
                this.notiService.notify(err);
                this.loading = false;
              }
            })
        );
      }
    } else {
      // add
      this.loading = true;

      this.subscriptions.push(
        this.maintenanceService.addTaskControl(this.taskControlForm)
          .subscribe({
            next: () => {
              this.getData();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    }
  }
}

import { GlobalService } from './../../services/global.service';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { Invoice } from './../../dtos/invoice';
import { NotificationService } from './../../services/notification.service';
import { InvoiceService } from './../../services/felixApi/invoice.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Vendor } from '../../dtos/vendor';
import { JobService } from '../../services/felixApi/job.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { UserService } from '../../services/felixApi/user.service';
import { GridService } from '../../services/grid.service';

@Component({
  selector: 'js-previous-invoices',
  templateUrl: './previous-invoices.component.html',
  styleUrls: ['./previous-invoices.component.scss']
})
export class PreviousInvoicesComponent implements OnInit, OnDestroy {
  @Input() purchaseOrderId: number;
  @Input() invoiceId: number;
  @Input() jobNumber: string;
  @Input() costCentreId: number;

  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  loading = true;
  vendors: Vendor[];
  invoices: Invoice[];
  popupHeight: number;
  loadingFile: boolean;
  invoice: Invoice;
  initialPurchaseOrderId: number;
  initialCostCentreId: number;
  popupWidth: number;

  constructor(
    private activeModal: NgbActiveModal,
    private invoiceService: InvoiceService,
    private notiService: NotificationService,
    private jobService: JobService,
    private userService: UserService,
    private maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    protected gridService: GridService
  ) {
    this.downloadAttachment = this.downloadAttachment.bind(this);
    this.openPO = this.openPO.bind(this);
  }

  ngOnInit() {
    this.popupHeight = window.innerHeight < 705 ? window.innerHeight - 5 : 700;
    this.vendors = this.userService.vendors;
    this.initialPurchaseOrderId = this.purchaseOrderId;
    this.initialCostCentreId = this.costCentreId;
    this.popupWidth = window.innerWidth < 1205 ? window.innerWidth - 5 : 1200;
    this.loadData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadData() {
    this.subscriptions.push(
      this.invoiceService.getInvoices(null, null, null, this.jobNumber, null, this.initialCostCentreId ? null : this.initialPurchaseOrderId, this.initialCostCentreId)
        .subscribe({
          next: (invoices) => {
            this.invoices = invoices?.filter(i => i.id !== this.invoiceId);
            this.invoice = invoices?.find(i => i.id === this.invoiceId);

            this.loading = false;
            setTimeout(() => {
              this.dataGrid.instance.expandRow([this.invoice?.poNumber]);
            }, 100);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'outline',
          text: this.initialCostCentreId ? 'Show All Cost Centres' : 'Show Original Cost Centre',
          onClick: this.showAllOrders.bind(this)
        }
      });
  }


  showAllOrders() {
    if (this.initialPurchaseOrderId) {
      this.initialPurchaseOrderId = null;
      this.initialCostCentreId = null;
    } else {
      this.initialPurchaseOrderId = this.purchaseOrderId;
      this.initialCostCentreId = this.costCentreId;
    }
    this.loading = true;
    this.loadData();
  }

  close() {
    this.activeModal.dismiss();
  }

  downloadAttachment(e) {
    // download attachment
    this.loadingFile = true;
    this.subscriptions.push(
      this.invoiceService.getInvoiceAttachment(e.row.data.id).subscribe({
        next: (res) => {
          if (res) {
            this.globalService.getBlobFromBase64(res.attachment, res.attachmentTypeId, res.attachmentName);
          } else {
            this.notiService.showInfo('No invoice attached');
          }
          this.loadingFile = false;
        }, error: (err) => {
          this.notiService.notify(err);
          this.loadingFile = false;
        }
      })
    );
  }

  openPO(e) {
    if (e.row.data.jobId && e.row.data.purchaseOrderId) {
      const jobNumber = this.jobService.jobs.find(i => i.id === e.row.data.jobId)?.jobNumber;

      const isHideCostsFromTracking = this.maintenanceService.costCentres.find(i => i.id === e.row.data.costCentreId)?.isHideCostsFromTracking

      const dataRecord = {
        purchaseOrderIds: [e.row.data.purchaseOrderId],
        emailAddresses: [],
        ccToSelf: false,
        download: true,
        printPrices: !isHideCostsFromTracking
      };

      this.notiService.showInfo('downloading order');

      this.subscriptions.push(
        this.maintenanceService.sendPurchaseOrders(e.row.data.jobId, dataRecord)
          .subscribe({
            next: (orderResponse) => {
              this.globalService.convertAndSave(orderResponse.pdfOrders, jobNumber);
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    }
  }
}

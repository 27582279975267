<div *ngIf="!showAllHistory" class="page">
  <dx-data-grid *ngIf="!loadingData" #invoiceHoldGrid id="invoiceHoldGrid" [height]="gridHeight"
    [dataSource]="dataSource" [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true"
    [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
    [columnHidingEnabled]="true" (onEditingStart)="onEditingStart($event)"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate', 'toolbarTemplate2', 'toolbarTemplate3')"
    (onCellPrepared)="onCellPrepared($event)" (onExporting)="gridService.onExporting($event, 'Invoices')"
    (onEditorPreparing)="onEditorPreparing($event)">

    <dxo-search-panel [visible]="true" location="after" width="120px"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="!invoiceAuthorisationAdmin" [contextMenuEnabled]="false"
      expandMode="rowClick"></dxo-grouping>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="tracking-invoices-on-hold"
      [savingTimeout]="500">
    </dxo-state-storing>

    <dxo-editing mode="popup" [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="false" [useIcons]="true"
      [selectTextOnEditStart]="true" refreshMode="repaint">
      <dxo-popup title="" [showTitle]="false" [width]="popupWidth" [height]="popupHeight" [fullScreen]="false">
        <dxi-toolbar-item toolbar="bottom" location="before" widget="dxButton"
          [options]="{ type: 'default', stylingMode: 'outlined', text: 'Related Invoices', onClick: showInvoiceHistory }">
        </dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="before" widget="dxButton"
          [options]="{ type: 'default', stylingMode: 'outlined', text: 'Reject', onClick: rejectInvoice }">
        </dxi-toolbar-item>
        <dxi-toolbar-item *ngIf="hasBackChargePermission" toolbar="bottom" location="before" widget="dxButton"
          [options]="{ type: 'default', stylingMode: 'outlined', text: 'Back-Charge', onClick: backChargeInvoice }">
        </dxi-toolbar-item>

        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton"
          [options]="{ type: 'default', text: 'Save', onClick: saveClickHandler }">
        </dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton"
          [options]="{ type:'danger', text: 'Cancel', onClick: cancelClickHandler }">
        </dxi-toolbar-item>
      </dxo-popup>
      <dxo-form [minColWidth]="200">
        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item dataField="jobNumber" [colSpan]="4"></dxi-item>
          <dxi-item dataField="jobId" [colSpan]="4"></dxi-item>
          <dxi-item dataField="costCentreId" [colSpan]="4"></dxi-item>
          <dxi-item dataField="vendorId" [colSpan]="4"></dxi-item>
          <dxi-item dataField="orderNumber" [colSpan]="2"></dxi-item>
          <dxi-item dataField="totalExGST" [colSpan]="2"></dxi-item>
          <dxi-item [colSpan]="2"></dxi-item>
          <dxi-item dataField="onHoldType" [colSpan]="2"></dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item dataField="allocatedToUserId" [colSpan]="4"></dxi-item>
          <dxi-item dataField="description" editorType="dxTextArea" [colSpan]="4" [editorOptions]="{ height: 120 }">
          </dxi-item>
          <dxi-item dataField="isWorkDone" [colSpan]="2"> </dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>

    <dxi-column dataField="allocatedTo" caption="Allocated To" width="100" [groupIndex]="0"
      [calculateCellValue]="calcAllocatedTo" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="jobNumber" caption="Job" dataType="string" [allowEditing]="false" width="90"
      [calculateCellValue]='calculateJobNumber' sortOrder="asc">
    </dxi-column>

    <dxi-column dataField="jobId" caption="Job Address" dataType="number" [allowEditing]="false"
      [width]="jobAddressWidth">
      <dxo-lookup [dataSource]="jobs" displayExpr="jobAddressString" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="costCentreId" caption="Cost Centre" dataType="string" [allowEditing]="false"
      [calculateCellValue]='calculateCostCentreId' width="150" [calculateSortValue]="calculateCostCentreSortValue">
      <dxo-lookup [dataSource]="costCentres" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [setCellValue]="setVendorCellValue"
      [width]="vendorWidth" [allowEditing]="false" [calculateSortValue]="calculateVendorSortValue">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="dueDate" dataType="date" width="90" format="d/M/yy" alignment="center"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="totalExGST" caption="Invoice Ex GST" dataType="number" width="90" format="#,##0.00"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column *ngIf="!showRemainingBudget" dataField="description" caption="Comment" [width]="descWidth">
    </dxi-column>

    <dxi-column *ngIf="showRemainingBudget" dataField="remainingBudget" caption="Remaining Budget ExGST Will Be"
      dataType="number" width="130" [calculateCellValue]='calculateRemainingBudget' format="#,##0"
      [allowEditing]="false" [allowFiltering]="false">
    </dxi-column>

    <dxi-column dataField="onHoldType" caption="Hold" dataType="string" [allowEditing]="false" width="62"
      cellTemplate="holdTypeCellTemplate">
    </dxi-column>

    <dxi-column dataField="orderNumber" width="100" [setCellValue]="setOrderNumberCellValue" [allowEditing]="false">
    </dxi-column>

    <dxi-column *ngIf="!showRemainingBudget" dataField="remainingBudget" caption="Remaining Budget ExGST Will Be"
      dataType="number" width="130" [calculateCellValue]='calculateRemainingBudget' format="#,##0"
      [allowEditing]="false" [allowFiltering]="false">
    </dxi-column>

    <dxi-column *ngIf="showRemainingBudget" dataField="description" caption="Comment" [width]="descWidth">
    </dxi-column>

    <dxi-column dataField="invoiceNumber" width="90" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="totalIncGST" caption="Invoice Inc GST" dataType="number" width="90" format="#,##0.00"
      [setCellValue]="setTotalIncGSTCellValue" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="siteManager" caption="Site Manager" dataType="string" [allowEditing]="false" width="120"
      [calculateCellValue]='calculateSiteManager'>
    </dxi-column>

    <dxi-column dataField="allocatedToUserId" caption="Allocated To" dataType="number" width="120"
      [editorOptions]="{ showClearButton: true }" editCellTemplate="editAllocatedUserTemplate">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="purchaseOrderId" dataType="number" width="100" [visible]="false" [allowEditing]="false">
    </dxi-column>

    <!-- sort by job then earliest entered -->
    <dxi-column dataField="id" dataType="number" [visible]="false" [allowEditing]="false" sortOrder='asc'>
    </dxi-column>

    <dxi-column dataField="isWorkDone" caption="Authorise" dataType="boolean" width="75" [allowFiltering]="false">
    </dxi-column>

    <dxi-column dataField="poTotal" caption="PO Total Ex GST" dataType="number" width="90" [allowFiltering]="false"
      [calculateCellValue]="calculatePOTotal" format="#,##0">
    </dxi-column>

    <dxi-column type="buttons" [width]="125">
      <dxi-button name="edit"></dxi-button>
      <dxi-button icon="check" [onClick]="approveInvoice" hint='Approve'></dxi-button>
      <!-- <dxi-button icon="tags" hint="Add simple extra" [onClick]="extrasPopup" hint='Enter Extra'></dxi-button> -->
      <dxi-button icon="doc" [onClick]="downloadAttachment" hint='Download Invoice'></dxi-button>
      <dxi-button icon="download" [onClick]="openPO" hint='Download PO'></dxi-button>
    </dxi-column>


    <!-- Templates -->
    <!-- select user but add the scheduler at the top for easy allocation -->
    <div *dxTemplate="let cellInfo of 'editAllocatedUserTemplate'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="users" [(value)]="cellInfo.value"
        displayExpr="fullName" valueExpr="id" contentTemplate="userContentTemplate" [showClearButton]="true"
        (onOpened)="getUsersForEdit(cellInfo)" (onValueChanged)="onDropDownChanged(cellInfo, $event)">
        <div *dxTemplate="let e of 'userContentTemplate'">
          <dx-data-grid [dataSource]="usersForEdit" keyExpr="id" [remoteOperations]="false" height="358px"
            [showBorders]="true" [wordWrapEnabled]="true" [selectedRowKeys]="[cellInfo.value]"
            [hoverStateEnabled]="true" [scrolling]="{ mode: 'standard', preloadEnabled: true }"
            [autoNavigateToFocusedRow]="true" (onSelectionChanged)="onSelectionChanged(cellInfo, e.component, $event)">

            <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>
            <dxi-column dataField="fullName"></dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>


    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <span class="me-1">Show my invoices only</span>
        <dx-check-box [(value)]="showMyInvoicesOnly" [width]="30" (onValueChanged)="showMyTasksOnlyChanged($event)"
          class="me-0">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate2'">
      <div>
        <span class="me-1">Show only invoices allocated & not manually authorised</span>
        <dx-check-box [(value)]="showInvoicesNeedingApprovalOnly" [width]="30"
          (onValueChanged)="showInvoicesNeedingApprovalOnlyChanged($event)" class="me-0">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate3'">
      <div>
        <dx-select-box id="selectVendorGroup" [dataSource]="vendorGroups" displayExpr="description" valueExpr="id"
          placeholder="All Groups" [width]="160" [(value)]='selectedVendorGroupId' (onValueChanged)='setUpDataSource()'>
        </dx-select-box>
      </div>
    </div>

    <div *dxTemplate="let cellInfo of 'holdTypeCellTemplate'">
      <div matTooltip='B=Budget Hold, W=Work Not Done, V=Vendor Hold'>
        {{cellInfo.value}}
      </div>
    </div>
  </dx-data-grid>

  <span>Hold Status: B=Over Budget, W=Work Not Complete (Can override by Authorising), V=Vendor Hold/Issue. Green are
    authorised.</span>
</div>

<div *ngIf="showAllHistory && !loadingData" class="page">

  <js-job-number (refreshJobNumber)='onJobNumberChanged($event)'></js-job-number>

  <dx-data-grid *ngIf="!loadingInvoices" id="grid-container" [dataSource]="historyInvoices" keyExpr="id"
    [showBorders]="true" [height]="gridHeight - 17" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    [allowColumnReordering]="false" [allowColumnResizing]="false" [columnAutoWidth]="false"
    (onToolbarPreparing)="onHistoryToolbarPreparing($event)"
    (onExporting)="gridService.onExporting($event, 'Invoices')">

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="after" [width]="200"></dxo-search-panel>

    <dxi-column dataField="poNumber" caption="PO Number" width="110" sortOrder="asc">
    </dxi-column>

    <dxi-column dataField="costCentreId" dataType="number" caption="Cost Code" minWidth="120"
      [calculateSortValue]="calculateCostCentreSortValue">
      <dxo-lookup [dataSource]="costCentres" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="invoiceNumber" minWidth="100" sortOrder="asc">
    </dxi-column>

    <dxi-column dataField="invoiceDate" caption="Date" dataType="date" [width]="95" alignment="center"
      format="d-MMM-yy">
    </dxi-column>

    <dxi-column dataField="totalExGST" caption="Invoice Ex GST" dataType="number" width="90" format="#,##0.00">
    </dxi-column>

    <dxi-column dataField="totalIncGST" caption="Invoice Inc GST" dataType="number" width="90" format="#,##0.00">
    </dxi-column>

    <dxi-column dataField="paidDate" caption="Paid" dataType="date" width="90" format="d/M/yy" alignment="center"
      cellTemplate="paidCellTemplate">
    </dxi-column>

    <div *dxTemplate="let cell of 'paidCellTemplate'">
      <div *ngIf="cell.value">{{cell.value | date : "d-MMM-yy"}}</div>
      <dx-button id="paidCheckButton" *ngIf="!cell.value" text="Check" stylingMode="text" type="default"
        (onClick)="checkIfPaid(cell)"></dx-button>
    </div>

    <dxi-column type="buttons" [width]="90">
      <dxi-button icon="doc" [onClick]="downloadAttachment" hint='Download Invoice'></dxi-button>
      <dxi-button icon="download" [onClick]="openPO" hint='Download PO'></dxi-button>
    </dxi-column>

    <dxo-summary>
      <dxi-group-item column="totalExGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}"
        [showInGroupFooter]="true">
      </dxi-group-item>
      <dxi-group-item column="totalIncGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}"
        [showInGroupFooter]="true">
      </dxi-group-item>
    </dxo-summary>
  </dx-data-grid>
</div>


<dx-popup class="popup" [width]="185" [height]="130" [showTitle]="true" title="" [dragEnabled]="true"
  [hideOnOutsideClick]="true" [(visible)]="approvePopupVisible" showCloseButton="true">
  <div *dxTemplate="let data of 'content'">
    <dx-button id="generateButton" text="Approve Invoice" type="success" (onClick)="approveInvoiceGo()">
    </dx-button>
  </div>
</dx-popup>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#employee' }" [(visible)]="loadingFile"
  [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>


<!-- <js-extra-select *ngIf="addExtraVisible" [purchaseOrderId]="purchaseOrderId" [poNumber]="poNumber"
  (popupClose)="extrasClose($event)"></js-extra-select> -->

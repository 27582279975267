<dx-popup *ngIf="!loading" class="popup" [width]="popupWidth" [height]="popupHeight" [showTitle]="true"
  title="Email/Note" [dragEnabled]="true" [hideOnOutsideClick]="true" [visible]="true" [shading]="false"
  [showCloseButton]="true" (onHidden)="close()">
  <div *dxTemplate="let data of 'content'">
    <dxi-item>
      <dx-box direction="row" class="p-t-10 full-width">
        <dxi-item [ratio]="1">
          <div>
            <dx-form id="form" [(formData)]="jobEmail" [readOnly]="true" [showColonAfterLabel]="true"
              validationGroup="formData" labelLocation="left" [colCount]="colCount">

              <dxi-item dataField="fromEmail" editorType="dxTextBox" [colSpan]="2">
                <dxo-label location="left" alignment="right" text="From">
                </dxo-label>
              </dxi-item>

              <dxi-item dataField="date" editorType="dxDateBox" [editorOptions]="{ width: '120px', type: 'date' }"
                [colSpan]="1">
                <dxo-label location="left" alignment="right" text="Dated">
                </dxo-label>
              </dxi-item>

              <dxi-item dataField="toEmail" editorType="dxTextBox" [colSpan]="2">
                <dxo-label location="left" alignment="right" text="To">
                </dxo-label>
              </dxi-item>

              <dxi-item dataField="createDate" editorType="dxDateBox" [editorOptions]="{ width: '120px', type: 'date' }"
                [colSpan]="1">
                <dxo-label location="left" alignment="right" text="Saved">
                </dxo-label>
              </dxi-item>

              <dxi-item *ngIf="showCC" dataField="ccToEmail" editorType="dxTextBox" [colSpan]="colCount">
                <dxo-label location="left" alignment="right" text="CC">
                </dxo-label>
              </dxi-item>

              <dxi-item dataField="subject" editorType="dxTextBox" [colSpan]="colCount">
                <dxo-label location="left" alignment="right" text="Subject">
                </dxo-label>
              </dxi-item>
            </dx-form>
          </div>
        </dxi-item>

        <dxi-item [ratio]="0" [baseSize]="baseSize" margin-left="2" *ngIf="hasAttachments">
          <dx-list id="attachmentList" [dataSource]="attachments" [style.height.px]="attachmentsHeight">
            <div *dxTemplate="let data of 'item'">
              <dx-button icon="download" [text]="data.fileName" [height]="25"
                (click)="downloadFile(data.fileName)"></dx-button>
            </div>
          </dx-list>
        </dxi-item>
      </dx-box>
    </dxi-item>

    <dxi-item class="email-container" [style.height.px]="bodyHeight">
      <div [innerHTML]="bodyText"></div>
    </dxi-item>

    <dxi-item *ngIf="showSharepointLink">
      <dx-button text="Download Email" (click)="downloadFromSharepoint()"></dx-button>
    </dxi-item>
  </div>
</dx-popup>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingFile" [showIndicator]="true"
  [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>

<div class="page">
  <dx-data-grid id="dataGrid" class="max-w-800 m-auto maintenancegrid-height" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" (onInitNewRow)="onInitNewRow($event)" (onToolbarPreparing)="onToolbarPreparing($event)">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <!-- <dxo-export [enabled]="true"></dxo-export> -->
    <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>

    <!-- refresh mode full so that the tamplate task form gets the latest list -->
    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="full">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxi-column dataField="description">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="trackingWorkFlowTypeId" caption="Workflow Type" [width]="160">
      <dxo-lookup [dataSource]="trackingWorkFlowTypes" displayExpr="description" valueExpr="id"></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="isDefault" caption="Default" dataType='boolean' [width]="80">
    </dxi-column>

    <dxi-column dataField="isActive" caption="Active" dataType='boolean' [width]="80">
    </dxi-column>
  </dx-data-grid>
</div>

<div class="modal-header">
  <h4 class="modal-title">Vendors for Trade/Region</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <div class="page">

    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <dx-data-grid *ngIf="!loading" id="dataGrid" class="m-auto" [dataSource]="dataSource" [allowColumnReordering]="true"
      [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
      [height]="gridHeight" (onInitNewRow)="onInitNewRow($event)" (onExporting)="gridService.onExporting($event, 'TradeRegionVendors')">

      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
      <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
        refreshMode="full">
      </dxo-editing>
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="true"> <dxo-search [enabled]="true"></dxo-search> </dxo-header-filter>
      <dxo-sorting mode="multiple"></dxo-sorting>

      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-grouping #expand [autoExpandAll]="false" [contextMenuEnabled]="true" expandMode="rowClick"></dxo-grouping>

      <dxi-column dataField="id" dataType="number" [visible]='false'> </dxi-column>

      <dxi-column dataField="tradeId" caption="Trade" dataType="number" [width]="250" sortOrder="asc">
        <dxo-lookup [dataSource]="trades" displayExpr="description" valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="tradeRegionId" caption="Region" dataType="number" [width]="150" sortOrder="asc">
        <dxo-lookup [dataSource]="tradeRegions" displayExpr="description" valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="vendorId" caption="Vendor" dataType="number" sortOrder="asc">
        <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="contactEmail" caption="Overriding Email" dataType="string">
      </dxi-column>
    </dx-data-grid>

  </div>
</div>

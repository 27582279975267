import { AuthService } from './../../../services/auth.service';
import { GlobalService } from './../../../services/global.service';
import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MaintenanceService } from '../../../services/felixApi/maintenance.service';
import { NotificationService } from '../../../services/notification.service';
import { TaskType } from '../../../dtos/task-type';
import { CompanyActivity } from '../../../dtos/company-activity';
import { CompanyService } from '../../../services/felixApi/company.service';
import { UserService } from '../../../services/felixApi/user.service';
import { User } from '../../../dtos/user';
import { CompanyRole } from '../../../dtos/companyRole';
import { UserTypeEnum } from '../../../dtos/user-type.enum';
import { Vendor } from '../../../dtos/vendor';
import { Trade } from '../../../dtos/trade';
import { CallUpDocsType } from '../../../dtos/call-up-docs-type';
import { Phase } from '../../../dtos/phase';
import { PriceFileItem } from '../../../dtos/price-file-item';
import CustomStore from 'devextreme/data/custom_store';
import Guid from 'devextreme/core/guid';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
const ExcelJS = require('exceljs');
import { saveAs } from 'file-saver';
import { TemplateTaskHeader } from '../../../dtos/templateTaskHeader';
import { TrackingWorkFlowTypeEnum } from '../../../dtos/tracking-work-flow-type.enum';
import { TemplateTaskCostCentreModalComponent } from '../template-task-cost-centre-modal/template-task-cost-centre-modal.component';
import { TemplateTaskPurchaseOrderComponent } from '../template-task-purchase-order/template-task-purchase-order.component';
import { TaskMaster } from '../../../dtos/task-master';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'js-template-task-modal',
  templateUrl: './template-task-modal.component.html',
  styleUrls: ['./template-task-modal.component.scss']
})
export class TemplateTaskModalComponent implements OnInit, OnDestroy {
  @Input() taskHeader: TemplateTaskHeader;

  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  loading = true;
  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  taskTypes: TaskType[];
  activities: CompanyActivity[];
  supplierTermsTypes = [{ id: 1, description: 'Statement' }, { id: 2, description: 'Invoice' }];
  companyRoles: CompanyRole[];
  users: User[];
  vendors: Vendor[];
  trades: Trade[];
  showCashFlowFields = false;
  showCallUpfields = false;
  showWorkflowFields = true;
  callUpDocsTypes: CallUpDocsType[];
  phases: Phase[] = [{ id: null, orderNo: 0, phaseCode: 'Default', description: 'Default' }];
  costCentres: PriceFileItem[];
  addendumCaption: string;
  newRowPosition = 'viewportTop';
  changes = [];
  editRowKey = null;
  fromRowAddId: number;
  editMode = 'row';
  popupVisible: boolean;
  fileValue: any[] = [];
  isAdmin = false;
  isCallUpAdmin = false;
  isWorkflowAdmin = false;
  isWorkflowWrite = false;
  trackingWorkFlowTypeEnum = TrackingWorkFlowTypeEnum;
  isCashFlowUsingPurchaseOrders: boolean;
  dropDownOptions: object;
  taskMasters: TaskMaster[];
  filteredTaskMasters: DataSource;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private maintenanceService: MaintenanceService,
    private companyService: CompanyService,
    private userService: UserService,
    private globalService: GlobalService,
    private authService: AuthService,
    private notiService: NotificationService) {
    this.getTaskList = this.getTaskList.bind(this);
    this.onInitNewRow = this.onInitNewRow.bind(this);
    this.calculateTaskDays = this.calculateTaskDays.bind(this);
    this.onAddButtonClick = this.onAddButtonClick.bind(this);
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
    this.calculateCostCentreSortValue = this.calculateCostCentreSortValue.bind(this);
  }

  ngOnInit() {
    this.isAdmin = this.authService.isAdminOrSuper();
    this.dropDownOptions = { width: 600 };

    if (this.isAdmin) {
      this.isCallUpAdmin = true;
      this.isWorkflowAdmin = true;
      this.isWorkflowWrite = true;
    } else {
      if (this.authService.getSelectionsPermissions('CallUps') === 'Admin') {
        this.isCallUpAdmin = true;
        this.showCallUpfields = true;
        this.showWorkflowFields = false;
      }

      if (this.authService.getSelectionsPermissions('Workflow') === 'Admin') {
        this.isWorkflowAdmin = true;
        this.isWorkflowWrite = true;
      } else if (this.authService.getSelectionsPermissions('Workflow') === 'Write') {
        this.isWorkflowWrite = true;
      }
    }

    this.addendumCaption = 'Attach ' + this.globalService.getAddendumName();
    this.getTaskData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getTaskData() {
    this.subscriptions.push(
      this.maintenanceService.getTaskData().subscribe({
        next: (taskTypes) => {
          this.taskTypes = taskTypes;
          this.activities = this.maintenanceService.activities;
          this.companyRoles = this.companyService.companyRoles;
          this.users = this.userService.users.filter(i => i.userTypeId !== UserTypeEnum.Client
            && i.userTypeId !== UserTypeEnum.Associate
            && i.isActive);
          this.vendors = this.userService.vendors.filter(i => i.canAttachOrdersToCallUps);
          this.trades = this.maintenanceService.trades;
          this.callUpDocsTypes = this.maintenanceService.callUpDocsTypes;
          this.phases = this.phases.concat(this.maintenanceService.phases);
          this.costCentres = this.maintenanceService.priceFileItemGroups.filter(i => !i.priceFileItemParentId && i.isActive);
          this.isCashFlowUsingPurchaseOrders = this.maintenanceService.taskControl.isCashFlowUsingPurchaseOrders;
          this.taskMasters = this.maintenanceService.taskMasters;

          this.loading = false;
          this.setUpDataSet();
        }, error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  setUpDataSet() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.getTemplateTasksWithCallUpDocs(this.taskHeader.id, true).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.addTemplateTask(this.taskHeader.id, values).subscribe({
            next: (res) => {
              // we auto create a dependency to the last item entered
              if (this.maintenanceService.templateTasks && this.maintenanceService.templateTasks.length) {
                let newRowId = this.maintenanceService.templateTasks[this.maintenanceService.templateTasks.length - 1].id;
                if (this.fromRowAddId) {
                  newRowId = this.fromRowAddId;
                  this.fromRowAddId = null;
                }
                const dependencyData = {
                  workFlowVersionNo: 1,
                  taskPredecessorId: newRowId,
                  taskSuccessorId: res.id,
                  dependencyTypeId: 0,
                  cannotSetStartIfPredecessorNotComplete: false
                };

                this.subscriptions.push(
                  this.maintenanceService.addTaskDependency(dependencyData)
                    .subscribe({
                      next: () => {
                        this.maintenanceService.templateTasks.push(res);
                        return resolve(res);
                      },
                      error: (err) => {
                        this.notiService.notify(err);
                        return resolve(res);
                      }
                    })
                );
              } else {
                return resolve(res);
              }
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        // console.log(JSON.stringify(values));
        return new Promise((resolve, reject) =>
          this.maintenanceService.updateTemplateTask(+encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.deleteTemplateTask(+encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onToolbarPreparing(e, toolbarTemplate: string, toolbarTemplate2: string) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: toolbarTemplate
    });

    toolbarItems.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          text: 'Extra POs',
          onClick: this.costCentrePurchaseOrders.bind(this)
        }
      });

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        template: toolbarTemplate2
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'upload',
          onClick: this.loadFromExcel.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          text: 'CC %s',
          onClick: this.costCentrePercentages.bind(this)
        }
      });
  }

  setCellValue(rowData, value) {
    if (value) {
      rowData.taskTypeId = value.id;
      rowData.taskMasterId = null;
      (<any>this).defaultSetCellValue(rowData, value);
    }
  }

  getTaskList(options) {
    this.filteredTaskMasters = new DataSource({
      key: 'id',
      loadMode: 'raw',
      load: () => this.taskMasters.filter(i => i.taskTypeId === options.data.taskTypeId)
    });
  }

  close() {
    this.activeModal.close();
  }

  showCashFlow() {
    this.showCashFlowFields = !this.showCashFlowFields;
  }

  calculateFilterExpression(filterValue, selectedFilterOperation, target) {
    if (target === 'search' && typeof (filterValue) === 'string') {
      return [(this as any).dataField, 'contains', filterValue];
    }
    return function (data) {
      return (data.AssignedEmployee || []).indexOf(filterValue) !== -1;
    };
  }

  cellTemplate(container, options) {
    const noBreakSpace = '\u00A0',
      text = (options.value || []).map(element => {
        return options.column.lookup.calculateCellValue(element);
      }).join(', ');
    container.textContent = text || noBreakSpace;
    container.title = text;
  }

  onInitNewRow(e) {
    e.data.sendPOWithCallUp = true;
    e.data.isAuthorisedByStartDate = false;
    e.data.orderNumber = 2; // dummy
    e.data.templateTaskHeaderId = this.taskHeader.id;
    e.data.canSetForcastToThis = true;
    e.data.isFormalApproval = false;
    e.data.isNextWorkFlowTrigger = false;
    e.data.isInitiatedBySalesDate = false;
    e.data.isForecastStart = false;
    e.data.isPracticalCompletionTask = false;
    e.data.isKeyHandoverTask = false;
    e.data.isAssignToPreviousUser = false;
    e.data.isMultipleAllowed = false;
    e.data.isCreatedByPostContractVO = false;
    e.data.isCreatedByConstructionVOApproved = false;
    e.data.copyCommentFromPrev = false;
    e.data.includeAddendum = false;
    e.data.doNotSendCallup = false;
    e.data.isUseWorkFlow = false;
    e.data.isCreatedBySalesVariation = false;
    e.data.isCreatedByPreContractVariation = false;
  }

  calculateTaskDays(data) {
    if (data && data.taskMasterId) {
      const taskMaster = this.maintenanceService.taskMasters.find(i => i.id === data.taskMasterId);
      return taskMaster?.days;
    }
  }

  isAddButtonVisible({ row }) {
    return !row.isEditing;
  }

  onAddButtonClick(e) {
    const key = new Guid().toString();
    this.changes = [{
      key,
      type: 'insert',
      insertAfterKey: e.row.key,
    }];
    this.editRowKey = key;
    this.fromRowAddId = e.row.key;
  }

  onRowInserted(e) {
    e.component.navigateToRow(e.key);
  }

  changEditMode() {
    if (this.dataGrid.instance.hasEditData() && this.editMode === 'batch') {
      this.notiService.showInfo('Please Save or Cancel the edited data');
    } else {
      if (this.editMode === 'row') {
        this.editMode = 'batch';
      } else {
        this.editMode = 'row';
      }
    }
  }

  loadFromExcel() {
    this.popupVisible = true;
  }

  uploadClick() {
    // load the file
    if (this.fileValue && this.fileValue.length) {
      this.loading = true;
      this.popupVisible = false;
      const formData: FormData = new FormData();
      formData.append('image', this.fileValue[0], this.fileValue[0].name);

      this.subscriptions.push(
        this.maintenanceService.uploadTemplateTasksExcel(this.taskHeader.id, formData).subscribe({
          next: () => {
            this.fileValue = [];
            this.loading = false;
            this.setUpDataSet();
          }, error: (err) => {
            this.notiService.notify(err);
            this.fileValue = [];
            this.loading = false;
          }
        })
      );
    }
  }

  calculateVendorSortValue(data) {
    return this.vendors.find(i => i.id === data.vendorId)?.vendorName;
  }

  calculateCostCentreSortValue(data) {
    return this.costCentres.find(i => i.id === data.costCentreId)?.orderNumber;
  }

  onExporting(e) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Tasks',
      { pageSetup: { paperSize: 9, orientation: 'landscape', showGridLines: true } });

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      keepColumnWidths: false,
      customizeCell: function (options) {
        const { gridCell, excelCell } = options;
        const column = gridCell.column;

        if (gridCell.rowType === 'data' && column.dataField === 'callUpDocsTypes') {
          const noBreakSpace = '\u00A0';
          const text = (gridCell.value || []).map((element) => column.lookup.calculateCellValue(element)).join(', ');
          excelCell.value = text || noBreakSpace;
        }
      }
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Workflow Tasks' + '.xlsx');
      });
    });
    e.cancel = true;
  }

  costCentrePercentages() {
    const modalRef = this.modalService.open(TemplateTaskCostCentreModalComponent, { windowClass: 'modal-800' });
    modalRef.componentInstance.templateTaskHeaderId = this.taskHeader.id;

    modalRef.result.then(() => { }, () => { });
  }

  costCentrePurchaseOrders() {
    const modalRef = this.modalService.open(TemplateTaskPurchaseOrderComponent, { windowClass: 'modal-1000' });
    modalRef.componentInstance.templateTaskHeaderId = this.taskHeader.id;

    modalRef.result.then(() => { }, () => { });
  }

  onSelectionChanged(cellInfo, e, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0]);
      e.component.close();
    }
  }

  setTaskMasterCellValue(rowData, value) {
    if (value) {
      rowData.taskMasterId = value.id;
    } else {
      rowData.taskMasterId = null;
    }
  }
}

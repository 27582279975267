import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-task-types',
  templateUrl: './task-types.component.html',
  styleUrls: ['./task-types.component.scss']
})
export class TaskTypesComponent implements OnInit, OnDestroy {

  dataSource: CustomStore;
  subscriptions: Subscription[] = [];

  constructor(
    private maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private notiService: NotificationService
  ) {
    this.onReorder = this.onReorder.bind(this);
  }

  ngOnInit(): void {
    this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.getTaskTypes(false).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.addTaskType(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.updateTaskType(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.deleteTaskType(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();
    const newOrderIndex = visibleRows[e.toIndex].data.orderNumber;

    this.subscriptions.push(
      this.maintenanceService.moveTaskType(e.itemData.id, newOrderIndex).subscribe({
        next: () => {
          e.component.refresh();
        }, error: () => {
          this.notiService.showInfo('Cannot drop here');
        }
      })
    );
  }
}

<div class="page">

  <js-job-number (refreshJobNumber)='onJobNumberChanged($event)'></js-job-number>

  <dx-tab-panel *ngIf="jobNumber" #panel height="calc(100vh - 100px)" [dataSource]="sections" [selectedIndex]="0"
    [loop]="false" [repaintChangesOnly]="false" [swipeEnabled]="false" (onTitleClick)="tabSelected($event)">

    <div *dxTemplate="let title of 'title'">
      <span [ngClass]="{'header': title == selectedSection}">{{title}}</span>
    </div>

    <div *dxTemplate="let section of 'item'">
      <!-- <ng-container [ngSwitch]="section"> -->
      <div style="height: calc(100vh - 100px);">
        <js-job-fields *ngIf="section === 'Job-Data'" [jobNumber]="jobNumber"></js-job-fields>
        <js-job-emails *ngIf="section === 'Emails/Notes'" [jobNumber]="jobNumber"></js-job-emails>
        <js-job-work-flow *ngIf="section === 'Workflows'" [jobNumber]="jobNumber"></js-job-work-flow>
        <js-job-gantt *ngIf="section === 'Gantt-Chart'" [jobNumber]="jobNumber"></js-job-gantt>
        <js-job-key-tasks *ngIf="section === 'Job-Tasks'" [jobNumber]="jobNumber"></js-job-key-tasks>
        <js-job-task-log *ngIf="section === 'Task-Log'" [jobNumber]="jobNumber"></js-job-task-log>
      </div>
      <!-- </ng-container> -->
    </div>
  </dx-tab-panel>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksComponent } from './tasks.component';
import { SharedModule } from '../shared/shared.module';
import { CancelJobTasksComponent } from './cancel-job-tasks/cancel-job-tasks.component';
import { ReassignJobTasksComponent } from './reassign-job-tasks/reassign-job-tasks.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    TasksComponent,
    CancelJobTasksComponent,
    ReassignJobTasksComponent
  ]
})
export class TasksModule { }

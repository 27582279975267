<div class="modal-header">
  <h4 class="modal-title">Extra Purchase Orders</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" id="dataGrid" class="m-auto" [dataSource]="dataSource" [allowColumnReordering]="true"
    [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    (onExporting)="gridService.onExporting($event)">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="full">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-header-filter [visible]="true"> <dxo-search [enabled]="true"></dxo-search> </dxo-header-filter>

    <dxi-column dataField="templateTaskId" dataType="number" caption="Task" sortOrder="asc">
      <dxo-lookup [dataSource]="templateTasks" displayExpr="taskTitle" valueExpr="id">
      </dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="costCentreId" dataType="number" caption="Cost Centre" sortOrder="asc">
      <dxo-lookup [dataSource]="costCentres" displayExpr="description" valueExpr="id">
      </dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="phaseId" caption="Phase" dataType="number" width="110" sortOrder="asc">
      <dxo-lookup [dataSource]="phases" displayExpr="phaseCode" valueExpr="id" [allowClearing]="true">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="vendorId" caption="Vendor" dataType="number" sortOrder="asc">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id" [allowClearing]="true">
      </dxo-lookup>
    </dxi-column>
  </dx-data-grid>
</div>

<div class="modal-footer">
  <!-- <button class="btn btn-secondary" (click)="close()">Close</button> -->
</div>

<dx-popup class="popup" [width]="popupWidth" [height]="sendGridHeight" [showTitle]="true" title="Extra POs"
  [dragEnabled]="false" [hideOnOutsideClick]="true" [visible]="true" (onHidden)="onHidden()" [showCloseButton]="true">
  <dx-data-grid #purchaseOrdersGrid id="purchaseOrdersGrid" [dataSource]="dataSource" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [allowColumnReordering]="false"
    [allowColumnResizing]="false" [columnAutoWidth]="false" [height]="sendGridHeight - 100"
    [(focusedRowKey)]="firstPurchaseOrderId" [autoNavigateToFocusedRow]="true">

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-editing mode="batch" [allowUpdating]="true" [useIcons]="true" startEditAction="click"> </dxo-editing>
    <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>

    <dxi-column dataField="id" [visible]='false'></dxi-column>

    <dxi-column dataField="poNumber" caption="PO#" [width]="100" sortOrder="asc"></dxi-column>

    <dxi-column dataField="costCentreId" dataType="number" caption="Cost Code"
      [calculateSortValue]="calculateCostCentreSortValue">
      <dxo-lookup [dataSource]="costCentres" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="vendorId" dataType="number" caption="Vendor" [calculateSortValue]="calculateVendorSortValue">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="selected" caption="Send" dataType="boolean" [width]="60"></dxi-column>

    <dxi-column dataField="showPrices" dataType="boolean" [width]="60"></dxi-column>

    <dxi-column type="buttons" [width]="50">
      <dxi-button icon="download" [onClick]="openPO" hint='Download PO'>
      </dxi-button>
    </dxi-column>
  </dx-data-grid>
</dx-popup>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingVisible" [showIndicator]="true"
  [showPane]="true" [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>

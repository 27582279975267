import { NotificationService } from './../../services/notification.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { UtilsService } from '../../services/utils.service';
import { ReportGridItem } from '../report-grid-item.type';
import { ReportGridService } from '../report-grid.service';

@Component({
  selector: 'js-column-chooser',
  templateUrl: './column-chooser.component.html',
  styleUrls: ['./column-chooser.component.scss']
})
export class ColumnChooserComponent implements OnInit, OnDestroy {
  localExtraColumns: ReportGridItem[];
  localGridColumns: ReportGridItem[];
  localHiddenColumns: ReportGridItem[];

  subscriptions: Subscription[] = [];
  showLoadDefaultButton: boolean;
  savePopupVisible = false;
  desktopSize = false;
  currentState: any;
  filterValue: string;

  constructor(
    public reportGridService: ReportGridService,
    private utils: UtilsService,
    private globalService: GlobalService,
    private _activeModal: NgbActiveModal,
    private notiService: NotificationService
  ) { }

  ngOnInit() {
    this.reportGridService.sortExtraCols();
    this.subscribeToWidthChanges();
    this.localExtraColumns = this.reportGridService.extraColumns;
    this.localGridColumns = this.reportGridService.gridColumns.filter(c => c.visible);
    this.localHiddenColumns = this.reportGridService.gridColumns.filter(c => !c.visible);

    this.currentState = JSON.parse(localStorage.getItem('allTaskGrid'));
    this.filterValue = JSON.stringify(this.currentState.filterValue);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onDragStart(e) {
    e.itemData = e.fromData[e.fromIndex];
  }

  onAdd = (e) => {
    // TODO: sort dropped item into correct available-items position?
    // virtual scrolling means the index numbers given are wrong so needs xtra work
    // if (e.element.id === "extraColumns") {
    //   this.utils.placeItemIntoSortedPosition(e.toData, e.itemData, 'caption');

    let validMove = true;

    if (e.element.id === 'hiddenColumns') {
      e.itemData.visible = false;
    } else if (e.element.id === 'gridColumns') {
      e.itemData.visible = true;
    } else {
      const currentColumn = this.currentState.columns.find(i => i.dataField === e.itemData.dataField);
      if (currentColumn
        && (currentColumn.filterValue || currentColumn.filterValues || this.filterValue.includes(currentColumn.dataField))) {
        this.notiService.showError('Cannot remove a field that has a filter defined');
        validMove = false;
      }
      e.itemData.visible = false;
    }

    if (validMove) {
      const itemPosition = e.fromData.findIndex(i => i.dataField === e.itemData.dataField);
      e.fromData.splice(itemPosition, 1);
      e.toData.push(e.itemData);
    }
  }

  // onRemove(e) {
  //   e.fromData.splice(e.fromIndex, 1);
  // }

  reorder = (e) => {
    this.utils.moveArrayItemToNewIndex(e.fromData, e.fromIndex, e.toIndex);
  }

  apply() {
    this.reportGridService.extraColumns = this.localExtraColumns;
    this.reportGridService.gridColumns = this.localGridColumns.concat(this.localHiddenColumns);
    this._activeModal.close();
  }

  close() {
    this._activeModal.close();
  }

  private subscribeToWidthChanges() {
    this.desktopSize = this.globalService.desktopSize;

    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe((width: number) => {
        this.desktopSize = this.globalService.desktopSize;
      })
    );
  }
}

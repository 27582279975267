import { TrackingWorkFlowTypeEnum } from './../../dtos/tracking-work-flow-type.enum';
import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { TemplateTask } from '../../dtos/templateTask';
import { NotificationService } from '../../services/notification.service';
import { TaskDependency } from '../../dtos/task-dependency';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateTaskModalComponent } from './template-task-modal/template-task-modal.component';
import { TemplateTaskHeader } from '../../dtos/templateTaskHeader';
import ArrayStore from 'devextreme/data/array_store';
import { GlobalService } from '../../services/global.service';
import { debounceTime } from 'rxjs/operators';
import { CopyTasksModalComponent } from './copy-tasks-modal/copy-tasks-modal.component';
import { DxGanttComponent } from 'devextreme-angular/ui/gantt';
import { jsPDF } from 'jspdf';
import { exportGantt as exportGanttToPdf } from 'devextreme/pdf_exporter';
import 'jspdf-autotable';
import { formatDate } from 'devextreme/localization';
import { UpdateJobsComponent } from './update-jobs/update-jobs.component';
import { TaskDependencyTypeEnum } from '../../dtos/task-dependency-type.enum';

@Component({
  selector: 'js-template-tasks',
  templateUrl: './template-tasks.component.html',
  styleUrls: ['./template-tasks.component.scss']
})
export class TemplateTasksComponent implements OnInit, OnChanges, OnDestroy {
  @Input() counter: number; // to force on changes

  @ViewChild(DxGanttComponent, { static: false }) gantt: DxGanttComponent;

  loading = true;
  loadingTasks = false;
  taskDataLoaded = false;
  subscriptions: Subscription[] = [];
  tasks: TemplateTask[];
  dependencies: TaskDependency[];
  originalDependencies: TaskDependency[];
  updateRecord: any;
  selectedTaskHeader: TemplateTaskHeader;
  taskHeaderId: number;
  gridHeight: number;
  tabs = [
    { text: 'Gantt' },
    { text: 'Diagram' }
  ];
  selectedIndex: number;
  flowNodesDataSource: ArrayStore;
  flowEdgesDataSource: ArrayStore;
  timeScale = 'months';
  taskHeaders: TemplateTaskHeader[];
  printSettingsPopupVisible = false;
  formats: string[] = ['A0', 'A1', 'A2', 'A3', 'A4', 'Auto'];
  exportModes: string[] = ['All', 'Chart', 'Tree List'];
  dateRanges: string[] = ['All', 'Visible', 'Custom'];
  formatBoxValue: string;
  exportModeBoxValue: string;
  dateRangeBoxValue: string;
  landscapeCheckBoxValue: boolean;
  startTaskIndex = 0;
  endTaskIndex = 0;
  startDate: Date;
  endDate: Date;
  customRangeDisabled: boolean;
  templateDescription: string;
  showTitles = true;
  titleColumnWidth = 660;
  showTitleColumnsOptions: { text: string; stylingMode: string; onClick: () => void; };
  exportButtonOptions: { text: string; stylingMode: string; onClick: () => void; };
  workflowWeeks: number;
  startDateString: string;
  workflowDays: number;
  completionTask = '';
  showTitlesText = 'Hide Title Columns';
  canUpdateJobs: boolean;
  groupByTypes = false;
  contextMenuItems: {};
  taskHeader: TemplateTaskHeader;
  workflowMessageWidth = 500;

  constructor(public maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    private modalService: NgbModal,
    private notiService: NotificationService) {

    this.openPrintSettings = this.openPrintSettings.bind(this);
    this.getJustDependency = this.getJustDependency.bind(this);

    this.exportButtonOptions = {
      text: 'Export to PDF',
      stylingMode: 'text',
      onClick: () => this.openPrintSettings(),
    };
  }

  ngOnInit() {
    this.resizeGrid();
    this.showTitleColumnsOptions = {
      text: this.showTitlesText,
      stylingMode: 'text',
      onClick: () => this.showTitleColumns(),
    };

    this.formatBoxValue = this.formats[4];
    this.landscapeCheckBoxValue = true;
    this.exportModeBoxValue = this.exportModes[0];
    this.dateRangeBoxValue = this.dateRanges[1];
    this.startTaskIndex = 0;
    this.customRangeDisabled = true;
    this.contextMenuItems = this.getContextMenuItems();
  }

  ngOnChanges() {
    this.gridHeight = this.globalService.innerHeight - 230;
    this.selectedIndex = 0;
    this.subscribeToResizeChanges();
    this.getTemplateHeaders();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getContextMenuItems() {
    return [
      'deleteDependency',
    ];
  }

  subscribeToResizeChanges() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.pipe(debounceTime(500)).subscribe(() => {
        this.resizeGrid();
      })
    );
  }

  resizeGrid() {
    this.gridHeight = this.globalService.innerHeight - 230;
    this.workflowMessageWidth = window.innerWidth - 1000;
    if (this.workflowMessageWidth < 500) {
      this.workflowMessageWidth = 0;
    }
    this.refresh();
  }

  getTemplateHeaders() {
    this.loading = true;
    this.subscriptions.push(
      this.maintenanceService.getTemplateTaskHeaders(true, true)
        .subscribe({
          next: (taskHeaders) => {
            this.taskHeaders = taskHeaders.filter(i => i.isActive);
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  getData(e) {
    this.taskDataLoaded = false;
    // this.canUpdateJobs = false;
    if (e && e.selectedItem) {
      this.taskHeaderId = e.selectedItem.id;
      this.taskHeader = this.taskHeaders.find(i => i.id === this.taskHeaderId);

      this.templateDescription = this.taskHeader?.description;
      this.canUpdateJobs = this.taskHeader?.trackingWorkFlowTypeId === TrackingWorkFlowTypeEnum.Construction
        || this.taskHeader?.trackingWorkFlowTypeId === TrackingWorkFlowTypeEnum.Maintenance;
    }
    this.loadingTasks = true;
    this.subscriptions.push(
      this.maintenanceService.getTemplateTaskData(this.taskHeaderId, true)
        .subscribe({
          next: (tasks) => {
            this.tasks = tasks;

            if (this.groupByTypes) {
              tasks.forEach(task => {
                task.parentTaskId = -task.taskTypeId;

                const parentTask = this.tasks.find(i => i.id === task.parentTaskId);
                if (!parentTask) {
                  const taskType = this.maintenanceService.taskTypes.find(i => i.id === task.taskTypeId);
                  this.tasks.push(new TemplateTask(task.parentTaskId, taskType.description));
                }
              });
            }

            if (tasks && tasks.length) {
              this.startDate = this.tasks[0].start;

              let lastTask = this.tasks.find(i => i.isPracticalCompletionTask);
              if (!lastTask) {
                lastTask = this.tasks.find(i => i.isNextWorkFlowTrigger);
              }
              if (lastTask) {
                this.endDate = lastTask.end;
                this.completionTask = lastTask.taskTitle;

                this.endTaskIndex = tasks && tasks.length ? tasks.length - 1 : 0;

                let startDateString = '';
                if (this.startDate instanceof Date) {
                  startDateString = formatDate(this.startDate, 'yyyy-MM-dd');
                } else {
                  startDateString = this.startDate;
                }
                const startDate = new Date(+startDateString.substr(0, 4),
                  +startDateString.substr(5, 2) - 1, +startDateString.substr(8, 2), 0, 0, 0, 0);

                let endDateString = '';
                if (this.endDate instanceof Date) {
                  endDateString = formatDate(this.endDate, 'yyyy-MM-dd');
                } else {
                  endDateString = this.endDate;
                }
                const endDate = new Date(+endDateString.substr(0, 4),
                  +endDateString.substr(5, 2) - 1, +endDateString.substr(8, 2), 0, 0, 0, 0);

                const workFlowTotalTime = (endDate?.getTime() - startDate?.getTime()) / (1000 * 3600 * 24);
                this.workflowWeeks = Math.round(((workFlowTotalTime / 7) + Number.EPSILON) * 100) / 100;
                this.workflowDays = this.workflowWeeks % 1;
                this.workflowWeeks = this.workflowWeeks - this.workflowDays;
                this.workflowDays = Math.round(this.workflowDays * 7);
              } else {
                this.completionTask = '';
              }
            }
            this.dependencies = this.maintenanceService.taskDependencies;

            this.originalDependencies = this.dependencies.map(this.getJustDependency);

            // for diagram
            this.flowNodesDataSource = new ArrayStore({
              key: 'id',
              data: tasks
            });
            this.flowEdgesDataSource = new ArrayStore({
              key: 'id',
              data: this.originalDependencies
            });

            this.loadingTasks = false;
            this.taskDataLoaded = true;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingTasks = false;
          }
        })
    );
  }

  getJustDependency(v: TaskDependency, index, self) {
    return {
      id: v.id,
      workFlowVersionNo: v.workFlowVersionNo,
      taskPredecessorId: v.taskPredecessorId,
      taskSuccessorId: v.taskSuccessorId,
      dependencyTypeId: v.dependencyTypeId,
      offsetDays: v.offsetDays,
      dependencyQuestion: v.dependencyQuestion,
      cannotSetStartIfPredecessorNotComplete: v.cannotSetStartIfPredecessorNotComplete,
      modifiedUserId: v.modifiedUserId,
      modifiedDate: v.modifiedDate,
      dependencyText: v.dependencyTypeId === TaskDependencyTypeEnum.StartToStart ? 'Start' : null
    };
  }

  addEditTasks() {
    const modalRef = this.modalService.open(TemplateTaskModalComponent,
      { windowClass: 'modal-infopdf', scrollable: true });
    modalRef.componentInstance.taskHeader = this.taskHeader;

    modalRef.result.then(() => {
      this.loadingTasks = true;
      this.getData(null);
    }, () => {
      this.loadingTasks = true;
      this.getData(null);
    });
  }

  copyTasks() {
    const modalRef = this.modalService.open(CopyTasksModalComponent,
      { windowClass: 'modal-lg', scrollable: true });
    modalRef.componentInstance.taskHeaderId = this.taskHeaderId;

    modalRef.result.then(() => {
      this.loadingTasks = true;
      this.getData(null);
    }, () => {
    });
  }

  refresh() {
    this.loading = true;
    this.getTemplateHeaders();

    if (this.taskHeaderId) {
      this.loadingTasks = true;
      this.getData(null);
    }
  }

  onTaskEditDialogShowing(e) {
    e.cancel = true;
  }

  onDependencyDeleting(e) {
    this.subscriptions.push(
      this.maintenanceService.deleteTaskDependency(e.values.taskPredecessorId, e.values.taskSuccessorId)
        .subscribe({
          next: () => {
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  onDependencyInserting(e) {
    this.updateRecord = {
      workFlowVersionNo: 1,
      taskPredecessorId: e.values.taskPredecessorId,
      taskSuccessorId: e.values.taskSuccessorId,
      dependencyTypeId: e.values.dependencyTypeId,
      CannotSetStartIfPredecessorNotComplete: false
    };

    this.subscriptions.push(
      this.maintenanceService.addTaskDependency(this.updateRecord)
        .subscribe({
          next: (res) => {
            this.dependencies.push(res);
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  openPrintSettings() {
    this.printSettingsPopupVisible = true;
  }

  exportButtonClick() {
    const gantt = this.gantt.instance;
    const format = this.formatBoxValue;
    const isLandscape = this.landscapeCheckBoxValue;
    const exportMode = this.getExportMode();
    const dataRangeMode = this.dateRangeBoxValue.toLowerCase();
    let dataRange;
    if (dataRangeMode === 'custom') {
      dataRange = {
        startIndex: this.startTaskIndex,
        endIndex: this.endTaskIndex,
        startDate: this.startDate,
        endDate: this.endDate,
      };
    } else {
      dataRange = dataRangeMode;
    }

    exportGanttToPdf(
      {
        component: gantt,
        createDocumentMethod: (args?: any) => new jsPDF(args),
        format,
        landscape: isLandscape,
        exportMode,
        dateRange: dataRange,
      },
    ).then((doc) => doc.save('gantt.pdf'));
  }

  getExportMode() {
    if (this.exportModeBoxValue === 'Tree List') { return 'treeList'; }
    if (this.exportModeBoxValue === 'All') { return 'all'; }
    if (this.exportModeBoxValue === 'Chart') { return 'chart'; }
    return 'all';
  }

  onDateRangeBoxSelectionChanged(e) {
    this.customRangeDisabled = e.value !== 'Custom';
    // this.ref.detectChanges();
  }

  showTitleColumns() {
    this.taskDataLoaded = false;
    setTimeout(() => {
      if (this.showTitles) {
        this.titleColumnWidth = 1;
        this.showTitlesText = 'Show Title Columns';
      } else {
        this.titleColumnWidth = 660;
        this.showTitlesText = 'Hide Title Columns';
      }

      this.showTitleColumnsOptions = {
        text: this.showTitlesText,
        stylingMode: 'text',
        onClick: () => this.showTitleColumns(),
      };

      this.showTitles = !this.showTitles;
      this.taskDataLoaded = true;
    }, 10); // wait for grid
  }

  itemTextStyleExpr(obj) {
    return { 'font-size': 11 };
  }

  updateJobs() {
    const modalRef = this.modalService.open(UpdateJobsComponent,
      { windowClass: 'modal-1400', scrollable: true });
    modalRef.componentInstance.taskHeaderId = this.taskHeaderId;

    modalRef.result.then(() => { });
  }
}

import { Component, OnInit } from '@angular/core';
import { MaintenanceHelperService } from '../maintenance-helper.service';

@Component({
  selector: 'js-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  sections: string[];
  counter = 0;
  documentsConfigValue: number;
  documentsActive: boolean;

  constructor(public helper: MaintenanceHelperService) { }

  ngOnInit() {
    this.helper.selectedSection = 'Tasks';
    this.sections = this.helper.sections;
    // this.documentsConfigValue = this.globalService.getCompanyConfigValue(ConfigurationEnum.DocumentManagementSystem);

    // if (this.documentsConfigValue) {
    //   const docSection = this.sections.find(i => i === 'Document Folders');

    //   if (!docSection) {
    //     this.sections.push('Document Folders');
    //   }
    // }
  }

  tabSelected(e) {
    this.counter++;
    this.helper.selectedSection = e.itemData;
  }
}

<div class="modal-header">
  <h4 class="modal-title">Column Chooser</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <div aria-hidden="true">&times;</div>
  </button>
</div>


<div class="modal-body">
  <div class="page">
    <div class="widget-container">
      <dx-data-grid [ngClass]="{'m-r-10' : desktopSize}" id="extraColumns" [dataSource]="localExtraColumns"
        [showBorders]="true" [wordWrapEnabled]="true">
        <dxo-row-dragging [data]="localExtraColumns" group="tasksGroup" allowReordering="false" [onAdd]="onAdd">
        </dxo-row-dragging>
        <dxo-search-panel visible="true" [width]="200" searchVisibleColumnsOnly="true" highlightSearchText="false"
          placeholder="Search..."></dxo-search-panel>
        <dxo-scrolling mode='virtual'></dxo-scrolling>
        <dxo-paging [enabled]="false"></dxo-paging>

        <dxi-column dataField="caption" caption="Available Columns" dataType="string"></dxi-column>
      </dx-data-grid>

      <dx-data-grid id="gridColumns" [dataSource]="localGridColumns" [showBorders]="true" [wordWrapEnabled]="true">
        <dxo-row-dragging [onReorder]="reorder" [data]="localGridColumns" group="tasksGroup" allowReordering="true"
          [onAdd]="onAdd"></dxo-row-dragging>
        <dxo-search-panel visible="true" [width]="200" searchVisibleColumnsOnly="true" highlightSearchText="false"
          placeholder="Search..."></dxo-search-panel>
        <dxo-scrolling mode='virtual'></dxo-scrolling>
        <dxo-paging [enabled]="false"></dxo-paging>

        <dxi-column dataField="caption" caption="Grid Columns" dataType="string"></dxi-column>
      </dx-data-grid>

      <dx-data-grid [ngClass]="{'m-r-10' : desktopSize}" id="hiddenColumns" [dataSource]="localHiddenColumns"
        [showBorders]="true" [wordWrapEnabled]="true">
        <dxo-row-dragging [data]="localHiddenColumns" group="tasksGroup" allowReordering="false" [onAdd]="onAdd">
        </dxo-row-dragging>
        <dxo-search-panel visible="true" [width]="200" searchVisibleColumnsOnly="true" highlightSearchText="false"
          placeholder="Search..."></dxo-search-panel>
        <dxo-scrolling mode='virtual'></dxo-scrolling>
        <dxo-paging [enabled]="false"></dxo-paging>

        <dxi-column dataField="caption" caption="Hidden Columns (for filtering only)" dataType="string"></dxi-column>
      </dx-data-grid>
    </div>
  </div>
</div>

<div class="modal-footer">
  <span>Note: Set the order of the fields in the report grid itself. Ordering the fields here has no affect</span>
  <button class="btn btn-danger" (click)="close()">Cancel</button>
  <button class="btn btn-primary" (click)="apply()">Apply</button>
</div>

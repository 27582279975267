<div class="modal-header">
  <h4 class="modal-title">Variation Workflow Triggers</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <div class="page">

    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <dx-data-grid *ngIf="!loading" id="dataGrid" class="m-auto" [dataSource]="dataSource" [allowColumnReordering]="true"
      [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
      [height]="gridHeight" (onExporting)="gridService.onExporting($event, 'Variation-Workflows')"
      (onInitNewRow)="onInitNewRow($event)">

      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
      <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
        refreshMode="reshape">
      </dxo-editing>
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="true"> <dxo-search [enabled]="true"></dxo-search> </dxo-header-filter>
      <dxo-sorting mode="multiple"></dxo-sorting>

      <dxi-column dataField="templateTaskHeaderId" caption="Workflow to trigger" dataType="number" sortOrder="asc"
        [minWidth]="100">
        <dxo-lookup [dataSource]="taskHeaders" displayExpr="description" valueExpr="id">
        </dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="variationTypeId" caption="Variation Type" dataType="number" [width]="300" sortOrder="asc">
        <dxo-lookup [dataSource]="variationTypes" displayExpr="description" valueExpr="id">
        </dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="variationStatusId" caption="Status" dataType="number" [width]="150" sortOrder="asc">
        <dxo-lookup [dataSource]="variationStatuses" displayExpr="description" valueExpr="id">
        </dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="divisionId" caption="Divison (blank for all)" dataType="number" [width]="200"
        [editorOptions]="{ showClearButton: true }">
        <dxo-lookup [dataSource]="divisions" displayExpr="description" valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="askUserToCreate" dataType="boolean" [width]="110"> </dxi-column>

      <dxi-column dataField="modifiedUserId" caption="Modified By" dataType="number" [allowEditing]="false"
        [width]="180">
        <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="modifiedDate" caption="Modified" dataType="date" [allowEditing]="false" [width]="100"
        alignment="center">
      </dxi-column>
    </dx-data-grid>

  </div>
</div>

<div class="modal-header">
  <h2 class="modal-title">Admin: Cancel Open Job Tasks</h2>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <js-loading-spinner *ngIf="deleteInProgress"></js-loading-spinner>

  <div *ngIf="!deleteInProgress">
    <js-job-number [smallWindow]="true" (refreshJobNumber)='onJobNumberChanged()'></js-job-number>
    <br>
    <br>
    <div>Cancel any incomplete job tasks for this job.</div>
    <br>
    <span class="me-1">Cancel tasks that have a start date?</span>
    <dx-check-box [(value)]="cancelStartedTasks" [width]="30"> </dx-check-box>
    <br>
    <span class="me-1">Cancel tasks in a maintenance workflow?</span>
    <dx-check-box [(value)]="cancelMaintenanceTasks" [width]="30"> </dx-check-box>
  </div>
</div>

<div *ngIf="!deleteInProgress" class="modal-footer">
  <button class="btn btn-sm btn-danger" (click)="runCancel()" [disabled]="!jobId">Cancel Tasks</button>
</div>

<div class="modal-header">
  <h2 class="modal-title">{{modalTitle}}</h2>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-check-box *ngIf="allowNonHtml" [(value)]="isHtml" text="Use HTML" class="mb-1"></dx-check-box>

  <dx-text-area *ngIf="!isHtml" [(value)]="htmlText" height="400px"></dx-text-area>

  <dx-html-editor *ngIf="isHtml" #editor height="400px" class="full-width" (onFocusOut)="editorFocusOut()"
    [value]="htmlText">
    <dxo-toolbar [multiline]="true">
      <dxi-item name="undo"></dxi-item>
      <dxi-item name="redo"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="size"
        [acceptedValues]="['6pt', '7pt', '8pt', '9pt', '10pt', '11pt', '12pt', '14pt', '16pt', '18pt', '24pt', '36pt']">
      </dxi-item>
      <dxi-item name="font" [acceptedValues]="[
        'Arial', 'Calibri', 'Courier New', 'Georgia', 'Impact', 'Lucida Console',
        'Tahoma', 'Times New Roman', 'Verdana'
      ]"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="bold"></dxi-item>
      <dxi-item name="italic"></dxi-item>
      <dxi-item name="strike"></dxi-item>
      <dxi-item name="underline"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="alignLeft"></dxi-item>
      <dxi-item name="alignCenter"></dxi-item>
      <dxi-item name="alignRight"></dxi-item>
      <dxi-item name="alignJustify"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="orderedList"></dxi-item>
      <dxi-item name="bulletList"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="color"></dxi-item>
      <dxi-item name="background"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="link"></dxi-item>
      <dxi-item name="image"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="clear"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="insertTable"></dxi-item>
      <dxi-item name="deleteTable"></dxi-item>
      <dxi-item name="insertRowAbove"></dxi-item>
      <dxi-item name="insertRowBelow"></dxi-item>
      <dxi-item name="deleteRow"></dxi-item>
      <dxi-item name="insertColumnLeft"></dxi-item>
      <dxi-item name="insertColumnRight"></dxi-item>
      <dxi-item name="deleteColumn"></dxi-item>
    </dxo-toolbar>
    <dxo-media-resizing [enabled]="true"> </dxo-media-resizing>
  </dx-html-editor>

  <!-- <div>Fields: [Date]</div> -->
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-primary" (click)="close()">Ok</button>
</div>

import { DocumentService } from './../../services/felixApi/document.service';
import { TaskService } from './../../services/felixApi/task.service';
import { JobService } from './../../services/felixApi/job.service';
import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { JobDocument } from '../../dtos/job-document';
import { NotificationService } from '../../services/notification.service';
import { GlobalService } from '../../services/global.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { Task } from '../../dtos/task';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddDocumentsComponent } from '../add-documents/add-documents.component';
import { JobTaskDocument } from '../../dtos/job-task-document';
import { JobDocumentRecordTypeEnum } from '../../dtos/job-document-record-type.enum';
import { AttachmentTypeEnum } from '../../dtos/attachment-type.enum';
import { saveAs } from 'file-saver';
import { TemplateTask } from '../../dtos/templateTask';

@Component({
  selector: 'js-task-documents',
  templateUrl: './task-documents.component.html',
  styleUrls: ['./task-documents.component.scss']
})
export class TaskDocumentsComponent implements OnInit {
  @Input() jobTask: Task;
  @Input() useCache: boolean;

  subscriptions: Subscription[] = [];
  loadingDocs = true;
  jobDocuments: JobDocument[];
  selectedJobDocs: string[] = [];
  popupHeight: number;
  popupWidth: number;
  jobTaskDocuments: JobTaskDocument[];
  currentTemplateTask: TemplateTask;
  callUpTemplateDocs: string[] = [];

  constructor(
    private jobService: JobService,
    private notiService: NotificationService,
    private globalService: GlobalService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private maintenanceService: MaintenanceService,
    private taskService: TaskService,
    private documentService: DocumentService
  ) {
    this.calculateCallUpDocsType = this.calculateCallUpDocsType.bind(this);
    this.download = this.download.bind(this);
  }

  ngOnInit() {
    this.popupHeight = window.innerHeight < 575 ? window.innerHeight : 575;
    this.popupWidth = window.innerWidth < 650 ? window.innerWidth : 650;

    // we may be coming from the Tasks grid
    this.jobService.currentJob = this.jobService.jobs.find(i => i.id === this.jobTask.jobId);
    this.currentTemplateTask = this.maintenanceService.templateTasks?.find(i => i.templateTaskHeaderId === this.jobTask.templateTaskHeaderId
      && i.taskMasterId === this.jobTask.taskMasterId);

    this.getData();
  }

  getData() {
    if (this.jobTask.templateTaskHeaderId) {
      this.subscriptions.push(
        this.taskService.getTaskDocumentsData(this.useCache, this.jobTask.templateTaskHeaderId)
          .subscribe({
            next: () => {
              this.getJobTaskDocs();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loadingDocs = false;
            }
          })
      );
    } else {
      this.getJobTaskDocs();
    }
  }

  getJobTaskDocs() {
    this.subscriptions.push(
      this.jobService.getJobTaskDocuments(this.jobTask.jobId, this.jobTask.id)
        .subscribe({
          next: (res) => {
            this.jobTaskDocuments = res;
            this.getJobDocuments();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingDocs = false;
          }
        })
    );
  }

  getJobDocuments() {
    this.subscriptions.push(
      this.jobService.getJobDocuments(this.jobTask.jobId, this.useCache)
        .subscribe({
          next: () => {
            this.jobDocuments = this.jobService.jobDocuments;

            // add checked
            this.jobDocuments.forEach(jobDoc => {
              const foundRecord = this.jobTaskDocuments?.find(i => i.jobDocumentId === jobDoc.id);
              if (foundRecord) {
                this.selectedJobDocs.push(jobDoc.id.toString());
              } else {
                const foundSharePointRecord = this.jobTaskDocuments?.find(i => i.sharePointId === jobDoc.sharePointId);
                if (foundSharePointRecord) {
                  this.selectedJobDocs.push(jobDoc.sharePointId);
                } else if (jobDoc.recordTypeId === JobDocumentRecordTypeEnum.Detail) {
                  // maybe auto attached
                  if (this.calculateCallUpDocsType(jobDoc)) {
                    const callUpDocId = jobDoc.sharePointId === null || jobDoc.sharePointId === '' ? jobDoc.id.toString() : jobDoc.sharePointId;
                    this.selectedJobDocs.push(callUpDocId);
                    this.callUpTemplateDocs.push(callUpDocId);
                  }
                }
              }
            });

            this.loadingDocs = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.jobDocuments = [];
            this.loadingDocs = false;
          }
        })
    );
  }

  calculateCallUpDocsType(data: JobDocument) {
    if (data.recordTypeId === JobDocumentRecordTypeEnum.Detail &&
      data.callUpDocsTypeId && this.currentTemplateTask?.callUpDocsTypes && this.currentTemplateTask?.callUpDocsTypes.length) {
      const foundType = this.currentTemplateTask.callUpDocsTypes?.find(i => i === data.callUpDocsTypeId);

      if (foundType) {
        return true;
      }
    }
    return false;
  }

  attachmentSelectionChanged(e): void {
    // get all leaves as new list
    this.selectedJobDocs = e.component.getSelectedRowKeys('all').filter(this.globalService.onlyUnique);
  }

  close() {
    this.activeModal.dismiss();
  }

  saveAttachedDocs() {
    this.loadingDocs = true;
    let selectedDocs: string[] = [];

    this.selectedJobDocs.forEach(selectedJobDoc => {
      // exclude if a callUpTemplateDoc
      if (!this.callUpTemplateDocs.includes(selectedJobDoc)) {
        selectedDocs.push(selectedJobDoc);
      }
    });

    this.subscriptions.push(
      this.jobService.addJobTaskDocuments(this.jobTask.id, { keyIds: selectedDocs })
        .subscribe({
          next: () => {
            this.activeModal.close();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingDocs = false;
          }
        })
    );
  }

  uploadDocuments() {
    // use modal to add secondary POs that are sent with the Call-Up
    const modalRef = this.modalService.open(AddDocumentsComponent,
      { backdrop: 'static', keyboard: false, backdropClass: 'backdropClass' });

    modalRef.result.then(resultIds => {
      this.loadingDocs = true;
      this.jobDocuments = this.jobService.jobDocuments;
      this.selectedJobDocs = this.selectedJobDocs.concat(resultIds);
      setTimeout(() => {
        this.loadingDocs = false;
      }, 250);
    }, () => {
    });
  }

  isDownloadVisible = (e) => {
    return e.row.data.recordTypeId === JobDocumentRecordTypeEnum.Detail;
  }

  download(e) {
    if (!e.row.data.isSharePoint) {
      this.subscriptions = this.subscriptions.concat(
        this.documentService.getJobDocAttachment(e.row.data.id).subscribe({
          next: (res) => {
            this.saveFile(this.globalService.base64ToArrayBuffer(res.attachment, res.attachmentName), res.attachmentName, res.attachmentTypeId);
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    } else {
      this.subscriptions = this.subscriptions.concat(
        this.documentService.getSharePointDocument(this.jobService.currentJob?.jobNumber, e.row.data.sharePointId).subscribe({
          next: (res) => {
            this.saveFile(this.globalService.base64ToArrayBuffer(res.attachment, res.name), res.name, res.attachmentTypeId);
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  saveFile(blob: Blob, saveName: string, attachmentTypeId: number) {
    if (attachmentTypeId && !saveName.includes('.')) {
      saveName += '.' + AttachmentTypeEnum[attachmentTypeId];
    }
    saveAs(blob, saveName);
  }
}

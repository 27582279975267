import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { TaskType } from '../../dtos/task-type';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import { CompanyService } from '../../services/felixApi/company.service';
import { CompanyRole } from '../../dtos/companyRole';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HtmlEditorComponent } from '../../shared/html-editor/html-editor.component';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'js-task-masters',
  templateUrl: './task-masters.component.html',
  styleUrls: ['./task-masters.component.scss']
})
export class TaskMastersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() counter: number; // to force on changes

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  loading = true;
  dataSource: CustomStore;
  taskTypes: TaskType[];
  subscriptions: Subscription[] = [];
  popupVisible: boolean;
  fileValue: any[] = [];
  isConstructiveEnabled = false;
  companyRoles: CompanyRole[];
  emailUpdatesToClientsActive: boolean;
  editMode: string;
  isPackagesSystemActive: boolean;
  isTruthEngineClientPortalActive: boolean;

  constructor(
    private maintenanceService: MaintenanceService,
    private companyService: CompanyService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private notiService: NotificationService,
    private modalService: NgbModal
  ) {
    this.onReorder = this.onReorder.bind(this);
    this.calculateTaskTypeSortValue = this.calculateTaskTypeSortValue.bind(this);
    this.editHtmlText = this.editHtmlText.bind(this);
    this.changEditMode = this.changEditMode.bind(this);
  }

  ngOnInit() {
    this.editMode = 'row';
    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.ConstructiveIntegrationEnabled)) {
      this.isConstructiveEnabled = true;
    }
    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.TruthEngineClientPortalActive)) {
      this.isTruthEngineClientPortalActive = true;
    }
    this.emailUpdatesToClientsActive = this.globalService.getCompanyConfigValue(ConfigurationEnum.EmailUpdatesToClientsActive) === 1;
    this.isPackagesSystemActive = this.globalService.getCompanyConfigValue(ConfigurationEnum.PackageSystemActive) === 1;
  }

  ngOnChanges(): void {
    this.getTaskData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getTaskData() {
    this.subscriptions.push(
      this.maintenanceService.getTaskData().subscribe({
        next: (taskTypes) => {
          this.taskTypes = taskTypes;
          this.companyRoles = this.companyService.companyRoles;
          this.loading = false;
          this.setUpDataSource();
        }, error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: this.editMode === 'row' ? 'Batch Edit' : 'Exit',
          type: 'default',
          onClick: this.changEditMode.bind(this),
          matTooltip: 'Batch Edit'
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          width: 40,
          icon: 'refresh',
          onClick: this.refresh.bind(this),
          matTooltip: 'Refresh'
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          width: 40,
          icon: 'upload',
          onClick: this.loadFromExcel.bind(this)
        }
      });
  }

  changEditMode() {
    if (this.grid.instance.hasEditData() && this.editMode === 'batch') {
      this.notiService.showInfo('Please Save or Cancel the edited data');
    } else {
      if (this.editMode === 'row') {
        this.editMode = 'batch';
      } else {
        this.editMode = 'row';
      }
    }
  }

  onEditorPreparing(e) {
    if (e.dataField === 'defaultOfficeComment'
      || e.dataField === 'defaultVendorComment'
      || e.dataField === 'clientDescription') {
      e.editorName = 'dxTextArea';
    }
    if (e.dataField === 'taskTypeId') {
      e.editorOptions.dropDownOptions = { minWidth: 350 };
    }
    if (e.dataField === 'emailToRoleIdOnComplete') {
      e.editorOptions.dropDownOptions = { minWidth: 220 };
    }
    if (e.dataType === 'boolean') {
      e.editorOptions.dropDownOptions = { minWidth: 70 };
    }
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.getTaskMasters(false).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.addTaskMaster(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.updateTaskMaster(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.deleteTaskMaster(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();
    if (e.itemData.taskTypeId === visibleRows[e.toIndex].data.taskTypeId) {
      const newOrderIndex = visibleRows[e.toIndex].data.orderNumber;

      this.subscriptions.push(
        this.maintenanceService.updateTaskMaster(e.itemData.id, { orderNumber: newOrderIndex }).subscribe({
          next: () => {
            this.maintenanceService.taskMasters = [];
            e.component.refresh();
          }, error: () => {
            this.notiService.showInfo('Cannot drop here');
          }
        })
      );
    } else {
      this.notiService.showInfo('Cannot drop here');
    }
  }

  loadFromExcel() {
    this.popupVisible = true;
  }

  uploadClick() {
    // load the file
    if (this.fileValue && this.fileValue.length) {
      this.loading = true;
      this.popupVisible = false;
      const formData: FormData = new FormData();
      formData.append('image', this.fileValue[0], this.fileValue[0].name);

      this.subscriptions.push(
        this.maintenanceService.uploadTaskMasterExcel(formData).subscribe({
          next: () => {
            this.fileValue = [];
            this.refresh();
          }, error: (err) => {
            this.notiService.notify(err);
            this.fileValue = [];
            this.loading = false;
          }
        })
      );
    }
  }

  refresh() {
    this.loading = true;
    this.maintenanceService.taskMasters = [];

    // reread data
    this.getTaskData();
  }

  calculateTaskTypeSortValue(data) {
    return this.taskTypes.find(i => i.id === data.taskTypeId)?.orderNumber;
  }

  onInitNewRow(e) {
    e.data.isActive = true;
    e.data.isLockOrders = false;
    e.data.isQuoteRequest = false;
    e.data.isKeyTask = false;
    e.data.isContractSigning = false;
    e.data.isSentToConstructive = false;
    e.data.isEmailAssigneeOnStart = false;
    e.data.isHiddenFromCallUps = false;
    e.data.isTitlesTask = false;
  }

  editHtmlText(e: any, cellName) {
    const modalRef = this.modalService.open(HtmlEditorComponent);
    modalRef.componentInstance.modalTitle = cellName === 'clientDescription' ? 'Client description' : 'Email on complete';
    modalRef.componentInstance.htmlText = e.data[cellName] ?? e.data.taskTitle;
    modalRef.componentInstance.allowNonHtml = this.isPackagesSystemActive;

    modalRef.result.then((updatedText: string) => {
      // fix html for new lines
      let htmlText = updatedText.replace(new RegExp('<p', 'g'), '<div').replace(new RegExp('p>', 'g'), 'div>');

      if (htmlText.startsWith('<div') && htmlText.endsWith('div>')) {
        // replace with span so we can be on the same line
        htmlText = '<span' + htmlText.substring(4, htmlText.length - 4) + 'span>';
      }

      const rowIndex = this.grid.instance.getRowIndexByKey(e.data.id);
      this.grid.instance.cellValue(rowIndex, cellName, htmlText);
      this.grid.instance.saveEditData();
    }, () => {
    });
  }

  calculateFilterExpression(filterValue, selectedFilterOperation, target) {
    if (target === 'search' && typeof (filterValue) === 'string') {
      return [(this as any).dataField, 'contains', filterValue];
    }
    return function (data) {
      return (data.AssignedEmployee || []).indexOf(filterValue) !== -1;
    };
  }

  cellTemplate(container, options) {
    const noBreakSpace = '\u00A0',
      text = (options.value || []).map(element => {
        return options.column.lookup.calculateCellValue(element);
      }).join(', ');
    container.textContent = text || noBreakSpace;
    container.title = text;
  }
  //filter for email roles on complete
  calculateRoleFilterExpression(filterValue: any, selectedFilterOperation: any) {
    return function (i) {
      return i.roleIds && i.roleIds.includes(filterValue);
    };
  }

}

export class ManualOrderLine {
  id: number;
  priceFileItemId: number;
  description: string;
  unitOfMeasure: string;
  quantity: number;
  rate: number;
  productCode: string;
  lineTotal: number;
  ccToSelf: boolean;
  quantityString: string;
  measuredQuantity: number;
  supplierQuantity: number;
}

export class TaskDependency {
    public id: number;
    public workFlowVersionNo: number;
    public taskPredecessorId: number;
    public taskSuccessorId: number;
    public dependencyTypeId: number;
    public offsetDays: number;
    public dependencyQuestion: string;
    public cannotSetStartIfPredecessorNotComplete: boolean;
    public modifiedUserId: number;
    public modifiedDate: Date;

    public dependencyText: string;

    constructor(id: number, taskPredecessorId: number, taskSuccessorId: number) {
        this.id = id;
        this.workFlowVersionNo = 0;
        this.taskPredecessorId = taskPredecessorId;
        this.taskSuccessorId = taskSuccessorId;
        this.dependencyTypeId = 0;
        this.offsetDays = 0;
        this.dependencyQuestion = '';
        this.cannotSetStartIfPredecessorNotComplete = false;
        this.modifiedUserId = 0;
        this.modifiedDate = new Date();
    }
}

import { UserService } from './../../services/felixApi/user.service';
import { GlobalService } from './../../services/global.service';
import { NotificationService } from './../../services/notification.service';
import { JobService } from './../../services/felixApi/job.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { JobRole } from '../../dtos/job-role';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-job-roles',
  templateUrl: './job-roles.component.html',
  styleUrls: ['./job-roles.component.scss']
})
export class JobRolesComponent implements OnInit {
  @Input() jobNumber: string;

  subscriptions: Subscription[] = [];
  loading = true;
  jobRoles: CustomStore<JobRole, any>;
  gridHeight: number;
  clientEmailString: string;

  constructor(
    private _activeModal: NgbActiveModal,
    private _jobService: JobService,
    private notiService: NotificationService,
    private _globalService: GlobalService,
    private userService: UserService
  ) {
    this.calculateUser = this.calculateUser.bind(this);
    this.calculateEmailAddress = this.calculateEmailAddress.bind(this);
    this.getEmailString = this.getEmailString.bind(this);
   }

  ngOnInit() {
    this.gridHeight = this._globalService.innerHeight - 200;
    this.gridHeight = this.gridHeight > 800 ? 800 : this.gridHeight;

    this.getJobRoles();
    this.clientEmailString = this.getClientEmailString();
  }

  getJobRoles() {
    this.subscriptions = this.subscriptions.concat(
      this._jobService.getJobRoles(this.jobNumber)
        .subscribe({
          next: (jobRoles) => {
            this.jobRoles = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => jobRoles
            });
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }})
    );
  }

  close() {
    this._activeModal.close();
  }

  calculateUser(data) {
    return this.userService.users.find(i => i.id === data.userId)?.fullName;
  }

  calculateEmailAddress(data) {
    return this.userService.users.find(i => i.id === data.userId)?.email;
  }

  getEmailString(emailAddress) {
    if (emailAddress) {
      return 'mailto:' + emailAddress
        + '?subject=Job ' + this.jobNumber + ' - ' + this._globalService.getJobString(this._jobService.currentJob);
    } else {
      return null;
    }
  }

  getClientEmailString() {
    if (this._jobService.currentJob?.clientEmail) {
      return 'mailto:' + this._jobService.currentJob.clientEmail.replace(' ', ';').replace('\n', ';')
        + '?subject=Job ' + this.jobNumber + ' - ' + this._globalService.getJobString(this._jobService.currentJob);
    } else {
      return null;
    }
  }
}

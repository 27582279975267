<div class="page max-w-1500 m-auto">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" id="dataGrid" class="maintenancegrid-height" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)" (onInitNewRow)="onInitNewRow($event)"
    (onExporting)="gridService.onExporting($event, 'TrackingFields')" (onEditorPreparing)="onEditorPreparing($event)">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"> <dxo-search [enabled]="true"></dxo-search> </dxo-header-filter>

    <!-- refresh mode full so we can get the data if a user goes to the modal -->
    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="full">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-row-dragging [allowReordering]="true" [onReorder]="onReorder"></dxo-row-dragging>
    <dxo-sorting mode="none"></dxo-sorting>


    <dxi-column dataField="id" dataType="number" [visible]='false'> </dxi-column>

    <dxi-column dataField="trackingFieldGroupId" caption="Section" dataType="number" [width]='200'>
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="trackingFieldGroups" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="trackingFieldTypeId" caption="Type" dataType="number" [width]='125'>
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="trackingFieldTypes" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="fieldName" dataType="string" cellTemplate="fieldNameCellTemplate" minWidth="150">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="trackingCalculationFieldId" caption="Calculate Using" dataType="number" [width]="170"
      editCellTemplate="editTrackingCalculationFieldTemplate">
      <dxo-lookup [dataSource]="trackingCalculationFields" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="calculationDaysToAdd" caption="Calendar days to add" dataType="number" [width]="110"
      editCellTemplate="editCalculationDaysToAddTemplate"> </dxi-column>

    <dxi-column dataField="isCalculationDaysWorkingDays" caption="Working Days" dataType="boolean" [width]="105">
    </dxi-column>

    <dxi-column caption="Edit List" [width]="80" cellTemplate="fileTemplate" alignment="center"></dxi-column>
    <div *dxTemplate="let data of 'fileTemplate'" class="text-center">
      <dx-button icon="edit" (onClick)="editList(data)" class="bootstrap-blue"></dx-button>
    </div>

    <dxi-column *ngIf="isConstructiveEnabled" dataField="sendToConstructive" dataType='boolean' [width]='130'>
    </dxi-column>

    <dxi-column dataField="isActive" caption="Active" dataType='boolean' [width]='90'>
    </dxi-column>

    <div *dxTemplate="let cellInfo of 'editTrackingCalculationFieldTemplate'">
      <div>
        <dx-select-box [dataSource]="trackingCalculationFields" valueExpr="id" displayExpr="description"
          [(value)]="cellInfo.value" (onValueChanged)="setEditedQtyValue($event, cellInfo)"
          [disabled]="isCalculatedDaysEditable(cellInfo)">
        </dx-select-box>
      </div>
    </div>

    <div *dxTemplate="let cellInfo of 'fieldNameCellTemplate'">
      <div id="fieldName" class='mx-0 px-0'>{{cellInfo.value}}</div>
    </div>

    <div *dxTemplate="let cellInfo of 'editCalculationDaysToAddTemplate'">
      <div>
        <dx-number-box [(value)]="cellInfo.value" (onValueChanged)="setEditedQtyValue($event, cellInfo)"
          [disabled]="isCalculatedDaysEditable(cellInfo)">
        </dx-number-box>
      </div>
    </div>

  </dx-data-grid>

</div>

<div class="modal-header">
  <h4 class="modal-title">{{trackingFieldName}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <div class="page">

    <dx-data-grid #trackingLookupGrid id="trackingLookupGrid" class="m-auto taskgrid-height" [dataSource]="dataSource"
      [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true"
      [wordWrapEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)" (onInitNewRow)="onInitNewRow($event)">

      <dxo-scrolling mode="standard"></dxo-scrolling>
      <!-- <dxo-export [enabled]="true"></dxo-export> -->
      <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
      <dxo-editing mode="batch" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
        refreshMode="reshape">
      </dxo-editing>
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-row-dragging [allowReordering]="true" [onReorder]="onReorder"></dxo-row-dragging>
      <dxo-sorting mode="none"></dxo-sorting>


      <dxi-column dataField="id" dataType="number" [visible]='false'>
      </dxi-column>

      <dxi-column dataField="description" cellTemplate="noWrapTemplate">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="templateTaskHeaderId" caption="WorkFlow to trigger" dataType="number" width='250'>
        <dxo-lookup [dataSource]="templateTaskHeaders" displayExpr="description" valueExpr="id"
          [allowClearing]="true"></dxo-lookup>
      </dxi-column>

      <div *dxTemplate="let cell of 'noWrapTemplate'">
        <div style="white-space: nowrap; overflow: hidden;">{{cell.text}}</div>
      </div>

    </dx-data-grid>

  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" (click)="close()">Close</button>
</div>

import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateDaysModalComponent } from './template-days-modal/template-days-modal.component';
import { TemplateTaskHeader } from '../../dtos/templateTaskHeader';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-template-days-headers',
  templateUrl: './template-days-headers.component.html',
  styleUrls: ['./template-days-headers.component.scss']
})
export class TemplateDaysHeadersComponent implements OnChanges, OnDestroy {
  @Input() counter: number; // to force on changes

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  loading = true;
  taskHeaders: TemplateTaskHeader[];

  constructor(
    public maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private modalService: NgbModal,
    private notiService: NotificationService
  ) { }

  ngOnChanges(): void {
    this.getTemplateHeaders();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getTemplateHeaders() {
    this.subscriptions.push(
      this.maintenanceService.getTemplateTaskHeaders(true, true)
        .subscribe({
          next: (taskHeaders) => {
            this.taskHeaders = taskHeaders.filter(i => i.isActive);
            this.loading = false;
            this.setUpDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.getTemplateDaysHeaders(false).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.addTemplateDaysHeader(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.updateTemplateDaysHeader(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.deleteTemplateDaysHeader(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  addEditDays() {
    const modalRef = this.modalService.open(TemplateDaysModalComponent,
      { windowClass: 'modal-1400', scrollable: true });

    modalRef.result.then(() => {
    }, () => {
    });
  }

  onInitNewRow(e) {
    e.data.isActive = true;
  }
}

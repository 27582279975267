<dx-popup *ngIf="!loading" class="popup" [width]="popupWidth" [height]="popupHeight" [showTitle]="true" title="Email/Note"
  [dragEnabled]="true" [hideOnOutsideClick]="true" [visible]="true" [shading]="false" (onHidden)="close()" [showCloseButton]="true">
  <div *dxTemplate="let data of 'content'">
    <dx-form id="form" [(formData)]="jobEmail" [readOnly]="true" [showColonAfterLabel]="true" validationGroup="formData"
      labelLocation="left" [colCount]="colCount">

      <dxi-item dataField="date" editorType="dxDateBox" [colSpan]="1">
        <dxo-label location="left" alignment="right" text="Dated">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="createDate" editorType="dxDateBox" [colSpan]="1">
        <dxo-label location="left" alignment="right" text="Saved">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="subject" editorType="dxTextBox" [colSpan]="colCount">
        <dxo-label location="left" alignment="right" text="Subject">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="bodyAsText" editorType="dxTextArea" [colSpan]="colCount"
        [editorOptions]="{ height: bodyHeight }">
        <dxo-label location="left" alignment="right" text="Note">
        </dxo-label>
      </dxi-item>
    </dx-form>
  </div>
</dx-popup>

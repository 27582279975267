<div class="modal-header">
  <h4 class="modal-title">Cancel Job</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <div class="page">

    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <div *ngIf="!loading" class="page max-w-800 m-auto">

      <dx-form id="form" [(formData)]="jobExtraForm" [readOnly]="false" [showColonAfterLabel]="true"
        validationGroup="formData" labelLocation="left" [minColWidth]="500" [colCount]="1" [width]="800">

        <dxi-item dataField="cancellationDate" editorType="dxDateBox" [editorOptions]="{ showClearButton: true, width: 180 }">
          <dxo-label location="left" alignment="right"> </dxo-label>
        </dxi-item>

        <dxi-item dataField="cancellationReason" [editorOptions]="{ required: true }" editorType="dxTextArea"
          [visible]="jobExtraForm.cancellationDate !== null">
          <dxo-label location="left" alignment="right" text="Reason"></dxo-label>
          <dxi-validation-rule type="required" message="Reason is required"> </dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="modifiedUser" [editorOptions]="{ disabled: true }" editorType="dxTextBox">
          <dxo-label location="left" alignment="right" text="Last modified by"></dxo-label>
        </dxi-item>

        <dxi-item dataField="modifiedDate" [editorOptions]="{ width: '120px', disabled: true }" editorType="dxDateBox">
          <dxo-label location="left" alignment="right" text="Last modified"></dxo-label>
        </dxi-item>

        <dxi-item itemType="button" horizontalAlignment="right" [buttonOptions]="updateButtonOptions">
        </dxi-item>
      </dx-form>
    </div>

    <div *ngIf="jobExtraForm.cancellationDate !== null">NOTE: Cancelling a job will also mark it inactive in Truth
      Engine.</div>


  </div>
</div>

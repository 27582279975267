<div class='mx-2 mt-2'>
  <div class="w400">
    <div class="dx-field-label">Workflow Template</div>
    <div class="dx-field-value">
      <dx-select-box [dataSource]="taskHeaders" displayExpr="description" valueExpr="id" [(value)]='selectedTaskHeader'
        (onSelectionChanged)='getData($event)'>
      </dx-select-box>
    </div>
  </div>

  <dx-button *ngIf="taskHeaderId" class='ms-1 mt-0' id="button" icon="refresh" type="default" (onClick)="refresh()">
  </dx-button>

  <div *ngIf="taskHeaderId" class="timeScaleDiv ms-3">
    <div class="dx-field-label text-right">Time Scale</div>
    <div class="dx-field-value">
      <dx-select-box [items]="['auto', 'days', 'weeks', 'months', 'quarters']" [(value)]="timeScale"></dx-select-box>
    </div>
  </div>

  <div *ngIf="taskHeaderId" class="groupByTypesDiv ms-3">
    <div class="dx-field-label text-right groupByTypesLabel">Group by Types</div>
    <div class="dx-field-value groupByTypesBox">
      <dx-check-box [(value)]="groupByTypes" (onValueChanged)="getData(null)"></dx-check-box>
    </div>
  </div>

  <div *ngIf="workflowWeeks && workflowMessageWidth" class="ms-5 inlineBlockClass"
    [style.width.px]="workflowMessageWidth">
    Total calendar time: {{workflowWeeks}} weeks {{workflowDays}} days
    <span *ngIf="completionTask !== ''">to completion of: {{completionTask}}</span>
  </div>
</div>

<js-loading-spinner *ngIf="loading || loadingTasks"></js-loading-spinner>

<div *ngIf="taskDataLoaded" class="mt-1 mx-2">

  <dx-tabs [items]="tabs" [(selectedIndex)]="selectedIndex"> </dx-tabs>

  <div *ngIf="selectedIndex === 0">
    <dx-gantt [taskListWidth]="titleColumnWidth" [scaleType]="timeScale" taskTitlePosition="outside"
      [height]="gridHeight" (onTaskEditDialogShowing)="onTaskEditDialogShowing($event)"
      (onDependencyDeleting)="onDependencyDeleting($event)" (onDependencyInserting)="onDependencyInserting($event)"
      taskTooltipContentTemplate="taskTooltipTemplate">

      <dxo-context-menu [items]="contextMenuItems"></dxo-context-menu>
      <dxo-tasks [dataSource]="tasks" keyExpr="id" parentIdExpr="parentTaskId" titleExpr="taskTitle" startExpr="start"
        endExpr="end"></dxo-tasks>
      <dxo-dependencies [dataSource]="dependencies" keyExpr="id" predecessorIdExpr="taskPredecessorId"
        successorIdExpr="taskSuccessorId" typeExpr="dependencyTypeId"></dxo-dependencies>

      <dxi-column dataField="taskTitle" caption="Task Title" [minWidth]="300"></dxi-column>
      <dxi-column dataField="start" caption="Start Date" dataType="date" [width]="95"></dxi-column>
      <dxi-column dataField="end" caption="End Date" dataType="date" [width]="95"></dxi-column>
      <dxi-column dataField="days" caption="Days" dataType="number" [width]="65"></dxi-column>
      <dxi-column dataField="leadDays" caption="Lead Days" dataType="number" [width]="95"></dxi-column>

      <dxo-validation [autoUpdateParentTasks]="groupByTypes"></dxo-validation>
      <dxo-editing enabled="true"></dxo-editing>
      <dxo-header-filter [visible]="true" [search]="{enabled: true}"></dxo-header-filter>

      <dxo-toolbar>
        <dxi-item name="zoomIn"></dxi-item>
        <dxi-item name="zoomOut"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item widget="dxButton" [options]="showTitleColumnsOptions"> </dxi-item>
        <dxi-item widget="dxButton" [options]="exportButtonOptions"> </dxi-item>
      </dxo-toolbar>
      <div *dxTemplate="let task of 'taskTooltipTemplate'" class="custom-task-edit-tooltip">
      </div>
    </dx-gantt>

    <dx-button class='mt-1' id="button" text="Add/Edit Tasks" type="default" (onClick)="addEditTasks()">
    </dx-button>

    <dx-button class='mt-1 ms-2' id="button" text="Copy Template" type="default" stylingMode="outlined"
      (onClick)="copyTasks()">
    </dx-button>

    <dx-button *ngIf="canUpdateJobs" class='mt-1 ms-2' id="button" text="Update Jobs" type="default"
      stylingMode="outlined" (onClick)="updateJobs()">
    </dx-button>

    <span class="ms-4">Note: To change a dependency you must delete and re-add it.
      Moving tasks around does not save so moving tasks can help see the dependencies.</span>
  </div>

  <div *ngIf="selectedIndex === 1">
    <dx-diagram #diagram id="diagram" [height]="gridHeight" [readOnly]="false" units="cm"
      [pageSize]='{width: 29.7, height: 42, items: [{ text: "A4 (21cm x 29.7cm)", width: 21, height: 29.7 },{ text: "A3 (29.7cm x 42cm)", width: 29.7, height: 42 }]}'>
      <dxo-nodes [dataSource]="flowNodesDataSource" keyExpr="id" textExpr="taskTitle"
        [textStyleExpr]="itemTextStyleExpr">
        <dxo-auto-layout type="layered"></dxo-auto-layout>
      </dxo-nodes>
      <dxo-edges [dataSource]="flowEdgesDataSource" keyExpr="id" fromExpr="taskPredecessorId" toExpr="taskSuccessorId"
        textExpr="dependencyText">
      </dxo-edges>

      <dxo-context-menu [enabled]="false"> </dxo-context-menu>
      <dxo-context-toolbox [enabled]="false"> </dxo-context-toolbox>
      <dxo-history-toolbar [visible]="false"> </dxo-history-toolbar>
      <dxo-view-toolbar [visible]="false"> </dxo-view-toolbar>
      <dxo-main-toolbar [visible]="false"> </dxo-main-toolbar>
      <dxo-toolbox visibility="disabled"> </dxo-toolbox>
      <dxo-view-toolbar [visible]="true"> </dxo-view-toolbar>
      <dxo-properties-panel visibility="invisible">
        <dxi-tab>
          <dxi-group title="Page Properties" [commands]='["pageSize","pageOrientation"]'></dxi-group>
        </dxi-tab>
      </dxo-properties-panel>
    </dx-diagram>

    <div class="mt-2">Note: Changes in the Diagram are not saved and can be used to improve the view for printing.</div>
  </div>
</div>

<!-- print pop-up -->
<dx-popup class="popup" [width]="850" [height]="390" [showTitle]="true" title="PDF Settings" [dragEnabled]="true"
  [hideOnOutsideClick]="true" [(visible)]="printSettingsPopupVisible" [shading]="false">
  <div *dxTemplate="let data of 'content'">
    <div class="options">
      <div class="column">
        <div class="caption">Export Options</div>
        <div class="option">
          <div class="label">Document format:</div>
          <div class="value">
            <dx-select-box id="formatBox" [items]="formats" [(value)]="formatBoxValue">
            </dx-select-box>
          </div>
        </div>
        <div class="option">
          <div class="label">Landscape orientation:</div>
          <div class="value">
            <dx-check-box [(value)]="landscapeCheckBoxValue"></dx-check-box>
          </div>
        </div>
        <div class="option">
          <div class="label">Export mode:</div>
          <div class="value">
            <dx-select-box [items]="exportModes" [(value)]="exportModeBoxValue">
            </dx-select-box>
          </div>
        </div>
        <div class="option">
          <div class="label">Date range:</div>
          <div class="value">
            <dx-select-box [items]="dateRanges" [(value)]="dateRangeBoxValue"
              (onValueChanged)="onDateRangeBoxSelectionChanged($event)">
            </dx-select-box>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="caption">Task Filter Options</div>
        <div class="option">
          <div class="label">Start task (index):</div>
          <div class="value">
            <dx-number-box [disabled]="customRangeDisabled" [(value)]="startTaskIndex" [min]="0" [max]="endTaskIndex"
              [showSpinButtons]="true">
            </dx-number-box>
          </div>
        </div>
        <div class="option">
          <div class="label">End task (index):</div>
          <div class="value">
            <dx-number-box [disabled]="customRangeDisabled" [(value)]="endTaskIndex" [min]="startTaskIndex"
              [max]="tasks.length - 1" [showSpinButtons]="true">
            </dx-number-box>
          </div>
        </div>
        <div class="option">
          <div class="label">Start date:</div>
          <div class="value">
            <dx-date-box [disabled]="customRangeDisabled" [(value)]="startDate" [max]="endDate" type="date">
            </dx-date-box>
          </div>
        </div>
        <div class="option">
          <div class="label">End date:</div>
          <div class="value">
            <dx-date-box [disabled]="customRangeDisabled" [(value)]="endDate" [min]="startDate" type="date">
            </dx-date-box>
          </div>
        </div>
      </div>
    </div>

    <dx-button text="Generate PDF" class="mt-1" type="default" (onClick)="exportButtonClick()">
    </dx-button>

  </div>
</dx-popup>

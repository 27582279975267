<div class="modal-header">
  <h2 class="modal-title">Warning</h2>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div>{{message}}</div>
  <br>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-primary" (click)="accept()">{{acceptMessage}}</button>
</div>

import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import CustomStore from 'devextreme/data/custom_store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VariationWorkflowsComponent } from '../variation-workflows/variation-workflows.component';

@Component({
  selector: 'js-template-task-headers',
  templateUrl: './template-task-headers.component.html',
  styleUrls: ['./template-task-headers.component.scss']
})
export class TemplateTaskHeadersComponent implements OnInit {

  dataSource: CustomStore;

  trackingWorkFlowTypes = [
    { id: 1, description: 'Pre-Construction' },
    { id: 2, description: 'Construction' },
    { id: 4, description: 'Sales' },
    { id: 5, description: 'Maintenance' },
    { id: 3, description: 'Other' },
  ];

  constructor(
    private maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    private modalService: NgbModal,
    protected gridService: GridService
  ) { }

  ngOnInit(): void {
    this.setUpDataSource();
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.getTemplateTaskHeaders(false, false).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.addTemplateTaskHeader(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.updateTemplateTaskHeader(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.deleteTemplateTaskHeader(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          stylingMode: 'default',
          type: 'default',
          text: 'Edit workflow triggers',
          onClick: this.editVariationworkflows.bind(this)
        }
      });
  }

  onInitNewRow(e) {
    e.data.isActive = true;
  }

  editVariationworkflows() {
    const modalRef = this.modalService.open(VariationWorkflowsComponent, { windowClass: 'modal-1500' });
    modalRef.result.then(() => { });
  }
}

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Job } from '../../dtos/job';
import { JobWorkFlowService } from '../../services/felixApi/job-work-flow.service';
import { JobService } from '../../services/felixApi/job.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { TaskService } from '../../services/felixApi/task.service';
import { UserService } from '../../services/felixApi/user.service';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { NotificationService } from '../../services/notification.service';
import { TaskMaster } from '../../dtos/task-master';
import { Task } from '../../dtos/task';
import { Vendor } from '../../dtos/vendor';

@Component({
  selector: 'js-vendor-summaries',
  templateUrl: './vendor-summaries.component.html',
  styleUrls: ['./vendor-summaries.component.scss']
})
export class VendorSummariesComponent implements OnInit, OnDestroy {
  @Input() jobId: number;
  @Input() selectedTaskHeaderId: number;

  loading = false;
  subscriptions: Subscription[] = [];
  gridHeight: number;
  sendGridHeight: number;
  jobs: Job[];
  taskMasters: TaskMaster[];
  vendors: Vendor[];
  callUpTasks: Task[];
  ccToSelf = true;
  selectedVendorId: number;
  emailAddress: string;
  subjectLine = 'Summary of Call-Ups';

  constructor(
    private notiService: NotificationService,
    private activeModal: NgbActiveModal,
    private globalService: GlobalService,
    protected jobWorkFlowService: JobWorkFlowService,
    private taskService: TaskService,
    protected gridService: GridService,
    private jobService: JobService,
    private maintenanceService: MaintenanceService,
    private userService: UserService) {
    this.calculateJobAddress = this.calculateJobAddress.bind(this);
    this.onVendorSelected = this.onVendorSelected.bind(this);
  }


  ngOnInit(): void {
    this.setJobStringMaxWidth();
    this.subscribeToInnerHeightWidth();

    this.jobs = this.jobService.jobs;
    this.taskMasters = this.maintenanceService.taskMasters;
    this.vendors = this.userService.vendors;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  close() {
    this.activeModal.dismiss();
  }

  subscribeToInnerHeightWidth() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          setTimeout(() => {
            this.setJobStringMaxWidth();
          }, 10); // wait for iPhone and grid
        }
      )
    );
  }

  setJobStringMaxWidth() {
    this.gridHeight = window.innerHeight - 100; // for menu and job row
    this.sendGridHeight = this.gridHeight - 115;
    // this.callUpWidth = window.innerWidth > 1200 ? 1200 : window.innerWidth;
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxSelectBox',
        options: {
          width: 350,
          placeholder: 'Select Vendor...',
          items: this.vendors,
          displayExpr: 'vendorName',
          // displayExpr: function (item) {
          //   if (!item) {
          //     return item;
          //   }
          //   return item && item.vendorName + ': ' + (item.callUpEmail ? item.callUpEmail : item.email ? item.email : '');
          // },
          valueExpr: 'id',
          showClearButton: true,
          searchEnabled: true,
          onValueChanged: this.onVendorSelected.bind(this),
          value: this.selectedVendorId
        }
      });
  }

  onVendorSelected(ea) {
    this.selectedVendorId = ea.value;
    const vendor = this.vendors.find(i => i.id === ea.value);
    this.emailAddress = vendor?.callUpEmail ? vendor.callUpEmail : vendor?.email ? vendor.email : '';
    this.getTasks();
  }

  // ccToSelfChanged(e) {
  //   this.ccToSelf = e.value;
  // }

  getTasks() {
    // get tasks and set up grid
    if (this.selectedVendorId) {
      this.subscriptions.push(
        this.taskService.getJobTasksForVendorSummary(this.jobId, this.selectedTaskHeaderId, this.selectedVendorId)
          .subscribe({
            next: (tasks) => {
              this.callUpTasks = tasks;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    } else {
      this.callUpTasks = [];
    }
  }

  sendSummary() {
    // send
    const bodyInfo = {
      emailAddress: this.emailAddress,
      ccToSelf: this.ccToSelf,
      subjectLine: this.subjectLine
    };
  }

  calculateJobAddress(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobAddressString;
  }
}
